import { InputLabel } from "@mui/material";
import { styled } from "@mui/styles";
import * as React from "react";

export const StyledInputLabel = styled(InputLabel)({
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#9b9b9b",
});
