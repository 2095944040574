export const Colors = {
    MAIN: "#2693A6",
    TEXT: "#9B9B9B",
    TEXT_BOLD: "#121212",
    HEADINGS_DARK: "#121212",
    WHITE: "#FFFFFF",
    TEXT_LIGHT: "#4F4F4F",
    BACKGROUND: "#F8F8F8",
    TABLE_BORDER: "#E0E0E0",
    BORDER_LIGHT: "#EDEDED",
    LIGHT_BACKGROUND: "#FCFCFC",
    DISABLED: "#8A8A8A",
    ERROR: "#E25141",
    VALUE_DISPLAY_RED: "#ff0a0aE0",
    VALUE_DISPLAY_ORANGE: "#FF620A",
    VALUE_DISPLAY_GREEN: "#00B712",
    GREY_200: "#E7E7E7",
};
