import { Field } from "formik";
import * as React from "react";
import { CustomTextField } from "../ui/CustomTextField";

type IProps = {
    disabled?: boolean;
};

export const PatientStatus = ({ disabled }: IProps) => (
    <Field name="textInput" component={CustomTextField} minRows={3} disabled={disabled} />
);
