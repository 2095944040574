import { observer } from "mobx-react";
import * as React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { generalStore } from "../../../stores/GeneralStore";
import { ForgotPasswordSite } from "../../auth/sites/ForgotPasswordSite";
import { LoginSite } from "../../auth/sites/LoginSite";
import { ResetPasswordSite } from "../../auth/sites/ResetPasswordSite";
import { NavigationBar } from "../../shared/NavigationBar";
import { NotFoundSite } from "../../shared/NotFoundSite";
import { ErrorToast } from "../../ui/ErrorToast";
import { LoadingOverlay } from "../../ui/LoadingOverlay";
import { CheckupReportSite } from "../../users/sites/CheckupReportSite";
import { AuthorizedRouter } from "./AuthorizedRouter";
import { history } from "./history";
import { NoAuthOnlyRoute } from "./NoAuthOnlyRoute";
import { PrivateRoute } from "./PrivateRoute";
import { Routes } from "./Routes";
import { RoutingManager } from "./RoutingManager";

export const AppRouter = observer(() => (
    <>
        <Router history={history}>
            <RoutingManager>
                <Route exact path={Routes.CHECKUP_REPORT}>
                    <NavigationBar />
                </Route>
                <Switch>
                    <NoAuthOnlyRoute exact path={Routes.ROOT}>
                        <LoginSite />
                    </NoAuthOnlyRoute>
                    {/* <NoAuthOnlyRoute exact path={Routes.AUTH.FORGOT_PASSWORD}>
                        <ForgotPasswordSite />
                    </NoAuthOnlyRoute> */}
                    {/* <NoAuthOnlyRoute exact path={Routes.AUTH.RESET_PASSWORD}>
                        <ResetPasswordSite />
                    </NoAuthOnlyRoute> */}
                    <PrivateRoute exact path={Routes.CHECKUP_REPORT}>
                        <CheckupReportSite />
                    </PrivateRoute>
                    <PrivateRoute>
                        <AuthorizedRouter />
                    </PrivateRoute>
                    <Route>
                        <NotFoundSite />
                    </Route>
                </Switch>
            </RoutingManager>
        </Router>
        {generalStore.isLoading && <LoadingOverlay />}
        <ErrorToast />
    </>
));
