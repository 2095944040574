import { Divider, IconButton, Link, Menu, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { compact } from "lodash";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as ChevronRight } from "../../../assets/icons/ic_chevron_right.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/ic_file.svg";
import { ReactComponent as GraphIcon } from "../../../assets/icons/ic_graph.svg";
import { ReactComponent as UserProfileIcon } from "../../../assets/icons/ic_user_profile.svg";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { CheckupReport, CheckupReportListItem, PatientCheckupReportList } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { patientStore } from "../../../stores/PatientStore";
import { ILinkItem } from "../../../types";
import { history } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { Colors } from "../../util/Colors";
import { formatDate } from "../../util/helpers";
import { ImageLogo } from "../../util/Images";
import privacyPolicyPdf from "../../../assets/documents/Datenschutzbestimmungen.pdf";

const WelcomeSiteContainer = styled("div")({
    margin: "0 24px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
});

const SiteContainer = styled("div")({
    borderRadius: 20,
    background: "linear-gradient(142.49deg, #4CA1D0 -16.17%, #4CD061 124.06%)",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
    padding: "56px 0",
});

const Topbar = styled("div")({
    height: 80,
    display: "flex",
    justifyContent: "space-between",
});

const BottomBar = styled("div")({
    height: 56,
    display: "flex",
    alignItems: "center",
});

const SegmentContainer = styled("div")({
    borderRadius: 12,
    backgroundColor: Colors.WHITE,
    minWidth: 636,
    fontWeight: 400,
});

const WelcomeTitle = styled("h3")({
    padding: 16,
    height: 70,
    display: "flex",
    alignItems: "center",
});

const CheckupInfo = styled("div")({
    display: "flex",
    padding: 16,
    height: 70,
    alignItems: "center",
});

const InfoContainer = styled("div")({
    width: "50%",
});

const InfoTitle = styled("div")({
    fontSize: 12,
    fontWeight: 400,
    color: Colors.DISABLED,
});

const HistoryContainer = styled("div")({
    height: 48,
    display: "flex",
    alignItems: "center",
    padding: "14px 16px",
});

const CheckupReportTitle = styled("div")({
    height: 48,
    display: "flex",
    alignItems: "center",
    padding: "14px 16px",
    fontWeight: 500,
});

const CheckupContainer = styled("div")({
    height: 48,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 16px",
    cursor: "pointer",
});

const links: ILinkItem[] = [
    {
        label: "screen.welcome.links.about",
        url: "https://www.vorsorgeinstitut.at/ueber-uns/dr-petra-fabritz",
    },
    {
        label: "screen.welcome.links.products",
        url: "https://www.vorsorgeinstitut.at/preise",
    },
    {
        label: "screen.welcome.links.help",
        url: "https://www.vorsorgeinstitut.at/ueber-uns/kontakt",
    },
    // TODO: imprint & privacy policy
    {
        label: "screen.welcome.links.imprint",
        url: "https://www.vorsorgeinstitut.at/impressum",
    },
    {
        label: "screen.welcome.links.privacyPolicy",
        url: privacyPolicyPdf,
    },
];

export const WelcomeSite = () => {
    const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
    const [totalCheckups, setTotalCheckups] = React.useState(0);
    const [lastCheckupDate, setLastCheckupDate] = React.useState("");
    const [checkups, setCheckups] = React.useState<CheckupReportListItem[] | null>(null);
    const [checkupHistoryString, setCheckupHistoryString] = React.useState("");

    const loadPatientCheckups = React.useCallback(async () => {
        generalStore.isLoading = true;
        try {
            const patientCheckups = await API.getPatientCheckups();
            patientStore.totalCheckups = patientCheckups.length;

            setCheckups(patientCheckups);
            setTotalCheckups(patientCheckups.length);
            setLastCheckupDate(formatDate(patientCheckups[0].date));
            setCheckupHistoryString(
                `${dayjs(patientCheckups[patientCheckups.length - 1].date).format("MMMM YYYY")} - ${dayjs(
                    patientCheckups[0].date,
                ).format("MMMM YYYY")}`,
            );
        } catch (error) {
            generalStore.setError(t("error.load"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, []);

    React.useEffect(() => {
        loadPatientCheckups();
    }, [loadPatientCheckups]);

    const handleClickOpenProfile = (event: React.MouseEvent<HTMLElement>) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setProfileAnchorEl(null);
    };

    const handleClickLogout = () => {
        authStore.preventLastLocationRedirect = true;
        authStore.logout();
    };

    const handleClickHistory = () => {
        history.push(Routes.PATIENT.HISTORY.replace(":checkupId", checkups?.[0]?.checkupId ?? ""));
    };

    const handleClickCheckupReport = (checkup?: CheckupReportListItem) => {
        if (checkup && checkup.checkupId) {
            patientStore.checkupReport = checkup;
            history.push(Routes.CHECKUP_REPORT.replace(":checkupId", checkup.checkupId));
        }
    };

    return (
        <WelcomeSiteContainer>
            <Topbar>
                <ImageLogo style={{ maxWidth: 95 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton color="inherit" onClick={handleClickOpenProfile}>
                        <UserProfileIcon style={{ fontSize: 30 }} />
                    </IconButton>
                    <Menu
                        anchorEl={profileAnchorEl}
                        open={!!profileAnchorEl}
                        onClose={handleCloseMenu}
                        onClick={handleCloseMenu}
                    >
                        <MenuItem onClick={handleClickLogout}>{t("common.logout")}</MenuItem>
                    </Menu>
                </div>
            </Topbar>
            <SiteContainer>
                <SegmentContainer style={{ marginBottom: 24 }}>
                    <WelcomeTitle>{t("screen.welcome.title")}</WelcomeTitle>
                    <Divider />
                    <CheckupInfo>
                        <InfoContainer>
                            <InfoTitle>{t("screen.welcome.totalCheckups")}</InfoTitle>
                            <div>{totalCheckups}</div>
                        </InfoContainer>
                        <Divider style={{ marginRight: 16 }} orientation="vertical" />
                        <InfoContainer>
                            <InfoTitle>{t("screen.welcome.lastCheckup")}</InfoTitle>
                            <div>{lastCheckupDate}</div>
                        </InfoContainer>
                    </CheckupInfo>
                </SegmentContainer>
                {totalCheckups > 1 && (
                    <SegmentContainer style={{ marginBottom: 24 }}>
                        <HistoryContainer>
                            <GraphIcon style={{ marginRight: 10 }} />
                            <div style={{ fontWeight: 500 }}>{t("screen.welcome.history")}</div>
                        </HistoryContainer>
                        <Divider />
                        <HistoryContainer
                            onClick={handleClickHistory}
                            style={{ justifyContent: "space-between", cursor: "pointer" }}
                        >
                            <div>{checkupHistoryString}</div>
                            <IconButton>
                                <ChevronRight fill={Colors.DISABLED} />
                            </IconButton>
                        </HistoryContainer>
                    </SegmentContainer>
                )}
                <SegmentContainer>
                    <CheckupReportTitle>
                        <FileIcon style={{ marginRight: 10 }} />
                        <div>{t("screen.welcome.checkupReports")}</div>
                    </CheckupReportTitle>
                    {checkups && <Divider />}
                    {checkups &&
                        checkups.map((checkup) => (
                            <CheckupContainer onClick={() => handleClickCheckupReport(checkup)} key={checkup.checkupId}>
                                <div style={{ color: Colors.TEXT_LIGHT }}>{formatDate(checkup.date)}</div>

                                <div>
                                    <IconButton>
                                        <ChevronRight fill={Colors.DISABLED} />
                                    </IconButton>
                                </div>
                            </CheckupContainer>
                        ))}
                </SegmentContainer>
            </SiteContainer>
            <BottomBar>
                {links.map((link, index) => (
                    <React.Fragment key={index}>
                        <Link color={Colors.DISABLED} href={link.url} underline="none" target="_blank" rel="noreferrer">
                            {t(link.label)}
                        </Link>
                        {index < links.length - 1 && <span style={{ margin: "0 6px", color: Colors.DISABLED }}>•</span>}
                    </React.Fragment>
                ))}
            </BottomBar>
        </WelcomeSiteContainer>
    );
};
