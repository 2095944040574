import { Field } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { CustomInputField } from "../ui/CustomInputField";

type IProps = {
    disabled?: boolean;
};

export const EcgResult = ({ disabled }: IProps) => (
    <Field
        label={t("screen.patientInformation.field.ecgResult")}
        name="ecgResult.value"
        disabled={disabled}
        component={CustomInputField}
    />
);
