import { Field, useFormikContext } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { TemplateTexts } from "../../network/APITypes";
import { CustomSelect } from "../ui/CustomSelect";
import { CustomTextField } from "../ui/CustomTextField";
import { getRecommendationSiteOptions } from "../util/helpers";

type IProps = {
    disabled?: boolean;
};

const ecgOptions = [
    {
        value: "notSpecified",
        label: "common.notSpecified",
    },
    {
        value: "normal",
        label: "common.normal",
    },
    {
        value: "conspicuous",
        label: "common.conspicuous",
    },
];

export const EcgRecommendation = ({ disabled }: IProps) => {
    const { values, setFieldValue } = useFormikContext<any>();

    const templateTexts = getRecommendationSiteOptions(values).ecg.templateTexts;

    const handleChangeAssessment = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const templateText = templateTexts?.find(
            (item) => item.targetValue === `ecg.${event.target.value}`,
        )?.templateText;

        setFieldValue("textInput", templateText ?? "");
    };

    return (
        <>
            <Field
                component={CustomSelect}
                onChange={handleChangeAssessment}
                label={t("screen.patientInformation.field.ecg.dropdownLabel")}
                name="assessment"
                type="input"
                disabled={disabled}
                options={ecgOptions}
                style={{ marginBottom: 16 }}
            />
            <Field name="textInput" component={CustomTextField} minRows={3} disabled={disabled} />
        </>
    );
};
