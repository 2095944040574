import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { t } from "../../i18n/util";
import { ICell } from "../../types";
import { Colors } from "../util/Colors";
import { TextDot } from "./Dots";
import { StyledTableCell } from "./StyledTableCell";
import { TableHeaderCell } from "./TableHeaderCell";

type IHeaderItem = {
    label?: string;
};

type IProps = {
    headerItems?: IHeaderItem[];
    rows: ICell[][];
    style?: React.CSSProperties;
};

export const HistoryValuesTable = ({ headerItems, rows, style }: IProps) => (
    <Table style={style}>
        <colgroup>
            <col style={{ width: 44 }} />
            <col style={{ minWidth: 98 }} />
            <col style={{ minWidth: 98 }} />
            <col style={{ minWidth: 98 }} />
            <col style={{ minWidth: 98 }} />
            <col style={{ minWidth: 98 }} />
            <col style={{ minWidth: 98, borderLeft: `4px solid ${Colors.GREY_200}` }} />
        </colgroup>
        <TableHead>
            <TableRow>
                {headerItems?.map((headerItem, index) => (
                    <TableHeaderCell key={index} style={{ textAlign: "center", backgroundColor: "unset" }}>
                        {headerItems.length - 1 === index && <div>{t("screen.history.firstValue")}</div>}
                        {headerItem.label}
                    </TableHeaderCell>
                ))}
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map((row, index) => (
                <TableRow key={index}>
                    {row.map((cell, cellIndex) => (
                        <StyledTableCell
                            key={cellIndex}
                            style={{
                                color: cell.color,
                                borderRight: cellIndex < row.length - 1 ? `1px solid ${Colors.GREY_200}` : undefined,
                                backgroundColor: "unset",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {cell.color && <TextDot style={{ backgroundColor: cell.color, marginRight: 6 }} />}
                                {cell.content === undefined || cell.content === null || cell.content === ""
                                    ? "-"
                                    : cell.content}
                                {cell.content !== undefined && cell.content !== "" && cell.adornmentText && (
                                    <span>&nbsp;{cell.adornmentText}</span>
                                )}
                            </div>
                        </StyledTableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    </Table>
);
