import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import { FieldInputProps } from "formik";
import { IMessageIDS, t } from "../../i18n/util";
import { Colors } from "../util/Colors";
import { StyledInputLabel } from "./StyledInputLabel";

type IOption = {
    value: string;
    label: IMessageIDS;
};

type IProps = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    isTouched?: boolean;
    onChange?: (event: SelectChangeEvent) => void;
    options: IOption[];
    ignoreHeaderLabel?: boolean;
    paddingTop?: number;
};

export const CustomSelect = ({
    style,
    label,
    type,
    autoComplete,
    required,
    errorMessage,
    field,
    isTouched,
    disabled,
    onChange,
    options,
    ignoreHeaderLabel,
    paddingTop,
    ...props
}: IProps) => {
    const showError = isTouched && !!errorMessage;

    const handleChange = (event: SelectChangeEvent) => {
        if (field.onChange) {
            field.onChange(event);
        }
        if (onChange) {
            onChange(event);
        }
    };

    const requiredLabel = required ? `${label} *` : label;
    return (
        <div style={{ ...style, paddingTop: paddingTop ?? 0 }}>
            {ignoreHeaderLabel ? null : (
                <StyledInputLabel htmlFor={field.name} style={{ marginBottom: 8 }}>
                    {label}
                </StyledInputLabel>
            )}

            <FormControl fullWidth>
                {ignoreHeaderLabel ? <InputLabel htmlFor={field.name}>{label}</InputLabel> : null}
                <Select
                    id={field.name}
                    label={ignoreHeaderLabel ? requiredLabel : undefined}
                    value={field.value}
                    name={field.name}
                    onChange={handleChange}
                    onBlur={field.onBlur}
                    type={type}
                    error={showError}
                    margin="dense"
                    aria-label={props["aria-label"]}
                    disabled={disabled}
                    style={{ backgroundColor: disabled ? Colors.BACKGROUND : undefined }}
                >
                    {options.map((option: IOption, index) => (
                        <MenuItem key={index} value={option.value}>
                            {t(option.label)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {showError && errorMessage && (
                <span
                    style={{
                        color: "#f00",
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        fontSize: 12,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
