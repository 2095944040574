import * as React from "react";

// Returns dimensions of a component
export const useResize = (ref: React.RefObject<any>) => {
    const [dimensions, setDimensions] = React.useState<DOMRect | undefined>(undefined);

    React.useEffect(() => {
        const handleResize = () => {
            // Reset when resizing window
            setDimensions(ref.current?.getBoundingClientRect());
        };

        if (ref.current) {
            // Initial dimensions
            setDimensions(ref.current?.getBoundingClientRect());
        }

        window.addEventListener("resize", handleResize);

        // Cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [ref]);

    return dimensions || { width: 0, height: 0, left: 0, right: 0, bottom: 0, top: 0, x: 0, y: 0 };
};
