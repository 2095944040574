import { FormControl, Switch } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/styles";
import { FieldInputProps } from "formik";
import * as React from "react";
import { Colors } from "../util/Colors";

type IProps = TextFieldProps & {
    field: FieldInputProps<string>;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: string;
    isTouched?: boolean;
    defaultChecked?: boolean;
};

const StyledSwitch = styled(Switch)({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: Colors.MAIN,
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: Colors.MAIN,
            border: "6px solid #fff",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: "#E9E9EA",
        opacity: 1,
    },
});

export const CustomSwitch = ({
    style,
    label,
    required,
    errorMessage,
    field,
    isTouched,
    disabled,
    onChange,
    defaultChecked,
    ...props
}: IProps) => {
    const showError = isTouched && !!errorMessage;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (field.onChange) {
            field.onChange(event);
        }
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <div style={style}>
            <FormControl fullWidth>
                <StyledSwitch
                    id={field.name}
                    name={field.name}
                    onChange={handleChange}
                    defaultChecked={defaultChecked}
                    checked={field.checked}
                    disabled={disabled}
                />
            </FormControl>
            {showError && errorMessage && (
                <span
                    style={{
                        color: "#f00",
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        fontSize: 12,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
