import { Tooltip } from "@mui/material";
import * as React from "react";

export const OptionalTooltip = ({ title, children }: { title?: string; children: any }) =>
    title ? (
        <Tooltip PopperProps={{ style: { whiteSpace: "pre-line" } }} title={title}>
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );
