import { IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { ReactComponent as UploadIcon } from "../../assets/icons/ic_upload.svg";
import { IMessageIDS, t } from "../../i18n/util";
import { Colors } from "../util/Colors";
import { FailedDialog } from "./FailedDialog";

export const MAX_UPLOAD_SIZE_MB = 2;

type IUpload<T> = {
    file: File;
    response: T;
};

type IProps<T> = {
    maxFileSize: number;
    acceptedFilesFormat?: string;
    fileTypesLabel?: IMessageIDS;
    uploadFile: (file: File) => Promise<T>;
    onSuccess: (uploads: IUpload<T>[]) => void;
    text: string;
    disabled?: boolean;
};

type IFailedUpload = {
    file: File;
    errorMessage?: string;
    info?: string;
};

const UploadButtonContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
});

function filterEmptyFiles(accepted: File[], rejected: FileRejection[]) {
    const emptyFiles = accepted.filter((file) => file.size === 0);
    return {
        // Accept only non empty files
        accepted: accepted.filter((file) => file.size > 0),

        // Add empty files to rejected
        rejected: rejected.concat(
            // Convert empty to FileRejection
            emptyFiles.map((file) => ({
                file,
                errors: [{ message: "file is empty", code: "file-empty" }],
            })),
        ),
    };
}

export const DocumentUpload = observer(
    <T,>({ maxFileSize, acceptedFilesFormat, fileTypesLabel, uploadFile, onSuccess, text, disabled }: IProps<T>) => {
        const [highlight, setHighlight] = React.useState(false);
        const [rejectedFiles, setRejectedFiles] = React.useState<FileRejection[]>([]);
        const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);
        const [failedUploads, setFailedUploads] = React.useState<IFailedUpload[]>([]);
        const [successfulUploads, setSuccessfulUploads] = React.useState<IUpload<T>[]>([]);
        const [isUploading, setIsUploading] = React.useState(false);

        const uploadFiles = async (files: File[]) => {
            if (!files || files.length === 0) {
                return;
            }

            const failed: IFailedUpload[] = [];
            const success: IUpload<T>[] = [];

            setIsUploading(true);

            // Iterate over file list and upload each file
            for (let i = 0; i < files.length; i++) {
                try {
                    const file = files[i];
                    const response = await uploadFile(file);

                    success.push({ file, response });
                } catch (error) {
                    failed.push({ file: files[i] });
                }
            }

            setIsUploading(false);

            // Append new successful uploads to previous ones
            const newSuccess = successfulUploads.concat(success);
            setSuccessfulUploads(newSuccess);
            setFailedUploads(failed);

            if (failed.length === 0) {
                onSuccess(newSuccess);
            }
        };

        const handleFileDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            const { accepted, rejected } = filterEmptyFiles(acceptedFiles, rejectedFiles);
            setAcceptedFiles(accepted);
            setRejectedFiles(rejected);

            // No rejected files -> proceed with upload
            if (!rejected || rejected.length === 0) {
                await uploadFiles(acceptedFiles);
            }
        };

        const handleDragOver = () => {
            setHighlight(true);
        };

        const handleDragLeave = () => {
            setHighlight(false);
        };

        const handleDropRejected = (fileRejections: FileRejection[] = []) => {
            setRejectedFiles(fileRejections);
            setHighlight(false);
        };

        const handleCloseRejectedFilesDialog = () => {
            setRejectedFiles([]);
            setAcceptedFiles([]);
        };

        const handleSubmitRejectedFilesDialog = () => {
            setRejectedFiles([]);
            uploadFiles(acceptedFiles);
        };

        const handleCloseFailedUploadsDialog = () => {
            if (successfulUploads.length > 0) {
                onSuccess(successfulUploads);
            }
            setFailedUploads([]);
            setAcceptedFiles([]);
        };

        const handleSubmitFailedUploadsDialog = () => {
            onSuccess(successfulUploads);
        };

        const handleRetryFailedUploadsDialog = async () => {
            const failedFiles = failedUploads.map((failed) => failed.file);
            setAcceptedFiles(failedFiles);
            await uploadFiles(failedFiles);
        };

        const { getRootProps, getInputProps, open } = useDropzone({
            accept: acceptedFilesFormat,
            multiple: true,
            maxSize: maxFileSize,
            disabled: isUploading,
            onDrop: handleFileDrop,
            onDropRejected: handleDropRejected,
            onDragOver: handleDragOver,
            onDragLeave: handleDragLeave,
        });

        const hasRejectedFiles = rejectedFiles.length > 0;
        const hasFailedUploads = failedUploads.length > 0;

        return (
            <>
                <UploadButtonContainer>
                    <div style={{ fontSize: 12, maxWidth: 180, color: Colors.DISABLED, marginRight: 12 }}>{text}</div>
                    <div
                        {...getRootProps()}
                        style={{
                            cursor: disabled ? undefined : "pointer",
                            backgroundColor: highlight || disabled ? "#F6F8F9" : undefined,
                            width: 64,
                            height: 44,
                            borderRadius: 100,
                            border: "1px solid #E6E6E6",
                            textAlign: "center",
                        }}
                        role="button"
                        onClick={disabled ? undefined : open}
                    >
                        <IconButton disabled={disabled}>
                            <UploadIcon />
                        </IconButton>

                        <input {...getInputProps()} />
                    </div>
                </UploadButtonContainer>

                <FailedDialog // Failed dialog for rejected files
                    open={hasRejectedFiles}
                    fileRejections={rejectedFiles}
                    rejectionInfoText={fileTypesLabel ? t(fileTypesLabel) : undefined}
                    totalFiles={acceptedFiles.length + rejectedFiles.length}
                    onClose={handleCloseRejectedFilesDialog}
                    onSubmit={handleSubmitRejectedFilesDialog}
                />

                <FailedDialog // Failed dialog for failed uploads
                    open={hasFailedUploads}
                    failedUploads={failedUploads}
                    totalFiles={acceptedFiles.length}
                    onClose={handleCloseFailedUploadsDialog}
                    onSubmit={successfulUploads.length > 0 ? handleSubmitFailedUploadsDialog : undefined}
                    onRetry={handleRetryFailedUploadsDialog}
                />
            </>
        );
    },
);
