import { Location } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { authStore } from "../../../stores/AuthStore";
import { LoadingOverlay } from "../../ui/LoadingOverlay";
import { Routes } from "./Routes";

const getRedirectPath = (isDoctor: boolean, isPatient: boolean) => {
    if (isDoctor) {
        return Routes.DOCTOR.OVERVIEW;
    } else if (isPatient) {
        return Routes.PATIENT.WELCOME;
    }

    return Routes.ROOT;
};

export const NoAuthOnlyRoute = observer(({ children, ...props }: React.ComponentProps<typeof Route>) => {
    const location = useLocation<{ from?: Location }>();

    if (!authStore.isRehydrated) {
        return <LoadingOverlay />;
    }

    const previousLocation = location.state?.from;
    const redirectTo = previousLocation || {
        pathname: getRedirectPath(authStore.isDoctor, authStore.isPatient),
        state: authStore.preventLastLocationRedirect ? undefined : { from: location },
    };

    return <Route {...props}>{!authStore.isAuthenticated ? children : <Redirect to={redirectTo} />}</Route>;
});
