import styled from "styled-components";
import { FieldRow } from "../ui/FieldRow";
import { IFormField, IFormFieldMessageIDS } from "../../types";
import { patientStore } from "../../stores/PatientStore";
import { t } from "../../i18n/util";
import { CustomSelect } from "../ui/CustomSelect";
import { CustomDatePicker } from "../ui/CustomDatePicker";

type IPatientInformation = {
    disabled?: boolean;
};

const StyledFieldRow = styled(FieldRow)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`;

const checkupSiteOptions = [
    { value: "vienna", label: "screen.patientInformation.field.patientInfo.vienna" },
    { value: "klosterneuburg", label: "screen.patientInformation.field.patientInfo.klosterneuburg" },
];

const sexOptions = [
    { value: "male", label: "screen.patientInformation.field.patientInfo.male" },
    { value: "female", label: "screen.patientInformation.field.patientInfo.female" },
];

const fields: IFormFieldMessageIDS[] = [
    {
        name: "patientInfo.lastName",
        label: "screen.patientInformation.field.patientInfo.lastName",
    },
    {
        name: "patientInfo.firstName",
        label: "screen.patientInformation.field.patientInfo.firstName",
    },
    {
        name: "patientInfo.titlePrefix",
        label: "screen.patientInformation.field.patientInfo.titlePrefix",
    },
    {
        name: "patientInfo.titleSuffix",
        label: "screen.patientInformation.field.patientInfo.titleSuffix",
    },
    {
        name: "patientInfo.patientId",
        label: "screen.patientInformation.field.patientInfo.patientId",
        required: true,
        disabled: true,
        // TODO: set to number, when BE adapted it
        // type: "number",
    },
    {
        name: "patientInfo.birthday",
        component: CustomDatePicker,
        label: "screen.patientInformation.field.patientInfo.birthday",
        disableFuture: true,
    },
    {
        name: "patientInfo.email",
        label: "screen.patientInformation.field.patientInfo.patientEmail",
        required: true,
    },
    {
        name: "patientInfo.checkupDate",
        component: CustomDatePicker,
        label: "screen.patientInformation.field.patientInfo.checkupDate",
        disableFuture: true,
    },
    {
        name: "patientInfo.checkupSite",
        label: "screen.patientInformation.field.patientInfo.checkupSite",
        component: CustomSelect,
        options: checkupSiteOptions,
    },
    {
        name: "patientInfo.sex",
        label: "screen.patientInformation.field.patientInfo.sex",
        component: CustomSelect,
        options: sexOptions,
    },
    {
        name: "patientInfo.doctor",
        label: "screen.patientInformation.field.patientInfo.doctor",
    },
    {
        name: "patientInfo.locumDoctor",
        label: "screen.patientInformation.field.patientInfo.locumDoctor",
    },
];

export const PatientInformation = ({ disabled }: IPatientInformation) => (
    <StyledFieldRow fields={fields} disabled={disabled} />
);
