import { styled } from "@mui/system";
import { t } from "../../i18n/util";
import { BIAReportHistory, LifestyleReportHistory, PatientInfo } from "../../network/APITypes";
import { BiaValueDisplay } from "../patients/BiaValueDisplay";
import { MultiValueDisplay } from "../patients/MultiValueDisplay";
import { BMI_MAX_VALUE, BMI_MIN_VALUE } from "../util/constants";
import { formatDate } from "../util/helpers";
import { getBmiDotPosition } from "../util/MultiValueDisplayHelpers";
import {
    getAbdominalGirthBreakpoints,
    getAbdominalGirthColor,
    getBiaDotPosition,
    getBiaValueColor,
    getBloodpressureColor,
    getBmiColor,
    getExerciseColor,
    getExerciseDropdownText,
    getHistoryHeaderItems,
    getSmileyByColor,
    getSmokerColor,
    getSmokerText,
} from "../util/ValueDisplayHelpers";
import { Card } from "./Card";
import { CurrentValueTable } from "./CurrentValueTable";
import { HistoryValuesTable } from "./HistoryValuesTable";
import { ValueGradient } from "./ValueGradient";
import { VerticalTableDivider } from "./VerticalTableDivider";

type IProps = {
    lifestyleReport?: LifestyleReportHistory;
    biaReport?: BIAReportHistory;
    patientInformation?: PatientInfo;
};

const Title = styled("div")({
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 40,
});

export const LifestyleParametersHistory = ({ lifestyleReport, biaReport, patientInformation }: IProps) => {
    const currentValueHeaderItems = [
        {
            name: "name",
            label: t("screen.history.table.measurement"),
        },
        {
            name: "value",
            label: t("screen.history.table.currentValue", {
                date: formatDate(lifestyleReport?.latest?.checkupDate ?? biaReport?.latest?.checkupDate),
                br: (<br />) as any,
            }),
        },
        {
            name: "referenceRange",
            label: t("screen.history.table.referenceRange"),
        },
    ];

    const { valueHeaderItems, latestHistoryItems, lastCheckupReport, emptyColumns } = getHistoryHeaderItems(
        lifestyleReport?.history,
    );

    // abdominalGirth
    const latestAbdominalGirthHistory = latestHistoryItems.map((checkupReport) => ({
        content: checkupReport.abdominalGirth ?? "",
        color: getAbdominalGirthColor(checkupReport.abdominalGirth, patientInformation?.sex),
        adornmentText: t("common.centimeter"),
    }));
    const lastAbdominalGirth = lastCheckupReport?.abdominalGirth;
    const lastAbdominalGirthItem = {
        content: lastAbdominalGirth ?? "",
        color: getAbdominalGirthColor(lastAbdominalGirth, patientInformation?.sex),
        adornmentText: t("common.centimeter"),
    };
    const abdominalGirthSmiley = getSmileyByColor(
        getAbdominalGirthColor(lifestyleReport?.latest?.abdominalGirth, patientInformation?.sex),
    );
    const abdominalGirthRow = [
        { content: abdominalGirthSmiley },
        ...emptyColumns,
        ...latestAbdominalGirthHistory,
        lastAbdominalGirthItem,
    ];

    // exerciseDropdown
    const exerciseDropdownColor = getExerciseColor(lifestyleReport?.latest?.exerciseDropdown);

    const latestExerciseDropdownHistory = latestHistoryItems.map((historyItem) => ({
        content: getExerciseDropdownText(historyItem.exerciseDropdown ?? ""),
        color: getExerciseColor(historyItem.exerciseDropdown),
    }));

    const lastExerciseDropdownItem = {
        content: getExerciseDropdownText(lastCheckupReport?.exerciseDropdown ?? ""),
        color: getExerciseColor(lastCheckupReport?.exerciseDropdown),
    };

    const exerciseDropdownHistoryValuesRow = [
        { content: getSmileyByColor(exerciseDropdownColor) },
        ...emptyColumns,
        ...latestExerciseDropdownHistory,
        lastExerciseDropdownItem,
    ];

    // smokerDropdown
    const smokerDropdownColor = getSmokerColor(lifestyleReport?.latest?.smokerDropdown);

    const latestSmokerDropdownHistory = latestHistoryItems.map((historyItem) => ({
        content: getSmokerText(historyItem.smokerDropdown ?? ""),
        color: getSmokerColor(historyItem.smokerDropdown),
    }));

    const lastSmokerDropdownItem = {
        content: getSmokerText(lastCheckupReport?.smokerDropdown ?? ""),
        color: getSmokerColor(lastCheckupReport?.smokerDropdown),
    };

    const smokerDropdownHistoryValuesRow = [
        { content: getSmileyByColor(smokerDropdownColor) },
        ...emptyColumns,
        ...latestSmokerDropdownHistory,
        lastSmokerDropdownItem,
    ];

    // bmi
    const getBmi = (weight?: number, height?: number) => {
        if (weight && height) {
            return Math.round((weight / Math.pow(height / 100, 2)) * 100) / 100;
        }

        return undefined;
    };

    const bmiColor = getBmiColor(getBmi(lifestyleReport?.latest?.weight, lifestyleReport?.latest?.height));

    const latestBmiHistory = latestHistoryItems.map((historyItem) => ({
        content: getBmi(historyItem.weight, historyItem.height),
        color: getBmiColor(getBmi(historyItem.weight, historyItem.height)),
    }));

    const lastBmiItem = {
        content: getBmi(lastCheckupReport?.weight, lastCheckupReport?.height),
        color: getBmiColor(getBmi(lastCheckupReport?.weight, lastCheckupReport?.height)),
    };

    const bmiHistoryValuesRow = [
        { content: getSmileyByColor(bmiColor) },
        ...emptyColumns,
        ...latestBmiHistory,
        lastBmiItem,
    ];

    // bloodpressure
    const latestBloodpressureHistory = latestHistoryItems.map((checkupReport) => ({
        content:
            checkupReport.bloodpressure?.systole && checkupReport.bloodpressure?.diastole
                ? `${checkupReport.bloodpressure.systole}/${checkupReport.bloodpressure.diastole}`
                : "",
        color: getBloodpressureColor(checkupReport.bloodpressureDropdown),
    }));
    const lastBloodpressureItem = {
        content:
            lastCheckupReport?.bloodpressure?.systole && lastCheckupReport?.bloodpressure?.diastole
                ? `${lastCheckupReport.bloodpressure.systole}/${lastCheckupReport.bloodpressure.diastole}`
                : "",
        color: getBloodpressureColor(lastCheckupReport?.bloodpressureDropdown),
    };
    const bloodpressureSmiley =
        lifestyleReport?.latest?.bloodpressure?.systole && lifestyleReport?.latest?.bloodpressure?.diastole
            ? getSmileyByColor(getBloodpressureColor(lifestyleReport?.latest?.bloodpressureDropdown))
            : null;
    const bloodpressureRow = [
        { content: bloodpressureSmiley },
        ...emptyColumns,
        ...latestBloodpressureHistory,
        lastBloodpressureItem,
    ];

    // bia items
    const {
        valueHeaderItems: biaValueHeaderItems,
        latestHistoryItems: latestBiaHistoryItems,
        lastCheckupReport: lastBiaCheckupReport,
        emptyColumns: biaEmptyColumns,
    } = getHistoryHeaderItems(biaReport?.history);

    // muscle
    const latestMuscleHistory = latestBiaHistoryItems.map((checkupReport) => ({
        content: checkupReport.muscle?.value ?? "",
        color: getBiaValueColor(getBiaDotPosition({ name: "muscle", results: checkupReport }), true),
        adornmentText: t("common.kilogram"),
    }));

    const lastMuscleItemColor = getBiaValueColor(
        getBiaDotPosition({ name: "muscle", results: lastBiaCheckupReport }),
        true,
    );

    const lastMuscleItem = {
        content: lastBiaCheckupReport?.muscle?.value ?? "",
        color: lastMuscleItemColor,
        adornmentText: t("common.kilogram"),
    };

    const muscleSmiley = getSmileyByColor(
        getBiaValueColor(getBiaDotPosition({ name: "muscle", results: biaReport?.latest }), true),
    );

    const muscleHistoryValuesRow = [
        { content: muscleSmiley },
        ...biaEmptyColumns,
        ...latestMuscleHistory,
        lastMuscleItem,
    ];

    // fat
    const latestFatHistory = latestBiaHistoryItems.map((checkupReport) => ({
        content: checkupReport.fat?.value ?? "",
        color: getBiaValueColor(getBiaDotPosition({ name: "fat", results: checkupReport }), true),
        adornmentText: t("common.kilogram"),
    }));

    const lastFatItemColor = getBiaValueColor(getBiaDotPosition({ name: "fat", results: lastBiaCheckupReport }), true);

    const lastFatItem = {
        content: lastBiaCheckupReport?.fat?.value ?? "",
        color: lastFatItemColor,
        adornmentText: t("common.kilogram"),
    };

    const fatSmiley = getSmileyByColor(
        getBiaValueColor(getBiaDotPosition({ name: "fat", results: biaReport?.latest }), true),
    );

    const fatHistoryValuesRow = [{ content: fatSmiley }, ...biaEmptyColumns, ...latestFatHistory, lastFatItem];

    // fatPercent
    const latestFatPercentHistory = latestBiaHistoryItems.map((checkupReport) => ({
        content: checkupReport.fatPercent?.value ?? "",
        color: getBiaValueColor(getBiaDotPosition({ name: "fatPercent", results: checkupReport }), true),
        adornmentText: t("common.percent"),
    }));

    const lastFatPercentItemColor = getBiaValueColor(
        getBiaDotPosition({ name: "fatPercent", results: lastBiaCheckupReport }),
        true,
    );

    const lastFatPercentItem = {
        content: lastBiaCheckupReport?.fatPercent?.value ?? "",
        color: lastFatPercentItemColor,
        adornmentText: t("common.percent"),
    };

    const fatPercentSmiley = getSmileyByColor(
        getBiaValueColor(getBiaDotPosition({ name: "fatPercent", results: biaReport?.latest }), true),
    );

    const fatPercentHistoryValuesRow = [
        { content: fatPercentSmiley },
        ...biaEmptyColumns,
        ...latestFatPercentHistory,
        lastFatPercentItem,
    ];

    // put everything together
    const abdominalGirthCurrentValueRow = [
        { content: t("screen.history.table.abdominalGirth") },
        {
            content: lifestyleReport?.latest?.abdominalGirth,
            color: getAbdominalGirthColor(lifestyleReport?.latest?.abdominalGirth, patientInformation?.sex),
            adornmentText: t("common.centimeter"),
        },
        {
            content: lifestyleReport?.latest?.abdominalGirth ? (
                <ValueGradient
                    breakpoints={getAbdominalGirthBreakpoints(patientInformation?.sex)}
                    value={lifestyleReport?.latest?.abdominalGirth}
                    hideRange
                />
            ) : (
                "-"
            ),
        },
    ];

    const bloodpressureCurrentValueRow = [
        { content: t("screen.history.table.bloodpressure") },
        {
            content:
                lifestyleReport?.latest?.bloodpressure?.systole && lifestyleReport?.latest?.bloodpressure?.diastole
                    ? `${lifestyleReport?.latest.bloodpressure.systole}/${lifestyleReport?.latest.bloodpressure.diastole}`
                    : "",
            color:
                lifestyleReport?.latest?.bloodpressure?.systole && lifestyleReport?.latest?.bloodpressure?.diastole
                    ? getBloodpressureColor(lifestyleReport?.latest?.bloodpressureDropdown)
                    : undefined,
        },
        {
            content: "",
        },
    ];

    const exerciseDropdownCurrentValueRow = [
        {
            content: t("screen.history.table.exerciseDropdown"),
        },
        {
            content: getExerciseDropdownText(lifestyleReport?.latest?.exerciseDropdown ?? ""),
            color: getExerciseColor(lifestyleReport?.latest?.exerciseDropdown),
        },
        {
            content:
                lifestyleReport?.latest?.exerciseDropdown !== "notSpecified" ? (
                    <ValueGradient exerciseDropdown={lifestyleReport?.latest?.exerciseDropdown} hideRange />
                ) : (
                    ""
                ),
        },
    ];

    const smokerDropdownCurrentValueRow = [
        {
            content: t("screen.history.table.smokerDropdown"),
        },
        {
            content: getSmokerText(lifestyleReport?.latest?.smokerDropdown ?? ""),
            color: getSmokerColor(lifestyleReport?.latest?.smokerDropdown),
        },
        {
            content: "",
        },
    ];

    const currentBmi = getBmi(lifestyleReport?.latest?.weight, lifestyleReport?.latest?.height);
    const bmiCurrentValueRow = [
        {
            content: t("screen.history.table.bmi"),
        },
        {
            content: currentBmi,
            color: getBmiColor(currentBmi),
        },
        {
            content: currentBmi ? (
                <MultiValueDisplay
                    values={{ minVal: BMI_MIN_VALUE, maxVal: BMI_MAX_VALUE }}
                    dotPosition={getBmiDotPosition(currentBmi)}
                />
            ) : (
                ""
            ),
        },
    ];

    const currentMuscleDotPosition = getBiaDotPosition({ name: "muscle", results: biaReport?.latest });
    const muscleCurrentValueRow = [
        {
            content: t("screen.history.table.muscle"),
        },
        {
            content: biaReport?.latest?.muscle?.value,
            color: getBiaValueColor(currentMuscleDotPosition, true),
            adornmentText: t("common.kilogram"),
        },
        {
            content: biaReport?.latest?.muscle ? (
                <BiaValueDisplay
                    values={biaReport?.latest?.muscle}
                    dotPosition={currentMuscleDotPosition}
                    name="muscle"
                />
            ) : (
                ""
            ),
        },
    ];

    const currentFatDotPosition = getBiaDotPosition({ name: "fat", results: biaReport?.latest });
    const fatCurrentValueRow = [
        {
            content: t("screen.history.table.fat"),
        },
        {
            content: biaReport?.latest?.fat?.value,
            color: getBiaValueColor(currentFatDotPosition, true),
            adornmentText: t("common.kilogram"),
        },
        {
            content: biaReport?.latest?.fat ? (
                <BiaValueDisplay values={biaReport?.latest?.fat} dotPosition={currentFatDotPosition} name="fat" />
            ) : (
                ""
            ),
        },
    ];

    const currentFatPercentDotPosition = getBiaDotPosition({ name: "fatPercent", results: biaReport?.latest });
    const fatPercentCurrentValueRow = [
        {
            content: t("screen.history.table.fatPercent"),
        },
        {
            content: biaReport?.latest?.fatPercent?.value,
            color: getBiaValueColor(currentFatPercentDotPosition, true),
            adornmentText: t("common.percent"),
        },
        {
            content: biaReport?.latest?.fatPercent ? (
                <BiaValueDisplay
                    values={biaReport?.latest?.fatPercent}
                    dotPosition={currentFatPercentDotPosition}
                    name="fatPercent"
                />
            ) : (
                ""
            ),
        },
    ];

    const historyValueRows = [
        abdominalGirthRow,
        exerciseDropdownHistoryValuesRow,
        smokerDropdownHistoryValuesRow,
        bloodpressureRow,
        bmiHistoryValuesRow,
        muscleHistoryValuesRow,
        fatHistoryValuesRow,
        fatPercentHistoryValuesRow,
    ];
    const currentValueRows = [
        abdominalGirthCurrentValueRow,
        exerciseDropdownCurrentValueRow,
        smokerDropdownCurrentValueRow,
        bloodpressureCurrentValueRow,
        bmiCurrentValueRow,
        muscleCurrentValueRow,
        fatCurrentValueRow,
        fatPercentCurrentValueRow,
    ];

    return (
        <>
            <Title>{t("screen.history.lifestyleParameters")}</Title>
            <Card>
                <div style={{ display: "flex" }}>
                    <CurrentValueTable headerItems={currentValueHeaderItems} rows={currentValueRows} />
                    <VerticalTableDivider />
                    <HistoryValuesTable headerItems={valueHeaderItems} rows={historyValueRows} />
                </div>
            </Card>
        </>
    );
};
