import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { useResize } from "../hooks/useResize";
import { Colors } from "../util/Colors";
import { OptionalTooltip } from "./OptionalTooltip";

export type ITableLabelProps = {
    children: string;
    style?: React.CSSProperties;
    tooltip?: string;
};

export const TableLabel = ({ children, style, tooltip }: ITableLabelProps) => {
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);
    const dimensions = useResize(ref);

    useDeepCompareEffect(() => {
        if (ref.current) {
            setIsOverflowing(ref.current.offsetWidth < ref.current.scrollWidth);
        }
    }, [children, dimensions]);

    const tooltipMessage = tooltip ?? children;

    return (
        <OptionalTooltip title={isOverflowing || tooltip ? tooltipMessage : undefined}>
            <div
                style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: Colors.TEXT_BOLD,
                    fontWeight: 500,
                    textAlign: "center",
                    ...style,
                }}
                ref={ref}
            >
                {children}
            </div>
        </OptionalTooltip>
    );
};
