import { Field } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { CustomSelect } from "../ui/CustomSelect";

type IProps = {
    disabled?: boolean;
};

const options = [
    { value: "notSpecified", label: "common.notSpecified" },
    { value: "conspicuous", label: "common.conspicuous" },
    { value: "normal", label: "common.normal" },
];

export const StoolSample = ({ disabled }: IProps) => (
    <Field
        key="stoolSample.value"
        component={CustomSelect}
        label={t("screen.patientInformation.field.stoolSample.label")}
        name="stoolSample.value"
        options={options}
        disabled={disabled}
    />
);
