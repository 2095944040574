import { Divider } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { toJS } from "mobx";
import * as React from "react";
import styled from "styled-components";
import { IMessageIDS, t } from "../../i18n/util";
import { TemplateText } from "../../network/APITypes";
import { CustomSelect } from "../ui/CustomSelect";
import { CustomTextField } from "../ui/CustomTextField";
import { getRecommendationSiteOptions } from "../util/helpers";

type IProps = {
    disabled?: boolean;
};

type IOption = { value: string; label: IMessageIDS };

const Title = styled("div")({
    height: 45,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FDFDFD",
    fontSize: 16,
    fontWeight: 400,
    padding: 16,
});

const carotisOptions: IOption[] = [
    {
        value: "notSpecified",
        label: "common.notSpecified",
    },
    {
        value: "normal",
        label: "common.normal",
    },
    {
        value: "conspicuousControlDoctor",
        label: "screen.patientInformation.field.ultrasound.carotis.conspicuousControlDoctor",
    },
    {
        value: "conspicuousControl",
        label: "screen.patientInformation.field.ultrasound.carotis.conspicuousControl",
    },
];

const heartOptions: IOption[] = [
    {
        value: "notSpecified",
        label: "common.notSpecified",
    },
    {
        value: "normal",
        label: "common.normal",
    },
    {
        value: "conspicuous1yrControl",
        label: "screen.patientInformation.field.ultrasound.heart.conspicuous1yrControl",
    },
    {
        value: "conspicuousCardioControl",
        label: "screen.patientInformation.field.ultrasound.heart.conspicuousControlDoctor",
    },
];

const stomachOptions: IOption[] = [
    {
        value: "notSpecified",
        label: "common.notSpecified",
    },
    {
        value: "normal",
        label: "common.normal",
    },
    {
        value: "conspicuous",
        label: "common.conspicuous",
    },
    {
        value: "conspicuousCTAbdomens",
        label: "screen.patientInformation.field.ultrasound.stomach.conspicuousCTAbdomens",
    },
];

const thyroidOptions: IOption[] = [
    {
        value: "notSpecified",
        label: "common.notSpecified",
    },
    {
        value: "normal",
        label: "common.normal",
    },
    {
        value: "conspicuous6monthControl",
        label: "screen.patientInformation.field.ultrasound.thyroid.conspicuous6monthControl",
    },
    {
        value: "conspicuousThyroidScintigraphy",
        label: "screen.patientInformation.field.ultrasound.thyroid.conspicuousThyroidScintigraphy",
    },
];

const ultrasoundItems: { name: string; label: IMessageIDS; options: IOption[] }[] = [
    {
        name: "heart",
        label: "screen.patientInformation.field.ultrasound.heart",
        options: heartOptions,
    },
    {
        name: "thyroid",
        label: "screen.patientInformation.field.ultrasound.thyroid",
        options: thyroidOptions,
    },
    {
        name: "carotis",
        label: "screen.patientInformation.field.ultrasound.carotis",
        options: carotisOptions,
    },
    {
        name: "stomach",
        label: "screen.patientInformation.field.ultrasound.stomach",
        options: stomachOptions,
    },
];

export const UltrasoundRecommendation = ({ disabled }: IProps) => {
    const { values, setFieldValue } = useFormikContext<any>();

    const handleChangeDropdown = (event: React.ChangeEvent<HTMLSelectElement>, itemName: string) => {
        const ultrasound = getRecommendationSiteOptions(values).ultrasound.ultrasound;

        const templateText =
            ultrasound?.[itemName]?.templateTexts.find((item: TemplateText) => {
                return item.targetValue === `ultrasound.${itemName}.${event.target.value}`;
            })?.templateText ?? "";

        setFieldValue(`${itemName}.textInput`, templateText);
    };

    return (
        <>
            {ultrasoundItems.map((item, index) => (
                <React.Fragment key={item.name}>
                    {index !== 0 && <Divider />}
                    <Title>{t(item.label)}</Title>
                    <Divider />
                    <div style={{ padding: 16 }}>
                        <Field
                            component={CustomSelect}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                handleChangeDropdown(event, item.name)
                            }
                            label={t("screen.patientInformation.field.ultrasound.dropdownLabel")}
                            name={`${item.name}.value`}
                            type="input"
                            disabled={disabled}
                            options={item.options}
                            style={{ marginBottom: 16 }}
                        />
                        <Field
                            style={{ marginBottom: 16 }}
                            name={`${item.name}.textInput`}
                            component={CustomTextField}
                            minRows={3}
                            disabled={disabled}
                        />
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};
