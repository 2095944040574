import { InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/styles";
import { FieldInputProps } from "formik";
import { StyledInputLabel } from "./StyledInputLabel";

type IProps = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    isTouched?: boolean;
    onChange?: (e: React.SyntheticEvent) => void;
    minRows?: number;
};

export const CustomTextField = ({
    style,
    type,
    required,
    errorMessage,
    field,
    isTouched,
    disabled,
    onChange,
    minRows,
    ...props
}: IProps) => {
    const showError = isTouched && !!errorMessage;

    const handleChange = (e: React.SyntheticEvent) => {
        field.onChange(e);
        if (onChange) {
            onChange(e);
        }
    };

    const isFieldEmpty = field.value === "";

    return (
        <div style={style}>
            <TextField
                multiline
                minRows={minRows}
                id={field.name}
                value={field.value}
                name={field.name}
                onChange={handleChange}
                onBlur={field.onBlur}
                fullWidth
                type={type}
                error={showError}
                disabled={disabled}
            />
            {showError && errorMessage && (
                <span
                    style={{
                        color: "#f00",
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        fontSize: 12,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
