import { TableCell, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { IMessageIDS, t } from "../../i18n/util";
import { Colors } from "../util/Colors";

export type IHeaderField = {
    column: string;
    label?: IMessageIDS;
    style?: React.CSSProperties;
};

export const TableHeader = observer(({ headerFields }: { headerFields: IHeaderField[] }) => (
    <TableHead>
        <TableRow style={{ height: 44 }}>
            {headerFields.map(({ column, label, style }) => (
                <TableCell key={column} style={{ textAlign: "center", padding: "0 16px", ...style }}>
                    {label && <div style={{ color: Colors.TEXT, fontSize: 16 }}>{t(label as IMessageIDS)}</div>}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
));
