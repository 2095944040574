import { Dialog, Divider } from "@mui/material";
import { styled } from "@mui/styles";
import { t } from "../../i18n/util";
import { Colors } from "../util/Colors";
import { CustomDialogActions } from "./CustomDialogActions";

type IProps = {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
    title: string;
    text: string;
    submitText?: string;
    hideCancel?: boolean;
};

export const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        borderRadius: 12,
    },
});

export const DialogText = styled("div")({
    maxWidth: 410,
    fontSize: 16,
    fontWeight: 400,
    color: Colors.DISABLED,
});

export const CustomDialog = ({ open, onSubmit, onClose, title, text, submitText, hideCancel }: IProps) => (
    <StyledDialog open={open}>
        <div style={{ padding: 16 }}>
            <h4 style={{ marginBottom: 8 }}>{title}</h4>
            <DialogText>{text}</DialogText>
        </div>
        <Divider />
        <div style={{ padding: 16 }}>
            <CustomDialogActions
                onSubmit={onSubmit}
                onClose={onClose}
                hideCancel={hideCancel}
                cancelText={t("common.cancel")}
                submitText={submitText ?? t("common.save")}
            />
        </div>
    </StyledDialog>
);
