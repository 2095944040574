import { Divider } from "@mui/material";
import { compact, get } from "lodash";
import * as React from "react";
import styled from "styled-components";
import { IMessageIDS, t } from "../../i18n/util";
import { CheckupReport } from "../../network/APITypes";
import { IconContainer } from "../ui/IconContainer";
import { Colors } from "../util/Colors";
import { ImageDiagnose, ImageMedikation } from "../util/Images";
import {
    getAbdominalGirthColor,
    getBiaDotPosition,
    getBiaValueColor,
    getBloodpressureColor,
    getConspicuousnessColor,
    getExerciseColor,
    getExerciseErgometricPercentColor,
    getRelaxationColor,
    getSmokerColor,
    getUltrasoundColor,
} from "../util/ValueDisplayHelpers";
import { CustomCard, Title } from "./sites/CheckupReportSite";

type IProps = {
    checkupReport?: CheckupReport | null;
};

type IIcon = React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
>;

const RecommendationContainer = styled("div")({
    padding: "12px 16px",
    display: "flex",
    alignItems: "center",
});

const RecommendationText = styled("div")({
    fontWeight: 400,
    color: "#4F4F4F",
    padding: 16,
    whiteSpace: "pre-line",
});

const doctorRecommendationItems: {
    name: string;
    title: IMessageIDS;
    icon?: React.ReactNode;
}[] = [
    {
        name: "general.diagnosis",
        title: "screen.checkupReport.doctorRecommendation.diagnosis.title",
        icon: (
            <IconContainer>
                <ImageDiagnose />
            </IconContainer>
        ),
    },
    {
        name: "general.medication",
        title: "screen.checkupReport.doctorRecommendation.medication.title",
        icon: (
            <IconContainer>
                <ImageMedikation />
            </IconContainer>
        ),
    },
    {
        name: "general.further",
        title: "screen.checkupReport.doctorRecommendation.further.title",
    },
];

const recommendationTitles: { [key: string]: IMessageIDS } = {
    bloodpanel: "screen.checkupReport.doctorRecommendation.bloodpanel.title",
    "general.vaccinations": "screen.checkupReport.doctorRecommendation.general.vaccinations.title",
    stoolsample: "screen.checkupReport.doctorRecommendation.stoolsample.title",
    "lifestyle.abdominalGirth": "screen.checkupReport.doctorRecommendation.lifestyle.abdominalGirth.title",
    "lifestyle.bloodpressure": "screen.checkupReport.doctorRecommendation.lifestyle.bloodpressure.title",
    "lifestyle.exercise": "screen.checkupReport.doctorRecommendation.lifestyle.exercise.title",
    "lifestyle.smoker": "screen.checkupReport.doctorRecommendation.lifestyle.smoker.title",
    bia: "screen.checkupReport.doctorRecommendation.bia.title",
    ecg: "screen.checkupReport.doctorRecommendation.ecg.title",
    relaxation: "screen.checkupReport.doctorRecommendation.relaxation.title",
    spirometric: "screen.checkupReport.doctorRecommendation.spirometric.title",
    exerciseErgometric: "screen.checkupReport.doctorRecommendation.exerciseErgometric.title",
    "ultrasound.carotis": "screen.checkupReport.doctorRecommendation.ultrasound.carotis.title",
    "ultrasound.stomach": "screen.checkupReport.doctorRecommendation.ultrasound.stomach.title",
    "ultrasound.heart": "screen.checkupReport.doctorRecommendation.ultrasound.heart.title",
    "ultrasound.thyroid": "screen.checkupReport.doctorRecommendation.ultrasound.thyroid.title",
};

const Recommendation = ({
    title,
    recommendation,
    Icon,
}: {
    title: IMessageIDS;
    recommendation?: string;
    Icon?: React.ReactNode;
}) => (
    <>
        <RecommendationContainer>
            {Icon}
            {t(title)}
        </RecommendationContainer>
        <Divider />
        <div style={{ padding: 16, fontSize: 16, fontWeight: 400, color: "#4F4F4F", whiteSpace: "pre-wrap" }}>
            {recommendation}
        </div>
    </>
);

export const DoctorRecommendation = ({ checkupReport }: IProps) => {
    const stackedRecommendations = checkupReport?.recommendations?.latest?.savedTexts?.filter((recommendation) => {
        const hiddenRecommendation = doctorRecommendationItems.find((item) => item.name === recommendation.category);

        const hideRecommendation =
            hiddenRecommendation || !recommendation.text || recommendation.category === "general.patientstatus";

        if (hideRecommendation) {
            return false;
        }

        switch (recommendation.category) {
            case "stoolsample": {
                const color = getConspicuousnessColor(checkupReport?.stoolSample?.latest?.value);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "lifestyle.abdominalGirth": {
                const color = getAbdominalGirthColor(
                    checkupReport?.lifestyle?.latest?.abdominalGirth,
                    checkupReport?.patientInformation?.sex,
                );

                return color === Colors.VALUE_DISPLAY_ORANGE || color === Colors.VALUE_DISPLAY_RED;
            }
            case "lifestyle.exercise": {
                const color = getExerciseColor(checkupReport?.lifestyle?.latest?.exerciseDropdown);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "lifestyle.smoker": {
                const color = getSmokerColor(checkupReport?.lifestyle?.latest?.smokerDropdown);

                return color === Colors.VALUE_DISPLAY_ORANGE || color === Colors.VALUE_DISPLAY_RED;
            }
            case "lifestyle.bloodpressure": {
                const color = getBloodpressureColor(checkupReport?.lifestyle?.latest?.bloodpressureDropdown);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "bia": {
                const dotPositionMuscle = getBiaDotPosition({ results: checkupReport?.bia?.latest, name: "muscle" });
                const dotPositionFat = getBiaDotPosition({ results: checkupReport?.bia?.latest, name: "fat" });
                const dotPositionFatPercent = getBiaDotPosition({
                    results: checkupReport?.bia?.latest,
                    name: "fatPercent",
                });

                const colorMuscle = getBiaValueColor(dotPositionMuscle);
                const colorFat = getBiaValueColor(dotPositionFat);
                const colorFatPercent = getBiaValueColor(dotPositionFatPercent);

                return (
                    colorMuscle === Colors.VALUE_DISPLAY_ORANGE ||
                    colorMuscle === Colors.VALUE_DISPLAY_RED ||
                    colorFat === Colors.VALUE_DISPLAY_ORANGE ||
                    colorFat === Colors.VALUE_DISPLAY_RED ||
                    colorFatPercent === Colors.VALUE_DISPLAY_ORANGE ||
                    colorFatPercent === Colors.VALUE_DISPLAY_RED
                );
            }
            case "ecg": {
                const color = getConspicuousnessColor(checkupReport?.ecg?.latest?.assessment);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "exerciseErgometric": {
                const colorHeartRate = getConspicuousnessColor(checkupReport?.exercise?.latest?.heartRate);
                const colorPercent = getExerciseErgometricPercentColor(checkupReport?.exercise?.latest?.percent);

                return (
                    colorHeartRate === Colors.VALUE_DISPLAY_ORANGE ||
                    colorPercent === Colors.VALUE_DISPLAY_ORANGE ||
                    colorPercent === Colors.VALUE_DISPLAY_RED
                );
            }
            case "relaxation": {
                const color = getRelaxationColor(checkupReport?.relaxation?.latest?.value);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "spirometric": {
                const color = getConspicuousnessColor(checkupReport?.spirometric?.latest?.assessment);
                // there shouldn't be the need to additionally check for medicalCondition since it's only available when the assessment is conspicious

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "ultrasound.heart": {
                const color = getUltrasoundColor(checkupReport?.ultrasound?.heart?.value);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "ultrasound.carotis": {
                const color = getUltrasoundColor(checkupReport?.ultrasound?.carotis?.value);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "ultrasound.stomach": {
                const color = getUltrasoundColor(checkupReport?.ultrasound?.stomach?.value);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            case "ultrasound.thyroid": {
                const color = getUltrasoundColor(checkupReport?.ultrasound?.thyroid?.value);

                return color === Colors.VALUE_DISPLAY_ORANGE;
            }
            default: {
                return true;
            }
        }
    });

    const doctorRecommendations = compact(
        doctorRecommendationItems.map((item) => {
            const recommendation = checkupReport?.recommendations?.latest?.savedTexts?.find(
                (recommendationItem) => recommendationItem.category === item.name,
            );

            return recommendation ? { ...recommendation, title: item.title, icon: item.icon } : undefined;
        }),
    );

    if (doctorRecommendations?.length === 0 && stackedRecommendations?.length === 0) {
        return null;
    }

    const showDoctorRecommendations = doctorRecommendations.filter((item) => item.text).length > 0;
    const showStackedRecommendations = stackedRecommendations && stackedRecommendations.length > 0;

    return (
        <>
            {(showDoctorRecommendations || showStackedRecommendations) && (
                <Title>{t("screen.checkupReport.doctorRecommendation.title")}</Title>
            )}
            {showDoctorRecommendations &&
                doctorRecommendations.map((item) => {
                    if (!item || !item.text) {
                        return null;
                    }

                    return (
                        <CustomCard key={item.category}>
                            <Recommendation title={item.title} recommendation={item.text} Icon={item.icon} />
                        </CustomCard>
                    );
                })}
            {showStackedRecommendations && (
                <CustomCard>
                    {stackedRecommendations?.map((recommendation, index) => {
                        const categoryTitle =
                            recommendation.category && recommendationTitles[recommendation.category]
                                ? t(recommendationTitles[recommendation.category])
                                : recommendation.category;

                        return (
                            <React.Fragment key={recommendation.category}>
                                {index !== 0 && <Divider />}
                                <div
                                    style={{
                                        padding: "12px 16px",
                                        backgroundColor: "#FCFCFC",
                                        borderTopRightRadius: index === 0 ? 12 : undefined,
                                        borderTopLeftRadius: index === 0 ? 12 : undefined,
                                    }}
                                >
                                    {categoryTitle}
                                </div>
                                <Divider />
                                <RecommendationText>{recommendation.text}</RecommendationText>
                            </React.Fragment>
                        );
                    })}
                </CustomCard>
            )}
        </>
    );
};
