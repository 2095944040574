import { Divider, IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import { Field, useFormikContext } from "formik";
import * as React from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/ic_edit.svg";
import { ReactComponent as LoadingIcon } from "../../assets/icons/ic_loading.svg";
import { IMessageIDS, t } from "../../i18n/util";
import { patientStore } from "../../stores/PatientStore";
import { CustomTextField } from "../ui/CustomTextField";
import { Colors } from "../util/Colors";
import { getRecommendationSiteOptions } from "../util/helpers";

type IProps = {
    disabled?: boolean;
    onClickJumpToSection?: () => void;
    onEdit?: () => void;
};

const Subtitle = styled("div")({
    fontSize: 16,
    fontWeight: 400,
    color: Colors.DISABLED,
});

const SubtitleContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
});

const SectionTitleContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    margin: "12px 16px",
});

const fieldStyle = {
    padding: 16,
};

const items: { name: string; title?: IMessageIDS }[] = [
    {
        name: "bloodpressure",
    },
    {
        name: "exercise",
        title: "screen.patientInformation.field.lifestyleParameters.activity",
    },
    {
        name: "abdominalGirth",
        title: "screen.patientInformation.field.lifestyleParameters.abdominalGirth",
    },
    {
        name: "smoker",
        title: "screen.patientInformation.field.lifestyleParameters.smoker",
    },
];

const SectionTitle = ({
    title,
    subtitle,
    onClickRestore,
    onClickJumpToSection,
}: {
    title?: IMessageIDS;
    subtitle?: string;
    onClickRestore: () => void;
    onClickJumpToSection?: () => void;
}) => (
    <div style={{ height: subtitle ? 65 : undefined }}>
        <Divider />
        <SectionTitleContainer>
            <SubtitleContainer>
                {title && t(title)}
                <Subtitle>{subtitle}</Subtitle>
            </SubtitleContainer>
            <div>
                <IconButton onClick={onClickRestore}>
                    <LoadingIcon />
                </IconButton>
                <IconButton onClick={onClickJumpToSection}>
                    <EditIcon />
                </IconButton>
            </div>
        </SectionTitleContainer>
        <Divider />
    </div>
);

export const LifestyleParametersRecommendation = ({ disabled, onClickJumpToSection, onEdit }: IProps) => {
    const { values, setFieldValue } = useFormikContext<any>();

    const options = getRecommendationSiteOptions(values).lifestyle;

    const handleClickRestore = (itemName: string) => {
        const currentText = values[itemName]?.textInput ?? "";
        const templateText =
            patientStore.checkupRecommendations?.lifestyle?.[itemName]?.templateTexts?.[0]?.templateText ?? "";

        if (onEdit && disabled && currentText !== templateText) {
            onEdit();
        }

        setFieldValue(`${itemName}.textInput`, templateText);
    };

    return (
        <>
            {items.map((item) => (
                <React.Fragment key={item.name}>
                    {item.name !== "bloodpressure" && (
                        <SectionTitle
                            title={item.title}
                            subtitle={options?.[item.name]}
                            onClickRestore={() => handleClickRestore(item.name)}
                            onClickJumpToSection={onClickJumpToSection}
                        />
                    )}
                    <Field
                        style={fieldStyle}
                        name={`${item.name}.textInput`}
                        component={CustomTextField}
                        minRows={2}
                        disabled={disabled}
                    />
                </React.Fragment>
            ))}
        </>
    );
};
