import { Button, DialogActions, Link, styled } from "@mui/material";
import * as React from "react";

type IProps = {
    onSubmit?: () => void;
    onClose: () => void;
    cancelText: string;
    submitText: string;
    hideCancel?: boolean;
    typeSubmit?: boolean;
};

const StyledButton = styled(Button)({
    height: 48,
    borderRadius: 8,
    textTransform: "none",
    fontSize: 16,
    fontWeight: 500,
});

export const CustomDialogActions = ({ onSubmit, onClose, cancelText, submitText, hideCancel, typeSubmit }: IProps) => (
    <DialogActions style={{ padding: 0 }}>
        {!hideCancel && (
            <Link
                component="button"
                underline="none"
                color="inherit"
                onClick={(e) => {
                    e.preventDefault();
                    onClose();
                }}
                style={{ marginRight: 16, color: "black" }}
                type="button"
            >
                {cancelText}
            </Link>
        )}
        <StyledButton onClick={onSubmit} variant="contained" type={typeSubmit ? "submit" : undefined}>
            {submitText}
        </StyledButton>
    </DialogActions>
);
