import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/styles";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ReactComponent as ChevronLeftIcon } from "../../assets/icons/ic_chevron_left.svg";
import { ReactComponent as UserProfileIcon } from "../../assets/icons/ic_user_profile.svg";
import { ReactComponent as Logo } from "../../assets/images/logo_minified_white.svg";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { patientStore } from "../../stores/PatientStore";
import { Routes } from "../app/router/Routes";

const MenuContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
});

const BackContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
});

const FlexContainer = styled("div")({
    display: "flex",
    alignItems: "center",
});

export const NavigationBar = observer(() => {
    const { checkupId } = useParams<{ checkupId?: string }>();
    const patientName = `${patientStore.firstName} ${patientStore.lastName}`;
    const [ProfileAnchorEl, SetProfileAnchorEl] = React.useState<null | HTMLElement>(null);

    const history = useHistory();
    const location = useLocation();

    const handleClickOpenProfile = (event: React.MouseEvent<HTMLElement>) => {
        SetProfileAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        SetProfileAnchorEl(null);
    };

    const handleClickGoBack = () => {
        if (authStore.isDoctor) {
            history.push(Routes.DOCTOR.OVERVIEW);
        } else if (authStore.isPatient) {
            history.push(Routes.PATIENT.WELCOME);
        }
    };

    const handleClickLogout = () => {
        authStore.preventLastLocationRedirect = true;
        authStore.logout();
    };

    const isPatientOverview = location.pathname === Routes.DOCTOR.OVERVIEW;
    const isDoctorCheckup = location.pathname.startsWith(Routes.DOCTOR.CHECKUP.replace(":checkupId", checkupId ?? ""));
    const isDoctorHistory = location.pathname.startsWith(Routes.DOCTOR.HISTORY.replace(":checkupId", checkupId ?? ""));
    const isPatientHistory = location.pathname.startsWith(Routes.PATIENT.HISTORY);
    const isDoctorReport =
        location.pathname.startsWith(Routes.CHECKUP_REPORT.replace(":checkupId", checkupId ?? "")) &&
        authStore.userProfile?.scopes?.includes("doctor");
    const isPatientReport =
        location.pathname.startsWith(Routes.CHECKUP_REPORT.replace(":checkupId", checkupId ?? "")) &&
        authStore.userProfile?.scopes?.includes("patient");

    return (
        <>
            <AppBar>
                <Toolbar>
                    <FlexContainer style={{ justifyContent: "space-between", width: "100%" }}>
                        <FlexContainer>
                            {isPatientOverview && <Logo style={{ width: 28, height: 28 }}></Logo>}
                            {(isDoctorCheckup ||
                                isDoctorReport ||
                                isPatientReport ||
                                isDoctorHistory ||
                                isPatientHistory) && (
                                <BackContainer onClick={handleClickGoBack}>
                                    <ChevronLeftIcon fill="#ACE3EC" style={{ marginRight: 4 }} />
                                    {isDoctorCheckup && patientName}
                                    {(isPatientReport || isDoctorHistory || isPatientHistory || isDoctorReport) &&
                                        t("navbar.backToOverview")}
                                </BackContainer>
                            )}
                        </FlexContainer>

                        {/* TODO: add dropdown for checkups */}
                        {isPatientOverview && <div>{t("navbar.patientOverview")}</div>}
                        {(isDoctorCheckup || isDoctorReport || isPatientReport) && (
                            <div>
                                {t("navbar.checkup")}{" "}
                                {isPatientReport || isDoctorReport
                                    ? dayjs(patientStore.checkupReport?.date).format("DD. MMM YYYY")
                                    : dayjs(patientStore.checkup?.checkupDate).format("DD. MMM YYYY")}
                            </div>
                        )}

                        <MenuContainer>
                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleClickOpenProfile}
                            >
                                <UserProfileIcon fill="white" />
                            </IconButton>
                            <Menu
                                anchorEl={ProfileAnchorEl}
                                open={!!ProfileAnchorEl}
                                onClose={handleCloseMenu}
                                onClick={handleCloseMenu}
                            >
                                <MenuItem onClick={handleClickLogout}>{t("navbar.logout")}</MenuItem>
                            </Menu>
                        </MenuContainer>
                    </FlexContainer>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    );
});
