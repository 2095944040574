import { Divider } from "@mui/material";
import * as React from "react";
import { t } from "../../i18n/util";
import { CardTitle } from "../ui/CardTitle";
import { CustomCard } from "./sites/CheckupReportSite";

export const Status = ({ status }: { status?: string }) => {
    if (!status) {
        return null;
    }

    return (
        <CustomCard>
            <CardTitle>{t("screen.checkupReport.status.title")}</CardTitle>
            <Divider />
            <div style={{ padding: 16, fontWeight: 400, fontSize: 16, color: "#4F4F4F", whiteSpace: "pre-wrap" }}>
                {status}
            </div>
        </CustomCard>
    );
};
