import { styled } from "@mui/styles";
import { Field, useFormikContext } from "formik";
import { t } from "../../i18n/util";
import { IFormField, IFormFieldMessageIDS } from "../../types";
import { CustomInputField } from "../ui/CustomInputField";
import { CustomSelect } from "../ui/CustomSelect";
import { StyledInputLabel } from "../ui/StyledInputLabel";
import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { Colors } from "../util/Colors";

type IPatientInformation = {
    disabled?: boolean;
};

const Container = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 16,
});

const bloodpressureDropdownOptions = [
    { value: "notSpecified", label: "common.notSpecified" },
    { value: "conspicuous", label: "common.conspicuous" },
    { value: "normal", label: "common.normal" },
];

const exerciseDropdownOptions = [
    { value: "notSpecified", label: "common.notSpecified" },
    { value: "none", label: "screen.patientInformation.field.lifestyleParameters.activity.noActivity" },
    { value: "lessThan2h5", label: "screen.patientInformation.field.lifestyleParameters.activity.lessThan2h5" },
    { value: "moreThan2h5", label: "screen.patientInformation.field.lifestyleParameters.activity.moreThan2h5" },
];
const smokerDropdownOptions = [
    { value: "nonSmoker", label: "screen.patientInformation.field.lifestyleParameters.smokingStatus.nonSmoker" },
    {
        value: "occasionalSmoker",
        label: "screen.patientInformation.field.lifestyleParameters.smokingStatus.occasionalSmoker",
    },
    { value: "smoker", label: "screen.patientInformation.field.lifestyleParameters.smokingStatus.smoker" },
    { value: "exSmoker", label: "screen.patientInformation.field.lifestyleParameters.smokingStatus.exSmoker" },
];

const fields: IFormFieldMessageIDS[] = [
    {
        name: "lifestyleParameters.height",
        label: "screen.patientInformation.field.lifestyleParameters.height",
        type: "number",
        adornmentText: "common.centimeter",
    },
    {
        name: "lifestyleParameters.weight",
        label: "screen.patientInformation.field.lifestyleParameters.weight",
        type: "number",
        adornmentText: "common.kilogram",
    },
    {
        name: "lifestyleParameters.bmi",
        label: "screen.patientInformation.field.lifestyleParameters.bmi",
        type: "number",
        disabled: true,
        style: { color: Colors.DISABLED },
    },
    {
        name: "lifestyleParameters.abdominalGirth",
        label: "screen.patientInformation.field.lifestyleParameters.abdominalGirth",
        type: "number",
        adornmentText: "common.centimeter",
    },
    {
        name: "lifestyleParameters.exerciseDropdown",
        label: "screen.patientInformation.field.lifestyleParameters.activity",
        component: CustomSelect,
        options: exerciseDropdownOptions,
    },
    {
        name: "lifestyleParameters.smokerDropdown",
        label: "screen.patientInformation.field.lifestyleParameters.smokingStatus",
        component: CustomSelect,
        options: smokerDropdownOptions,
    },
    {
        name: "lifestyleParameters.bloodpressure",
        label: "screen.patientInformation.field.lifestyleParameters.bloodPressure",
        items: [
            {
                name: "lifestyleParameters.bloodpressure.systole",
                type: "number",
                label: "screen.patientInformation.field.exerciseErgometric.systole",
            },
            {
                name: "lifestyleParameters.bloodpressure.diastole",
                type: "number",
                label: "screen.patientInformation.field.exerciseErgometric.diastole",
            },
        ],
    },
    {
        name: "lifestyleParameters.bloodpressureDropdown",
        label: "screen.patientInformation.field.lifestyleParameters.bloodPressureStatus",
        component: CustomSelect,
        options: bloodpressureDropdownOptions,
    },
];

export const LifestyleParameters = ({ disabled }: IPatientInformation) => {
    const { values, setFieldValue } = useFormikContext<any>();

    const weight = values.lifestyleParameters.weight;
    const height = values.lifestyleParameters.height;

    useDeepCompareEffect(() => {
        if (weight && height) {
            const calculatedBmi = Math.round((weight / Math.pow(height / 100, 2)) * 100) / 100;

            setFieldValue("lifestyleParameters.bmi", calculatedBmi);
        }
    }, [height, weight, setFieldValue]);

    return (
        <Container>
            {fields.map((field) =>
                field.items ? (
                    <div key={field.name}>
                        <StyledInputLabel htmlFor={field.name}>{field.label && t(field.label)}</StyledInputLabel>
                        <div style={{ display: "flex", width: "100%" }}>
                            {field.items.map((item, index) => (
                                <Field
                                    key={item.name}
                                    component={CustomInputField}
                                    label={t(item.label)}
                                    ignoreHeaderLabel={item.label}
                                    paddingTop={item.label ? 8 : 0}
                                    name={item.name}
                                    type={item.type}
                                    disabled={disabled || field.disabled}
                                    style={{ width: "100%", marginRight: index === 0 ? "1rem" : undefined }}
                                    min={item.type === "number" ? 0 : undefined}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <Field
                        key={field.name}
                        component={field.component ?? CustomInputField}
                        label={field.label && t(field.label)}
                        name={field.name}
                        type={field.type ?? "input"}
                        disabled={disabled || field.disabled}
                        onChange={field.onChange}
                        options={field.options}
                        ignoreHeaderLabel={field.ignoreHeaderLabel}
                        paddingTop={field.ignoreHeaderLabel ? 8 : 0}
                        adornmentText={field.adornmentText && t(field.adornmentText)}
                        min={field.type === "number" ? 0 : undefined}
                    />
                ),
            )}
        </Container>
    );
};
