/* eslint-disable no-throw-literal */

import * as queryString from "query-string";
import * as Config from "../config";
import { APIError } from "../errors/APIError";
import { authStore, ICredentials } from "../stores/AuthStore";
import {
    Attachment,
    BloodpanelHL7File,
    Checkup,
    CheckupRecommendations,
    CheckupReport,
    GetUserInfoResponse,
    OrderDir,
    PatientCheckupOverview,
    PatientCheckupReportList,
    PostLoginResponse,
} from "./APITypes";
import {
    APIClientStatusCodeError,
    getAuthHeaders,
    IAPITarget,
    injectHeaders,
    sanitizeFilename,
    urljoin,
} from "./helpers";

export const STATUS_CODE_UNAUTHORIZED = 401;

const throwOnErrorStatusCodes = true;

export const isUnauthorizedError = (error: any) => error.statusCode === STATUS_CODE_UNAUTHORIZED;

const handleUnauthorizedError = (error: any): any => {
    if (error.statusCode && isUnauthorizedError(error)) {
        authStore.logout();
    }
};

const uploadFormData = async (target: IAPITarget) => {
    const headers = injectHeaders(target);

    // FormData with fetch must not have Content-Type set. This gets set automatically by
    // fetch()
    delete headers["Content-Type"];

    let requestUrl = urljoin(Config.API_BASE_URL, target.url);

    if (target.queryParameters) {
        const query = queryString.stringify(target.queryParameters);
        if (query) {
            requestUrl = urljoin(requestUrl, `?${query}`);
        }
    }

    const options: any = {
        method: target.method || "GET",
        body: target.body,
        headers: headers as any,
    };
    const response = await fetch(requestUrl, options);
    if (throwOnErrorStatusCodes) {
        if (response.status >= 200 && response.status < 400) {
            return response;
        }
        throw new APIClientStatusCodeError(response, response.status);
    }

    return response;
};

const uploadFormDataJSON = async (target: IAPITarget): Promise<object> => {
    return (await uploadFormData(target)).json();
};

const request = async ({
    path,
    method,
    body,
    appendAuthToken,
    errors,
    blob,
    headers = {},
    queryParameters,
    emptyResponse,
}: {
    path: string;
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
    body?: any;
    appendAuthToken?: boolean;
    errors?: { [code: string]: string };
    blob?: boolean;
    headers?: {
        [header: string]: string;
    };
    queryParameters?: {
        [param: string]: any;
    };
    emptyResponse?: boolean;
}) => {
    try {
        const params = queryParameters ? `?${queryString.stringify(queryParameters)}` : "";
        const response = await fetch(`${Config.API_BASE_URL}/api/v1${path}${params}`, {
            method,
            headers: {
                ...(appendAuthToken ? getAuthHeaders() : {}),
                ...headers,
            },
            ...(body ? { body } : {}),
        });

        if (!response.ok) {
            throw {
                statusCode: response.status,
                statusText: errors ? errors[response.status] : response.statusText,
            };
        }

        if (emptyResponse) {
            return response;
        } else {
            return blob ? response.blob() : response.json();
        }
    } catch (error) {
        handleUnauthorizedError(error);
        throw error;
    }
};

export const API = {
    async loginWithPassword(options: { username: string; password: string }): Promise<ICredentials> {
        try {
            const response = await fetch(`${Config.API_BASE_URL}/api/v1/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: options.username,
                    password: options.password,
                }),
            });

            if (!response.ok) {
                throw new APIError(response.status, response.statusText);
            }

            return response.json();
        } catch (error) {
            if (error instanceof APIError) {
                handleUnauthorizedError(error);
            }
            throw error;
        }
    },

    getCheckup(params: { checkupId: string }): Promise<Checkup> {
        return request({ path: `/checkup/${params.checkupId}`, method: "GET", appendAuthToken: true });
    },

    getCheckupRecommendations(params: { checkupId: string }): Promise<CheckupRecommendations> {
        return request({ path: `/checkup/${params.checkupId}/recommendations`, method: "GET", appendAuthToken: true });
    },

    getPatientCheckupOverview(queryParameters?: {
        offset?: number;
        limit?: number;
        orderDir?: OrderDir;
        query?: string;
    }): Promise<PatientCheckupOverview> {
        return request({ path: "/checkup/list", method: "GET", appendAuthToken: true, queryParameters });
    },

    patchCheckup(params: { checkupId: string; checkup: Checkup }): Promise<Checkup> {
        return request({
            path: `/checkup/${params.checkupId}`,
            method: "PATCH",
            appendAuthToken: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params.checkup),
        });
    },

    patchCheckupRecommendations(params: {
        checkupId: string;
        recommendations: CheckupRecommendations;
    }): Promise<CheckupRecommendations> {
        return request({
            path: `/checkup/${params.checkupId}/recommendations`,
            method: "PATCH",
            appendAuthToken: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params.recommendations),
        });
    },

    uploadHl7(params: { file: File; checkupId: string }): Promise<BloodpanelHL7File> {
        const data = new FormData();
        data.append("file", params.file);
        data.append("name", sanitizeFilename(params.file.name));

        return uploadFormDataJSON({
            url: `/api/v1/checkup/${params.checkupId}/hl7upload`,
            method: "POST",
            body: data,
        });
    },

    uploadAttachments(params: { file: File; checkupId: string }): Promise<Attachment> {
        const data = new FormData();
        data.append("file", params.file);
        data.append("name", sanitizeFilename(params.file.name));

        return uploadFormDataJSON({
            url: `/api/v1/checkup/${params.checkupId}/attachments`,
            method: "POST",
            body: data,
        });
    },

    deleteHl7File(params: { hl7File: BloodpanelHL7File; checkupId: string }): Promise<BloodpanelHL7File[]> {
        return request({
            path: `/checkup/${params.checkupId}/hl7upload`,
            method: "DELETE",
            appendAuthToken: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params.hl7File),
        });
    },

    deleteAttachment(params: { attachment: Attachment; checkupId: string }): Promise<Attachment[]> {
        return request({
            path: `/checkup/${params.checkupId}/attachments`,
            method: "DELETE",
            appendAuthToken: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params.attachment),
        });
    },

    createNewCheckup(params: { email: string; password: string; patientId: string }): Promise<Checkup> {
        return request({
            path: "/checkup/new",
            method: "POST",
            appendAuthToken: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: params.email, password: params.password, patientId: params.patientId }),
        });
    },

    createNewCheckupForPatient(params: { patientId: string }): Promise<Checkup> {
        return request({ path: `/checkup/new/${params.patientId}`, method: "POST", appendAuthToken: true });
    },

    changePatientPassword(params: { patientId: string; newPassword: string }): Promise<Checkup> {
        return request({
            path: "/checkup/change-patient-password",
            method: "POST",
            body: JSON.stringify({
                newPassword: params.newPassword,
                patientID: params.patientId
            }),
            headers: {
                "Content-Type": "application/json",
            },
            appendAuthToken: true,
            emptyResponse: true
        });
    },

    register(params: { username: string; password: string }): Promise<PostLoginResponse> {
        const body = JSON.stringify({ password: params.password, username: params.username });

        return request({
            path: "/auth/register",
            method: "POST",
            body,
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    getPatientCheckups(): Promise<PatientCheckupReportList> {
        return request({
            path: "/patient",
            method: "GET",
            appendAuthToken: true,
        });
    },

    getUserInfo(): Promise<GetUserInfoResponse> {
        return request({
            path: "/auth/userinfo",
            method: "GET",
            appendAuthToken: true,
        });
    },

    releaseCheckup(params: { checkupId: string }): Promise<void> {
        return request({
            path: `/checkup/${params.checkupId}/release`,
            method: "POST",
            appendAuthToken: true,
            emptyResponse: true,
        });
    },

    getDoctorCheckupReport(params: { checkupId: string }): Promise<CheckupReport> {
        return request({
            path: `/checkup/${params.checkupId}/report`,
            method: "GET",
            appendAuthToken: true,
        });
    },

    getPatientCheckupReport(params: { checkupId: string }): Promise<CheckupReport> {
        return request({
            path: `/patient/${params.checkupId}`,
            method: "GET",
            appendAuthToken: true,
        });
    },

    downloadAttachmentPatient(params: { checkupId: string; attachmentHash: string }): Promise<Blob> {
        return request({
            path: `/patient/${params.checkupId}/attachment/${params.attachmentHash}`,
            method: "GET",
            appendAuthToken: true,
            blob: true,
        });
    },

    downloadAttachmentDoctor(params: { checkupId: string; attachmentHash: string }): Promise<Blob> {
        return request({
            path: `/checkup/${params.checkupId}/attachment/${params.attachmentHash}`,
            method: "GET",
            appendAuthToken: true,
            blob: true,
        });
    },

    forgotPassword(params: { email: string }): Promise<void> {
        return request({
            path: "/auth/forgot-password",
            method: "POST",
            body: JSON.stringify({ username: params.email }),
            headers: {
                "Content-Type": "application/json",
            },
            emptyResponse: true,
        });
    },

    resetPassword(params: { token: string }): Promise<void> {
        return request({
            path: "/auth/forgot-password/complete",
            method: "POST",
            body: JSON.stringify({ token: params.token }),
            headers: {
                "Content-Type": "application/json",
            },
            emptyResponse: true,
        });
    },
};
