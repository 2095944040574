import { AddCircleRounded, KeyboardArrowDown, KeyboardArrowUp, Password, History } from "@mui/icons-material";
import { styled, TableCell, TableRow, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import * as React from "react";
import { t } from "../../i18n/util";
import { IPatient } from "../../types";
import { IHeaderField } from "../ui/TableHeader";
import { TableLabel } from "../ui/TableLabel";
import { TableLabelName } from "../ui/TableLabelName";
import { Colors } from "../util/Colors";
import { formatDate } from "../util/helpers";

type IExpandIconProps = {
    isExpanded: boolean;
    onClick: () => void;
    style: React.CSSProperties;
};

type IProps = {
    patient: IPatient;
    headerFields: IHeaderField[];
    isLastRow: boolean;
    onClickExpand: (patientId: string) => void;
    onClickCreateCheckup: (patientId: string) => void;
    onClickChangePassword: (patient: IPatient) => void;
    onClickHistory?: () => void;
};

const StyledTableCell = styled(TableCell, {
    shouldForwardProp: (prop: string) => prop !== "isLastRow",
})({
    border: ({ isLastRow }: { isLastRow: boolean }) => (isLastRow ? "none" : ""),
});

const ExpandIcon = ({ isExpanded, ...props }: IExpandIconProps) =>
    isExpanded ? <KeyboardArrowUp {...props} /> : <KeyboardArrowDown {...props} />;

export const PatientRow = ({
    patient,
    headerFields,
    isLastRow,
    onClickExpand,
    onClickCreateCheckup,
    onClickChangePassword,
    onClickHistory,
}: IProps) => {
    const handleClickExpand = () => {
        onClickExpand(patient.patientId);
    };

    return (
        <TableRow>
            {headerFields.map((headerField) => {
                let label = patient[headerField.column];
                if (headerField.column === "expandCell") {
                    return (
                        <StyledTableCell key={headerField.column} isLastRow={isLastRow}>
                            <ExpandIcon
                                isExpanded={patient.isExpanded}
                                onClick={handleClickExpand}
                                style={{ fontSize: 28, color: Colors.TEXT, cursor: "pointer" }}
                            />
                        </StyledTableCell>
                    );
                } else if (headerField.column === "name") {
                    return (
                        <StyledTableCell isLastRow={isLastRow} key={headerField.column}>
                            <TableLabelName>{patient.lastName ?? ""}</TableLabelName>
                            <TableLabelName style={{ color: Colors.TEXT }}>{patient.firstName ?? ""}</TableLabelName>
                        </StyledTableCell>
                    );
                } else if (headerField.column === "patientId") {
                    label = patient.patientId;
                } else if (headerField.column === "sex") {
                    if (patient.sex === "male") {
                        label = t("common.male");
                    } else if (patient.sex === "female") {
                        label = t("common.female");
                    }
                } else if (headerField.column === "lastCheckup") {
                    label = formatDate(patient.lastCheckup);
                } else if (headerField.column === "totalCheckups") {
                    label = patient.totalCheckups;
                } else if (headerField.column === "doctor") {
                    return (
                        <StyledTableCell isLastRow={isLastRow} key={headerField.column}>
                            <TableLabelName>{patient.locumDoctor ?? ""}</TableLabelName>
                        </StyledTableCell>
                    );
                } else if (headerField.column === "changePassword") {
                    return (
                        <StyledTableCell isLastRow={isLastRow} key={headerField.column} style={{ textAlign: "end" }}>
                            <Tooltip title={t("screen.overview.table.action.changePassword")}>
                                <Password
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => onClickChangePassword(patient)}
                                />
                            </Tooltip>
                        </StyledTableCell>
                    );
                } else if (headerField.column === "history" && onClickHistory) {
                    return (
                        <StyledTableCell isLastRow={isLastRow} key={headerField.column} style={{ textAlign: "end" }}>
                            <Tooltip title={t("screen.overview.table.action.showHistory")}>
                                <History color="primary" style={{ cursor: "pointer" }} onClick={onClickHistory} />
                            </Tooltip>
                        </StyledTableCell>
                    );
                } else if (headerField.column === "addPatient") {
                    return (
                        <StyledTableCell isLastRow={isLastRow} key={headerField.column} style={{ textAlign: "end" }}>
                            <Tooltip title={t("screen.overview.table.action.createCheckup")}>
                                <AddCircleRounded
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => onClickCreateCheckup(patient.patientId)}
                                />
                            </Tooltip>
                        </StyledTableCell>
                    );
                }

                return (
                    <StyledTableCell isLastRow={isLastRow} key={headerField.column}>
                        <TableLabel>{label}</TableLabel>
                    </StyledTableCell>
                );
            })}
        </TableRow>
    );
};
