import { LocalizationProvider } from "@mui/lab";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { RawIntlProvider } from "react-intl";
import { AppRouter } from "./components/app/router/AppRouter";
import { theme } from "./components/util/Theme";
import { intl } from "./i18n/util";
import { generalStore } from "./stores/GeneralStore";
import DateAdapter from "@mui/lab/AdapterDayjs";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/de";
import { configure } from "mobx";

configure({
    enforceActions: "never",
});

dayjs.extend(updateLocale);

dayjs.updateLocale("de", {
    months: [
        "Jänner",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ],
});

const App: React.FunctionComponent = observer(() => {
    dayjs.locale("de");

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <RawIntlProvider value={intl} key={generalStore.locale}>
                        <AppRouter />
                    </RawIntlProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
});

export default App;
