import { last, take } from "lodash";
import { ReactComponent as SmileyBad } from "../../assets/icons/ic_smiley_bad.svg";
import { ReactComponent as SmileyDanger } from "../../assets/icons/ic_smiley_danger.svg";
import { ReactComponent as SmileyGood } from "../../assets/icons/ic_smiley_good.svg";
import { t } from "../../i18n/util";
import {
    BiaResults,
    BloodpanelReport,
    BloodpanelReportValue,
    CheckupReport,
    ConspicuousnessDropdown,
    ObstructiveDropdown,
    RelaxationDropdown,
    UltrasoundCarotisDropdown,
    UltrasoundHeartDropdown,
    UltrasoundStomachDropdown,
    UltrasoundThyroidDropdown,
} from "../../network/APITypes";
import { IBiaValueDisplayDotPosition, IBreakpoints, IDotPosition } from "../../types";
import { Colors } from "./Colors";
import { LATEST_HISTORY } from "./constants";
import { formatDate } from "./helpers";

export const getValueColor = (dotPosition?: IDotPosition) => {
    if (typeof dotPosition === "number") {
        return undefined;
    }
    return Colors.VALUE_DISPLAY_ORANGE;
};

export const getValuePosition = (minVal: number, maxVal: number, value?: number) => {
    if (value) {
        if (value >= minVal && value <= maxVal) {
            return ((value - minVal) / (maxVal - minVal)) * 100;
        }
        if (value > maxVal) {
            return "high";
        }
        if (value < minVal) {
            return "low";
        }
    }
    return 0;
};

export const getGradientColor = (breakpoints?: IBreakpoints, value?: number) => {
    if (breakpoints && value) {
        if (value > breakpoints.green) {
            return Colors.VALUE_DISPLAY_ORANGE;
        }
    }
    return undefined;
};

export const getBmiColor = (value?: number) => {
    if (value === undefined) {
        return undefined;
    }

    if (value <= 16 || value >= 31) {
        return Colors.VALUE_DISPLAY_RED;
    }

    if ((value > 16 && value < 19) || (value > 25 && value < 31)) {
        return Colors.VALUE_DISPLAY_ORANGE;
    }

    return Colors.VALUE_DISPLAY_GREEN;
};

export const getExerciseDropdownText = (exerciseDropdown: string) => {
    switch (exerciseDropdown) {
        case "notSpecified":
            return t("common.notSpecified");
        case "none":
            return t("screen.checkupReport.lifestyleParameters.exercise.none");
        case "lessThan2h5":
            return t("screen.checkupReport.lifestyleParameters.exercise.lessThan2h5");
        case "moreThan2h5":
            return t("screen.checkupReport.lifestyleParameters.exercise.moreThan2h5");
        default:
            return exerciseDropdown;
    }
};

export const getExerciseColor = (exerciseDropdown?: string) => {
    if (exerciseDropdown) {
        if (exerciseDropdown === "none" || exerciseDropdown === "lessThan2h5") {
            return Colors.VALUE_DISPLAY_ORANGE;
        } else if (exerciseDropdown === "moreThan2h5") {
            return Colors.VALUE_DISPLAY_GREEN;
        }
    }
    return undefined;
};

export const getBloodpressureColor = (bloodpressureDropdown?: ConspicuousnessDropdown) => {
    if (bloodpressureDropdown) {
        if (bloodpressureDropdown === "conspicuous") {
            return Colors.VALUE_DISPLAY_ORANGE;
        }
        if (bloodpressureDropdown === "normal") {
            return Colors.VALUE_DISPLAY_GREEN;
        }
    }
    return undefined;
};

export const getSmokerText = (smokerDropdown: string) => {
    switch (smokerDropdown) {
        case "nonSmoker":
            return t("screen.checkupReport.lifestyleParameters.smoker.nonSmoker");
        case "occasionalSmoker":
            return t("screen.checkupReport.lifestyleParameters.smoker.occasionalSmoker");
        case "smoker":
            return t("screen.checkupReport.lifestyleParameters.smoker.smoker");
        case "exSmoker":
            return t("screen.checkupReport.lifestyleParameters.smoker.exSmoker");
        default:
            return smokerDropdown;
    }
};

export const getSmokerColor = (smokerDropdown?: string) => {
    if (smokerDropdown) {
        if (smokerDropdown === "smoker") {
            return Colors.VALUE_DISPLAY_RED;
        } else if (smokerDropdown === "occasionalSmoker") {
            return Colors.VALUE_DISPLAY_ORANGE;
        } else if (smokerDropdown === "exSmoker" || smokerDropdown === "nonSmoker") {
            return Colors.VALUE_DISPLAY_GREEN;
        }
    }
    return undefined;
};

export const getConspicuousnessColor = (value?: ConspicuousnessDropdown | ObstructiveDropdown) => {
    if (value === "conspicuous" || value === "obstructive" || value === "restrictive") {
        return Colors.VALUE_DISPLAY_ORANGE;
    }
    if (value === "normal") {
        return Colors.VALUE_DISPLAY_GREEN;
    }
    return undefined;
};

export const getConspicuousnessDropdownLabel = (value?: ConspicuousnessDropdown) => {
    if (value === "conspicuous") {
        return t("common.conspicuous");
    }
    if (value === "normal") {
        return t("common.normal");
    }
    return "";
};

export const getObstructiveDropdownLabel = (value?: ObstructiveDropdown) => {
    if (value === "obstructive") {
        return t("common.obstructive");
    }
    if (value === "restrictive") {
        return t("common.restrictive");
    }
    if (value === "normal") {
        return t("common.normal");
    }
    return "";
};

export const getExerciseErgometricPercentColor = (value?: number) => {
    if (value) {
        if (value >= 100) {
            return Colors.VALUE_DISPLAY_GREEN;
        }

        if (value >= 75) {
            return Colors.VALUE_DISPLAY_ORANGE;
        }

        return Colors.VALUE_DISPLAY_RED;
    }

    return "";
};

export const getUltrasoundColor = (
    value?: UltrasoundCarotisDropdown | UltrasoundHeartDropdown | UltrasoundStomachDropdown | UltrasoundThyroidDropdown,
) => {
    if (value) {
        if (value === "notSpecified" || value === "normal") {
            return "";
        }
        return Colors.VALUE_DISPLAY_ORANGE;
    }
    return "";
};

export const getAbdominalGirthBreakpoints = (sex?: string) =>
    sex === "female" ? { green: 80, yellow: 81, red: 89 } : { green: 94, yellow: 95, red: 103 };

export const getAbdominalGirthColor = (value?: number, sex?: string) => {
    if (!value || !sex) {
        return;
    }

    if (sex === "female") {
        if (value <= 80) {
            return Colors.VALUE_DISPLAY_GREEN;
        } else if (value <= 88) {
            return Colors.VALUE_DISPLAY_ORANGE;
        }

        return Colors.VALUE_DISPLAY_RED;
    } else {
        if (value <= 94) {
            return Colors.VALUE_DISPLAY_GREEN;
        } else if (value <= 102) {
            return Colors.VALUE_DISPLAY_ORANGE;
        }

        return Colors.VALUE_DISPLAY_RED;
    }
};

export const getSmileyByColor = (color?: string) => {
    if (color === Colors.VALUE_DISPLAY_RED) {
        return <SmileyDanger />;
    } else if (color === Colors.VALUE_DISPLAY_ORANGE) {
        return <SmileyBad />;
    } else if (color) {
        return <SmileyGood />;
    } else {
        return null;
    }
};

export const getBloodpanelValueDisplayValue = (value?: BloodpanelReportValue) => {
    if (value === undefined) {
        return undefined;
    } else if (value.valueType === "bool") {
        return t(value.valueBool ? "common.positive" : "common.negative");
    } else if (value.valueType === "numeric") {
        return value.valueNumeric;
    } else if (value.valueType === "text") {
        return value.valueText;
    } else {
        return undefined;
    }
};

export const getBloodpanelValueDotPosition = (
    brv: BloodpanelReportValue | undefined,
    rangeValues?: { minVal?: number; maxVal?: number },
    value?: number | string,
) => {
    // Sanity check just in case.
    try {
        if (typeof value === "string") {
            value = parseFloat(value);
        }
    } catch (error) {
        console.error("Failed to parse value as float", value, error);
    }

    if (rangeValues && typeof value === "number") {
        if (typeof rangeValues.maxVal === "number" && value > rangeValues.maxVal) {
            return "high";
        } else if (typeof rangeValues.minVal === "number" && value < rangeValues.minVal) {
            return "low";
        } else if (typeof rangeValues.minVal === "number" && typeof rangeValues.maxVal === "number") {
            if (rangeValues.minVal === rangeValues.maxVal) {
                return 50;
            }
            return ((value - rangeValues?.minVal) / (rangeValues.maxVal - rangeValues.minVal)) * 100;
        }
    }

    // If there is no valid range we just look at the raw range indicator.
    if (brv) {
        if (brv.rangeIndicatorRaw === "+") {
            return "high";
        } else if (brv.rangeIndicatorRaw === "-") {
            return "low";
        }
    }

    return undefined;
};

export const getBloodpanelValueRangeValues = (value?: BloodpanelReportValue) => {
    if (value === undefined || value.valueType !== "numeric") {
        return { minVal: undefined, maxVal: undefined };
    } else if (value.rangeType === "greaterThan") {
        return { minVal: value.rangeFrom, maxVal: undefined };
    } else if (value.rangeType === "lowerThan") {
        return { minVal: undefined, maxVal: value.rangeTo };
    } else if (value.rangeType === "span") {
        return { minVal: value.rangeFrom, maxVal: value.rangeTo };
    } else {
        console.error("getBloodpanelValueRangeValues: No range handling for", value);
        return;
    }
};

export const getHistoryHeaderItems = <T,>(history?: (T & { checkupDate?: string | Date })[]) => {
    const lastHistoryItemsLength = (history?.length ?? 1) - 1;
    const takeCount = Math.min(lastHistoryItemsLength, LATEST_HISTORY);
    const latestHistoryItems = take(history, takeCount);

    const latestHistoryHeaderItems = latestHistoryItems.map((checkupReport) => ({
        label: formatDate(checkupReport.checkupDate),
    }));
    const lastCheckupReport = last(history);
    const lastHistoryItem = { label: formatDate(lastCheckupReport?.checkupDate) };
    const emptyColumns = Array(LATEST_HISTORY - latestHistoryItems.length).fill({ label: "" });
    const valueHeaderItems = [{ label: "" }, ...emptyColumns, ...latestHistoryHeaderItems, lastHistoryItem];

    return { valueHeaderItems, latestHistoryItems, lastCheckupReport, emptyColumns };
};

export const getRelaxationColor = (value?: RelaxationDropdown) => {
    if (value === "insufficient") {
        return Colors.VALUE_DISPLAY_ORANGE;
    }
    if (value === "sufficient") {
        return Colors.VALUE_DISPLAY_GREEN;
    }
    return undefined;
};

export const getRelaxationDropdownLabel = (value?: RelaxationDropdown) => {
    if (value === "insufficient") {
        return t("common.insufficient");
    }
    if (value === "sufficient") {
        return t("common.sufficient");
    }
    return "";
};

export const getBiaValueColor = (dotPosition?: IBiaValueDisplayDotPosition, includeGreen?: boolean) => {
    if (typeof dotPosition === "number" || dotPosition?.toLowerCase().includes("green")) {
        return includeGreen ? Colors.VALUE_DISPLAY_GREEN : undefined;
    }
    if (
        dotPosition?.toLowerCase().includes("red") ||
        dotPosition?.toLowerCase().includes("orange") ||
        dotPosition?.toLowerCase().includes("yellow")
    ) {
        return Colors.VALUE_DISPLAY_ORANGE;
    }
    return undefined;
};

export const getBiaDotPosition = ({
    results,
    name,
}: {
    results?: BiaResults;
    name?: "muscle" | "fat" | "fatPercent";
}) => {
    if (!name || !results) {
        console.error("No BIA dot position for:", {
            results,
            name,
        });
        return undefined;
    }

    const value = results[name]?.value;
    const minValue = results[name]?.minVal;
    const maxValue = results[name]?.maxVal;

    if (
        value === undefined ||
        minValue === undefined ||
        maxValue === undefined ||
        typeof value !== "number" ||
        typeof minValue !== "number" ||
        typeof maxValue !== "number"
    ) {
        console.error("No BIA dot position for:", {
            value,
            minValue,
            maxValue,
        });
        return undefined;
    }

    // der Referenzbereich ist grün, bis 5kg über Referenzbereich gelb, mehr als 5kg über Referenzbereich rot
    // 2kg unter Referenzbereich ist gelb, weniger als 2kg unter Referenzbereich rot
    // https://allaboutapps-at.atlassian.net/browse/VOR-116
    // https://allaboutapps-at.atlassian.net/wiki/spaces/VOR/pages/14774763994/rztin+Assistenz#4.Ger%C3%A4te
    if (name === "fatPercent") {
        if (results.fat?.minVal === undefined || results.fat.maxVal === undefined) {
            return undefined;
        }
        const rangePercent = maxValue - minValue;
        const rangeKilogram = results.fat.maxVal - results.fat.minVal;

        const lowPercent = 2 / rangeKilogram;
        const lowBreakpoint = minValue - rangePercent * lowPercent;

        const highPercent = 10 / rangeKilogram;
        const highBreakpoint = maxValue + rangePercent * highPercent;

        if (value < minValue && value < lowBreakpoint) {
            return "lowOrange";
        }
        if (value < minValue) {
            return "yellow";
        }

        if (value > maxValue && value > highBreakpoint) {
            return "red";
        }
        if (value > maxValue) {
            return "highOrange";
        }
    } else if (name === "fat") {
        if (value < minValue && value < minValue - 2) {
            return "lowOrange";
        }
        if (value < minValue) {
            return "yellow";
        }

        if (value > maxValue && value > maxValue + 10) {
            return "red";
        }
        if (value > maxValue) {
            return "highOrange";
        }
    } else if (name === "muscle") {
        if (value < minValue && value < minValue - 2) {
            return "red";
        }
        if (value < minValue) {
            return "orange";
        }

        if (value > maxValue && value > maxValue + 5) {
            return "darkGreen";
        }
        if (value > maxValue) {
            return "mediumGreen";
        }
    }
    const range = maxValue - minValue;
    const adjustedValue = value - minValue;

    return adjustedValue === 0 ? 1 : Math.round((adjustedValue / range) * 100);
};

export const hasFileCommonFileExtension = (value: any) => {
    if (typeof value === "string") {
        const commonFileExtensions = [
            ".jpg",
            ".jpeg",
            ".png",
            ".gif",
            ".bmp",
            ".svg",
            ".pdf",
            ".doc",
            ".txt",
            ".rtf",
            ".html",
            ".htm",
            ".xml",
            ".xls",
            ".xlsx",
            ".xlsm",
        ];

        return (
            value.trim() === "." || commonFileExtensions.some((fileExtension) => value.trim().endsWith(fileExtension))
        );
    }

    return false;
};

export const sanitizeBloodpanelReport = (bloodpanelReport: BloodpanelReport) => ({
    ...bloodpanelReport,
    values: bloodpanelReport?.values
        ? bloodpanelReport.values
              .map((category) => ({
                  ...category,
                  // remove bloodpanel values which contain a fileextension
                  values: category.values
                      ? category.values.filter((value) => {
                            const displayValue = getBloodpanelValueDisplayValue(value);

                            return displayValue && !hasFileCommonFileExtension(displayValue);
                        })
                      : undefined,
              }))
              // remove possibly empty categories after cleaning up fileextension values
              .filter((category) => (category.values ?? []).length > 0)
        : undefined,
});

export const sanitizeCheckupReport = (checkupReport: CheckupReport) => ({
    ...checkupReport,
    bloodpanels: checkupReport.bloodpanels
        ? {
              latest: checkupReport.bloodpanels.latest
                  ? sanitizeBloodpanelReport(checkupReport.bloodpanels.latest)
                  : undefined,
              history: checkupReport.bloodpanels.history
                  ? checkupReport.bloodpanels.history.map((historyCheckup) => sanitizeBloodpanelReport(historyCheckup))
                  : undefined,
          }
        : undefined,
});
