import { IMultiValueDisplayDotPosition } from "../../types";
import { BMI_MAX_VALUE, BMI_MIN_VALUE } from "./constants";

export const getBmiDotPosition = (value: number): IMultiValueDisplayDotPosition => {
    if (value <= 16) {
        return "lowRed";
    }

    if (value > 16 && value < 19) {
        return "lowOrange";
    }

    if (value > 25 && value < 31) {
        return "highOrange";
    }

    if (value >= 31) {
        return "highRed";
    }

    const range = BMI_MAX_VALUE - BMI_MIN_VALUE;
    const adjustedValue = value - BMI_MIN_VALUE;

    return adjustedValue === 0 ? 1 : Math.round((adjustedValue / range) * 100);
};
