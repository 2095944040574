import { InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { Field } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { CustomInputField } from "../ui/CustomInputField";
import { FieldRowHeader } from "../ui/FieldRowHeader";
import { Colors } from "../util/Colors";

type IProps = {
    disabled?: boolean;
};

type ICategory = {
    name: string;
    label: string;
    adornmentText: string;
    fields: {
        name: string;
        label: string;
        type: string;
        isPercentage?: boolean;
    }[];
};

const GridContainer = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: 16,
});

const headers = [
    {
        name: "category",
        label: t("screen.patientInformation.field.biaResult.header.category"),
    },
    {
        name: "minValue",
        label: t("screen.patientInformation.field.biaResult.header.minValue"),
    },
    {
        name: "maxValue",
        label: t("screen.patientInformation.field.biaResult.header.maxValue"),
    },
    {
        name: "currentValue",
        label: t("screen.patientInformation.field.biaResult.header.currentValue"),
    },
];

const categories: ICategory[] = [
    {
        name: "biaResult.muscle",
        label: t("screen.patientInformation.field.biaResult.muscle"),
        adornmentText: t("common.kilogram"),
        fields: [
            {
                name: "biaResult.muscle.minVal",
                label: t("screen.patientInformation.field.biaResult.minValue"),
                type: "number",
            },
            {
                name: "biaResult.muscle.maxVal",
                label: t("screen.patientInformation.field.biaResult.maxValue"),
                type: "number",
            },
            {
                name: "biaResult.muscle.value",
                label: t("screen.patientInformation.field.biaResult.currentValue"),
                type: "number",
            },
        ],
    },
    {
        name: "biaResult.fat",
        label: t("screen.patientInformation.field.biaResult.fat"),
        adornmentText: t("common.kilogram"),
        fields: [
            {
                name: "biaResult.fat.minVal",
                label: t("screen.patientInformation.field.biaResult.minValue"),
                type: "number",
            },
            {
                name: "biaResult.fat.maxVal",
                label: t("screen.patientInformation.field.biaResult.maxValue"),
                type: "number",
            },
            {
                name: "biaResult.fat.value",
                label: t("screen.patientInformation.field.biaResult.currentValue"),
                type: "number",
            },
        ],
    },
    {
        name: "biaResult.fatPercent",
        label: t("screen.patientInformation.field.biaResult.fat"),
        adornmentText: t("common.percent"),
        fields: [
            {
                name: "biaResult.fatPercent.minVal",
                label: t("screen.patientInformation.field.biaResult.minValue"),
                type: "number",
                isPercentage: true,
            },
            {
                name: "biaResult.fatPercent.maxVal",
                label: t("screen.patientInformation.field.biaResult.maxValue"),
                type: "number",
                isPercentage: true,
            },
            {
                name: "biaResult.fatPercent.value",
                label: t("screen.patientInformation.field.biaResult.currentValue"),
                type: "number",
                isPercentage: true,
            },
        ],
    },
];

export const BiaResult = ({ disabled }: IProps) => (
    <GridContainer>
        {headers.map((header) => (
            <FieldRowHeader key={header.name}>{header.label}</FieldRowHeader>
        ))}
        {categories.map((category) => (
            <React.Fragment key={category.name}>
                <TextField
                    value={category.label}
                    disabled
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{category.adornmentText}</InputAdornment>,
                        style: { backgroundColor: Colors.BACKGROUND, color: "black", marginTop: 8 },
                    }}
                />
                {category.fields.map((field) => (
                    <Field
                        key={field.name}
                        label={field.label}
                        name={field.name}
                        disabled={disabled}
                        component={CustomInputField}
                        type={field.type}
                        ignoreHeaderLabel={true}
                        paddingTop={8}
                        min={field.isPercentage || field.type === "number" ? 0 : undefined}
                        max={field.isPercentage ? 100 : undefined}
                    />
                ))}
            </React.Fragment>
        ))}
    </GridContainer>
);
