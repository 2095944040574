import { styled } from "@mui/material";
import * as React from "react";
import { t } from "../../i18n/util";
import { RecommendationsReportHistory } from "../../network/APITypes";
import { getRecommendations } from "../util/helpers";
import { RecommendationHistory } from "./RecommendationHistory";
import { Card } from "./Card";

const DIAGNOSIS_CATEGORY = "general.diagnosis";
const MEDICATION_CATEGORY = "general.medication";

type IProps = {
    recommendations?: RecommendationsReportHistory;
};

const Title = styled("div")({
    marginTop: 40,
    fontWeight: 600,
    fontSize: 19,
});

const StyledCard = styled(Card)({
    marginTop: 40,
});

const getDisplayStatus = (category: string, recommendations?: RecommendationsReportHistory) =>
    !!recommendations?.latest?.savedTexts?.find((savedText) => savedText.category === category) ||
    !!recommendations?.history?.find((historyItem) =>
        historyItem.savedTexts?.find((savedText) => savedText.category === category),
    );

export const DocumentationHistory = ({ recommendations }: IProps) => {
    const showDiagnosis = getDisplayStatus(DIAGNOSIS_CATEGORY, recommendations);
    const showMedication = getDisplayStatus(MEDICATION_CATEGORY, recommendations);
    const showDocumentation = showDiagnosis || showMedication;

    const {
        currentRecommendation: diagnosisRecommendation,
        currentRecommendationHistory: diagnosisRecommendationHistory,
    } = getRecommendations(DIAGNOSIS_CATEGORY, recommendations);

    const diagnosisCard = showDiagnosis && (
        <StyledCard>
            <RecommendationHistory
                title="screen.history.documentation.diagnosis"
                recommendation={diagnosisRecommendation}
                recommendationHistory={diagnosisRecommendationHistory}
            />
        </StyledCard>
    );

    const {
        currentRecommendation: medicationRecommendation,
        currentRecommendationHistory: medicationRecommendationHistory,
    } = getRecommendations(MEDICATION_CATEGORY, recommendations);

    const medicationCard = showMedication && (
        <StyledCard>
            <RecommendationHistory
                title="screen.history.documentation.medication"
                recommendation={medicationRecommendation}
                recommendationHistory={medicationRecommendationHistory}
            />
        </StyledCard>
    );

    return showDocumentation ? (
        <div>
            <Title>{t("screen.history.documentation")}</Title>
            {diagnosisCard}
            {medicationCard}
        </div>
    ) : null;
};
