import { Divider, Link } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { history } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { AuthCard, AuthSubmitActions, AuthTitle } from "../../ui/AuthPrimitives";
import { CustomInputField } from "../../ui/CustomInputField";
import { Colors } from "../../util/Colors";
import { AuthContainerSite } from "./AuthContainerSite";

interface ILoginValues {
    email: string;
    password: string;
}

export const LoginSite = observer(() => {
    const [error, setError] = React.useState("");

    const handleClickSubmit = async (model: ILoginValues) => {
        generalStore.isLoading = true;
        setError("");

        try {
            await authStore.loginWithPassword(model.email, model.password);
            if (authStore.error) {
                if (authStore.error === "PasswordWrong") {
                    setError(t("screen.auth.invalidPasswordOrEmail"));
                } else if (authStore.error === "Unknown") {
                    setError(t("error.login"));
                    setError(t("error.login"));
                }
            }
        } catch (error) {
            setError(t("error.login"));
        } finally {
            generalStore.isLoading = false;
        }
    };

    if (!authStore.isRehydrated) {
        return null;
    }

    // const handleClickResetPassword = () => {
    //     history.push(Routes.AUTH.FORGOT_PASSWORD);
    // };

    return (
        <AuthContainerSite>
            <AuthCard>
                <AuthTitle>{t("screen.auth.login.title")}</AuthTitle>
                <Divider />
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    onSubmit={handleClickSubmit}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email(t("error.email.validationError"))
                            .required(t("error.email.validationError"))
                            .trim(),
                        password: Yup.string().required(t("error.password.validationError")),
                    })}
                    validateOnBlur
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <div style={{ padding: 16 }}>
                                <Field
                                    component={CustomInputField}
                                    label={t("screen.auth.email")}
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="username"
                                    errorMessage={errors.email}
                                    isTouched={touched.email}
                                />
                                <Field
                                    component={CustomInputField}
                                    label={t("screen.auth.password")}
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    errorMessage={errors.password}
                                    isTouched={touched.password}
                                />
                                {error && <div style={{ color: "#f00", fontSize: 14, marginBottom: 10 }}>{error}</div>}
                                {/* <div style={{ fontSize: 16, fontWeight: 400, color: Colors.DISABLED }}>
                                    {t("screen.auth.login.resetPassword.partOne")}
                                    <span style={{ display: "inline-flex", alignItems: "center" }}>
                                        <Link
                                            component="button"
                                            color="inherit"
                                            onClick={handleClickResetPassword}
                                            style={{ fontWeight: 400 }}
                                            // If type is unspecified it triggers formik submit
                                            type="button"
                                        >
                                            {t("screen.auth.resetPassword.link")}
                                        </Link>
                                    </span>
                                    {t("screen.auth.login.resetPassword.partTwo")}
                                </div> */}
                            </div>
                            <Divider />
                            <AuthSubmitActions text={t("screen.auth.login")} disabled={isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </AuthCard>
        </AuthContainerSite>
    );
});
