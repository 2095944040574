import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/lab";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { FieldInputProps, FormikProps, FormikValues } from "formik";
import * as React from "react";
import { Colors } from "../util/Colors";
import { StyledInputLabel } from "./StyledInputLabel";

type IProps<Values extends FormikValues> = DesktopDatePickerProps & {
    errorMessage?: string;
    field: FieldInputProps<any>;
    form: FormikProps<Values>;
    isTouched?: boolean;
    showValidationErrorText: boolean;
    disabled?: boolean;
    disableFuture?: boolean;
};

export const CustomDatePicker = <Values extends FormikValues>({
    label,
    errorMessage,
    field,
    form,
    isTouched,
    disabled,
    disableFuture,
}: IProps<Values>) => {
    const showError = isTouched && !!errorMessage;
    const currentError = form.errors[field.name];
    const handleChange = (date: string | number | Date | dayjs.Dayjs | null | undefined) => {
        form.setFieldValue(field.name, date, true);
    };

    return (
        <div>
            <StyledInputLabel htmlFor={field.name} style={{ marginBottom: 8 }}>
                {label}
            </StyledInputLabel>
            <DesktopDatePicker
                disableFuture={disableFuture}
                inputFormat="DD.MM.YYYY"
                renderInput={(props) => (
                    <TextField
                        {...props}
                        style={{ backgroundColor: disabled ? Colors.BACKGROUND : undefined }}
                        fullWidth
                        inputProps={{ ...props.inputProps, placeholder: "" }}
                    />
                )}
                onChange={handleChange}
                value={field.value || null}
                disabled={disabled}
                onError={(error) => {
                    if (field.value && error !== currentError) {
                        form.setFieldError(field.name, error as string);
                    }
                }}
                mask="__.__.____"
            />
            {showError && (
                <span
                    style={{
                        color: "#f00",
                        display: "block",
                        minHeight: 18,
                        width: "100%",
                        marginTop: 4,
                        marginBottom: 5,
                        fontSize: 12,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
