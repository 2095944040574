import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/styles";
import { ReactComponent as InfoIcon } from "../../assets/icons/ic_info.svg";

const StyledIconButton = styled(IconButton)({
    "&:hover": {
        backgroundColor: "transparent",
    },
});

export const InfoButton = (props: { title: React.ReactNode; body?: React.ReactNode; style?: React.CSSProperties }) => {
    return (
        <Tooltip
            style={{
                backgroundColor: "#ffffff",
            }}
            title={
                <React.Fragment>
                    {props.title}
                    {props.body && (
                        <>
                            <br />
                            <br />
                            {props.body}
                        </>
                    )}
                </React.Fragment>
            }
            placement="right-start"
        >
            <StyledIconButton disableRipple style={props.style}>
                <InfoIcon />
            </StyledIconButton>
        </Tooltip>
    );
};
