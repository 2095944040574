import { styled } from "@mui/material";
import { Colors } from "../util/Colors";

export const HistorySectionTitle = styled("div")({
    fontSize: 16,
    fontWeight: 500,
    color: Colors.HEADINGS_DARK,
    height: 45,
    display: "flex",
    alignItems: "center",
    marginLeft: 16,
});
