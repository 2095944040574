import * as React from "react";
import Logo from "../../assets/images/logo.svg";
import BIA from "../../assets/images/diagnostik/BIA.png";
import Blutdruck from "../../assets/images/diagnostik/Blutdruck.png";
import Diagnose from "../../assets/images/diagnostik/Diagnose.png";
import EKG from "../../assets/images/diagnostik/EKG.png";
import Entspannung from "../../assets/images/diagnostik/Entspannung.png";
import Ergometrie from "../../assets/images/diagnostik/Ergometrie.png";
import KoerperlicheBewegungLaufenTrainingsempfehlung from "../../assets/images/diagnostik/körperliche Bewegung & Laufen Trainingsempfehlung.png";
import Labor from "../../assets/images/diagnostik/Labor.png";
import Medikation from "../../assets/images/diagnostik/Medikation.png";
import RadfahrenTrainingsempfehlung from "../../assets/images/diagnostik/Radfahren Trainingsempfehlung.png";
import Rauchen from "../../assets/images/diagnostik/Rauchen.png";
import SonoAbdomen from "../../assets/images/diagnostik/Sono Abdomen.png";
import SonoCarotis from "../../assets/images/diagnostik/Sono Carotis.png";
import SonoHerzEcho from "../../assets/images/diagnostik/Sono Herz_Echo.png";
import SonoSD from "../../assets/images/diagnostik/Sono SD.png";
import Spirometrie from "../../assets/images/diagnostik/Spirometrie.png";

export const ImageLogo = (props: { style?: React.CSSProperties }) => <img alt="Logo" src={Logo} style={props.style} />;
export const ImageBIA = (props: { style?: React.CSSProperties }) => <img alt="bia" src={BIA} style={props.style} />;
export const ImageBlutdruck = (props: { style?: React.CSSProperties }) => (
    <img alt="blutdruck" src={Blutdruck} style={props.style} />
);
export const ImageDiagnose = (props: { style?: React.CSSProperties }) => (
    <img alt="diagnose" src={Diagnose} style={props.style} />
);
export const ImageEKG = (props: { style?: React.CSSProperties }) => <img alt="ekg" src={EKG} style={props.style} />;
export const ImageEntspannung = (props: { style?: React.CSSProperties }) => (
    <img alt="entspannung" src={Entspannung} style={props.style} />
);
export const ImageErgometrie = (props: { style?: React.CSSProperties }) => (
    <img alt="ergometrie" src={Ergometrie} style={props.style} />
);
export const ImageBewegungLaufen = (props: { style?: React.CSSProperties }) => (
    <img
        alt="körperliche bewegung laufen trainingsempfehlung"
        src={KoerperlicheBewegungLaufenTrainingsempfehlung}
        style={props.style}
    />
);
export const ImageLabor = (props: { style?: React.CSSProperties }) => (
    <img alt="labor" src={Labor} style={props.style} />
);
export const ImageMedikation = (props: { style?: React.CSSProperties }) => (
    <img alt="medikation" src={Medikation} style={props.style} />
);
export const ImageBewegungRadfahren = (props: { style?: React.CSSProperties }) => (
    <img alt="radfahren trainingsempfehlung" src={RadfahrenTrainingsempfehlung} style={props.style} />
);
export const ImageRauchen = (props: { style?: React.CSSProperties }) => (
    <img alt="rauchen" src={Rauchen} style={props.style} />
);
export const ImageSonoAbdomen = (props: { style?: React.CSSProperties }) => (
    <img alt="sono abdomen" src={SonoAbdomen} style={props.style} />
);
export const ImageSonoCarotis = (props: { style?: React.CSSProperties }) => (
    <img alt="sono carotis" src={SonoCarotis} style={props.style} />
);
export const ImageSonoHerzEcho = (props: { style?: React.CSSProperties }) => (
    <img alt="sono herz echo" src={SonoHerzEcho} style={props.style} />
);
export const ImageSonoSD = (props: { style?: React.CSSProperties }) => (
    <img alt="sono sd" src={SonoSD} style={props.style} />
);
export const ImageSpirometrie = (props: { style?: React.CSSProperties }) => (
    <img alt="spirometrie" src={Spirometrie} style={props.style} />
);
