import styled from "styled-components";
import { IMultiValueDisplayDotPosition } from "../../types";
import { BlackDot, BLACK_DOT_WIDTH } from "../ui/Dots";
import { Colors } from "../util/Colors";

const GREEN_BAR_WIDTH = 56;

type IValues = {
    minVal?: number;
    maxVal?: number;
};

const RedBar = styled("div")({
    width: 20,
    height: 12,
    backgroundColor: Colors.VALUE_DISPLAY_RED,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const OrangeBar = styled("div")({
    width: 20,
    height: 12,
    backgroundColor: Colors.VALUE_DISPLAY_ORANGE,
    display: "flex",
    margin: "0 1px",
    alignItems: "center",
    justifyContent: "center",
});

const GreenBar = styled("div")({
    width: GREEN_BAR_WIDTH,
    height: 12,
    backgroundColor: Colors.VALUE_DISPLAY_GREEN,
    display: "flex",
    alignItems: "center",
    position: "relative",
});

export const MultiValueDisplay = ({
    values,
    dotPosition,
    valueAlignment = "center",
}: {
    values: IValues;
    dotPosition?: IMultiValueDisplayDotPosition;
    valueAlignment?: "left" | "right" | "center";
}) => {
    if (dotPosition === undefined || typeof values.minVal !== "number" || typeof values.maxVal !== "number") {
        return <div style={{ textAlign: "center" }}>-</div>;
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                style={{
                    fontWeight: 400,
                    color: Colors.TEXT_LIGHT,
                    marginRight: 16,
                    minWidth: 120,
                    textAlign: valueAlignment,
                }}
            >
                {values.minVal} - {values.maxVal}
            </div>
            <div style={{ display: "flex" }}>
                <RedBar>{dotPosition === "lowRed" && <BlackDot />}</RedBar>
                <OrangeBar>{dotPosition === "lowOrange" && <BlackDot />}</OrangeBar>
                <GreenBar>
                    {typeof dotPosition === "number" && (
                        <BlackDot
                            style={{
                                position: "absolute",
                                left: dotPosition * ((GREEN_BAR_WIDTH - BLACK_DOT_WIDTH) / 100),
                            }}
                        />
                    )}
                </GreenBar>
                <OrangeBar>{dotPosition === "highOrange" && <BlackDot />}</OrangeBar>
                <RedBar>{dotPosition === "highRed" && <BlackDot />}</RedBar>
            </div>
        </div>
    );
};
