import { Field, useFormikContext } from "formik";
import styled from "styled-components";
import { IMessageIDS, t } from "../../i18n/util";
import { IBiaName } from "../../types";
import { CustomTextField } from "../ui/CustomTextField";
import { TextDot } from "../ui/Dots";
import { Colors } from "../util/Colors";
import { getRecommendationSiteOptions } from "../util/helpers";
import { getBiaDotPosition, getBiaValueColor } from "../util/ValueDisplayHelpers";
import { BiaValueDisplay } from "./BiaValueDisplay";

type IProps = {
    disabled?: boolean;
};

const BiaValuesContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    height: 50,
    borderBottom: `1px solid ${Colors.BORDER_LIGHT}`,
});

const LabelContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "50%",
    padding: 16,
    fontSize: 16,
    fontWeight: 400,
    borderRight: `1px solid ${Colors.BORDER_LIGHT}`,
    color: Colors.TEXT_LIGHT,
});

const ValueLabel = styled("div")({
    display: "flex",
    alignItems: "center",
});

const ValueDisplayContainer = styled("div")({
    display: "flex",
    justifyContent: "center",
    width: "50%",
});

const biaItems: { name: IBiaName; label: IMessageIDS }[] = [
    {
        name: "muscle",
        label: "screen.patientInformation.field.biaResult.muscle",
    },
    {
        name: "fat",
        label: "screen.patientInformation.field.bia.fatKilogram",
    },
    {
        name: "fatPercent",
        label: "screen.patientInformation.field.bia.fatPercent",
    },
];

export const BiaRecommendation = ({ disabled }: IProps) => {
    const { values } = useFormikContext<any>();
    const results = getRecommendationSiteOptions(values).bia;

    return (
        <>
            <div>
                {biaItems.map((biaItem) => {
                    if (!results) {
                        console.error("No results for BIA item:", biaItem);
                        return null;
                    }

                    if (results[biaItem.name]?.value === undefined) {
                        console.error("No result value for BIA item:", biaItem);
                        return null;
                    }

                    const value = results?.[biaItem.name]?.value;
                    const dotPosition = getBiaDotPosition({ results, name: biaItem.name });
                    const valueColor = getBiaValueColor(dotPosition);
                    const { value: biaItemValue, ...valueDisplayItems } = results[biaItem.name] ?? {};

                    if (!valueDisplayItems) {
                        console.error("No value display items for BIA item:", biaItem, results);
                        return null;
                    }

                    return (
                        <BiaValuesContainer key={biaItem.name}>
                            <LabelContainer>
                                <div>{t(biaItem.label)}</div>
                                <ValueLabel style={{ color: valueColor }}>
                                    {value} {biaItem.name === "fatPercent" ? t("common.percent") : t("common.kilogram")}
                                    {valueColor && (
                                        <>
                                            &nbsp;
                                            <TextDot style={{ backgroundColor: valueColor }} />
                                        </>
                                    )}
                                </ValueLabel>
                            </LabelContainer>
                            <ValueDisplayContainer>
                                <BiaValueDisplay
                                    valueAlignment="right"
                                    values={valueDisplayItems}
                                    dotPosition={dotPosition}
                                    name={biaItem.name}
                                />
                            </ValueDisplayContainer>
                        </BiaValuesContainer>
                    );
                })}
            </div>
            <div style={{ padding: 16 }}>
                <Field name="textInput" component={CustomTextField} minRows={2} disabled={disabled} />
            </div>
        </>
    );
};
