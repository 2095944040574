import { styled } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { ReactComponent as DoctorIcon } from "../../assets/icons/ic_doctor.svg";
import { ReactComponent as FinishedIcon } from "../../assets/icons/ic_section-done.svg";
import { t } from "../../i18n/util";
import { patientStore } from "../../stores/PatientStore";
import { ICheckupItemStatus, ICheckupSectionItem, IProgressSectionItem } from "../../types";
import { Colors } from "../util/Colors";

type IProgressBar = {
    checkupSections: ICheckupSectionItem[] | IProgressSectionItem[];
    showDoctorRecommendation?: boolean;
};

const StatusColors: { [key in ICheckupItemStatus]: string } = {
    empty: Colors.TEXT,
    edited: Colors.HEADINGS_DARK,
    finished: Colors.MAIN,
};

const TimeLineContainer = styled("div")({
    width: 24,
    display: "flex",
    justifyContent: "center",
});

const TimeLine = styled("div")({
    width: 2,
    backgroundColor: Colors.TEXT,
});

const Circle = styled("div")({
    border: "2px solid #4f4f4f",
    borderRadius: "100%",
    width: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const CustomNumber = styled("div")({
    fontSize: 12,
    lineHeight: 14,
    fontWeight: 700,
});

const TitleContainer = styled("div")({
    display: "flex",
    margin: "14px 0",
});

export const getSectionStatus = (section: ICheckupSectionItem | IProgressSectionItem): ICheckupItemStatus => {
    const isFinished = section.items?.every((item) => patientStore.checkupStatus[item.name] === "finished");
    const isEdited = section.items?.some(
        (item) =>
            patientStore.checkupStatus[item.name] === "edited" || patientStore.checkupStatus[item.name] === "finished",
    );

    if (isFinished) {
        return "finished";
    }
    if (isEdited) {
        return "edited";
    }

    return "empty";
};

export const ProgressBar = observer(({ checkupSections, showDoctorRecommendation }: IProgressBar) => (
    <div>
        <div style={{ padding: "0 32px 52px 32px" }}>
            {checkupSections.map((section, index) => {
                if (!section.title) {
                    return null;
                }
                const sectionStatus = getSectionStatus(section);

                return (
                    <React.Fragment key={index}>
                        <TitleContainer>
                            {sectionStatus === "finished" ? (
                                <FinishedIcon />
                            ) : (
                                <Circle style={{ borderColor: StatusColors[sectionStatus ?? "empty"] }}>
                                    <CustomNumber style={{ borderColor: StatusColors[sectionStatus ?? "empty"] }}>
                                        {index + 1}
                                    </CustomNumber>
                                </Circle>
                            )}
                            <div
                                style={{
                                    color: StatusColors[sectionStatus ?? "empty"],
                                    marginLeft: 16,
                                    lineHeight: "24px",
                                }}
                            >
                                {t(section.title)}
                            </div>
                        </TitleContainer>

                        {section.items?.map((item) => (
                            <React.Fragment key={item.name || section.title}>
                                {!item.title && (
                                    <TimeLineContainer style={{ height: 12 }}>
                                        <TimeLine />
                                    </TimeLineContainer>
                                )}
                                {item.title && !item.hideNavbarStep && (
                                    <div style={{ display: "flex" }}>
                                        <TimeLineContainer>
                                            <TimeLine />
                                        </TimeLineContainer>

                                        <div
                                            style={{
                                                color: StatusColors[patientStore.checkupStatus[item.name] ?? "empty"],
                                                marginTop: item.name ? 16 : 0,
                                                marginLeft: 16,
                                            }}
                                        >
                                            {t(item.title)}
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                        <TimeLineContainer style={{ height: 12 }}>
                            <TimeLine />
                        </TimeLineContainer>
                    </React.Fragment>
                );
            })}
            {showDoctorRecommendation && (
                <TitleContainer>
                    <DoctorIcon />
                    <div style={{ color: StatusColors.empty, marginLeft: 16, lineHeight: "24px" }}>
                        {t("patientSite.doctorRecommendation")}
                    </div>
                </TitleContainer>
            )}
        </div>
    </div>
));
