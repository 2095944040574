import { Field } from "formik";
import * as React from "react";
import { CustomTextField } from "../ui/CustomTextField";

type IProps = {
    disabled?: boolean;
};

export const Diagnosis = ({ disabled }: IProps) => (
    <Field name="textInput" component={CustomTextField} minRows={2} disabled={disabled} />
);
