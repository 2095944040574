import * as React from "react";
import styled from "styled-components";

export const BLACK_DOT_WIDTH = 8;
export const TEXT_DOT_WIDTH = 6;

export const BlackDot = styled("div")({
    width: BLACK_DOT_WIDTH,
    height: BLACK_DOT_WIDTH,
    backgroundColor: "black",
    borderRadius: 100,
});

export const TextDot = styled(BlackDot)({
    width: 6,
    height: 6,
});
