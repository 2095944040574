import React from "react";
import { FileRejection } from "react-dropzone";
import { t } from "../../i18n/util";
import { Button, colors, Dialog, Slide } from "@mui/material";
import { InfoButton } from "./InfoButton";
import { Colors } from "../util/Colors";
import { MAX_UPLOAD_SIZE_MB } from "./DocumentUpload";
import { TransitionProps } from "@mui/material/transitions";
import { styled } from "@mui/styles";

type IFailedUpload = {
    file: File;
    errorMessage?: string;
    info?: string;
};

type IFailedDialogProps = {
    open: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
    failedUploads?: IFailedUpload[];
    fileRejections?: FileRejection[];
    rejectionInfoText?: string;
    totalFiles: number;
    onRetry?: () => void;
};

type IDialogErrorLineProps = {
    errorMessage?: string;
    fileName: string;
    infoTitle: string;
    infoBody?: string;
};

const ButtonContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
});

const StyledButton = styled(Button)({
    marginRight: 16,
});

const ErrorLineContainer = styled("div")({
    backgroundColor: "#fdf2f2",
    borderRadius: 4,
    height: 44,
    marginBottom: 4,
    display: "flex",
    alignItems: "center",
    padding: "12px 8px 12px 16px",
    justifyContent: "space-between",
    flexGrow: 1,
});

const FileName = styled("p")({
    color: Colors.ERROR,
    marginLeft: 16,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 12,
});

const ErrorMessage = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: 10,
});

function fileRejectionToErrorMessage(failedFile: FileRejection) {
    const hasInvalidType = failedFile.errors.findIndex((error) => error.code === "file-invalid-type") >= 0;
    const isTooLarge = failedFile.errors.findIndex((error) => error.code === "file-too-large") >= 0;
    const isTooSmall = failedFile.errors.findIndex((error) => error.code === "file-too-small") >= 0;
    const isEmpty = failedFile.errors.findIndex((error) => error.code === "file-empty") >= 0;
    let errorMessage = t("screen.patientInformation.field.bloodpanel.upload.error.unknown");
    if (hasInvalidType && isTooLarge) {
        errorMessage = t("screen.patientInformation.field.bloodpanel.upload.error.sizeAndFormat");
    } else if (hasInvalidType) {
        errorMessage = t("screen.patientInformation.field.bloodpanel.upload.error.format");
    } else if (isTooLarge) {
        errorMessage = t("screen.patientInformation.field.bloodpanel.upload.error.size");
    } else if (isEmpty || isTooSmall) {
        errorMessage = t("screen.patientInformation.field.bloodpanel.upload.error.empty");
    }
    return errorMessage;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogErrorLine = ({ errorMessage, fileName, infoTitle, infoBody }: IDialogErrorLineProps) => (
    <ErrorLineContainer>
        <div style={{ display: "flex", maxWidth: "50%" }}>
            <FileName className="body2">{fileName}</FileName>
        </div>
        <ErrorMessage>
            <p className="caption" style={{ color: Colors.ERROR }}>
                {errorMessage}
            </p>
            <InfoButton title={infoTitle} body={infoBody} style={{ color: Colors.ERROR }} />
        </ErrorMessage>
    </ErrorLineContainer>
);

const FileRejections = ({
    fileRejections,
    rejectionInfoText,
}: {
    fileRejections?: FileRejection[];
    rejectionInfoText?: string;
}) => {
    if (!fileRejections) {
        return null;
    }
    return (
        <>
            {fileRejections.map((failedFile, index) => (
                <DialogErrorLine
                    errorMessage={fileRejectionToErrorMessage(failedFile)}
                    fileName={failedFile.file.name}
                    infoTitle={rejectionInfoText ?? t("screen.patientInformation.field.bloodpanel.upload.fileFormats")}
                    infoBody={t("screen.patientInformation.field.bloodpanel.upload.maxSize", {
                        maxUploadSizeMb: MAX_UPLOAD_SIZE_MB,
                    })}
                    key={index}
                />
            ))}
        </>
    );
};

// List all failed uploads
export const FailedDialog = ({
    open,
    onClose,
    onSubmit,
    failedUploads,
    fileRejections,
    rejectionInfoText,
    totalFiles,
    onRetry,
}: IFailedDialogProps) => {
    let message;
    if (failedUploads) {
        message = (
            <div>
                {t("screen.patientInformation.field.bloodpanel.upload.error.amount", {
                    failedUploads: failedUploads.length,
                    uploads: totalFiles,
                })}
            </div>
        );
    } else if (fileRejections) {
        message = (
            <div>
                {t("screen.patientInformation.field.bloodpanel.upload.error.amount", {
                    failedUploads: fileRejections?.length,
                    uploads: totalFiles,
                })}
            </div>
        );
    }

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} fullWidth>
            <div style={{ padding: "32px 0 24px 0" }}>
                <div style={{ padding: "0 42px 16px 42px", borderBottom: `1px solid #EDEDED` }}>
                    <h1 style={{ marginBottom: 16 }}>
                        {t("screen.patientInformation.field.bloodpanel.upload.error.message")}
                    </h1>
                    <div style={{ fontSize: 14 }}>{message}</div>
                </div>
                <div style={{ padding: "24px 42px 0 42px", borderBottom: `1px solid #EDEDED` }}>
                    <div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {failedUploads?.map((failedFile, index) => {
                                return (
                                    <DialogErrorLine
                                        errorMessage={failedFile.errorMessage}
                                        fileName={failedFile.file.name}
                                        infoTitle={
                                            failedFile.info ??
                                            t("screen.patientInformation.field.bloodpanel.upload.error.server")
                                        }
                                        key={index}
                                    />
                                );
                            })}

                            <FileRejections fileRejections={fileRejections} rejectionInfoText={rejectionInfoText} />
                        </div>
                    </div>
                </div>

                <div style={{ padding: "24px 42px 0" }}>
                    <ButtonContainer>
                        {onSubmit && (
                            <StyledButton onClick={onSubmit} variant="outlined">
                                {t("screen.patientInformation.field.bloodpanel.upload.error.continue")}
                            </StyledButton>
                        )}
                        {onRetry && (
                            <StyledButton onClick={onRetry} variant="contained" color="primary">
                                {t("screen.patientInformation.field.bloodpanel.upload.error.retry")}
                            </StyledButton>
                        )}
                        {fileRejections && (
                            <StyledButton onClick={onClose} variant="contained" color="primary">
                                {t("common.cancel")}
                            </StyledButton>
                        )}
                    </ButtonContainer>
                </div>
            </div>
        </Dialog>
    );
};
