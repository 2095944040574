import { styled } from "@mui/styles";
import { Field } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { CustomInputField } from "../ui/CustomInputField";
import { FieldRowHeader } from "../ui/FieldRowHeader";
import { Colors } from "../util/Colors";

type IProps = {
    disabled?: boolean;
};

const gridStyles = { display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", gridGap: 16 };

const Container = styled("div")(gridStyles);

const headers = [
    {
        name: "percent",
        title: t("screen.patientInformation.field.exerciseErgometric.percent"),
    },
    {
        name: "energy",
        title: t("screen.patientInformation.field.exerciseErgometric.watt"),
    },
    {
        name: "maxHeartRate",
        title: t("screen.patientInformation.field.exerciseErgometric.maxHeartFrequenzy"),
    },
    {
        name: "maxBloodPressure",
        title: t("screen.patientInformation.field.exerciseErgometric.maxBloodPressure"),
    },
    {
        name: "",
        title: "",
    },
];

const fields = [
    {
        name: "exerciseErgometric.percent",
        label: t("screen.patientInformation.field.exerciseErgometric.percent"),
        adornmentText: t("common.percent"),
        type: "number",
    },
    {
        name: "exerciseErgometric.energy",
        label: t("screen.patientInformation.field.exerciseErgometric.watt"),
        adornmentText: t("common.watt"),
        type: "number",
    },
    {
        name: "exerciseErgometric.maxHeartRate",
        label: t("screen.patientInformation.field.exerciseErgometric.maxHeartFrequenzy"),
        adornmentText: t("common.heartFrequency"),
        type: "number",
    },
    {
        name: "exerciseErgometric.maxBloodpressure.systole",
        label: t("screen.patientInformation.field.exerciseErgometric.systole"),
        type: "number",
        showSlash: true,
    },
    {
        name: "exerciseErgometric.maxBloodpressure.diastole",
        label: t("screen.patientInformation.field.exerciseErgometric.diastole"),
        type: "number",
    },
];

export const ExerciseErgometric = ({ disabled }: IProps) => {
    return (
        <>
            <FieldRowHeader style={{ ...gridStyles, marginBottom: 8 }}>
                {headers.map((header) => (
                    <div key={header.name}>{header.title}</div>
                ))}
            </FieldRowHeader>
            <Container>
                {fields.map((field) => (
                    <div key={field.name} style={{ display: "flex", alignItems: "center" }}>
                        <Field
                            component={CustomInputField}
                            label={field.label}
                            name={field.name}
                            type={field.type}
                            disabled={disabled}
                            adornmentText={field.adornmentText}
                            ignoreHeaderLabel={true}
                            min={field.type === "number" ? 0 : undefined}
                        />
                        {field.showSlash && (
                            <div
                                style={{
                                    fontWeight: 400,
                                    color: Colors.DISABLED,
                                    fontSize: 20,
                                    marginTop: -16,
                                    marginRight: -10,
                                    marginLeft: 4,
                                }}
                            >
                                /
                            </div>
                        )}
                    </div>
                ))}
            </Container>
        </>
    );
};
