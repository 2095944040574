import styled from "styled-components";
import { IBiaName, IBiaValueDisplayDotPosition } from "../../types";
import { BlackDot, BLACK_DOT_WIDTH } from "../ui/Dots";
import { Colors } from "../util/Colors";

const MIDDLE_BAR_WIDTH = 56;

type IValues = {
    minVal?: number;
    maxVal?: number;
};

const EndSectionBar = styled("div")({
    width: 21,
    height: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const MiddleBar = styled("div")({
    width: MIDDLE_BAR_WIDTH,
    height: 12,
    display: "flex",
    alignItems: "center",
    position: "relative",
});

export const BiaValueDisplay = ({
    values,
    dotPosition,
    valueAlignment = "center",
    reverse,
    name,
}: {
    values: IValues;
    dotPosition?: IBiaValueDisplayDotPosition;
    valueAlignment?: "left" | "right" | "center";
    reverse?: boolean;
    name: IBiaName;
}) => {
    if (dotPosition === undefined || typeof values.minVal !== "number" || typeof values.maxVal !== "number") {
        return <div style={{ textAlign: "center" }}>-</div>;
    }

    const isFat = name === "fat" || name === "fatPercent";

    console.log(isFat, values, valueAlignment, name);

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                style={{
                    fontWeight: 400,
                    color: Colors.TEXT_LIGHT,
                    marginRight: 16,
                    minWidth: 120,
                    textAlign: valueAlignment,
                }}
            >
                {values.minVal} - {values.maxVal}
            </div>
            <div
                style={{
                    display: "flex",
                    background: isFat
                        ? `
                        linear-gradient(
                            90deg,
                            rgba(255, 98, 10, 0.88) 0%,
                            rgba(255, 98, 10, 0.88) 12%,
                            rgba(255, 166, 33, 0.88) 16%,
                            rgba(255, 166, 33, 0.88) 26%,
                            rgba(0, 202, 20, 0.88) 33%,
                            rgba(0, 202, 20, 0.88) 66%,
                            rgba(255, 98, 10, 0.88) 73%,
                            rgba(255, 98, 10, 0.88) 83%,
                            rgba(255, 10, 10, 0.88) 87%,
                            rgba(255, 10, 10, 0.88) 100%
                        )`
                        : `
                        linear-gradient(
                            90deg,
                            rgba(255, 98, 10, 0.88) 0%,
                            rgba(255, 98, 10, 0.88) 12%,
                            rgba(255, 166, 33, 0.88) 16%,
                            rgba(255, 166, 33, 0.88) 26%,
                            rgba(0, 202, 20, 0.88) 33%,
                            rgba(0, 202, 20, 0.88) 66%,
                            rgba(0, 160, 20, 0.88) 73%,
                            rgba(0, 160, 20, 0.88) 83%,
                            rgba(0, 120, 20, 0.88) 87%,
                            rgba(0, 120, 20, 0.88) 100%
                        )`,
                }}
            >
                <EndSectionBar>
                    {(isFat ? dotPosition === "lowOrange" : dotPosition === "red") && <BlackDot />}
                </EndSectionBar>
                <EndSectionBar>
                    {(isFat ? dotPosition === "yellow" : dotPosition === "orange") && <BlackDot />}
                </EndSectionBar>
                <MiddleBar>
                    {typeof dotPosition === "number" && (
                        <BlackDot
                            style={{
                                position: "absolute",
                                left: dotPosition * ((MIDDLE_BAR_WIDTH - BLACK_DOT_WIDTH) / 100),
                            }}
                        />
                    )}
                </MiddleBar>
                <EndSectionBar>
                    {(isFat ? dotPosition === "highOrange" : dotPosition === "mediumGreen") && <BlackDot />}
                </EndSectionBar>
                <EndSectionBar>
                    {(isFat ? dotPosition === "red" : dotPosition === "darkGreen") && <BlackDot />}
                </EndSectionBar>
            </div>
        </div>
    );
};
