import { Divider } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { compact, mapValues, sortBy, uniq, uniqBy, values } from "lodash";
import * as React from "react";
import { ReactComponent as PsaIcon } from "../../assets/icons/ic_psa.svg";
import { t } from "../../i18n/util";
import {
    BloodpanelReport,
    BloodpanelReportHistory,
    BloodpanelReportValue,
    PatientInfo,
    RecommendationsReportHistory,
    StoolSampleReportHistory,
} from "../../network/APITypes";
import { Colors } from "../util/Colors";
import { formatDate, getBloodpanelConspicuousnessValue, getRecommendations } from "../util/helpers";
import {
    getBloodpanelValueDisplayValue,
    getBloodpanelValueDotPosition,
    getBloodpanelValueRangeValues,
    getConspicuousnessColor,
    getConspicuousnessDropdownLabel,
    getHistoryHeaderItems,
    getSmileyByColor,
} from "../util/ValueDisplayHelpers";
import { Card } from "./Card";
import { ConspicuousnessDisplay } from "./ConspicuousnessDisplay";
import { CurrentValueTable } from "./CurrentValueTable";
import { HistoryValuesTable } from "./HistoryValuesTable";
import { RecommendationHistory } from "./RecommendationHistory";
import { HistorySectionTitle } from "./HistorySectionTitle";
import { ValueDisplay } from "./ValueDisplay";
import { VerticalTableDivider } from "./VerticalTableDivider";

const PSA_NAME = "psa";
const STOOLSAMPLE = "stoolsample";
const VACCINATIONS = "general.vaccinations";
const BLOODPANEL = "bloodpanel";

type IProps = {
    bloodpanels?: BloodpanelReportHistory;
    stoolSample?: StoolSampleReportHistory;
    recommendations?: RecommendationsReportHistory;
    patientInformation?: PatientInfo;
    showBloodpanels?: boolean;
    showStoolSample?: boolean;
};

type IValueItem = {
    name?: string;
    unit?: string;
};

const Title = styled("div")({
    margin: "40px 0",
    fontWeight: 600,
    fontSize: 19,
});

const TitleRowContainer = styled("div")({
    display: "flex",
    height: 45,
    alignItems: "center",
    backgroundColor: Colors.LIGHT_BACKGROUND,
    marginLeft: 16,
});

const Comment = styled("div")({
    height: 52,
    display: "flex",
    alignItems: "center",
    marginLeft: 16,
    fontWeight: 400,
    fontSize: 16,
    color: Colors.TEXT_LIGHT,
});

const Subtitle = styled("div")({
    color: Colors.DISABLED,
    fontWeight: 400,
});

const TitleRow = ({ title, subtitle, icon }: { title?: string; subtitle?: string; icon?: React.ReactNode }) => (
    <TitleRowContainer style={{ height: icon ? 65 : undefined }}>
        {icon && (
            <div
                style={{
                    padding: 9,
                    backgroundColor: Colors.WHITE,
                    borderRadius: 8,
                    border: `1px solid ${Colors.GREY_200}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 12,
                }}
            >
                {icon}
            </div>
        )}
        <HistorySectionTitle style={{ margin: 0, display: subtitle ? "block" : undefined }}>
            {title}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </HistorySectionTitle>
    </TitleRowContainer>
);

const getBloodpanelValueItemsForGroup = (group: string, checkupReport?: BloodpanelReport) =>
    checkupReport?.values
        ?.find((groupItem) => groupItem.name === group)
        ?.values?.flatMap((valueItem) =>
            getBloodpanelValueDisplayValue(valueItem)
                ? {
                      name: valueItem.name,
                      unit: valueItem.unit,
                  }
                : [],
        ) ?? [];

const getBloodpanelGroupNames = (checkupReport?: BloodpanelReport) =>
    checkupReport?.values?.flatMap((group) => {
        const groupHasValidValue = group.values?.some((value) => getBloodpanelValueDisplayValue(value));

        return groupHasValidValue ? group.name ?? [] : [];
    }) ?? [];

const BloodpanelHistory = ({
    bloodpanels,
    lastCheckupReport,
    latestHistoryItems,
    emptyColumns,
    currentValueHeaderItems,
    valueHeaderItems,
}: {
    bloodpanels?: BloodpanelReportHistory;
    lastCheckupReport?: BloodpanelReport & { checkupDate?: string | Date | undefined };
    latestHistoryItems: (BloodpanelReport & { checkupDate?: string | Date | undefined })[];
    emptyColumns: any[];
    currentValueHeaderItems: { name: string; label: string }[];
    valueHeaderItems: any[];
}) => {
    const bloodpanelGroups = uniq([
        ...getBloodpanelGroupNames(bloodpanels?.latest),
        ...([...latestHistoryItems, lastCheckupReport]?.flatMap((history) => getBloodpanelGroupNames(history)) ?? []),
    ]).sort();

    const valueItemsByGroup = bloodpanelGroups.reduce((accumulator, group) => {
        const latestValueItems = getBloodpanelValueItemsForGroup(group, bloodpanels?.latest);

        const historyValueItems =
            [...latestHistoryItems, lastCheckupReport]?.flatMap((historyItem) =>
                getBloodpanelValueItemsForGroup(group, historyItem),
            ) ?? [];

        return {
            ...accumulator,
            [group]: sortBy(
                uniqBy(
                    [...latestValueItems, ...historyValueItems],
                    (valueItem) => `${valueItem.name}_${valueItem.unit}`,
                ),
                (valueItem) => `${valueItem.name}_${valueItem.unit}`,
            ),
        };
    }, {} as { [key: string]: IValueItem[] });

    const mergedHistories = mapValues(valueItemsByGroup, (valueItems, group) => {
        return valueItems.map((valueItem) => {
            const latestBloodpanelValue: BloodpanelReportValue | undefined = bloodpanels?.latest?.values
                ?.find((groupItem) => groupItem.name === group)
                ?.values?.find(
                    (bloodpanelValueItem) =>
                        bloodpanelValueItem.name === valueItem.name && bloodpanelValueItem.unit === valueItem.unit,
                );

            const range = getBloodpanelValueRangeValues(latestBloodpanelValue);
            const displayValue = getBloodpanelValueDisplayValue(latestBloodpanelValue);

            // Indicates if there is a reference value to compare the value to.
            // If there is no reference value we can't show if the value is good or bad.
            // For bool and text values we don't have a reference value.
            // See: https://allaboutapps-at.atlassian.net/browse/VOR-335
            const hasReferenceValue =
                latestBloodpanelValue?.valueType !== "bool" &&
                latestBloodpanelValue?.valueType !== "text" &&
                latestBloodpanelValue?.rangeType;

            const dotPosition = getBloodpanelValueDotPosition(latestBloodpanelValue, range, displayValue);
            const isDangerValue = dotPosition === "high" || dotPosition === "low";
            const color = hasReferenceValue && isDangerValue ? Colors.VALUE_DISPLAY_ORANGE : undefined;
            const smileyColor = isDangerValue ? Colors.VALUE_DISPLAY_ORANGE : Colors.VALUE_DISPLAY_GREEN;
            const smiley = displayValue !== undefined ? getSmileyByColor(smileyColor) : null;
            const hasValue = displayValue !== undefined;

            const latest =
                displayValue !== undefined
                    ? [
                          {
                              content: valueItem.name,
                          },
                          {
                              content: hasValue ? displayValue : "-",
                              color,
                              adornmentText: hasValue ? valueItem.unit : undefined,
                          },
                          {
                              content: hasReferenceValue ? (
                                  range?.minVal !== undefined && range.maxVal !== undefined ? (
                                      <ValueDisplay dotPosition={dotPosition} values={range} />
                                  ) : (
                                      <>
                                          {getBloodpanelConspicuousnessValue(latestBloodpanelValue) ? (
                                              <ConspicuousnessDisplay
                                                  value={getBloodpanelConspicuousnessValue(latestBloodpanelValue)}
                                              />
                                          ) : (
                                              "-"
                                          )}
                                      </>
                                  )
                              ) : (
                                  "-"
                              ),
                          },
                      ]
                    : [
                          {
                              content: valueItem.name,
                          },
                          { content: "-" },
                          {
                              content: "-",
                          },
                      ];

            const historyBloodpanelValues = latestHistoryItems.map((historyItem) => {
                const currentValue = valueItem.name ? getBloodpanelValue(valueItem, historyItem) : undefined;
                return currentValue !== undefined ? getBloodpanelHistoryItem(currentValue) : { content: "-" };
            });

            const lastBloodpanelValue = valueItem.name ? getBloodpanelValue(valueItem, lastCheckupReport) : undefined;
            const lastBloodpanelItem =
                lastBloodpanelValue !== undefined ? getBloodpanelHistoryItem(lastBloodpanelValue) : { content: "-" };

            const history = [
                {
                    content: hasReferenceValue ? smiley : "-",
                },
                ...emptyColumns,
                ...historyBloodpanelValues,
                lastBloodpanelItem,
            ];

            return {
                latest,
                history,
            };
        });
    });

    return (
        <>
            {bloodpanelGroups.map((group, index) => (
                <React.Fragment key={group}>
                    <TitleRow title={group} />
                    <Divider />
                    <div style={{ display: "flex" }}>
                        <CurrentValueTable
                            headerItems={index === 0 ? currentValueHeaderItems : undefined}
                            rows={mergedHistories[group].map((groupHistory) => groupHistory.latest)}
                        />
                        <VerticalTableDivider />
                        <HistoryValuesTable
                            headerItems={index === 0 ? (valueHeaderItems as any) : undefined}
                            rows={mergedHistories[group].map((groupHistory) => groupHistory.history)}
                        />
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

const findBloodpanelValue = (value: BloodpanelReportValue, item: IValueItem) =>
    value.name?.toLowerCase() === item.name?.toLowerCase() && value.unit === item.unit;

const getBloodpanelValue = (item: IValueItem, checkupReport?: BloodpanelReport) => {
    const group = checkupReport?.values?.find((group) =>
        group.values?.find((value) => findBloodpanelValue(value, item)),
    );
    return group?.values?.find((value) => findBloodpanelValue(value, item));
};

const getBloodpanelHistoryItem = (bloodpanelValue?: BloodpanelReportValue) => {
    if (bloodpanelValue === undefined) {
        return {
            content: "-",
        };
    }

    const bloodpanelValueRange = getBloodpanelValueRangeValues(bloodpanelValue);
    const bloodpanelValueDisplayValue = getBloodpanelValueDisplayValue(bloodpanelValue);

    const dotPosition = getBloodpanelValueDotPosition(
        bloodpanelValue,
        bloodpanelValueRange,
        bloodpanelValueDisplayValue,
    );

    const isDangerValue = dotPosition === "high" || dotPosition === "low";

    // Indicates if there is a reference value to compare the value to.
    // If there is no reference value we can't show if the value is good or bad.
    // For bool and text values we don't have a reference value.
    // See: https://allaboutapps-at.atlassian.net/browse/VOR-335
    const hasReferenceValue =
        bloodpanelValue.valueType !== "bool" && bloodpanelValue.valueType !== "text" && bloodpanelValue?.rangeType;

    const hasValue = bloodpanelValueDisplayValue !== undefined;

    return {
        content: hasValue ? bloodpanelValueDisplayValue : "-",
        color: hasReferenceValue && isDangerValue ? Colors.VALUE_DISPLAY_ORANGE : undefined,
        adornmentText: hasValue ? bloodpanelValue.unit : null,
    };
};

export const LaboratoryResultsHistory = ({
    bloodpanels,
    stoolSample,
    recommendations,
    patientInformation,
    showBloodpanels,
    showStoolSample,
}: IProps) => {
    const currentValueHeaderItems = [
        {
            name: "name",
            label: t("screen.history.table.checkup"),
        },
        {
            name: "value",
            label: t("screen.history.table.currentValue", {
                date: formatDate(bloodpanels?.latest?.checkupDate),
                br: (<br />) as any,
            }),
        },
        {
            name: "referenceRange",
            label: t("screen.history.table.referenceRange"),
        },
    ];

    const psa = getBloodpanelValue({ name: PSA_NAME }, bloodpanels?.latest);

    const psaRangeValues = getBloodpanelValueRangeValues(psa);
    const psaDisplayValue = getBloodpanelValueDisplayValue(psa);

    // Indicates if there is a reference value to compare the value to.
    // If there is no reference value we can't show if the value is good or bad.
    // For bool and text values we don't have a reference value.
    // See: https://allaboutapps-at.atlassian.net/browse/VOR-335
    const hasReferenceValue = psa?.valueType !== "bool" && psa?.valueType !== "text" && psa?.rangeType;

    const psaDotPosition = getBloodpanelValueDotPosition(psa, psaRangeValues, psaDisplayValue);

    const isPsaConspicuous = psaDotPosition === "high" || psaDotPosition === "low";
    const psaColor = hasReferenceValue && isPsaConspicuous ? Colors.VALUE_DISPLAY_ORANGE : undefined;

    const psaCurrentValueRow = [
        { content: t("screen.history.table.psa") },
        {
            content: psaDisplayValue ?? "-",
            color: psaColor,
            adornmentText: psaDisplayValue !== undefined ? psa?.unit : undefined,
        },
        {
            content: <ValueDisplay dotPosition={psaDotPosition} values={psaRangeValues} />,
        },
    ];

    const {
        valueHeaderItems: bloodpanelValueHeaderItems,
        latestHistoryItems: latestBloodpanelHistoryItems,
        lastCheckupReport: lastBloodpanelCheckupReport,
        emptyColumns: emptyBloodpanelColumns,
    } = getHistoryHeaderItems(bloodpanels?.history);

    const latestPsaHistory = latestBloodpanelHistoryItems.map((checkupReport) => {
        const currentPsa = getBloodpanelValue({ name: PSA_NAME }, checkupReport);
        return getBloodpanelHistoryItem(currentPsa);
    });
    const lastPsa = lastBloodpanelCheckupReport?.values
        ?.find((group) => group.values?.find((value) => findBloodpanelValue(value, { name: PSA_NAME })))
        ?.values?.find((value) => findBloodpanelValue(value, { name: PSA_NAME }));
    const lastPsaItem = getBloodpanelHistoryItem(lastPsa);
    const psaSmiley = getSmileyByColor(psaColor);
    const psaHistoryValuesRow = [{ content: psaSmiley }, ...emptyBloodpanelColumns, ...latestPsaHistory, lastPsaItem];

    const {
        valueHeaderItems: stoolSampleValueHeaderItems,
        latestHistoryItems: latestStoolSampleHistoryItems,
        lastCheckupReport: lastStoolSampleCheckupReport,
        emptyColumns: emptyStoolSampleColumns,
    } = getHistoryHeaderItems(stoolSample?.history);
    const stoolSampleColor = getConspicuousnessColor(stoolSample?.latest?.value);
    const stoolSampleCurrentValueRow = [
        {
            content: t("screen.history.stool"),
        },
        {
            content: stoolSample?.latest?.value ? getConspicuousnessDropdownLabel(stoolSample?.latest?.value) : "-",
            color: stoolSampleColor,
        },
        {
            content: stoolSample?.latest?.value ? <ConspicuousnessDisplay value={stoolSample?.latest?.value} /> : "-",
        },
    ];

    const latestStoolSampleHistory = latestStoolSampleHistoryItems.map((stoolSampleItem) => ({
        content: getConspicuousnessDropdownLabel(stoolSampleItem.value),
        color: getConspicuousnessColor(stoolSampleItem.value),
    }));
    const lastStoolSampleItem = {
        content: getConspicuousnessDropdownLabel(lastStoolSampleCheckupReport?.value),
        color: getConspicuousnessColor(lastStoolSampleCheckupReport?.value),
    };

    const stoolSampleHistoryValuesRow = [
        { content: getSmileyByColor(stoolSampleColor) },
        ...emptyStoolSampleColumns,
        ...latestStoolSampleHistory,
        lastStoolSampleItem,
    ];

    const {
        currentRecommendation: stoolSampleRecommendation,
        currentRecommendationHistory: stoolSampleRecommendationHistory,
    } = getRecommendations(STOOLSAMPLE, recommendations);

    const {
        currentRecommendation: vaccinationRecommendation,
        currentRecommendationHistory: vaccinationRecommendationHistory,
    } = getRecommendations(VACCINATIONS, recommendations);

    const {
        currentRecommendation: bloodpanelRecommendation,
        currentRecommendationHistory: bloodpanelRecommendationHistory,
    } = getRecommendations(BLOODPANEL, recommendations);

    const patientAge = dayjs(dayjs()).diff(dayjs(patientInformation?.birthday), "years");

    return (
        <>
            <Title>{t("screen.history.laboratoryResults")}</Title>
            {showBloodpanels && (
                <>
                    <Card>
                        <HistorySectionTitle>{t("screen.history.bloodpanels")}</HistorySectionTitle>
                        <Divider />
                        {psa && patientAge >= 60 && patientAge <= 69 && (
                            <>
                                <TitleRow
                                    title={psa.name}
                                    subtitle={t("screen.history.laboratoryResults.psa.subtitle")}
                                    icon={<PsaIcon />}
                                />
                                <div style={{ display: "flex" }}>
                                    <CurrentValueTable
                                        headerItems={currentValueHeaderItems}
                                        rows={[psaCurrentValueRow]}
                                        style={{ borderTop: `1px solid ${Colors.GREY_200}` }}
                                    />
                                    <VerticalTableDivider />
                                    <HistoryValuesTable
                                        headerItems={bloodpanelValueHeaderItems}
                                        rows={[psaHistoryValuesRow]}
                                        style={{ borderTop: `1px solid ${Colors.GREY_200}` }}
                                    />
                                </div>
                                <Comment>{psa.commentTxt}</Comment>
                                <Divider />
                            </>
                        )}
                        <BloodpanelHistory
                            bloodpanels={bloodpanels}
                            currentValueHeaderItems={currentValueHeaderItems}
                            latestHistoryItems={latestBloodpanelHistoryItems}
                            emptyColumns={emptyBloodpanelColumns}
                            lastCheckupReport={lastBloodpanelCheckupReport}
                            valueHeaderItems={bloodpanelValueHeaderItems}
                        />
                        <RecommendationHistory
                            title={"screen.history.recommendation"}
                            recommendation={bloodpanelRecommendation}
                            recommendationHistory={bloodpanelRecommendationHistory}
                        />
                    </Card>
                    <Card style={{ marginTop: 24 }}>
                        <RecommendationHistory
                            title="screen.history.vaccinationRecommendation"
                            recommendation={vaccinationRecommendation}
                            recommendationHistory={vaccinationRecommendationHistory}
                        />
                    </Card>
                </>
            )}
            {showStoolSample && stoolSample && (
                <Card style={{ marginTop: 24 }}>
                    <HistorySectionTitle>{t("screen.history.stoolSample")}</HistorySectionTitle>
                    <Divider />
                    <div style={{ display: "flex" }}>
                        <CurrentValueTable headerItems={currentValueHeaderItems} rows={[stoolSampleCurrentValueRow]} />
                        <VerticalTableDivider />
                        <HistoryValuesTable
                            headerItems={stoolSampleValueHeaderItems}
                            rows={[stoolSampleHistoryValuesRow]}
                        />
                    </div>
                    <RecommendationHistory
                        title={"screen.history.recommendation"}
                        recommendation={stoolSampleRecommendation}
                        recommendationHistory={stoolSampleRecommendationHistory}
                    />
                </Card>
            )}
        </>
    );
};
