import * as React from "react";
import styled from "styled-components";
import { IDotPosition } from "../../types";
import { Colors } from "../util/Colors";
import { BlackDot, BLACK_DOT_WIDTH } from "./Dots";

const GREEN_BAR_WIDTH = 90;

type IValues = {
    minVal?: number;
    maxVal?: number;
};

const OrangeBar = styled("div")({
    width: 24,
    height: 12,
    backgroundColor: Colors.VALUE_DISPLAY_ORANGE,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const GreenBar = styled("div")({
    width: GREEN_BAR_WIDTH,
    height: 12,
    backgroundColor: Colors.VALUE_DISPLAY_GREEN,
    margin: "0 1px",
    display: "flex",
    alignItems: "center",
    position: "relative",
});

const sanitizeDotPosition = (dotPosition: IDotPosition) => {
    if (typeof dotPosition === "number") {
        if (dotPosition > 100) {
            return "high";
        }
        if (dotPosition < 0) {
            return "low";
        }
    }
    return dotPosition;
};

export const ValueDisplay = ({
    values,
    dotPosition,
    fontSize,
    valueAlignment = "center",
}: {
    values?: IValues;
    // dotPosition is expected to be percent here. If it is outside 0-100% range it will be sanitized
    dotPosition?: IDotPosition;
    fontSize?: number;
    valueAlignment?: "left" | "right" | "center";
}) => {
    if (
        dotPosition === undefined ||
        !values ||
        typeof values.minVal !== "number" ||
        typeof values.maxVal !== "number"
    ) {
        return null;
    }

    const sanitizedDotPosition = sanitizeDotPosition(dotPosition);

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                style={{
                    fontWeight: 400,
                    color: Colors.TEXT_LIGHT,
                    marginRight: 16,
                    minWidth: 120,
                    textAlign: valueAlignment,
                }}
            >
                {values.minVal} - {values.maxVal}
            </div>
            <div style={{ display: "flex" }}>
                <OrangeBar>{sanitizedDotPosition === "low" && <BlackDot />}</OrangeBar>
                <GreenBar>
                    {typeof sanitizedDotPosition === "number" && (
                        <BlackDot
                            style={{
                                position: "absolute",
                                left: sanitizedDotPosition * ((GREEN_BAR_WIDTH - BLACK_DOT_WIDTH) / 100),
                            }}
                        />
                    )}
                </GreenBar>
                <OrangeBar>{sanitizedDotPosition === "high" && <BlackDot />}</OrangeBar>
            </div>
        </div>
    );
};
