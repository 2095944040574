import * as React from "react";
import { t } from "../../i18n/util";
import { ICheckupSectionItem, IProgressSectionItem } from "../../types";
import { BiaResult } from "../patients/BiaResult";
import { Bloodpanel } from "../patients/Bloodpanel";
import { EcgResult } from "../patients/EcgResult";
import { ExerciseErgometric } from "../patients/ExerciseErgometric";
import { LifestyleParameters } from "../patients/LifestyleParameters";
import { PatientInformation } from "../patients/PatientInformation";
import { ProductSelection } from "../patients/ProductSelection";
import { Spirometric } from "../patients/Spirometric";
import { StoolSample } from "../patients/StoolSample";
import * as Yup from "yup";
import dayjs from "dayjs";
import { PatientStatus } from "../patients/PatientStatus";
import { BloodpanelRecommendation } from "../patients/BloodpanelRecommendation";
import { Vaccinations } from "../patients/Vaccinations";
import { StoolSampleRecommendation } from "../patients/StoolSampleRecommendation";
import { LifestyleParametersRecommendation } from "../patients/LifestyleParametersRecommendation";
import { BiaRecommendation } from "../patients/BiaRecommendation";
import { EcgRecommendation } from "../patients/EcgRecommendation";
import { RelaxationRecommendation } from "../patients/RelaxationRecommendation";
import { SpirometricRecommendation } from "../patients/SpirometricRecommendation";
import { ExerciseRecommendation } from "../patients/ExerciseRecommendation";
import { UltrasoundRecommendation } from "../patients/UltrasoundRecommendation";
import { Diagnosis } from "../patients/Diagnosis";
import { MedicationRecommendation } from "../patients/MedicationRecommendation";
import { FurtherCheckupRecommendation } from "../patients/FurtherCheckupRecommendation";

const initialDoctor = t("initialDoctor");
const initialEcgResult = t("initialEcgResult");

const defaultValidation = Yup.string().required(t("error.required"));

export const initialBloodpanelItem = {
    name: "",
    unit: "",
    referenceType: "span",
    referenceMin: "",
    referenceMax: "",
    value: "",
};

export const initialInputCheckupSections: ICheckupSectionItem[] = [
    {
        title: "screen.patientInformation.productType",
        items: [
            {
                title: "screen.patientInformation.productType",
                name: "productType",
                hideNavbarStep: true,
                Component: ProductSelection,
                initialValues: {
                    productType: "",
                },
            },
        ],
    },
    {
        title: "screen.patientInformation.patientInfo",
        items: [
            {
                name: "patientInfo",
                Component: PatientInformation,
                initialValues: {
                    lastName: "",
                    firstName: "",
                    titlePrefix: "",
                    titleSuffix: "",
                    patientId: "",
                    birthday: "",
                    email: "",
                    checkupDate: "",
                    checkupSite: "",
                    sex: "",
                    doctor: initialDoctor,
                    locumDoctor: "",
                },
                validationSchema: Yup.object().shape({
                    patientInfo: Yup.object({
                        email: defaultValidation.email(t("error.email.validationError")),
                        patientId: defaultValidation,
                    }),
                }),
            },
        ],
    },
    {
        title: "screen.patientInformation.laboratoryResults",
        items: [
            {
                title: "screen.patientInformation.bloodpanel",
                name: "bloodpanel",
                Component: Bloodpanel,
                initialValues: {
                    values: [],
                },
            },
            {
                title: "screen.patientInformation.stoolSample",
                name: "stoolSample",
                Component: StoolSample,
                initialValues: {
                    value: "",
                },
            },
        ],
    },
    {
        title: "screen.patientInformation.lifestyleParameters",
        items: [
            {
                name: "lifestyleParameters",
                Component: LifestyleParameters,
                initialValues: {
                    height: "",
                    weight: "",
                    bmi: "",
                    abdominalGirth: "",
                    exerciseDropdown: "",
                    smokerDropdown: "",
                    bloodpressure: {
                        diastole: "",
                        systole: "",
                    },
                    bloodpressureDropdown: "",
                },
            },
        ],
    },
    {
        title: "screen.patientInformation.devices",
        items: [
            {
                title: "screen.patientInformation.devices.biaResult",
                name: "biaResult",
                Component: BiaResult,
                initialValues: {
                    fat: {
                        maxVal: "",
                        minVal: "",
                        value: "",
                    },
                    fatPercent: {
                        maxVal: "",
                        minVal: "",
                        value: "",
                    },
                    muscle: {
                        maxVal: "",
                        minVal: "",
                        value: "",
                    },
                },
            },
            {
                title: "screen.patientInformation.devices.spirometric",
                name: "spirometric",
                Component: Spirometric,
                initialValues: {
                    FVC: "",
                    FVCPercent: "",
                    FeV1FVC: "",
                },
            },
            {
                title: "screen.patientInformation.devices.ecgResult",
                name: "ecgResult",
                Component: EcgResult,
                initialValues: {
                    value: initialEcgResult,
                },
            },
            {
                title: "screen.patientInformation.devices.exerciseErgometric",
                name: "exerciseErgometric",
                Component: ExerciseErgometric,
                initialValues: {
                    percent: "",
                    energy: "",
                    maxHeartRate: "",
                    maxBloodpressure: {
                        diastole: "",
                        systole: "",
                    },
                },
            },
        ],
    },
];

export const initialRecommendationCheckupSections: ICheckupSectionItem[] = [
    {
        title: "screen.patientInformation.patientStatus",
        items: [
            {
                title: "screen.patientInformation.patientStatus",
                name: "patientStatus",
                Component: PatientStatus,
                initialValues: {
                    textInput: "",
                },
                hideNavbarStep: true,
            },
        ],
    },
    {
        title: "screen.patientInformation.laboratoryResults",
        items: [
            {
                title: "screen.patientInformation.bloodpanel",
                name: "bloodpanelRecommendation",
                Component: BloodpanelRecommendation,
                initialValues: {
                    textInput: "",
                },
            },
            {
                title: "screen.patientInformation.vaccinations",
                name: "vaccinations",
                Component: Vaccinations,
                initialValues: {
                    textInput: "",
                },
            },
            {
                title: "screen.patientInformation.stoolSample",
                name: "stoolSampleRecommendation",
                Component: StoolSampleRecommendation,
                initialValues: {
                    textInput: "",
                },
            },
        ],
    },
    {
        title: "screen.patientInformation.lifestyleParameters",
        items: [
            {
                title: "screen.patientInformation.field.lifestyleParameters.bloodPressure.label",
                name: "lifestyle",
                Component: LifestyleParametersRecommendation,
                initialValues: {
                    abdominalGirth: {
                        textInput: "",
                    },
                    bloodpressure: {
                        textInput: "",
                    },
                    exercise: {
                        textInput: "",
                    },
                    smoker: {
                        textInput: "",
                    },
                },
                hideNavbarStep: true,
            },
        ],
    },
    {
        title: "screen.patientInformation.devices",
        items: [
            {
                title: "screen.patientInformation.devices.biaResult",
                name: "bia",
                Component: BiaRecommendation,
                initialValues: {
                    textInput: "",
                },
            },
            {
                title: "screen.patientInformation.devices.ecgResult",
                name: "ecg",
                Component: EcgRecommendation,
                initialValues: {
                    assessment: "",
                    textInput: "",
                },
            },
            {
                title: "screen.patientInformation.devices.relaxation",
                name: "relaxation",
                Component: RelaxationRecommendation,
                initialValues: {
                    relaxation: "",
                    textInput: "",
                },
            },
            {
                title: "screen.patientInformation.devices.spirometric",
                name: "spirometricRecommendation",
                Component: SpirometricRecommendation,
                initialValues: {
                    assessment: "",
                    medicalCondition: "",
                    textInput: "",
                },
            },
            {
                title: "screen.patientInformation.devices.exerciseErgometric",
                name: "exercise",
                Component: ExerciseRecommendation,
                initialValues: {
                    heartRate: "",
                    textInput: "",
                    displayTraining: "",
                },
            },
            {
                title: "screen.patientInformation.devices.ultrasound",
                name: "ultrasound",
                Component: UltrasoundRecommendation,
                initialValues: {
                    carotis: {
                        value: "",
                        textInput: "",
                    },
                    heart: {
                        value: "",
                        textInput: "",
                    },
                    stomach: {
                        value: "",
                        textInput: "",
                    },
                    thyroid: {
                        value: "",
                        textInput: "",
                    },
                },
            },
        ],
    },
    {
        title: "screen.patientInformation.doctorRecommendation",
        items: [
            {
                title: "screen.patientInformation.field.diagnosis",
                name: "diagnosis",
                Component: Diagnosis,
                initialValues: {
                    textInput: "",
                },
            },
            {
                title: "screen.patientInformation.field.medication",
                name: "medication",
                Component: MedicationRecommendation,
                initialValues: {
                    textInput: "",
                },
            },
            {
                title: "screen.patientInformation.field.furtherCheckup",
                name: "furtherCheckup",
                Component: FurtherCheckupRecommendation,
                initialValues: {
                    textInput: "",
                },
            },
        ],
    },
];

export const initialCheckupReportSections: IProgressSectionItem[] = [
    {
        title: "screen.checkupReport.overview",
    },
    {
        name: "lifestyleReport",
        title: "screen.patientInformation.lifestyleParameters",
    },
    {
        name: "laboratoryResultsReport",
        title: "screen.patientInformation.laboratoryResults",
        items: [
            {
                title: "screen.patientInformation.bloodpanel",
                name: "bloodpanelsReport",
            },
            {
                title: "screen.patientInformation.stoolSample",
                name: "stoolSampleReport",
            },
        ],
    },
    {
        name: "diagnosticReport",
        title: "screen.checkupReport.diagnostic.title",
        items: [
            {
                title: "screen.patientInformation.devices.biaResult",
                name: "biaReport",
            },
            {
                title: "screen.patientInformation.devices.ecgResult",
                name: "ecgReport",
            },
            {
                title: "screen.patientInformation.devices.relaxation",
                name: "relaxationReport",
            },
            {
                title: "screen.patientInformation.devices.spirometric",
                name: "spirometricReport",
            },
            {
                title: "screen.patientInformation.devices.exerciseErgometric",
                name: "exerciseReport",
            },
            {
                title: "screen.patientInformation.devices.ultrasound",
                name: "ultrasoundReport",
            },
        ],
    },
];
