import * as React from "react";
import { styled } from "@mui/styles";
import { Colors } from "../util/Colors";

export const FieldRowHeader = styled("div")({
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Roboto",
    color: Colors.DISABLED,
});
