import { Divider, IconButton, styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import * as React from "react";
import { ReactComponent as MoreVertical } from "../../assets/icons/ic_more_vertical.svg";
import { IMessageIDS, t } from "../../i18n/util";
import {
    BloodpanelReportHistory,
    BloodpanelReportValue,
    CheckupReport,
    ConspicuousnessDropdown,
    StoolSampleReportHistory,
} from "../../network/APITypes";
import { IHistory, IHistoryCategory } from "../../types";
import { history } from "../app/router/history";
import { CardTitle } from "../ui/CardTitle";
import { ConspicuousnessDisplay } from "../ui/ConspicuousnessDisplay";
import { TextDot } from "../ui/Dots";
import { ValueCell } from "../ui/ValueCell";
import { ValueDisplay } from "../ui/ValueDisplay";
import { Colors } from "../util/Colors";
import { formatDate, getBloodpanelConspicuousnessValue } from "../util/helpers";
import {
    getBloodpanelValueDisplayValue,
    getBloodpanelValueDotPosition,
    getBloodpanelValueRangeValues,
    getConspicuousnessColor,
    getConspicuousnessDropdownLabel,
} from "../util/ValueDisplayHelpers";
import { CustomCard, Title } from "./sites/CheckupReportSite";

const CHECKUP_CELL_WIDTH = 255;
const VALUE_CELL_WIDTH = 200;
const HISTORY_WIDTH = 80;
const STOOLSAMPLE_CATEGORY = "stoolsample";
const BLOODPANEL_CATEGORY = "bloodpanel";

type IProps = {
    checkupReport?: CheckupReport | null;
    onClickHistory: (event: React.MouseEvent<HTMLButtonElement>, history: any) => void;
};

type IHeader = {
    name: string;
    label: IMessageIDS;
};

const BigDivider = styled(Divider)({
    height: 4,
    backgroundColor: Colors.GREY_200,
});

const BloodpanelDate = styled("div")({
    height: 70,
    padding: 16,
    fontWeight: 400,
    color: "#121212",
});

const BloodpanelDateLabel = styled("div")({
    fontSize: 12,
    color: Colors.DISABLED,
    fontWeight: 400,
});

const CategoryTitle = styled("div")({
    height: 46,
    backgroundColor: "#FCFCFC",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
});

const TableHeaderCell = styled(TableCell)({
    height: 46,
    fontSize: 12,
    color: Colors.DISABLED,
});

const Recommendation = styled("div")({
    padding: 16,
    fontWeight: 400,
    fontSize: 16,
    color: "#4F4F4F",
    whiteSpace: "pre-wrap",
});

const ValueContainer = styled("div")({
    display: "flex",
    alignItems: "center",
});

const StyledTableCell = styled(TableCell, {
    shouldForwardProp: (prop: string) => prop !== "hideBorder",
})(({ hideBorder }: { hideBorder: boolean }) => ({
    borderColor: "#EDEDED",
    border: hideBorder ? "none" : undefined,
    fontSize: 16,
}));

const hl7TableHeaders: IHeader[] = [
    {
        name: "checkup",
        label: "screen.checkupReport.laboratoryResultsReport.checkup",
    },
    {
        name: "value",
        label: "screen.checkupReport.laboratoryResultsReport.value",
    },
    {
        name: "range",
        label: "screen.checkupReport.laboratoryResultsReport.range",
    },
    {
        name: "history",
        label: "screen.checkupReport.laboratoryResultsReport.history",
    },
];

const getHeaderStyle = (name: string) => {
    if (name === "checkup") {
        return { textAlign: "left", width: CHECKUP_CELL_WIDTH } as React.CSSProperties;
    }
    if (name === "value") {
        return { textAlign: "left", width: VALUE_CELL_WIDTH } as React.CSSProperties;
    }

    if (name === "range") {
        return { textAlign: "center" } as React.CSSProperties;
    }

    if (name === "history") {
        return { textAlign: "right", width: HISTORY_WIDTH } as React.CSSProperties;
    }
};

const getConspicuousnessDisplayLabel = (value: BloodpanelReportValue) => {
    if (value.rangeFrom && value.rangeFrom > 0) {
        return t("screen.checkupReport.laboratoryResultsReport.greaterThan", { value: value.rangeFrom });
    }
    if (value.rangeTo && value.rangeTo > 0) {
        return t("screen.checkupReport.laboratoryResultsReport.lowerThan", { value: value.rangeTo });
    }
    return;
};

const Value = ({
    value,
    unit,
    conspicuous,
}: {
    value?: string | number | boolean;
    unit?: string;
    conspicuous?: boolean;
}) => {
    return (
        <ValueContainer>
            <div style={{ marginRight: 4, color: conspicuous ? Colors.VALUE_DISPLAY_ORANGE : undefined }}>
                {value} {value !== undefined ? unit : null}
            </div>
            {conspicuous && <TextDot style={{ backgroundColor: Colors.VALUE_DISPLAY_ORANGE }} />}
        </ValueContainer>
    );
};

function getColor(bloodpanelValue: BloodpanelReportValue) {
    if (
        bloodpanelValue.valueType === "numeric" &&
        bloodpanelValue.valueNumeric &&
        bloodpanelValue.rangeFrom &&
        bloodpanelValue.rangeTo &&
        (bloodpanelValue.valueNumeric < bloodpanelValue.rangeFrom ||
            bloodpanelValue.valueNumeric > bloodpanelValue.rangeTo)
    ) {
        return Colors.VALUE_DISPLAY_ORANGE;
    } else {
        return undefined;
    }
}

const Bloodpanel = ({
    bloodpanels,
    recommendation,
    onClickHistory,
}: {
    bloodpanels?: BloodpanelReportHistory;
    recommendation?: string;
    onClickHistory: (event: React.MouseEvent<HTMLButtonElement>, history: any) => void;
}) => {
    const hl7Values = bloodpanels?.latest?.values;

    return (
        <div>
            <CardTitle>{t("screen.checkupReport.laboratoryResultsReport.bloodpanel.title")}</CardTitle>
            <Divider />
            <BloodpanelDate>
                <BloodpanelDateLabel>
                    {t("screen.checkupReport.laboratoryResultsReport.bloodpanel.date.label")}
                </BloodpanelDateLabel>
                <div>{formatDate(bloodpanels?.latest?.date)}</div>
            </BloodpanelDate>
            <BigDivider />
            {hl7Values
                ?.filter(
                    (category) =>
                        (category.values?.filter((value) => getBloodpanelValueDisplayValue(value))?.length ?? 0) > 0,
                )
                .map((category, index) => {
                    return (
                        <React.Fragment key={category.name}>
                            <CategoryTitle>{category.name}</CategoryTitle>
                            <Divider />
                            <Table>
                                {index === 0 && (
                                    <TableHead>
                                        <TableRow>
                                            {hl7TableHeaders.map((header) => (
                                                <TableHeaderCell style={getHeaderStyle(header.name)} key={header.name}>
                                                    {t(header.label)}
                                                </TableHeaderCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                )}
                                <TableBody>
                                    {category.values?.map((value, valueIndex) => {
                                        const rangeValues = getBloodpanelValueRangeValues(value);
                                        const displayValue = getBloodpanelValueDisplayValue(value);

                                        const dotPosition = getBloodpanelValueDotPosition(
                                            value,
                                            rangeValues,
                                            displayValue,
                                        );

                                        const isConspicuous = dotPosition === "high" || dotPosition === "low";

                                        const history = {
                                            category: "bloodpanels",
                                            valueKey: value.name,
                                            adornmentText: value.unit,
                                            title: value.name,
                                            unit: value.unit,
                                            getColor: getColor,
                                        };

                                        const hasReferenceValue =
                                            value?.valueType !== "bool" &&
                                            value?.valueType !== "text" &&
                                            value?.rangeType;

                                        const hideBorder =
                                            hl7Values.length - 1 === index &&
                                            category?.values?.length &&
                                            category?.values?.length - 1 === valueIndex;

                                        return displayValue !== undefined ? (
                                            <TableRow key={valueIndex}>
                                                <StyledTableCell
                                                    style={{ width: CHECKUP_CELL_WIDTH, overflow: "hidden" }}
                                                    hideBorder={hideBorder}
                                                >
                                                    {value.name}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{ width: VALUE_CELL_WIDTH }}
                                                    hideBorder={hideBorder}
                                                >
                                                    <Value
                                                        value={displayValue}
                                                        unit={value.unit}
                                                        conspicuous={isConspicuous}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell hideBorder={hideBorder}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {hasReferenceValue ? (
                                                            typeof rangeValues?.minVal === "number" &&
                                                            typeof rangeValues.maxVal === "number" ? (
                                                                <ValueDisplay
                                                                    valueAlignment="right"
                                                                    values={rangeValues}
                                                                    dotPosition={dotPosition}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {(value.valueType === "bool" ||
                                                                        rangeValues?.minVal !== undefined ||
                                                                        rangeValues?.maxVal !== undefined) && (
                                                                        <ConspicuousnessDisplay
                                                                            reverse={
                                                                                value.shortName === "HDL" ||
                                                                                value.name === "HDL-Cholesterin"
                                                                            }
                                                                            valueAlignment="right"
                                                                            label={getConspicuousnessDisplayLabel(
                                                                                value,
                                                                            )}
                                                                            value={getBloodpanelConspicuousnessValue(
                                                                                value,
                                                                            )}
                                                                        />
                                                                    )}
                                                                </>
                                                            )
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{ width: HISTORY_WIDTH, textAlign: "right" }}
                                                    hideBorder={hideBorder}
                                                >
                                                    <IconButton onClick={(event) => onClickHistory(event, history)}>
                                                        <MoreVertical />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </TableRow>
                                        ) : null;
                                    })}
                                </TableBody>
                            </Table>
                        </React.Fragment>
                    );
                })}
            {recommendation && (
                <>
                    <BigDivider />
                    <Recommendation>{recommendation}</Recommendation>
                </>
            )}
        </div>
    );
};

const StoolSample = ({
    stoolSample,
    onClickHistory,
    recommendation,
}: {
    stoolSample?: StoolSampleReportHistory;
    recommendation?: string;
    onClickHistory: (event: React.MouseEvent<HTMLButtonElement>, history: any) => void;
}) => {
    const value = stoolSample?.latest?.value;
    const history: IHistory = {
        category: "stoolSample",
        valueKey: "value",
        title: "screen.checkupReport.history.stoolSample",
        getColor: getConspicuousnessColor,
    };

    return (
        <div>
            <CardTitle>{t("screen.checkupReport.laboratoryResultsReport.stoolSample.title")}</CardTitle>
            <Divider />
            <ValueCell
                title={t("common.report")}
                value={getConspicuousnessDropdownLabel(value)}
                color={getConspicuousnessColor(value)}
                history={history}
                onClickHistory={onClickHistory}
            />

            {value === "conspicuous" && recommendation && (
                <>
                    <Divider />
                    <Recommendation>{recommendation}</Recommendation>
                </>
            )}
        </div>
    );
};

export const LaboratoryResultsReport = ({ checkupReport, onClickHistory }: IProps) => {
    const showBloodpanel = (checkupReport?.bloodpanels?.latest?.values?.length ?? 0) > 0;
    const showStoolSample =
        checkupReport?.stoolSample?.latest?.value && checkupReport?.stoolSample?.latest?.value !== "notSpecified";

    return (
        <>
            {(showBloodpanel || showStoolSample) && (
                <Title>{t("screen.checkupReport.laboratoryResultsReport.title")}</Title>
            )}
            {showBloodpanel && (
                <CustomCard>
                    <Bloodpanel
                        bloodpanels={checkupReport?.bloodpanels}
                        onClickHistory={onClickHistory}
                        recommendation={
                            checkupReport?.recommendations?.latest?.savedTexts?.find(
                                (recommendation) => recommendation.category === BLOODPANEL_CATEGORY,
                            )?.text
                        }
                    />
                </CustomCard>
            )}
            {showStoolSample && (
                <CustomCard>
                    <StoolSample
                        stoolSample={checkupReport?.stoolSample}
                        onClickHistory={onClickHistory}
                        recommendation={
                            checkupReport.recommendations?.latest?.savedTexts?.find(
                                (recommendation) => recommendation.category === STOOLSAMPLE_CATEGORY,
                            )?.text
                        }
                    />
                </CustomCard>
            )}
            {/* TODO: add when vaccinations are available */}
            {/* <CustomCard>
                <Vaccinations
                    vaccinations={checkupReport.vaccinations}
                    onClickHistory={onClickHistory}
                    recommendation={
                        checkupReport.recommendations?.find(
                            (recommendation) => recommendation.category === "vaccinations",
                        )?.text
                    }
                />
            </CustomCard> */}
        </>
    );
};
