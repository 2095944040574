import { styled } from "@mui/system";
import { t } from "../../i18n/util";
import { ConspicuousnessDropdown, ObstructiveDropdown, RelaxationDropdown } from "../../network/APITypes";
import { Colors } from "../util/Colors";

type IProps = {
    value?: ConspicuousnessDropdown | RelaxationDropdown | ObstructiveDropdown;
    label?: string;
    valueAlignment?: "left" | "center" | "right";
    reverse?: boolean;
};

const CenterContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const Bar = styled("div")({
    height: 12,
    width: 69,
});

export const ConspicuousnessDisplay = ({ value, label, reverse, valueAlignment = "center" }: IProps) => {
    const greenBar = (
        <Bar
            style={{
                backgroundColor: value === "normal" || value === "sufficient" ? Colors.VALUE_DISPLAY_GREEN : undefined,
            }}
        />
    );

    return (
        <>
            {value && value !== "notSpecified" ? (
                <CenterContainer>
                    <div
                        style={{
                            marginRight: 16,
                            whiteSpace: "nowrap",
                            minWidth: 120,
                            textAlign: valueAlignment,
                        }}
                    >
                        {label ?? t("screen.history.conspicuousAndNormal")}
                    </div>
                    <CenterContainer style={{ border: `1px solid ${Colors.GREY_200}` }}>
                        {!reverse && greenBar}
                        <Bar
                            style={{
                                backgroundColor:
                                    value === "conspicuous" ||
                                    value === "insufficient" ||
                                    value === "obstructive" ||
                                    value === "restrictive"
                                        ? Colors.VALUE_DISPLAY_ORANGE
                                        : undefined,
                            }}
                        />
                        {reverse && greenBar}
                    </CenterContainer>
                </CenterContainer>
            ) : (
                "-"
            )}
        </>
    );
};
