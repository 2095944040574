import { Alert, Divider, Snackbar } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { t } from "../../i18n/util";
import { DialogText, StyledDialog } from "./CustomDialog";
import { CustomDialogActions } from "./CustomDialogActions";
import * as Yup from "yup";
import { CustomInputField } from "./CustomInputField";
import { API } from "../../network/API";
import { useState } from "react";

type IProps = {
    patientId: string;
    open: boolean;
    onSuccess(): void;
    onClose(): void;
};

export const ChangePasswordDialog = ({ patientId, open, onSuccess, onClose }: IProps) => {
    const [successOpen, setSuccessOpen] = useState<boolean>(false);

    return (
        <>
            <StyledDialog open={open}>
                <div style={{ padding: 16, minWidth: 400 }}>
                    <h4 style={{ marginBottom: 8 }}>{t("patient.changePassword.title")}</h4>
                    <DialogText>{t("patient.changePassword.text")}</DialogText>
                    <Formik
                        initialValues={{
                            password: "",
                        }}
                        onSubmit={async (values) => {
                            console.error("submit");
                            try {
                                await API.changePatientPassword({
                                    patientId: patientId,
                                    newPassword: values.password,
                                });
                                setSuccessOpen(true);
                                onSuccess();
                            } catch (error) {
                                console.error(error);
                            }
                        }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string()
                                .min(4, t("screen.overview.createCheckup.password.error.length"))
                                .required(t("error.password.validationError"))
                                .trim(),
                        })}
                        validateOnBlur
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <div style={{ padding: "16px 0" }}>
                                    <Field
                                        component={CustomInputField}
                                        label={t("screen.auth.password")}
                                        name="password"
                                        type="password"
                                        required
                                        autoComplete="new-password"
                                        errorMessage={errors.password}
                                        isTouched={touched.password}
                                        autoFocus
                                    />
                                </div>
                                <Divider />
                                <div style={{ padding: "16px 0 0" }}>
                                    <CustomDialogActions
                                        onClose={onClose}
                                        cancelText={t("common.cancel")}
                                        submitText={t("common.save")}
                                        typeSubmit
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </StyledDialog>

            <Snackbar
                open={successOpen}
                autoHideDuration={4000}
                onClose={() => {
                    setSuccessOpen(false);
                }}
            >
                <Alert severity="success" sx={{ width: "100%" }}>
                    {t("patient.changePassword.success")}
                </Alert>
            </Snackbar>
        </>
    );
};
