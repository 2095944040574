import { Button, Link } from "@mui/material";
import { styled } from "@mui/styles";
import { t } from "../../i18n/util";
import { history } from "../app/router/history";
import { Routes } from "../app/router/Routes";
import { Colors } from "../util/Colors";

export const AuthCard = styled("div")({
    backgroundColor: "#FFFFFF",
    maxWidth: 440,
    width: "100%",
    borderRadius: 12,
});

export const AuthTitle = styled("h4")({
    display: "flex",
    alignItems: "center",
    height: 58,
    padding: 16,
});

const SubmitButtonContainer = styled("div")({
    padding: 16,
    display: "flex",
    alignItems: "center",
});

const SubmitButton = styled(Button)({
    height: 48,
    borderRadius: 8,
});

export const AuthSubmitActions = ({
    text,
    disabled,
    onClick,
    children,
}: {
    text: string;
    disabled?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
}) => (
    <SubmitButtonContainer style={{ justifyContent: children ? "space-between" : "end" }}>
        {children}
        <SubmitButton onClick={onClick} variant="contained" disabled={disabled} type={onClick ? undefined : "submit"}>
            {text}
        </SubmitButton>
    </SubmitButtonContainer>
);

const handleClickBack = () => {
    history.push(Routes.ROOT);
};

export const backToLoginLink = (
    <Link
        style={{
            color: Colors.DISABLED,
            cursor: "pointer",
            fontSize: 14,
            textDecoration: "underline",
        }}
        underline="none"
        onClick={handleClickBack}
    >
        {t("screen.auth.backToLogin")}
    </Link>
);
