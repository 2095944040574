import { Divider } from "@mui/material";
import { styled } from "@mui/styles";
import FileSaver from "file-saver";
import * as React from "react";
import Bewegungsempfehlung from "../../assets/images/bewegungsempfehlung.png";
import BloodpressureInfo from "../../assets/images/bloodpressure_info.jpg";
import Rauchstopp from "../../assets/images/rauchstopp.png";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { CheckupReport } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { generalStore } from "../../stores/GeneralStore";
import { FileLine } from "../ui/FileLine";
import { IconContainer } from "../ui/IconContainer";
import { ImageBlutdruck, ImageRauchen } from "../util/Images";
import { CustomCard, Title } from "./sites/CheckupReportSite";

type IProps = {
    checkupReport: CheckupReport | null;
};

const CategoryTitle = styled("div")({
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
});

function StyledImage(props: { src: string; alt: string }) {
    return (
        <div
            style={{
                padding: 16,
                textAlign: "center",
            }}
        >
            <img src={props.src} alt={props.alt} style={{ width: "75%" }} />
        </div>
    );
}

export const Attachments = ({ checkupReport }: IProps) => {
    const handleClickDownloadAttachment = async (attachmentHash?: string, fileName?: string) => {
        if (checkupReport?.checkupId && attachmentHash) {
            generalStore.isLoading = true;
            try {
                if (authStore.userProfile?.scopes?.includes("patient")) {
                    const attachment = await API.downloadAttachmentPatient({
                        checkupId: checkupReport.checkupId,
                        attachmentHash,
                    });

                    FileSaver.saveAs(attachment, fileName);
                } else if (authStore.userProfile?.scopes?.includes("doctor")) {
                    const attachment = await API.downloadAttachmentDoctor({
                        checkupId: checkupReport.checkupId,
                        attachmentHash,
                    });

                    FileSaver.saveAs(attachment, fileName);
                }
            } catch (error) {
                generalStore.setError(t("error.download.attachment"), error);
            } finally {
                generalStore.isLoading = false;
            }
        }
    };

    const isSmoker =
        checkupReport?.lifestyle?.latest?.smokerDropdown === "occasionalSmoker" ||
        checkupReport?.lifestyle?.latest?.smokerDropdown === "smoker";

    return (
        <>
            <Title>{t("screen.checkupReport.attachments.title")}</Title>
            <CustomCard>
                {checkupReport?.checkupId && (checkupReport.attachments?.length ?? 0) > 0 && (
                    <>
                        <CategoryTitle>{t("screen.checkupReport.attachments.documents")}</CategoryTitle>
                        <Divider />
                        {checkupReport?.attachments?.map((attachment, index) =>
                            attachment.sha256 ? (
                                <React.Fragment key={attachment.sha256 ?? index}>
                                    <FileLine
                                        fileName={attachment.filename}
                                        onClickDownload={() =>
                                            handleClickDownloadAttachment(attachment.sha256, attachment.filename)
                                        }
                                        fileSize={attachment.size}
                                    />
                                </React.Fragment>
                            ) : null,
                        )}
                        <Divider />
                    </>
                )}
                <CategoryTitle>
                    <IconContainer>
                        <ImageBlutdruck />
                    </IconContainer>
                    {t("screen.checkupReport.attachments.bloodpressure")}
                </CategoryTitle>
                <Divider />
                <div style={{ padding: 16 }}>
                    <StyledImage src={BloodpressureInfo} alt="bloodpressure info" />
                </div>
                <Divider />
                {isSmoker && (
                    <>
                        <CategoryTitle>
                            <IconContainer>
                                <ImageRauchen />
                            </IconContainer>
                            {t("screen.checkupReport.attachments.smoker")}
                        </CategoryTitle>
                        <Divider />
                        <StyledImage src={Rauchstopp} alt="quitting smoking effects" />
                        <Divider />
                    </>
                )}
                <StyledImage src={Bewegungsempfehlung} alt="activity recommendation" />
            </CustomCard>
        </>
    );
};
