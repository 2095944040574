import { styled } from "@mui/styles";
import * as React from "react";
import { ReactComponent as DeleteIcon } from "../../assets/icons/ic_trash.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/ic_file.svg";
import { ReactComponent as NewTabIcon } from "../../assets/icons/ic_new_tab.svg";
import { IconButton } from "@mui/material";
import { t } from "../../i18n/util";
import { Colors } from "../util/Colors";

type IProps = {
    onDelete?: () => void;
    onClickDownload?: () => void;
    fileName?: string;
    fileSize?: number;
    name?: string;
    totalValues?: number;
    date?: string;
    disabled?: boolean;
    deleteIconStyle?: React.CSSProperties;
};

const FileContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    height: 44,
    alignItems: "center",
    margin: 16,
});

const IconBackground = styled("div")({
    height: 44,
    width: 44,
    backgroundColor: "#F5F5F5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
});

const getSizeString = (size?: number) => {
    if (size) {
        // at least 1 Megabyte
        if (size / (1024 * 1024) > 1) {
            return `${Math.round(size / (1024 * 1024))}${t("common.megaByte")}`;
        }

        // at least 1 Kilobyte
        else if (size / 1024 > 1) {
            return `${Math.round(size / 1024)}${t("common.kiloByte")}`;
        }

        // everything below 1 Kilobyte
        else {
            return `${size}${t("common.byte")}`;
        }
    } else {
        return "";
    }
};

export const FileLine = ({
    fileName,
    fileSize,
    onDelete,
    onClickDownload,
    name,
    totalValues,
    date,
    disabled,
    deleteIconStyle,
}: IProps) => {
    const fileSizeString = getSizeString(fileSize);

    return (
        <FileContainer>
            <div style={{ display: "flex" }}>
                <div style={{ display: "flex", alignItems: "center", marginRight: 16 }}>
                    <IconBackground>
                        <FileIcon />
                    </IconBackground>
                </div>
                <div>
                    <div>{fileName}</div>
                    <div style={{ color: Colors.DISABLED }}>
                        {fileSize ? (
                            fileSizeString
                        ) : (
                            <div>
                                <div>
                                    {name}, {date}
                                </div>
                                <div>
                                    {t("screen.patientInformation.field.bloodpanel.upload.hl7.recordedValues")}{" "}
                                    {totalValues}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {onClickDownload && (
                <IconButton onClick={onClickDownload}>
                    <NewTabIcon />
                </IconButton>
            )}
            {onDelete && (
                <IconButton disabled={disabled} onClick={onDelete} style={deleteIconStyle}>
                    <DeleteIcon />
                </IconButton>
            )}
        </FileContainer>
    );
};
