import { Button, Dialog, DialogActions, Divider, Link } from "@mui/material";
import { Field, Form, Formik, FormikValues } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { CustomInputField } from "../ui/CustomInputField";

type IProps = {
    isOpen: boolean;
    onSubmit: (values: FormikValues) => void;
    onClose: () => void;
};

const StyledButton = styled(Button)({
    height: 48,
    borderRadius: 8,
    textTransform: "none",
    fontSize: 16,
    fontWeight: 500,
});

export const CreateNewCheckupDialog = ({ isOpen, onSubmit, onClose }: IProps) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Formik
                initialValues={{
                    patientId: "",
                    email: "",
                    password: "",
                }}
                validationSchema={Yup.object().shape({
                    patientId: Yup.string().required(t("error.required")),
                    email: Yup.string().email(t("error.email")).required(t("error.required")).trim(),
                    password: Yup.string()
                        .min(4, t("screen.overview.createCheckup.password.error.length"))
                        .required(t("error.required"))
                        .trim(),
                })}
                onSubmit={onSubmit}
            >
                {({ errors, touched, values }) => (
                    <>
                        <Form>
                            <div style={{ padding: 16 }}>
                                <h4 style={{ marginBottom: 16 }}>{t("screen.overview.createCheckup.title")}</h4>

                                <>
                                    <Field
                                        name="patientId"
                                        component={CustomInputField}
                                        label={t("screen.overview.createCheckup.patientId")}
                                        errorMessage={errors.patientId}
                                        isTouched={touched.patientId}
                                        style={{ minWidth: 400 }}
                                    />
                                    <Field
                                        name="email"
                                        component={CustomInputField}
                                        label={t("screen.overview.createCheckup.email")}
                                        errorMessage={errors.email}
                                        isTouched={touched.email}
                                    />
                                    <Field
                                        name="password"
                                        type="password"
                                        component={CustomInputField}
                                        label={t("screen.overview.createCheckup.password")}
                                        errorMessage={errors.password}
                                        isTouched={touched.password}
                                    />
                                </>
                            </div>
                            <Divider />
                            <DialogActions style={{ padding: 16 }}>
                                <Link
                                    component="button"
                                    underline="none"
                                    color="inherit"
                                    onClick={onClose}
                                    style={{ marginRight: 16, color: "black" }}
                                    // Without a type this button triggers formik submit
                                    type="button"
                                >
                                    {t("common.cancel")}
                                </Link>
                                <StyledButton type="submit" variant="contained">
                                    {t("screen.overview.createCheckup.create")}
                                </StyledButton>
                            </DialogActions>
                        </Form>
                    </>
                )}
            </Formik>
        </Dialog>
    );
};
