import { createTheme } from "@mui/material/styles";
import { Styles } from "./Styles";

export const theme = createTheme({
    typography: {
        fontFamily: `"IBM Plex Sans", "Roboto"`,
    },
    palette: {
        primary: {
            main: Styles.PRIMARY_COLOR,
        },
        secondary: {
            main: Styles.PRIMARY_COLOR,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: Styles.PRIMARY_COLOR,
                    color: "white",
                    "&:hover": {
                        backgroundColor: Styles.PRIMARY_COLOR_ACTIVE,
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: "#ededed",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: "IBM Plex Sans",
                    fontSize: 16,
                    fontWeight: 500,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    "@media print": {
                        display: "none",
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    "@media print": {
                        display: "none",
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    "@media print": {
                        display: "none",
                    },
                },
            },
        },
    },
});
