import { Button, Divider, Link } from "@mui/material";
import { styled } from "@mui/styles";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation, useParams } from "react-router";
import { ReactComponent as CheckmarkIcon } from "../../../assets/icons/16px/ic_checkmark.svg";
import logo from "../../../assets/images/logo_minified.svg";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { generalStore } from "../../../stores/GeneralStore";
import { patientStore } from "../../../stores/PatientStore";
import { history } from "../../app/router/history";
import { CustomDialog } from "../../ui/CustomDialog";
import { Colors } from "../../util/Colors";
import { initialInputCheckupSections, initialRecommendationCheckupSections } from "../../util/patientSiteConfig";
import { CheckupInput } from "../CheckupInput";
import { ProgressBar } from "../ProgressBar";

const ACTION_CONTAINER_HEIGHT = 80;
const NAVBAR_HEIGHT = 64;
const SPACING_OFFSET = 87;

const PROGRESS_BAR_WIDTH = "315px";

const SiteContainer = styled("div")({
    backgroundColor: Colors.WHITE,
    display: "flex",
});

const ProgressContainer = styled("aside")({
    position: "fixed",
    width: PROGRESS_BAR_WIDTH,
    overflow: "auto",
    height: "100%",
    paddingBottom: 100,
});

const MainContainer = styled("main")({
    padding: "38px 7.5vw",
    backgroundColor: Colors.BACKGROUND,
    flex: 1,
    marginBottom: ACTION_CONTAINER_HEIGHT,
});

const Header = styled("div")({
    display: "flex",
});

const TitleContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
});

const Icon = styled("img")({
    backgroundColor: Colors.WHITE,
    border: "1px solid #ededed",
    borderRadius: 12,
    padding: 8,
    height: 56,
});

const ActionsContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    alignItems: "center",
    width: "fill-available",
    paddingRight: 24,
});

const BottomBar = styled("div")({
    position: "fixed",
    bottom: 0,
    height: ACTION_CONTAINER_HEIGHT,
    width: "100%",
    backgroundColor: Colors.WHITE,
});

const StyledButton = styled(Button)({
    display: "block",
    height: 48,
    borderRadius: 8,
});

const ButtonTitle = styled("div")({
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "18px",
    textTransform: "none",
});

const ButtonSubtitle = styled("div")({
    fontSize: 12,
    fontWeight: 400,
    opacity: 0.64,
    lineHeight: "14px",
    textTransform: "none",
});

const ReleasedContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    marginRight: 16,
    color: Colors.MAIN,
});

export const PatientSite = observer(() => {
    const [checkupSections, setCheckupSections] = React.useState(initialInputCheckupSections);
    const [isInputTabActive, setIsInputTabActive] = React.useState(true);
    const [isReleaseDialogOpen, setIsReleaseDialogOpen] = React.useState(false);
    const [scrollToId, setScrollToId] = React.useState("");

    const { checkupId } = useParams<{ checkupId?: string }>();

    const { pathname, hash } = useLocation();
    const isLoading = generalStore.isLoading;

    const loadCheckup = React.useCallback(async () => {
        if (checkupId) {
            generalStore.isLoading = true;
            try {
                const checkup = await API.getCheckup({ checkupId });
                patientStore.checkup = checkup;

                const checkupRecommendationsResponse = await API.getCheckupRecommendations({ checkupId });
                patientStore.checkupRecommendations = checkupRecommendationsResponse.recommendations;

                checkupSections.forEach((section) => {
                    section.items?.forEach((item) => {
                        patientStore.setItemCheckupStatus({
                            currentTab: isInputTabActive ? "input" : "recommendation",
                            initialValues: item.initialValues,
                            itemName: item.name,
                            itemStatus: patientStore.checkupStatus[item.name],
                            released: checkup.released,
                            checkupRecommendations: checkupRecommendationsResponse.recommendations,
                            checkupCategory: checkup?.[item.name],
                        });
                    });
                });

                if (checkup.patientInfo?.firstName && checkup.patientInfo.lastName) {
                    patientStore.setPatientName(checkup.patientInfo?.firstName, checkup.patientInfo?.lastName);
                } else {
                    patientStore.setPatientName("", "");
                }
            } catch (error) {
                generalStore.setError(t("error.load"), error);
            } finally {
                generalStore.isLoading = false;
            }
        }
    }, [checkupId, checkupSections, isInputTabActive]);

    const currentTab = isInputTabActive ? "input" : "recommendation";

    React.useEffect(() => {
        window.scrollTo(0, 0);

        loadCheckup();

        return () => {
            patientStore.checkup = undefined;
            patientStore.resetCheckupStatus();
        };
    }, [loadCheckup, currentTab]);

    React.useEffect(() => {
        if (hash) {
            const id = hash.replace("#", "");
            history.replace(pathname);
            // use a state to trigger the next effect which actually scrolls in order to make scroll work in firefox
            // firefox seems to cancel the scroll when the history changes in the same effect/same frame
            setScrollToId(id);
        }
    }, [pathname, hash]);

    React.useEffect(() => {
        if (!isLoading && scrollToId) {
            const element = document.getElementById(scrollToId);

            if (element) {
                const offset = element.offsetTop - (NAVBAR_HEIGHT + SPACING_OFFSET);
                window.scrollTo({ top: offset, behavior: "smooth" });
            }

            setScrollToId("");
        }
    }, [scrollToId, isLoading]);

    const handleClickInputTab = () => {
        setIsInputTabActive(true);
        setCheckupSections(initialInputCheckupSections);
        // TODO: get sections from BE when switching tab
    };

    const handleClickRecommendationTab = () => {
        setIsInputTabActive(false);
        setCheckupSections(initialRecommendationCheckupSections);
        // TODO: get sections from BE when switching tab
    };

    const checkupDate = patientStore.checkup?.checkupDate;

    const handleClickFinish = (name: string) => {
        patientStore.setCheckupSectionFinished(name);
    };

    const handleClickEdit = (name: string) => {
        patientStore.setCheckupSectionEditable(name);
    };

    const handleClickReleaseCheckup = async () => {
        generalStore.isLoading = true;
        try {
            if (checkupId) {
                await API.releaseCheckup({ checkupId });
                loadCheckup();
            }
        } catch (error) {
            generalStore.setError(t("error.save"), error);
        } finally {
            generalStore.isLoading = false;
        }

        setIsReleaseDialogOpen(false);
    };

    const handleClickOpenReleaseDialog = () => {
        setIsReleaseDialogOpen(true);
    };

    const handleCloseReleaseDialog = () => {
        setIsReleaseDialogOpen(false);
    };

    return (
        <>
            <SiteContainer>
                <ProgressContainer>
                    <div style={{ padding: "16px 0 16px 32px" }}>
                        <Link
                            component="button"
                            underline="none"
                            color="inherit"
                            onClick={handleClickInputTab}
                            style={{ marginRight: 16, color: isInputTabActive ? "black" : Colors.DISABLED }}
                        >
                            {t("screen.patientInformation.progressBar.input")}
                        </Link>
                        <Link
                            component="button"
                            underline="none"
                            color="inherit"
                            onClick={handleClickRecommendationTab}
                            style={{ color: isInputTabActive ? Colors.DISABLED : "black" }}
                        >
                            {t("screen.patientInformation.progressBar.recommendation")}
                        </Link>
                    </div>
                    <Divider />
                    <ProgressBar checkupSections={checkupSections} />
                </ProgressContainer>
                <Divider orientation="vertical" flexItem style={{ marginLeft: PROGRESS_BAR_WIDTH }} />
                <MainContainer>
                    <Header style={{ marginBottom: 32 }}>
                        <Icon src={logo} alt="Vorsorgeinstitut Dr. Fabritz" />
                        <TitleContainer style={{ marginLeft: 16 }}>
                            <h3>{t("patientSite.title")}</h3>
                            <div className="body-semi" style={{ marginTop: 4 }}>
                                {dayjs(checkupDate).format("MMM YYYY")}
                            </div>
                        </TitleContainer>
                    </Header>
                    <Divider />
                    {patientStore.checkup && (
                        <CheckupInput
                            // When the key changes the component unmounts & remounts.
                            // We want a full reinitialization of the form
                            key={currentTab}
                            checkupSections={checkupSections}
                            onFinish={handleClickFinish}
                            onEdit={handleClickEdit}
                            currentTab={currentTab}
                            onChangeTab={handleClickInputTab}
                            reloadCheckup={loadCheckup}
                        />
                    )}
                </MainContainer>
            </SiteContainer>
            <BottomBar>
                <Divider />
                <ActionsContainer>
                    {patientStore.checkup?.released ? (
                        <ReleasedContainer>
                            <CheckmarkIcon style={{ marginRight: 6 }} fill={Colors.MAIN} />
                            {t("screen.patientInformation.releaseButton.released")}
                        </ReleasedContainer>
                    ) : (
                        <StyledButton onClick={handleClickOpenReleaseDialog} variant="contained">
                            <ButtonTitle>{t("screen.patientInformation.releaseButton.title")}</ButtonTitle>
                            <ButtonSubtitle>{t("screen.patientInformation.releaseButton.subtitle")}</ButtonSubtitle>
                        </StyledButton>
                    )}
                </ActionsContainer>
            </BottomBar>
            <CustomDialog
                open={isReleaseDialogOpen}
                onClose={handleCloseReleaseDialog}
                onSubmit={handleClickReleaseCheckup}
                title={t("screen.patientInformation.releaseDialog.title")}
                text={t("screen.patientInformation.releaseDialog.text")}
                submitText={t("screen.patientInformation.releaseButton.title")}
            />
        </>
    );
});
