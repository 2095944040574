import { IconButton, styled } from "@mui/material";
import * as React from "react";
import { IMultiValueDisplayDotPosition, IBreakpoints, IDotPosition, IHistory, IHistoryItem } from "../../types";
import { Colors } from "../util/Colors";
import { TextDot } from "./Dots";
import { ValueDisplay } from "./ValueDisplay";
import { ValueGradient } from "./ValueGradient";
import { ReactComponent as MoreVertical } from "../../assets/icons/ic_more_vertical.svg";
import { MultiValueDisplay } from "../patients/MultiValueDisplay";

const ValueContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px",
});

const ValueCellTitle = styled("div")({
    fontSize: 12,
    fontWeight: 400,
    color: Colors.DISABLED,
});

const ValueCellText = styled("div")({
    fontSite: 16,
    fontWeight: 400,
    color: Colors.TEXT_LIGHT,
    display: "flex",
    alignItems: "center",
});

const lightBorderStyle = `1px solid ${Colors.GREY_200}`;

export const ValueCell = ({
    title,
    value,
    adornmentText,
    color,
    history,
    indicatorReplacement,
    style,
    containerStyle,
    graphType = "normal",
    graphValues,
    graphDotPosition,
    graphMultiDotPosition,
    graphBreakpoints,
    graphValue,
    exerciseDropdown,
    onClickHistory,
}: {
    title: string;
    value?: number | string;
    adornmentText?: string;
    color?: string;
    history?: IHistory;
    indicatorReplacement?: string;
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    graphType?: "gradient" | "normal" | "multi";
    graphValues?: { minVal: number; maxVal: number };
    graphMultiDotPosition?: IMultiValueDisplayDotPosition;
    graphDotPosition?: IDotPosition;
    graphBreakpoints?: IBreakpoints;
    graphValue?: number;
    exerciseDropdown?: string;
    onClickHistory: (event: React.MouseEvent<HTMLButtonElement>, history: any) => void;
}) => (
    <div style={{ padding: "16px 0", ...containerStyle }}>
        <ValueContainer style={{ ...style }}>
            <div style={{ width: "100%", borderRight: graphDotPosition ? lightBorderStyle : undefined }}>
                <ValueCellTitle>{title}</ValueCellTitle>
                <ValueCellText style={{ color }}>
                    <>
                        <div style={{ marginRight: 4 }}>{`${value} ${adornmentText ?? ""}`}</div>
                        {color &&
                            (indicatorReplacement ? (
                                <div>{indicatorReplacement}</div>
                            ) : (
                                <TextDot style={{ backgroundColor: color }} />
                            ))}
                    </>
                </ValueCellText>
            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <div style={{ marginRight: 30 }}>
                    {graphType === "normal" && (
                        <ValueDisplay valueAlignment="right" values={graphValues} dotPosition={graphDotPosition} />
                    )}
                    {graphType === "gradient" && (
                        <ValueGradient
                            breakpoints={graphBreakpoints}
                            value={graphValue}
                            exerciseDropdown={exerciseDropdown}
                            valueAlignment="right"
                        />
                    )}
                    {graphType === "multi" && graphValues && (
                        <MultiValueDisplay
                            valueAlignment="right"
                            values={graphValues}
                            dotPosition={graphMultiDotPosition}
                        />
                    )}
                </div>
                {history && (
                    <IconButton onClick={(event) => onClickHistory(event, history)}>
                        <MoreVertical />
                    </IconButton>
                )}
            </div>
        </ValueContainer>
    </div>
);
