import { IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/styles";
import { FieldInputProps, FormikProps, FormikValues } from "formik";
import { StyledInputLabel } from "./StyledInputLabel";
import { ReactComponent as ShowPassword } from "../../assets/icons/ic_show_password.svg";
import { ReactComponent as HidePassword } from "../../assets/icons/ic_hide_password.svg";
import * as React from "react";
import { Colors } from "../util/Colors";

type IProps = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    form: FormikProps<FormikValues>;
    isTouched?: boolean;
    onChange?: (e: React.SyntheticEvent) => void;
    ignoreHeaderLabel?: boolean;
    adornmentText?: string;
    hideError?: boolean;
    min?: number;
    max?: number;
    paddingTop?: number;
};

const StyledIconButton = styled(IconButton)({
    "&:hover": {
        backgroundColor: "transparent",
    },
});

const getType = (type: string, showPassword: boolean) => {
    if (type === "password") {
        return showPassword ? "input" : "password";
    }
    return type;
};

export const CustomInputField = ({
    style,
    label,
    type,
    autoComplete,
    required,
    errorMessage,
    field,
    isTouched,
    disabled,
    onChange,
    ignoreHeaderLabel,
    adornmentText,
    hideError,
    min,
    max,
    form,
    paddingTop,
    ...props
}: IProps) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const showError = isTouched && !!errorMessage;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = parseInt(e.target.value);
        if (min !== undefined && value < min) {
            form.setFieldValue(field.name, min);
            return;
        }
        if (max !== undefined && value > max) {
            form.setFieldValue(field.name, max);
            return;
        }

        field.onChange(e);

        if (onChange) {
            onChange(e);
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const passwordInputProps = (
        <InputAdornment position="end">
            <StyledIconButton onClick={handleClickShowPassword} style={{ padding: 0 }}>
                {showPassword ? <HidePassword /> : <ShowPassword />}
            </StyledIconButton>
        </InputAdornment>
    );

    return (
        <div style={{ ...style, paddingTop: paddingTop ?? 0 }}>
            {ignoreHeaderLabel ? null : (
                <StyledInputLabel htmlFor={field.name} style={{ marginBottom: 8 }}>
                    {required ? `${label} *` : label}
                </StyledInputLabel>
            )}

            <OutlinedInput
                id={field.name}
                label={required ? `${label} *` : label}
                value={field.value}
                name={field.name}
                onChange={handleChange}
                onBlur={field.onBlur}
                fullWidth
                type={getType(type ?? "", showPassword)}
                autoComplete={autoComplete}
                error={showError}
                margin="dense"
                notched={false}
                placeholder={ignoreHeaderLabel ? label?.toString() : undefined}
                aria-label={props["aria-label"]}
                disabled={disabled}
                style={{ backgroundColor: disabled ? Colors.BACKGROUND : undefined }}
                endAdornment={
                    type === "password" ? (
                        passwordInputProps
                    ) : (
                        <InputAdornment position="end">{adornmentText}</InputAdornment>
                    )
                }
                autoFocus={props.autoFocus}
            />
            {!hideError && (
                <span
                    style={{
                        color: "#f00",
                        display: "block",
                        minHeight: 20,
                        width: "100%",
                        marginTop: 4,
                        fontSize: 12,
                    }}
                >
                    {showError && errorMessage}
                </span>
            )}
        </div>
    );
};
