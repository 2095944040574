import { Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/styles";
import * as React from "react";
import { ReactComponent as MoreVertical } from "../../assets/icons/ic_more_vertical.svg";
import { IMessageIDS, t } from "../../i18n/util";
import { BiaResults, CheckupReport, ObstructiveDropdown } from "../../network/APITypes";
import { IBiaName, IHistory } from "../../types";
import { BiaValueDisplay } from "../patients/BiaValueDisplay";
import { ExerciseValueDisplay } from "../patients/ExerciseValueDisplay";
import { CardTitle } from "../ui/CardTitle";
import { TextDot } from "../ui/Dots";
import { IconContainer } from "../ui/IconContainer";
import { Colors } from "../util/Colors";
import { getRecommendationText } from "../util/helpers";
import {
    ImageBewegungLaufen,
    ImageBewegungRadfahren,
    ImageBIA,
    ImageEKG,
    ImageEntspannung,
    ImageErgometrie,
    ImageSonoAbdomen,
    ImageSonoCarotis,
    ImageSonoHerzEcho,
    ImageSonoSD,
    ImageSpirometrie,
} from "../util/Images";
import {
    getBiaDotPosition,
    getBiaValueColor,
    getConspicuousnessColor,
    getConspicuousnessDropdownLabel,
    getExerciseErgometricPercentColor,
    getRelaxationColor,
    getUltrasoundColor,
} from "../util/ValueDisplayHelpers";
import { CustomCard, Title } from "./sites/CheckupReportSite";

type IProps = {
    checkupReport?: CheckupReport | null;
    onClickHistory: (event: React.MouseEvent<HTMLButtonElement>, history: any) => void;
};

type IValue = {
    value?: number | string;
    title?: string;
    color?: string;
    adornmentText?: string;
    showHistory?: boolean;
    history?: IHistory;
    component?: React.ReactNode;
};

type IIcon = React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
>;

type ITrainingValues = {
    first: {
        min: number;
        max: number;
    };
    second: {
        min: number;
        max: number;
    };
};

const Recommendation = styled("div")({
    padding: 16,
    fontWeight: 400,
    fontSize: 16,
    color: "#4F4F4F",
    whiteSpace: "pre-wrap",
});

const RecommendationContainer = styled("div")({
    padding: 16,
    fontSize: 16,
    fontWeight: 400,
    color: "#4F4F4F",
});

const ValueCellTitle = styled("div")({
    fontSize: 12,
    fontWeight: 400,
    color: Colors.DISABLED,
});

const ValueCellText = styled("div")({
    fontSite: 16,
    fontWeight: 400,
    color: Colors.TEXT_LIGHT,
    display: "flex",
    alignItems: "center",
});

const ValueContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px",
});

const ValuesContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
});

const TrainingAreasContainer = styled("div")({
    padding: "0 16px",
    fontWeight: 400,
    color: "#4F4F4F",
});

const TrainingContainer = styled("div")({
    padding: "16px 0",
    display: "flex",
});

const TrainingRecommendationContainer = styled("div")({
    display: "flex",
    width: "50%",
});

const TrainingSubtitle = styled("div")({
    color: Colors.DISABLED,
    fontWeight: 400,
});

const StyledTableCell = styled(TableCell)({
    fontSize: 16,
});

const relaxationKeys: { [key: string]: IMessageIDS } = {
    notSpecified: "common.notSpecified",
    insufficient: "screen.checkupReport.diagnostic.relaxation.insufficient",
    sufficient: "screen.checkupReport.diagnostic.relaxation.sufficient",
};

const spirometricAssessmentKeys: { [key: string]: IMessageIDS } = {
    notSpecified: "common.notSpecified",
    normal: "common.normal",
    obstructive: "screen.checkupReport.diagnostic.spirometric.obstructive",
    restrictive: "screen.checkupReport.diagnostic.spirometric.restrictive",
};

const spirometricMedicalConditionKeys: { [key: string]: IMessageIDS } = {
    notSpecified: "common.notSpecified",
    asthmatic: "screen.checkupReport.diagnostic.spirometric.asthmatic",
    smoker: "screen.checkupReport.diagnostic.spirometric.smoker",
    smokerAsthmatic: "screen.checkupReport.diagnostic.spirometric.smokerAsthmatic",
};

const ultrasoundKeys: { [key: string]: IMessageIDS } = {
    notSpecified: "common.notSpecified",
    normal: "common.normal",
    conspicuous: "common.conspicuous",
    conspicuous1yrControl: "screen.checkupReport.diagnostic.ultrasound.conspicuous1yrControl",
    conspicuous6monthControl: "screen.checkupReport.diagnostic.ultrasound.conspicuous6monthControl",
    conspicuousCTAbdomens: "screen.checkupReport.diagnostic.ultrasound.conspicuousCTAbdomens",
    conspicuousCardioControl: "common.conspicuous",
    conspicuousControl: "screen.checkupReport.diagnostic.ultrasound.conspicuousControl",
    conspicuousControlDoctor: "screen.checkupReport.diagnostic.ultrasound.conspicuousControlDoctor",
    conspicuousThyroidScintigraphy: "screen.checkupReport.diagnostic.ultrasound.conspicuousThyroidScintigraphy",
};

const getSpirometricColor = (value?: ObstructiveDropdown) => {
    if (value === "obstructive" || value === "restrictive") {
        return Colors.VALUE_DISPLAY_ORANGE;
    }
    if (value === "normal") {
        return Colors.VALUE_DISPLAY_GREEN;
    }
    return undefined;
};

const getSanitizedValues = (values?: IValue[]) => {
    if (!values) {
        return [];
    }
    return values.filter((value) => value.value && value.value !== "");
};

const ValueComponent = ({
    value,
    onClickHistory,
    showBorder,
}: {
    value: IValue;
    onClickHistory: (event: React.MouseEvent<HTMLButtonElement>, history: any) => void;
    showBorder?: boolean;
}) => (
    <div
        style={{
            padding: "16px 0",
            flexBasis: 1,
            flexGrow: 1,
        }}
    >
        <ValueContainer
            style={{ borderRight: showBorder ? `1px solid ${Colors.GREY_200}` : undefined, height: "100%" }}
        >
            <div style={{ width: "100%" }}>
                {value.component ?? (
                    <>
                        <ValueCellTitle>{value.title ? value.title : t("common.report")}</ValueCellTitle>
                        <ValueCellText style={{ color: value.color }}>
                            <>
                                <div style={{ marginRight: 4 }}>{`${value.value} ${value.adornmentText ?? ""}`}</div>
                                {value.color && <TextDot style={{ backgroundColor: value.color }} />}
                            </>
                        </ValueCellText>
                    </>
                )}
            </div>
            {value.showHistory && (
                <IconButton onClick={(event) => onClickHistory(event, value.history)}>
                    <MoreVertical />
                </IconButton>
            )}
        </ValueContainer>
    </div>
);

const CategoryCard = ({
    values,
    secondaryValues,
    title,
    onClickHistory,
    recommendation,
    Icon,
    children,
    recommendationPosition = "bottom",
}: {
    values: IValue[];
    secondaryValues?: IValue[];
    title: string;
    onClickHistory: (event: React.MouseEvent<HTMLButtonElement>, history: any) => void;
    recommendation?: string;
    Icon?: React.ReactNode;
    children?: React.ReactNode;
    recommendationPosition?: "top" | "bottom";
}) => {
    const sanitizedValues = getSanitizedValues(values);
    const hasValues = sanitizedValues.length !== 0;

    const sanitizedSecondaryValues = getSanitizedValues(secondaryValues);
    const hasSecondaryValues = sanitizedSecondaryValues.length !== 0;

    if (!hasValues && !hasSecondaryValues && !children) {
        return null;
    }

    const recommendationComponent = recommendation ? (
        <>
            <Divider />
            <RecommendationContainer>{recommendation}</RecommendationContainer>
        </>
    ) : null;

    return (
        <CustomCard>
            <CardTitle>
                {Icon}
                {title}
            </CardTitle>
            <Divider />
            {hasValues && (
                <ValuesContainer>
                    {sanitizedValues.map((value, index) => (
                        <ValueComponent
                            key={index}
                            value={value}
                            onClickHistory={onClickHistory}
                            showBorder={index !== sanitizedValues.length - 1}
                        />
                    ))}
                </ValuesContainer>
            )}
            {hasSecondaryValues && (
                <>
                    <Divider />
                    <ValuesContainer>
                        {sanitizedSecondaryValues?.map((value, index) => (
                            <ValueComponent
                                key={index}
                                value={value}
                                onClickHistory={onClickHistory}
                                showBorder={index !== sanitizedSecondaryValues.length - 1}
                            />
                        ))}
                    </ValuesContainer>
                </>
            )}
            {recommendationPosition === "top" && recommendationComponent}
            {children}
            {recommendationPosition === "bottom" && recommendationComponent}
        </CustomCard>
    );
};

const TrainingRecommendation = ({ title, percent, value }: { title: string; percent: string; value: string }) => (
    <TrainingContainer>
        <TrainingRecommendationContainer style={{ borderRight: `1px solid ${Colors.GREY_200}` }}>
            {title}
            <div style={{ color: Colors.DISABLED, marginLeft: 8 }}>{percent}</div>
        </TrainingRecommendationContainer>
        <TrainingRecommendationContainer style={{ marginLeft: 16 }}>
            {t("screen.checkupReport.diagnostic.exerciseErgometric.heartRateRange")} {value}
        </TrainingRecommendationContainer>
    </TrainingContainer>
);

const TrainingAreas = ({
    runValues,
    bicycleValues,
}: {
    runValues: ITrainingValues;
    bicycleValues: ITrainingValues;
}) => {
    const trainingAreaItems = [
        {
            icon: <ImageBewegungLaufen />,
            title: t("screen.checkupReport.diagnostic.exerciseErgometric.run.title"),
            subtitle: t("screen.checkupReport.diagnostic.exerciseErgometric.run.subtitle"),
            trainingRecommendations: [
                {
                    title: t("screen.checkupReport.diagnostic.exerciseErgometric.basicsOne.label"),
                    percent: t("screen.checkupReport.diagnostic.exerciseErgometric.eightyPercent"),
                    value: `${Math.round(runValues.first.min)} - ${Math.round(runValues.first.max)}`,
                },
                {
                    title: t("screen.checkupReport.diagnostic.exerciseErgometric.basicsTwo.label"),
                    percent: t("screen.checkupReport.diagnostic.exerciseErgometric.twentyPercent"),
                    value: `${Math.round(runValues.second.min)} - ${Math.round(runValues.second.max)}`,
                },
            ],
        },
        {
            icon: <ImageBewegungRadfahren />,
            title: t("screen.checkupReport.diagnostic.exerciseErgometric.bicycle.title"),
            subtitle: t("screen.checkupReport.diagnostic.exerciseErgometric.bicycle.subtitle"),
            trainingRecommendations: [
                {
                    title: t("screen.checkupReport.diagnostic.exerciseErgometric.basicsOne.label"),
                    percent: t("screen.checkupReport.diagnostic.exerciseErgometric.eightyPercent"),
                    value: `${Math.round(bicycleValues.first.min)} - ${Math.round(bicycleValues.first.max)}`,
                },
                {
                    title: t("screen.checkupReport.diagnostic.exerciseErgometric.basicsTwo.label"),
                    percent: t("screen.checkupReport.diagnostic.exerciseErgometric.twentyPercent"),
                    value: `${Math.round(bicycleValues.second.min)} - ${Math.round(bicycleValues.second.max)}`,
                },
            ],
        },
    ];
    return (
        <div>
            <Divider />
            <div style={{ padding: "12px 16px" }}>
                {t("screen.checkupReport.diagnostic.exerciseErgometric.training.title")}
            </div>
            <Divider />
            {trainingAreaItems.map((trainingAreaItem, index) => (
                <React.Fragment key={index}>
                    <CardTitle style={{ backgroundColor: "#FCFCFC" }}>
                        <IconContainer>{trainingAreaItem.icon}</IconContainer>
                        <div>
                            <div>{trainingAreaItem.title}</div>
                            <TrainingSubtitle>{trainingAreaItem.subtitle}</TrainingSubtitle>
                        </div>
                    </CardTitle>
                    <Divider />
                    <TrainingAreasContainer>
                        {trainingAreaItem.trainingRecommendations.map(
                            (trainingRecommendationItem, trainingRecommendationItemIndex) => (
                                <React.Fragment key={trainingRecommendationItemIndex}>
                                    <TrainingRecommendation
                                        title={trainingRecommendationItem.title}
                                        percent={trainingRecommendationItem.percent}
                                        value={trainingRecommendationItem.value}
                                    />
                                    {trainingRecommendationItemIndex + 1 !==
                                        trainingAreaItem.trainingRecommendations.length && <Divider />}
                                </React.Fragment>
                            ),
                        )}
                    </TrainingAreasContainer>
                    {index + 1 !== trainingAreaItems.length && <Divider />}
                </React.Fragment>
            ))}
        </div>
    );
};

export const Diagnostic = ({ checkupReport, onClickHistory }: IProps) => {
    if (
        (!checkupReport?.ecg?.latest || checkupReport.ecg.latest.assessment === "notSpecified") &&
        (!checkupReport?.relaxation?.latest || checkupReport.relaxation.latest.value === "notSpecified") &&
        (!checkupReport?.spirometric?.latest ||
            (checkupReport.spirometric.latest.assessment === "notSpecified" &&
                !checkupReport?.spirometric?.latest?.FVC &&
                !checkupReport?.spirometric?.latest?.FVCPercent &&
                !checkupReport?.spirometric?.latest?.FeV1FVC)) &&
        !checkupReport?.bia?.latest?.fat?.value &&
        !checkupReport?.bia?.latest?.fatPercent?.value &&
        !checkupReport?.bia?.latest?.muscle?.value &&
        !checkupReport?.exercise?.latest
    ) {
        return null;
    }

    const ecgAssessment = checkupReport?.ecg?.latest?.assessment;
    const relaxation = checkupReport?.relaxation?.latest?.value;
    const spirometricAssessment = checkupReport?.spirometric?.latest?.assessment;
    const spirometricMedicalCondition = checkupReport?.spirometric?.latest?.medicalCondition;

    const ecgValues: IValue[] = [
        {
            value: getConspicuousnessDropdownLabel(ecgAssessment),
            color: getConspicuousnessColor(ecgAssessment),
            showHistory: true,
            history: {
                category: "ecg",
                valueKey: "assessment",
                title: "screen.checkupReport.diagnostic.ecg.assessment.title",
                getColor: getConspicuousnessColor,
            },
        },
        {
            value: checkupReport?.ecg?.latest?.value,
            title: t("screen.checkupReport.diagnostic.ecg.value.title"),
            showHistory: true,
            history: {
                category: "ecg",
                valueKey: "value",
                title: "screen.checkupReport.diagnostic.ecg.value.title",
            },
        },
    ];

    const relaxationValues: IValue[] = [
        {
            value: relaxation ? t(relaxationKeys[relaxation]) : "",
            showHistory: true,
            color: getRelaxationColor(relaxation),
            history: {
                category: "relaxation",
                valueKey: "value",
                title: "screen.patientInformation.devices.relaxation",
                getColor: getRelaxationColor,
            },
        },
    ];

    const spirometricValues: IValue[] = [
        {
            value: spirometricAssessment ? t(spirometricAssessmentKeys[spirometricAssessment]) : "",
            showHistory: true,
            color: getSpirometricColor(spirometricAssessment),
            history: {
                category: "spirometric",
                valueKey: "assessment",
                title: "screen.checkupReport.diagnostic.spirometric.title",
                getColor: getSpirometricColor,
            },
        },
        {
            value: spirometricMedicalCondition ? t(spirometricMedicalConditionKeys[spirometricMedicalCondition]) : "",
        },
    ];

    const spirometricSecondaryValues = [
        {
            value: checkupReport?.spirometric?.latest?.FVC,
            title: t("screen.checkupReport.diagnostic.spirometric.fvc"),
        },
        {
            value: checkupReport?.spirometric?.latest?.FVCPercent,
            title: t("screen.checkupReport.diagnostic.spirometric.fvcPercent"),
        },
        {
            value: checkupReport?.spirometric?.latest?.FeV1FVC,
            title: t("screen.checkupReport.diagnostic.spirometric.feV1Fvc"),
        },
    ];

    const exerciseErgometricValues: IValue[] = [
        {
            value: checkupReport?.exercise?.latest?.percent ? `${checkupReport?.exercise?.latest?.percent}%` : "",
            title: t("screen.checkupReport.diagnostic.exerciseErgometric.percent"),
            color: getExerciseErgometricPercentColor(checkupReport?.exercise?.latest?.percent),
        },
        {
            value: checkupReport?.exercise?.latest?.percent,
            component:
                typeof checkupReport?.exercise?.latest?.percent === "number" ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <ExerciseValueDisplay value={checkupReport?.exercise?.latest?.percent} />
                    </div>
                ) : null,
        },
        {
            value: checkupReport?.exercise?.latest?.energy,
            title: t("screen.checkupReport.diagnostic.exerciseErgometric.energy"),
        },
        {
            value: checkupReport?.exercise?.latest?.maxHeartRate,
            title: t("screen.checkupReport.diagnostic.exerciseErgometric.maxHeartRate"),
        },
        {
            value:
                checkupReport?.exercise?.latest?.maxBloodpressure?.systole &&
                checkupReport?.exercise?.latest?.maxBloodpressure?.diastole
                    ? `${checkupReport?.exercise?.latest?.maxBloodpressure?.systole}/${checkupReport?.exercise?.latest?.maxBloodpressure?.diastole}`
                    : undefined,
            title: t("screen.checkupReport.diagnostic.exerciseErgometric.maxBloodpressure"),
        },
        {
            value: getConspicuousnessDropdownLabel(checkupReport?.exercise?.latest?.heartRate),
            color: getConspicuousnessColor(checkupReport?.exercise?.latest?.heartRate),
            showHistory: true,
            history: {
                category: "exercise",
                valueKey: "heartRate",
                title: "screen.checkupReport.diagnostic.exerciseErgometric.title",
                getColor: getConspicuousnessColor,
            },
        },
    ];

    const biaItems: {
        name: IBiaName;
        label: IMessageIDS;
        adornmentText: IMessageIDS;
        value?: number;
        color?: string;
        referenceValues: { minVal?: number; maxVal?: number };
        history: IHistory;
    }[] = [
        {
            name: "muscle",
            label: "screen.checkupReport.diagnostic.bia.muscle.title",
            adornmentText: "common.kilogram",
            value: checkupReport?.bia?.latest?.muscle?.value,
            referenceValues: {
                minVal: checkupReport?.bia?.latest?.muscle?.minVal,
                maxVal: checkupReport?.bia?.latest?.muscle?.maxVal,
            },
            history: {
                category: "bia",
                valueKey: "muscle",
                adornmentText: "common.kilogram",
                title: "screen.checkupReport.diagnostic.bia.muscle.title",
                getColor: (biaResult?: BiaResults) => {
                    const dotPosition = getBiaDotPosition({
                        name: "muscle",
                        results: biaResult,
                    });

                    return getBiaValueColor(dotPosition);
                },
            },
        },
        {
            name: "fat",
            label: "screen.checkupReport.diagnostic.bia.fat.title",
            adornmentText: "common.kilogram",
            value: checkupReport?.bia?.latest?.fat?.value,
            referenceValues: {
                minVal: checkupReport?.bia?.latest?.fat?.minVal,
                maxVal: checkupReport?.bia?.latest?.fat?.maxVal,
            },
            history: {
                category: "bia",
                valueKey: "fat",
                adornmentText: "common.kilogram",
                title: "screen.checkupReport.diagnostic.bia.fat.title",
                getColor: (biaResult?: BiaResults) => {
                    const dotPosition = getBiaDotPosition({
                        name: "fat",
                        results: biaResult,
                    });

                    return getBiaValueColor(dotPosition);
                },
            },
        },
        {
            name: "fatPercent",
            label: "screen.checkupReport.diagnostic.bia.fatPercent.title",
            adornmentText: "common.percent",
            value: checkupReport?.bia?.latest?.fatPercent?.value,
            referenceValues: {
                minVal: checkupReport?.bia?.latest?.fatPercent?.minVal,
                maxVal: checkupReport?.bia?.latest?.fatPercent?.maxVal,
            },
            history: {
                category: "bia",
                valueKey: "fatPercent",
                adornmentText: "common.percent",
                title: "screen.checkupReport.diagnostic.bia.fatPercent.title",
                getColor: (biaResult?: BiaResults) => {
                    const dotPosition = getBiaDotPosition({
                        name: "fatPercent",
                        results: biaResult,
                    });

                    return getBiaValueColor(dotPosition);
                },
            },
        },
    ];

    const biaTableHeaders: { name: string; title?: IMessageIDS; style?: React.CSSProperties }[] = [
        {
            name: "name",
            title: "screen.checkupReport.diagnostic.bia.checkup",
            style: {
                width: 250,
            },
        },
        {
            name: "value",
            title: "screen.checkupReport.diagnostic.bia.value",
            style: {
                width: 250,
            },
        },
        {
            name: "referenceRange",
            title: "screen.checkupReport.diagnostic.bia.referenceRange",
        },
        {
            name: "icon",
            title: "screen.checkupReport.diagnostic.bia.history",
            style: {
                width: 50,
            },
        },
    ];

    const latestExercise = checkupReport?.exercise?.latest;

    const runValues = latestExercise?.maxHeartRate
        ? {
              first: {
                  min: latestExercise.maxHeartRate * 0.6 + 10,
                  max: latestExercise.maxHeartRate * 0.7 + 10,
              },
              second: {
                  min: latestExercise.maxHeartRate * 0.7 + 10,
                  max: latestExercise.maxHeartRate * 0.8 + 10,
              },
          }
        : undefined;

    const bicycleValues = latestExercise?.maxHeartRate
        ? {
              first: {
                  min: latestExercise.maxHeartRate * 0.6,
                  max: latestExercise.maxHeartRate * 0.7,
              },
              second: {
                  min: latestExercise.maxHeartRate * 0.7,
                  max: latestExercise.maxHeartRate * 0.8,
              },
          }
        : undefined;

    const ultrasound = checkupReport?.ultrasound;
    const heart = ultrasound?.heart?.value;
    const thyroid = ultrasound?.thyroid?.value;
    const carotis = ultrasound?.carotis?.value;
    const stomach = ultrasound?.stomach?.value;

    const ultrasoundItems = [
        {
            title: t("screen.checkupReport.diagnostic.ultrasound.heart.title"),
            icon: <ImageSonoHerzEcho />,
            value: heart,
            label: heart ? t(ultrasoundKeys[heart]) : "",
            color: getUltrasoundColor(heart),
            text: ultrasound?.heart?.text,
        },
        {
            title: t("screen.checkupReport.diagnostic.ultrasound.thyroid.title"),
            icon: <ImageSonoSD />,
            value: thyroid,
            label: thyroid ? t(ultrasoundKeys[thyroid]) : "",
            color: getUltrasoundColor(thyroid),
            text: ultrasound?.thyroid?.text,
        },
        {
            title: t("screen.checkupReport.diagnostic.ultrasound.carotis.title"),
            icon: <ImageSonoCarotis />,
            value: carotis,
            label: carotis ? t(ultrasoundKeys[carotis]) : "",
            color: getUltrasoundColor(carotis),
            text: ultrasound?.carotis?.text,
        },
        {
            title: t("screen.checkupReport.diagnostic.ultrasound.stomach.title"),
            icon: <ImageSonoAbdomen />,
            value: stomach,
            label: stomach ? t(ultrasoundKeys[stomach]) : "",
            color: getUltrasoundColor(stomach),
            text: ultrasound?.stomach?.text,
        },
    ];

    const showBia = !!(
        checkupReport?.bia?.latest?.fat?.value ||
        checkupReport?.bia?.latest?.fatPercent?.value ||
        checkupReport?.bia?.latest?.muscle?.value
    );

    const biaRecommendation = getRecommendationText("bia", checkupReport?.recommendations?.latest?.savedTexts);

    return (
        <>
            <Title>{t("screen.checkupReport.diagnostic.title")}</Title>
            <CategoryCard
                values={ecgValues}
                title={t("screen.checkupReport.diagnostic.ecg.title")}
                onClickHistory={onClickHistory}
                recommendation={getRecommendationText("ecg", checkupReport?.recommendations?.latest?.savedTexts)}
                Icon={
                    <IconContainer>
                        <ImageEKG />
                    </IconContainer>
                }
            />
            {showBia && (
                <CustomCard>
                    <CardTitle>
                        <IconContainer>
                            <ImageBIA />
                        </IconContainer>
                        {t("screen.checkupReport.diagnostic.bia.title")}
                    </CardTitle>
                    <Divider />
                    <Table>
                        <TableHead>
                            <TableRow>
                                {biaTableHeaders.map((tableHeader) => (
                                    <StyledTableCell
                                        key={tableHeader.name}
                                        style={{
                                            ...tableHeader.style,
                                            color: "#8A8A8A",
                                            fontWeight: 500,
                                            fontSize: 12,
                                        }}
                                    >
                                        {tableHeader.title && t(tableHeader.title)}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {biaItems
                                .filter((biaItem) => biaItem.value !== undefined)
                                .map((biaItem, index) => {
                                    const dotPosition = getBiaDotPosition({
                                        results: checkupReport.bia?.latest,
                                        name: biaItem.name,
                                    });

                                    const color = getBiaValueColor(dotPosition);

                                    return (
                                        <TableRow key={index}>
                                            <StyledTableCell>{t(biaItem.label)}</StyledTableCell>
                                            <StyledTableCell>
                                                <div
                                                    style={{
                                                        color,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        height: "100%",
                                                    }}
                                                >
                                                    {biaItem.value}
                                                    {t(biaItem.adornmentText)}
                                                    {color && (
                                                        <TextDot style={{ backgroundColor: color, marginLeft: 4 }} />
                                                    )}
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <BiaValueDisplay
                                                    valueAlignment="right"
                                                    values={biaItem.referenceValues}
                                                    dotPosition={dotPosition}
                                                    name={biaItem.name}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {biaItem.history && (
                                                    <IconButton
                                                        onClick={(event) => onClickHistory(event, biaItem.history)}
                                                    >
                                                        <MoreVertical />
                                                    </IconButton>
                                                )}
                                            </StyledTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {biaRecommendation && (
                        <>
                            <Divider />
                            <Recommendation>{biaRecommendation}</Recommendation>
                        </>
                    )}
                </CustomCard>
            )}
            <CategoryCard
                values={relaxationValues}
                title={t("screen.checkupReport.diagnostic.relaxation.title")}
                onClickHistory={onClickHistory}
                recommendation={getRecommendationText("relaxation", checkupReport?.recommendations?.latest?.savedTexts)}
                Icon={
                    <IconContainer>
                        <ImageEntspannung />
                    </IconContainer>
                }
            />
            <CategoryCard
                values={spirometricValues}
                secondaryValues={spirometricSecondaryValues}
                title={t("screen.checkupReport.diagnostic.spirometric.title")}
                onClickHistory={onClickHistory}
                recommendation={getRecommendationText(
                    "spirometric",
                    checkupReport?.recommendations?.latest?.savedTexts,
                )}
                Icon={
                    <IconContainer>
                        <ImageSpirometrie />
                    </IconContainer>
                }
            />
            <CategoryCard
                values={exerciseErgometricValues}
                title={t("screen.checkupReport.diagnostic.exerciseErgometric.title")}
                onClickHistory={onClickHistory}
                recommendation={getRecommendationText(
                    "exerciseErgometric",
                    checkupReport?.recommendations?.latest?.savedTexts,
                )}
                Icon={
                    <IconContainer>
                        <ImageErgometrie />
                    </IconContainer>
                }
                recommendationPosition="top"
            >
                {latestExercise &&
                    latestExercise.displayTraining &&
                    latestExercise.maxHeartRate &&
                    latestExercise.percent &&
                    runValues &&
                    bicycleValues && <TrainingAreas runValues={runValues} bicycleValues={bicycleValues} />}
            </CategoryCard>
            {ultrasoundItems.find((item) => (item.value && item.value !== "notSpecified" ? item : false)) && (
                <CustomCard>
                    <CardTitle>{t("screen.checkupReport.diagnostic.ultrasound.title")}</CardTitle>
                    {ultrasoundItems.map((item) =>
                        item.value && item.value !== "notSpecified" ? (
                            <React.Fragment key={item.title}>
                                <Divider />
                                <CardTitle style={{ backgroundColor: "#FCFCFC" }}>
                                    <IconContainer>{item.icon}</IconContainer>
                                    {item.title}
                                </CardTitle>
                                <Divider />
                                <div style={{ padding: 16 }}>
                                    <ValueCellTitle>{t("common.report")}</ValueCellTitle>
                                    <ValueCellText style={{ color: item.color }}>
                                        <>
                                            <div style={{ marginRight: 4 }}>{item.label}</div>
                                            {item.color && <TextDot style={{ backgroundColor: item.color }} />}
                                        </>
                                    </ValueCellText>
                                </div>
                                <Divider />
                                {item.text && <Recommendation>{item.text}</Recommendation>}
                            </React.Fragment>
                        ) : null,
                    )}
                </CustomCard>
            )}
        </>
    );
};
