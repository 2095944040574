import { Pagination, PaginationItem, PaginationRenderItemParams } from "@mui/material";
import { styled } from "@mui/styles";
import * as React from "react";

const StyledPagination = styled(Pagination)({
    marginTop: 24,
});

const StyledPaginationItem = styled(PaginationItem)({
    width: 32,
    height: 32,
});

type IProps = {
    totalCount: number;
    rowsPerPage: number;
    page: number;
    onChangePage: (page: number) => void;
};

export const TablePagination = ({ totalCount: count, rowsPerPage, page, onChangePage }: IProps) => {
    const pageCount = Math.ceil(count / rowsPerPage);
    const handleChangePage = (_: any, page: number) => {
        onChangePage(page);
    };

    return pageCount > 1 ? (
        <StyledPagination
            color="primary"
            count={pageCount}
            page={page}
            onChange={handleChangePage}
            size="small"
            renderItem={(item: PaginationRenderItemParams) => <StyledPaginationItem {...item} />}
        />
    ) : null;
};
