import { Button, Divider, IconButton, Toolbar } from "@mui/material";
import { styled } from "@mui/styles";
import dayjs from "dayjs";
import { Form, Formik, FormikValues } from "formik";
import { compact, fromPairs, isEmpty, isEqual, mapValues, omitBy, some } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/16px/ic_checkmark.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/ic_edit.svg";
import { ReactComponent as LoadingIcon } from "../../assets/icons/ic_loading.svg";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import {
    DoctorsRecommendations,
    LifestyleParametersTexts,
    TemplateText,
    UltrasoundTexts,
} from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { patientStore } from "../../stores/PatientStore";
import { ICheckupSection, ICheckupSectionItem, ISectionName, ITab } from "../../types";
import { history } from "../app/router/history";
import { Routes } from "../app/router/Routes";
import { CustomDialog } from "../ui/CustomDialog";
import { Colors } from "../util/Colors";
import { initialBloodpanelItem } from "../util/patientSiteConfig";

type ICheckupInputProps = {
    checkupSections: ICheckupSectionItem[];
    onFinish: (name: string) => void;
    onEdit: (name: string) => void;
    currentTab: ITab;
    onChangeTab: () => void;
    reloadCheckup: () => Promise<void>;
};

export const CustomCard = styled("div")({
    display: "flex",
    flexDirection: "column",
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.BORDER_LIGHT}`,
    borderRadius: 12,
    marginTop: 24,
    marginBottom: 24,
});

const StyledToolbar = styled(Toolbar)({
    paddingLeft: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
});

const ButtonsContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItem: "center",
    padding: 16,
});

const StyledButton = styled(Button)({
    height: 48,
    borderRadius: 8,
    textTransform: "none",
    fontSize: 16,
    fontWeight: 500,
});

const FinishedContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    marginRight: 16,
    fontSize: 14,
    color: Colors.MAIN,
});

const Subtitle = styled("div")({
    fontSize: 16,
    fontWeight: 400,
    color: Colors.DISABLED,
});

const SubtitleContainer = styled("div")({
    display: "flex",
    alignItems: "center",
});

const Dot = styled("div")({
    width: 4,
    height: 4,
    backgroundColor: Colors.DISABLED,
    margin: "0 5px",
    borderRadius: 100,
});

const jumpMarkItems = [
    { input: "bloodpanel", recommendation: "bloodpanelRecommendation" },
    { input: "stoolSample", recommendation: "stoolSampleRecommendation" },
    { input: "lifestyleParameters", recommendation: "lifestyle" },
    { input: "biaResult", recommendation: "bia" },
    { input: "ecgResult", recommendation: "ecg" },
    { input: "spirometric", recommendation: "spirometricRecommendation" },
    { input: "exerciseErgometric", recommendation: "exercise" },
];

const checkupInputToRecommendation = {
    biaResult: "bia",
    bloodpanel: "bloodpanel",
    ecgResult: "ecg",
    exerciseErgometric: "exercise",
    lifestyleParameters: "lifestyle",
    spirometric: "spirometric",
    stoolSample: "stoolSample",
};

const ToolbarWrapper = ({
    showToolbar,
    children,
    currentTab,
    subtitle,
}: {
    showToolbar: boolean;
    children: React.ReactChild;
    currentTab: ITab;
    subtitle?: string | JSX.Element;
}) =>
    showToolbar ? (
        <>
            <StyledToolbar
                variant="dense"
                style={{
                    height: currentTab === "recommendation" && subtitle ? 65 : 50,
                }}
            >
                {children}
            </StyledToolbar>
            <Divider />
        </>
    ) : (
        <div>{children}</div>
    );

const getSubtitle = (itemName: string) => {
    const checkupRecommendations = patientStore.checkupRecommendations;
    if (itemName === "stoolSampleRecommendation") {
        const result = checkupRecommendations?.stoolSample?.results;
        if (result === "normal") {
            return t("common.normal");
        }
        if (result === "conspicuous") {
            return t("common.conspicuous");
        }
    }
    if (itemName === "ecg") {
        return checkupRecommendations?.ecg?.results;
    }
    if (itemName === "spirometricRecommendation") {
        const fvc = checkupRecommendations?.spirometric?.results?.FVC;
        const fvcPercent = checkupRecommendations?.spirometric?.results?.FVCPercent;
        const feV1 = checkupRecommendations?.spirometric?.results?.FeV1FVC;

        return (
            <SubtitleContainer>
                {t("screen.patientinformation.field.spirometric.subtitle.fvcLabel")} {fvc}
                {t("common.liter")}
                <Dot />
                {t("screen.patientinformation.field.spirometric.subtitle.fvcLabel")} {fvcPercent}
                {t("common.percent")}
                <Dot />
                {t("screen.patientinformation.field.spirometric.subtitle.feV1")} {feV1}
                {t("common.percent")}
            </SubtitleContainer>
        );
    }
    if (itemName === "exercise") {
        const energy = checkupRecommendations?.exercise?.results?.energy;
        const diastole = checkupRecommendations?.exercise?.results?.maxBloodpressure?.diastole;
        const systole = checkupRecommendations?.exercise?.results?.maxBloodpressure?.systole;
        const maxHeartRate = checkupRecommendations?.exercise?.results?.maxHeartRate;
        const percent = checkupRecommendations?.exercise?.results?.percent;

        return (
            <SubtitleContainer>
                {percent}
                {t("common.percent")}
                <Dot />
                {energy}
                {t("common.watt")}
                <Dot />
                {maxHeartRate}
                {t("common.heartFrequency")}
                <Dot />
                {t("common.bloodpressure", { systole, diastole })}
            </SubtitleContainer>
        );
    }
    if (itemName === "lifestyle") {
        const bloodpressure = checkupRecommendations?.lifestyle?.results?.bloodpressure;
        if (bloodpressure?.systole && bloodpressure.diastole) {
            return `${bloodpressure?.systole}/${bloodpressure?.diastole}`;
        }
    }
    return "";
};

export const getHasSavedRecommendation = (
    itemName: ISectionName,
    currentTab: ITab,
    checkupRecommendations?: DoctorsRecommendations,
) => {
    if (itemName === "lifestyleParameters" || itemName === "lifestyle") {
        const lifestyle = checkupRecommendations?.lifestyle;
        return (
            !!lifestyle?.bloodpressure?.textInput ||
            !!lifestyle?.exercise?.textInput ||
            !!lifestyle?.abdominalGirth?.textInput ||
            !!lifestyle?.smoker?.textInput
        );
    }
    if (itemName === "ultrasound") {
        const ultrasound = checkupRecommendations?.ultrasound;

        if (
            ultrasound?.carotis?.value === "notSpecified" ||
            ultrasound?.heart?.value === "notSpecified" ||
            ultrasound?.stomach?.value === "notSpecified" ||
            ultrasound?.thyroid?.value === "notSpecified"
        ) {
            // return true because text can be empty in this case(s)
            return true;
        }

        return (
            !!ultrasound?.carotis?.textInput ||
            !!ultrasound?.heart?.textInput ||
            !!ultrasound?.stomach?.textInput ||
            !!ultrasound?.thyroid?.textInput
        );
    }
    if (itemName === "ecg") {
        const ecg = checkupRecommendations?.ecg;
        if (ecg?.assessment && (ecg.assessment === "notSpecified" || ecg.assessment === "normal")) {
            // return true because text can be empty in this case(s)
            return true;
        }
    }
    if (itemName === "relaxation") {
        const relaxation = checkupRecommendations?.relaxation;
        if (relaxation?.relaxation && relaxation.relaxation === "notSpecified") {
            // return true because text can be empty in this case(s)
            return true;
        }
    }
    if (itemName === "spirometricRecommendation") {
        const spirometric = checkupRecommendations?.spirometric;
        if (spirometric?.assessment && spirometric.assessment === "notSpecified") {
            // return true because text can be empty in this case(s)
            return true;
        }
    }
    return currentTab === "input"
        ? !!checkupRecommendations?.[checkupInputToRecommendation[itemName]]?.textInput
        : !!checkupRecommendations?.[itemName.replace("Recommendation", "")]?.textInput;
};

const getInitialValues = ({
    currentTab,
    item,
    checkupRecommendations,
}: {
    currentTab: ITab;
    item: ICheckupSection;
    checkupRecommendations?: DoctorsRecommendations;
}) => {
    if (currentTab === "input") {
        let bmi;

        if (item.name === "lifestyleParameters" && patientStore.checkup?.lifestyleParameters) {
            const { weight, height } = patientStore.checkup.lifestyleParameters;

            if (weight && height) {
                bmi = Math.round((weight / Math.pow(height / 100, 2)) * 100) / 100;
            }
        }

        // this is done to get all values inside formik, even empty ones, to check for emptyFields on submit
        const initialCheckupItemValues = {
            ...item.initialValues,
            ...patientStore.checkup?.[item.name],
            ...(item.name === "lifestyleParameters" && bmi
                ? {
                      bmi,
                  }
                : {}),
            // in order to make the not everything filled out dialog work, empty values have to use empty string
            ...(item.name === "bloodpanel" && patientStore.checkup?.[item.name]?.values
                ? {
                      values: patientStore.checkup?.[item.name]?.values?.map((bloodpanelItem) => ({
                          ...initialBloodpanelItem,
                          ...bloodpanelItem,
                      })),
                  }
                : {}),
        };

        return {
            [item.name]:
                item.name === "productType"
                    ? patientStore.checkup?.[item.name] ?? item.initialValues[item.name]
                    : initialCheckupItemValues ?? item.initialValues,
        };
    } else {
        let initialCheckupRecommendationItemValues;

        if (item.name === "lifestyle") {
            const lifestyle: LifestyleParametersTexts | undefined = checkupRecommendations?.lifestyle;
            const lifestyleItems = ["abdominalGirth", "bloodpressure", "exercise", "smoker"];

            if (lifestyle) {
                const lifestyleValuePairs = lifestyleItems.map((lifestyleItem) => [
                    lifestyleItem,
                    {
                        textInput:
                            lifestyle[lifestyleItem]?.textInput ??
                            lifestyle[lifestyleItem]?.templateTexts
                                ?.filter((templateTextItem) => !!templateTextItem.templateText)
                                .map((templateTextItem: TemplateText) => templateTextItem.templateText)
                                .join("\n\n"),
                    },
                ]);

                initialCheckupRecommendationItemValues = fromPairs(lifestyleValuePairs);
            }
        } else if (item.name === "ultrasound") {
            const ultrasound: UltrasoundTexts | undefined = checkupRecommendations?.ultrasound;
            const ultrasoundItems = ["carotis", "heart", "stomach", "thyroid"];

            if (ultrasound) {
                const ultrasoundValuePairs = ultrasoundItems.map((ultrasoundItem) => [
                    ultrasoundItem,
                    {
                        textInput: ultrasound[ultrasoundItem]?.textInput ?? "",
                        value: ultrasound[ultrasoundItem]?.value,
                    },
                ]);

                initialCheckupRecommendationItemValues = fromPairs(ultrasoundValuePairs);
            }
        } else if (item.name === "exercise") {
            // TODO why are we doing this mapping per item.name (except for the recommendations where it seems necessary)
            const currentItem = checkupRecommendations?.[item.name];
            initialCheckupRecommendationItemValues = {
                textInput: currentItem?.textInput ?? "",
                displayTraining: currentItem?.displayTraining,
                heartRate: currentItem?.heartRate,
            };
        } else if (item.name === "stoolSampleRecommendation") {
            const currentItem = checkupRecommendations?.stoolSample;
            initialCheckupRecommendationItemValues = {
                textInput:
                    currentItem?.textInput ??
                    currentItem?.templateTexts?.find(
                        (templateText) => templateText.targetValue === `stoolsample.${currentItem.results}`,
                    )?.templateText,
            };
        } else if (item.name === "bloodpanelRecommendation") {
            const currentItem = checkupRecommendations?.bloodpanel;
            initialCheckupRecommendationItemValues = {
                textInput:
                    currentItem?.textInput ??
                    checkupRecommendations?.bloodpanel?.templateTexts
                        ?.filter((templateTextItem) => !!templateTextItem.templateText)
                        .map((templateTextItem: TemplateText) => templateTextItem.templateText)
                        .join("\n\n"),
            };
        } else if (item.name === "spirometricRecommendation") {
            const currentItem = checkupRecommendations?.spirometric;
            initialCheckupRecommendationItemValues = {
                assessment: currentItem?.assessment ?? "",
                medicalCondition: currentItem?.medicalCondition ?? "",
                textInput: currentItem?.textInput ?? "",
            };
        } else if (item.name === "ecg") {
            const currentItem = checkupRecommendations?.ecg;
            initialCheckupRecommendationItemValues = {
                assessment: currentItem?.assessment ?? "",
                textInput:
                    currentItem?.textInput ??
                    currentItem?.templateTexts?.find(
                        (templateText) => templateText.targetValue === `ecg.${currentItem.assessment}`,
                    )?.templateText,
            };
        } else if (item.name === "relaxation") {
            const currentItem = checkupRecommendations?.relaxation;
            initialCheckupRecommendationItemValues = {
                relaxation: currentItem?.relaxation ?? "",
                textInput:
                    currentItem?.textInput ??
                    currentItem?.templateTexts?.find(
                        (templateText) => templateText.targetValue === `relaxation.${currentItem.relaxation}`,
                    )?.templateText,
            };
        } else {
            const { templateTexts, results, ...currentItem } = checkupRecommendations?.[item.name] ?? {};

            if (currentItem) {
                initialCheckupRecommendationItemValues = {
                    ...currentItem,
                    textInput:
                        currentItem.textInput ??
                        templateTexts
                            ?.filter((templateTextItem: TemplateText) => !!templateTextItem.templateText)
                            .map((templateTextItem: TemplateText) => templateTextItem.templateText)
                            .join("\n\n"),
                };
            }
        }

        return {
            ...item.initialValues,
            ...initialCheckupRecommendationItemValues,
        };
    }
};

const setEmptyValue = (value: string) => (value === "" ? undefined : value);

const isValueEmpty = (value: string) => value === "";

export const CheckupInput = observer(
    ({ checkupSections, onFinish, onEdit, currentTab, onChangeTab, reloadCheckup }: ICheckupInputProps) => {
        const [isEmptyFieldsWarningDialogOpen, setIsEmptyFieldsWarningDialogOpen] = React.useState(false);
        const [isRecommendationWarningDialogOpen, setIsRecommendationWarningDialogOpen] = React.useState(false);
        const [formikValues, setFormikValues] = React.useState<FormikValues>({});
        const [activeItem, setActiveItem] = React.useState<ICheckupSection | null>(null);

        const { checkupId } = useParams<{ checkupId?: string }>();

        const checkupRecommendations = patientStore.checkupRecommendations;

        const handleSaveCheckupInput = async (values: FormikValues, item: ICheckupSection) => {
            if (checkupId) {
                // productType comes as a string, so it should not be spread
                let valuesToUpload = item.name === "productType" ? values : { ...values };

                if (item.name === "patientInfo") {
                    valuesToUpload.birthday = valuesToUpload.birthday
                        ? dayjs(valuesToUpload.birthday).format("YYYY-MM-DD")
                        : null;

                    valuesToUpload.checkupDate = valuesToUpload.checkupDate
                        ? dayjs(valuesToUpload.checkupDate).format("YYYY-MM-DD")
                        : null;
                }

                if (item.name === "lifestyleParameters") {
                    if (valuesToUpload.bmi) {
                        const { bmi, ...restValues } = valuesToUpload;
                        valuesToUpload = restValues;
                    }
                }

                if (item.name !== "productType" && item.name !== "stoolSample") {
                    const sanitizedValuesToUpdate = mapValues(valuesToUpload, (value, key) => {
                        if (typeof valuesToUpload[key] === "object") {
                            return mapValues(valuesToUpload[key], setEmptyValue);
                        }
                        return setEmptyValue(value);
                    });

                    valuesToUpload = sanitizedValuesToUpdate;
                }

                if (item.name === "bloodpanel") {
                    const additionalValues = values
                        // remove values that are empty from the object (has to be omit, isEmpty doesn't work with "undefined" values)
                        // because it's not allowed to send strings for numbers
                        .map((bloodpanelItem) => omitBy(bloodpanelItem, (value) => value === ""))
                        .filter((bloodpanelItem) => !isEmpty(bloodpanelItem));

                    valuesToUpload = {
                        values: additionalValues.length > 0 ? additionalValues : undefined,
                    };
                }

                await API.patchCheckup({
                    checkupId,
                    checkup: {
                        checkupId,
                        [item.name]: valuesToUpload,
                    },
                });

                await reloadCheckup();

                onFinish(item.name);
            }
        };

        const handleSaveCheckupRecommendations = async (values: FormikValues, item: ICheckupSection) => {
            if (checkupId) {
                let recommendations: DoctorsRecommendations = {
                    [item.name]: {
                        textInput: values.textInput,
                    },
                };

                if (item.name === "ecg") {
                    recommendations[item.name] = { ...recommendations[item.name], assessment: values.assessment };
                } else if (item.name === "exercise") {
                    recommendations[item.name] = {
                        textInput: values.textInput,
                        displayTraining: values.displayTraining,
                        heartRate: values.heartRate,
                    };
                } else if (item.name === "lifestyle") {
                    recommendations[item.name] = values;
                } else if (item.name === "relaxation") {
                    recommendations[item.name] = { ...recommendations[item.name], relaxation: values.relaxation };
                } else if (item.name === "spirometricRecommendation") {
                    recommendations = {
                        spirometric: {
                            textInput: values.textInput,
                            assessment: values.assessment,
                            medicalCondition: values.medicalCondition,
                        },
                    };
                } else if (item.name === "ultrasound") {
                    recommendations = {
                        ultrasound: values,
                    };
                } else if (item.name === "stoolSampleRecommendation") {
                    recommendations = {
                        stoolSample: {
                            textInput: values.textInput,
                        },
                    };
                } else if (item.name === "bloodpanelRecommendation") {
                    recommendations = {
                        bloodpanel: {
                            textInput: values.textInput,
                        },
                    };
                }

                const nextCheckupRecommendations = await API.patchCheckupRecommendations({
                    checkupId,
                    recommendations: {
                        checkupId,
                        recommendations,
                    },
                });

                await reloadCheckup();

                if (
                    patientStore.checkup?.released ||
                    getHasSavedRecommendation(item.name, currentTab, nextCheckupRecommendations.recommendations)
                ) {
                    onFinish(item.name);
                }
            }
        };

        const handleClickSubmitCheckup = async (values: FormikValues, item: ICheckupSection, initialValues: any) => {
            generalStore.isLoading = true;

            try {
                let valuesToUpload = values[item.name];

                if (item.name === "bloodpanel") {
                    // in order to make the not everything filled out dialog work, empty values have to use empty string
                    // but without the ones that are disabled
                    valuesToUpload = values[item.name].values.map((bloodpanelItem) => {
                        if (bloodpanelItem.referenceType === "greaterThan") {
                            return {
                                ...bloodpanelItem,
                                referenceMax: undefined,
                            };
                        }

                        if (bloodpanelItem.referenceType === "lowerThan") {
                            return {
                                ...bloodpanelItem,
                                referenceMin: undefined,
                            };
                        }

                        return bloodpanelItem;
                    });
                }

                const includesEmptyValues = some(valuesToUpload, (value, key) => {
                    if (typeof valuesToUpload[key] === "object") {
                        return some(valuesToUpload[key], isValueEmpty);
                    }
                    return isValueEmpty(value);
                });

                const hasSavedRecommendation =
                    getHasSavedRecommendation(item.name, currentTab, checkupRecommendations) &&
                    !isEqual(initialValues[item.name], values[item.name]);

                if (includesEmptyValues) {
                    setIsEmptyFieldsWarningDialogOpen(true);
                    setFormikValues(valuesToUpload);
                    setActiveItem(item);
                } else {
                    await handleSaveCheckupInput(valuesToUpload, item);
                }

                if (hasSavedRecommendation) {
                    setIsRecommendationWarningDialogOpen(true);
                }
            } catch (error) {
                generalStore.setError(t("error.save"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        const handleClickSubmitCheckupRecommendations = async (values: FormikValues, item: ICheckupSection) => {
            generalStore.isLoading = true;

            try {
                await handleSaveCheckupRecommendations(values, item);
            } catch (error) {
                generalStore.setError(t("error.save"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        const handleSubmitEmptyFieldsWarningDialog = async () => {
            if (activeItem) {
                await handleSaveCheckupInput(formikValues, activeItem);
            }
            setFormikValues({});
            setActiveItem(null);
            setIsEmptyFieldsWarningDialogOpen(false);
        };

        const handeCloseEmptyFieldsWarningDialog = () => {
            setFormikValues({});
            setActiveItem(null);
            setIsEmptyFieldsWarningDialogOpen(false);
            setIsRecommendationWarningDialogOpen(false);
        };

        const handeCloseRecommendationWarningDialog = () => {
            setIsRecommendationWarningDialogOpen(false);
        };

        const handleClickJumpMark = (jumpMarkId: string) => {
            onChangeTab();
            history.push(`${Routes.DOCTOR.CHECKUP.replace(":checkupId", checkupId ?? "")}#${jumpMarkId}`);
        };

        return (
            <>
                {checkupSections.map((section, index) => (
                    <React.Fragment key={index}>
                        {section.title && index !== 0 && (
                            <h4 style={{ marginTop: 32, marginBottom: 32 }}>{t(section.title)}</h4>
                        )}

                        {section.items?.map((item) => {
                            const initialValues = getInitialValues({ currentTab, item, checkupRecommendations });
                            const subtitle = getSubtitle(item.name);
                            const isFinished = patientStore.checkupStatus[item.name] === "finished";

                            return (
                                <Formik
                                    enableReinitialize
                                    initialValues={initialValues}
                                    validationSchema={item.validationSchema}
                                    onSubmit={async (values, actions) => {
                                        if (currentTab === "input") {
                                            await handleClickSubmitCheckup(values, item, initialValues);
                                        } else {
                                            await handleClickSubmitCheckupRecommendations(values, item);
                                            // reset the form to make sure the currently saved value is shown in case enableReinitialize
                                            // doesn't work -> happens when there is an unsaved templatetext and saving with an empty field
                                            actions.resetForm();
                                        }
                                    }}
                                    key={item.name}
                                >
                                    {({ setFieldValue, values }) => {
                                        const handleClickRestoreText = (item: ICheckupSection) => {
                                            const getTemplateText = (
                                                targetValueString: string,
                                                targetValue: string,
                                                itemNameOverwrite?: string,
                                            ) => {
                                                const templateText =
                                                    checkupRecommendations?.[
                                                        itemNameOverwrite ?? item.name
                                                    ]?.templateTexts?.find(
                                                        (templateTextItem: TemplateText) =>
                                                            templateTextItem.targetValue ===
                                                            `${targetValueString}.${targetValue}`,
                                                    )?.templateText ?? "";

                                                return templateText;
                                            };

                                            if (item.name === "lifestyle") {
                                                const currentText = values.bloodpressure?.textInput ?? "";

                                                const templateText =
                                                    checkupRecommendations?.lifestyle?.bloodpressure?.templateTexts?.[0]
                                                        ?.templateText ?? "";

                                                if (isFinished && currentText !== templateText) {
                                                    onEdit(item.name);
                                                }

                                                setFieldValue("bloodpressure.textInput", templateText);
                                            } else {
                                                const currentText = values.textInput ?? "";
                                                let templateText = "";

                                                if (item.name === "ecg") {
                                                    templateText = getTemplateText(
                                                        "ecg",
                                                        `${checkupRecommendations?.ecg?.assessment}`,
                                                    );
                                                } else if (item.name === "relaxation") {
                                                    templateText = getTemplateText(
                                                        "relaxation",
                                                        `${checkupRecommendations?.relaxation?.relaxation}`,
                                                    );
                                                } else if (item.name === "stoolSampleRecommendation") {
                                                    templateText = getTemplateText(
                                                        "stoolsample",
                                                        `${checkupRecommendations?.stoolSample?.results}`,
                                                        "stoolSample",
                                                    );
                                                } else if (item.name === "bloodpanelRecommendation") {
                                                    templateText =
                                                        checkupRecommendations?.bloodpanel?.templateTexts
                                                            ?.filter(
                                                                (templateTextItem: TemplateText) =>
                                                                    !!templateTextItem.templateText,
                                                            )
                                                            .map(
                                                                (templateTextItem: TemplateText) =>
                                                                    templateTextItem.templateText,
                                                            )
                                                            .join("\n\n") ?? "";
                                                } else if (item.name === "spirometricRecommendation") {
                                                    const assessmentTemplateText = getTemplateText(
                                                        "spirometric.assessment",
                                                        `${checkupRecommendations?.spirometric?.assessment}`,
                                                        "spirometric",
                                                    );

                                                    const medicalConditionTemplateText = getTemplateText(
                                                        "spirometric.medicalCondition",
                                                        `${checkupRecommendations?.spirometric?.medicalCondition}`,
                                                    );

                                                    templateText = `${assessmentTemplateText}\n\n${medicalConditionTemplateText}`;
                                                } else if (item.name === "exercise") {
                                                    templateText =
                                                        compact([
                                                            getTemplateText("exerciseErgometric.percent", `lt100`),
                                                            getTemplateText(
                                                                "exerciseErgometric.heartRate",
                                                                `${checkupRecommendations?.exercise?.heartRate}`,
                                                            ),
                                                        ]).join("\n\n") ?? "";
                                                } else if (item.name === "furtherCheckup") {
                                                    templateText = getTemplateText(
                                                        "general.further",
                                                        `${patientStore.checkup?.patientInfo?.sex ?? "none"}`,
                                                    );
                                                } else {
                                                    templateText =
                                                        checkupRecommendations?.[item.name]?.templateTexts
                                                            ?.filter(
                                                                (templateTextItem: TemplateText) =>
                                                                    !!templateTextItem.templateText,
                                                            )
                                                            .map(
                                                                (templateTextItem: TemplateText) =>
                                                                    templateTextItem.templateText,
                                                            )
                                                            .join("\n\n") ?? "";
                                                }

                                                if (isFinished && currentText !== templateText) {
                                                    onEdit(item.name);
                                                }
                                                setFieldValue("textInput", templateText);
                                            }
                                        };

                                        const jumpMarkId = jumpMarkItems.find(
                                            (jumpMarkItem) => jumpMarkItem[currentTab] === item.name,
                                        )?.input;

                                        return (
                                            <Form>
                                                <CustomCard
                                                    key={item.name || section.title}
                                                    id={currentTab === "input" ? jumpMarkId : undefined}
                                                >
                                                    <ToolbarWrapper
                                                        currentTab={currentTab}
                                                        showToolbar={!!item.title}
                                                        subtitle={subtitle}
                                                    >
                                                        <>
                                                            <div>
                                                                {item.title && <div>{t(item.title)}</div>}
                                                                <Subtitle>
                                                                    {currentTab === "recommendation" && subtitle}
                                                                </Subtitle>
                                                            </div>
                                                            {currentTab === "recommendation" &&
                                                                item.name !== "patientStatus" &&
                                                                item.name !== "ultrasound" &&
                                                                item.name !== "vaccinations" && (
                                                                    <div>
                                                                        <IconButton
                                                                            onClick={() => handleClickRestoreText(item)}
                                                                        >
                                                                            <LoadingIcon />
                                                                        </IconButton>
                                                                        {!!jumpMarkId && (
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleClickJumpMark(jumpMarkId)
                                                                                }
                                                                            >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </>
                                                    </ToolbarWrapper>
                                                    <div
                                                        style={{
                                                            padding:
                                                                item.name === "bloodpanel" ||
                                                                item.name === "lifestyle" ||
                                                                item.name === "bia" ||
                                                                item.name === "exercise" ||
                                                                item.name === "ultrasound"
                                                                    ? 0
                                                                    : 16,
                                                        }}
                                                    >
                                                        <item.Component
                                                            disabled={isFinished}
                                                            onEdit={() => onEdit(item.name)}
                                                            onClickJumpToSection={() =>
                                                                handleClickJumpMark(jumpMarkId ?? "")
                                                            }
                                                        />
                                                    </div>
                                                    <Divider />
                                                    <ButtonsContainer>
                                                        {isFinished ? (
                                                            <>
                                                                <FinishedContainer>
                                                                    <CheckmarkIcon fill={Colors.MAIN} />
                                                                    {t("common.finished")}
                                                                </FinishedContainer>
                                                                <StyledButton
                                                                    style={{ borderColor: "#E6E6E6", color: "#000000" }}
                                                                    onClick={() => onEdit(item.name)}
                                                                    variant="outlined"
                                                                >
                                                                    {t("common.edit")}
                                                                </StyledButton>
                                                            </>
                                                        ) : (
                                                            <StyledButton variant="contained" type="submit">
                                                                {t("common.save")}
                                                            </StyledButton>
                                                        )}
                                                    </ButtonsContainer>
                                                </CustomCard>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            );
                        })}
                    </React.Fragment>
                ))}
                <CustomDialog
                    open={isEmptyFieldsWarningDialogOpen}
                    onSubmit={handleSubmitEmptyFieldsWarningDialog}
                    onClose={handeCloseEmptyFieldsWarningDialog}
                    title={t("screen.patientInformation.dialog.emptyFields.title")}
                    text={t("screen.patientInformation.dialog.emptyFields.text")}
                />
                <CustomDialog
                    open={isRecommendationWarningDialogOpen && !isEmptyFieldsWarningDialogOpen}
                    onSubmit={handeCloseRecommendationWarningDialog}
                    onClose={handeCloseRecommendationWarningDialog}
                    title={t("screen.patientInformation.dialog.recommendationWarning.title")}
                    text={t("screen.patientInformation.dialog.recommendationWarning.text")}
                    submitText={t("common.next")}
                    hideCancel
                />
            </>
        );
    },
);
