import { Divider, Popover, styled } from "@mui/material";
import dayjs from "dayjs";
import { compact, isEmpty, times, unset } from "lodash";
import * as React from "react";
import { useParams } from "react-router";
import logo from "../../../assets/images/logo_minified.svg";
import DonauAktiv from "../../../assets/images/products/ic_product_donau-aktiv.svg";
import UniquaVital from "../../../assets/images/products/ic_product_uniqa-vital.svg";
import UniquaVorsorge from "../../../assets/images/products/ic_product_uniqa-vorsorge.svg";
import VorsorgeGold from "../../../assets/images/products/ic_product_vorsorge-gold.svg";
import VorsorgeDiamond from "../../../assets/images/products/ic_product_vorsorge-diamond.svg";
import VorsorgePlatin from "../../../assets/images/products/ic_product_vorsorge-platin.svg";
import VorsorgePlus from "../../../assets/images/products/ic_product_vorsorge-plus.svg";
import BesserLebenCheckWrStaedtische from "../../../assets/images/products/ic_product_wiener-staedtische.svg";
import { IMessageIDS, t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { BloodpanelGroup, BloodpanelReportValue, CheckupReport } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { patientStore } from "../../../stores/PatientStore";
import { IHistory, IHistoryItem } from "../../../types";
import { history } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { products } from "../../patients/ProductSelection";
import { ProgressBar } from "../../patients/ProgressBar";
import { OptionalTooltip } from "../../ui/OptionalTooltip";
import { TableLabel } from "../../ui/TableLabel";
import { Colors } from "../../util/Colors";
import { formatDate } from "../../util/helpers";
import { initialCheckupReportSections } from "../../util/patientSiteConfig";
import { getConspicuousnessColor, sanitizeCheckupReport } from "../../util/ValueDisplayHelpers";
import { Attachments } from "../Attachments";
import { Diagnostic } from "../Diagnostic";
import { DoctorRecommendation } from "../DoctorRecommendation";
import { LaboratoryResultsReport } from "../LaboratoryResultsReport";
import { LifestyleParametersReport } from "../LifestyleParametersReport";
import { Status } from "../Status";

const PROGRESS_BAR_WIDTH = 315;
const TILE_CONTAINER_PADDING = 16;
const PRODUCT_MARGIN = 8;
const PRODUCT_TEXT_MARGIN = 16;
const PRODUCT_SIZE = 24;

type ICustomRowProps = {
    columnCount?: number;
};

const SiteContainer = styled("div")({
    display: "flex",
    flexGrow: 1,
});

const ProgressBarContainer = styled("div")({
    position: "fixed",
    width: PROGRESS_BAR_WIDTH,
    overflow: "auto",
    height: "100%",
    "@media print": {
        display: "none",
    },
});

const NonPrintableDivider = styled(Divider)({
    "@media print": {
        display: "none",
    },
});

const MainContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: Colors.BACKGROUND,
    alignItems: "center",
    paddingTop: 74,
    "@media print": {
        // unset flexbox -> firefox print breaks with column layout
        display: "unset",
        backgroundColor: "unset",
        paddingTop: "unset",
    },
});

const StyledProductImage = styled("img")({
    height: 40,
    width: 40,
});

export const CustomCard = styled("div")({
    height: "fit-content",
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.BORDER_LIGHT}`,
    borderRadius: 12,
    marginTop: 24,
    marginBottom: 24,
    maxWidth: 910,
    width: "100%",
    "@media print": {
        // unset maxWidth so the document uses the whole space of the print
        maxWidth: "unset",
        backgroundColor: "unset",
        paddingTop: "unset",
    },
});

export const Title = styled("div")({
    marginBottom: 8,
    fontWeight: 600,
    fontSize: 19,
    textAlign: "left",
    maxWidth: 910,
    width: "100%",
});
const CustomRow = styled("div", {
    shouldForwardProp: (prop: string) => prop !== "columnCount",
})(({ columnCount }: ICustomRowProps) => ({
    padding: 16,
    height: 70,
    display: "grid",
    gridTemplateColumns: times(columnCount ?? 1, () => "1fr")
        .toString()
        .replaceAll(",", " "),
    gridAutoFlow: "row",
    gridAutoRows: "auto",
}));

const TileContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alingItems: "center",
    width: 303,
    padding: `0 ${TILE_CONTAINER_PADDING}px`,
});

const CustomLabel = styled("div")({
    color: Colors.DISABLED,
    fontSize: 12,
    fontWeight: "normal",
});

const LogoContainer = styled("div")({
    borderRadius: 12,
    border: `1px solid ${Colors.GREY_200}`,
    padding: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 46,
    marginRight: 16,
});

const HistoryRowContainer = styled("div")<{ backgroundColor: string }>(({ backgroundColor }) => ({
    padding: "10px 12px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: backgroundColor,
    gap: 15,
}));

const FlexContainer = styled("div")({
    display: "flex",
});

const HistoryRow = ({
    date,
    color,
    backgroundColor,
    value,
    adornmentText,
}: {
    value: string | number;
    backgroundColor: string;
    date?: string | Date;
    color?: string;
    adornmentText?: string;
}) => (
    <HistoryRowContainer backgroundColor={backgroundColor}>
        <div>{dayjs(date).format("DD.MM.YYYY")}</div>
        <div style={{ color }}>
            {value}
            {adornmentText}
        </div>
    </HistoryRowContainer>
);

const VerticalDivider = () => {
    return <Divider orientation="vertical" style={{ height: "auto" }} />;
};

const ReportTile = ({ label, value }: { label?: string; value?: string }) => (
    <TileContainer>
        <CustomLabel>{label}</CustomLabel>
        <TableLabel
            style={{
                fontWeight: 400,
                textAlign: "left",
            }}
        >
            {value ?? t("common.notSpecified")}
        </TableLabel>
    </TileContainer>
);

const productImages = {
    vorsorgePlus: VorsorgePlus,
    vorsorgePlusBusiness: VorsorgePlus,
    vorsorgeGold: VorsorgeGold,
    vorsorgeGoldBusiness: VorsorgeGold,
    vorsorgePlatin: VorsorgePlatin,
    vorsorgePlatinBusiness: VorsorgePlatin,
    vorsorgeDiamond: VorsorgeDiamond,
    vorsorgeDiamondBusiness: VorsorgeDiamond,
    besserLebenCheckWrStaedtische: BesserLebenCheckWrStaedtische,
    donauAktivCheckDonauversicherung: DonauAktiv,
    uniquaVitalcheck: UniquaVital,
    uniquaVorsorgeprogramm: UniquaVorsorge,

    // Currently no icon available for "vorsorgeCheck".
    // vorsorgeCheck: VorsorgePlus,
};

const historyStrings: { [key: string]: IMessageIDS } = {
    notSpecified: "screen.checkupReport.history.notSpecified",
    none: "screen.checkupReport.history.none",
    nonSmoker: "screen.checkupReport.history.nonSmoker",
    occasionalSmoker: "screen.checkupReport.history.occasionalSmoker",
    smoker: "screen.checkupReport.history.smoker",
    exSmoker: "screen.checkupReport.history.exSmoker",
    lessThan2h5: "screen.checkupReport.history.lessThan2h5",
    moreThan2h5: "screen.checkupReport.history.moreThan2h5",
    normal: "screen.checkupReport.history.normal",
    conspicuous: "screen.checkupReport.history.conspicuous",
    insufficient: "screen.checkupReport.history.insufficient",
    sufficient: "screen.checkupReport.history.sufficient",
    obstructive: "screen.checkupReport.history.obstructive",
    restrictive: "screen.checkupReport.history.restrictive",
};

const PatientInformationReport = ({ checkupReport }: { checkupReport: CheckupReport | null }) => {
    const [isProductOverflowing, setIsProductOverflowing] = React.useState(false);
    const patientInfo = checkupReport?.patientInformation;

    const productRef = React.useRef<HTMLDivElement>(null);
    const productContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (productRef.current && productContainerRef.current) {
            setIsProductOverflowing(
                productContainerRef.current.clientWidth -
                    (PRODUCT_SIZE + PRODUCT_MARGIN + PRODUCT_TEXT_MARGIN + TILE_CONTAINER_PADDING * 2) <=
                    productRef.current.clientWidth,
            );
        }
    }, [productRef.current?.clientWidth, productContainerRef.current?.clientWidth]);

    return (
        <CustomCard>
            <CustomRow columnCount={1} style={{ height: 80 }}>
                <div style={{ display: "flex" }}>
                    <LogoContainer>
                        <img width={32} src={logo} alt="" />
                    </LogoContainer>
                    <div>
                        <h4>{t("screen.checkupReport.title")}</h4>
                        <div style={{ color: Colors.DISABLED }}>{formatDate(checkupReport?.date)}</div>
                    </div>
                </div>
            </CustomRow>
            <Divider />
            <CustomRow columnCount={3}>
                <ReportTile
                    label={t("screen.checkupReport.intro.name")}
                    value={
                        patientInfo?.firstName && patientInfo.lastName
                            ? `${patientInfo?.titlePrefix ?? ""} ${patientInfo?.firstName} ${patientInfo?.lastName} ${
                                  patientInfo?.titleSuffix ?? ""
                              }`
                            : undefined
                    }
                />
                <FlexContainer>
                    <VerticalDivider />
                    <ReportTile
                        label={t("screen.checkupReport.intro.sex")}
                        value={
                            patientInfo?.sex
                                ? t(
                                      patientInfo?.sex === "male"
                                          ? "screen.patientInformation.field.patientInfo.male"
                                          : "screen.patientInformation.field.patientInfo.female",
                                  )
                                : undefined
                        }
                    />
                </FlexContainer>
                <FlexContainer>
                    <VerticalDivider />
                    {checkupReport?.productType ? (
                        <TileContainer ref={productContainerRef} style={{ flexDirection: "row", alignItems: "center" }}>
                            {productImages[checkupReport?.productType] && (
                                <StyledProductImage
                                    style={{ marginRight: PRODUCT_MARGIN }}
                                    src={productImages[checkupReport?.productType]}
                                    alt="product"
                                />
                            )}
                            <OptionalTooltip
                                title={isProductOverflowing ? t(products[checkupReport.productType]) : undefined}
                            >
                                <div
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        color: Colors.TEXT_BOLD,
                                        fontWeight: 500,
                                        textAlign: "center",
                                        marginRight: PRODUCT_TEXT_MARGIN,
                                    }}
                                    ref={productRef}
                                >
                                    {t(products[checkupReport.productType])}
                                </div>
                            </OptionalTooltip>
                        </TileContainer>
                    ) : (
                        <ReportTile label={t("screen.checkupReport.intro.product")} value={t("common.notSpecified")} />
                    )}
                </FlexContainer>
            </CustomRow>
            <Divider />
            <CustomRow columnCount={3}>
                <ReportTile
                    label={t("screen.checkupReport.intro.birthday")}
                    value={formatDate(patientInfo?.birthday)}
                />
                {/* <FlexContainer>
                    <VerticalDivider />
                    <ReportTile label={t("screen.checkupReport.intro.email")} value={patientInfo?.email} />
                </FlexContainer> */}
                <FlexContainer>
                    <VerticalDivider />
                    <ReportTile label={t("screen.checkupReport.intro.doctor")} value={patientInfo?.doctor} />
                </FlexContainer>
            </CustomRow>
        </CustomCard>
    );
};

export const CheckupReportSite = () => {
    const [checkupReport, setCheckupReport] = React.useState<CheckupReport | null>(null);
    const [historyPopoverAnchor, setHistoryPopoverAnchor] = React.useState<HTMLButtonElement | null>(null);
    const [currentItemHistory, setCurrentItemHistory] = React.useState<IHistoryItem[] | null>(null);

    const { checkupId } = useParams<{ checkupId?: string }>();

    const loadCheckupReport = React.useCallback(async () => {
        if (checkupId) {
            generalStore.isLoading = true;
            try {
                let checkupReport;
                if (authStore.userProfile?.scopes?.includes("doctor")) {
                    checkupReport = await API.getDoctorCheckupReport({ checkupId });
                }
                if (authStore.userProfile?.scopes?.includes("patient")) {
                    checkupReport = await API.getPatientCheckupReport({ checkupId });
                }
                if (checkupReport) {
                    patientStore.checkupReport = checkupReport;
                    setCheckupReport(sanitizeCheckupReport(checkupReport));
                }
            } catch (error) {
                if (error.statusCode === 404) {
                    history.push(Routes.PATIENT.WELCOME);
                    generalStore.setError(t("error.reportNotFound"), error);
                } else {
                    generalStore.setError(t("error.load"), error);
                }
            } finally {
                generalStore.isLoading = false;
            }
        }
    }, [checkupId]);

    React.useEffect(() => {
        loadCheckupReport();
    }, [loadCheckupReport]);

    const handleClickOpenHistory = (event: React.MouseEvent<HTMLButtonElement>, historyItem: IHistory) => {
        if (historyItem) {
            if (historyItem.category) {
                const categoryHistory = checkupReport?.[historyItem.category]?.history ?? [];
                if (categoryHistory.length !== 0) {
                    const itemHistory = categoryHistory.map((item) => {
                        const newDate = item.checkupDate;
                        let newValue;
                        let newColor;
                        if (historyItem.valueKey === "bloodpressure") {
                            newValue =
                                item?.[historyItem.valueKey]?.systole && item?.[historyItem.valueKey]?.diastole
                                    ? `${item?.[historyItem.valueKey].systole}/${item?.[historyItem.valueKey].diastole}`
                                    : "";
                            if (item?.["bloodpressureDropdown"]) {
                                newColor = getConspicuousnessColor(item?.["bloodpressureDropdown"]);
                            }
                        } else if (historyItem.valueKey === "bmi") {
                            const weight = item["weight"];
                            const height = item["height"];
                            const bmi = weight / Math.pow(height / 100, 2);

                            newValue = Math.round(bmi * 100) / 100;

                            if (historyItem.getColor) {
                                newColor = historyItem.getColor(bmi);
                            }
                        } else if (historyItem.category === "bloodpanels") {
                            const historyFilter = (value: BloodpanelReportValue) =>
                                value.name === historyItem.valueKey && value.unit === historyItem.unit;

                            const bloodpanelValue: BloodpanelReportValue = item["values"]
                                .find((group: BloodpanelGroup) => group.values?.find(historyFilter))
                                ?.values?.find(historyFilter);

                            if (bloodpanelValue) {
                                newColor = historyItem.getColor && historyItem.getColor(bloodpanelValue);

                                if (bloodpanelValue.valueType === "numeric") {
                                    newValue = bloodpanelValue.valueNumeric;
                                } else if (bloodpanelValue.valueType === "text") {
                                    newValue = bloodpanelValue.valueText;
                                } else {
                                    newValue = t(bloodpanelValue.valueBool ? "common.positive" : "common.negative");
                                }
                            }
                        } else if (historyItem.category === "bia") {
                            newColor = historyItem.getColor && historyItem.getColor(item);

                            newValue = item[historyItem.valueKey].value;
                        } else {
                            newColor = historyItem.getColor && historyItem.getColor(item?.[historyItem.valueKey]);

                            const historyString = historyStrings[item?.[historyItem.valueKey]];
                            if (historyString) {
                                newValue = t(historyString);
                            } else {
                                newValue = item?.[historyItem.valueKey];
                            }
                        }

                        if (!newValue) {
                            return undefined;
                        }

                        const newObject = {
                            value: newValue,
                            date: newDate,
                            adornmentText: historyItem.adornmentText && t(historyItem.adornmentText),
                            title: historyItem.title && t(historyItem.title),
                            color: newColor,
                        };

                        return newObject;
                    });
                    const filteredItemHistory = compact(itemHistory);

                    if (filteredItemHistory.length !== 0) {
                        setCurrentItemHistory(filteredItemHistory);
                        setHistoryPopoverAnchor(event.currentTarget);
                    } else {
                        setCurrentItemHistory(null);
                        setHistoryPopoverAnchor(event.currentTarget);
                    }
                } else {
                    setCurrentItemHistory(null);
                    setHistoryPopoverAnchor(event.currentTarget);
                }
            }
        } else {
            setCurrentItemHistory(null);
        }
    };

    const handleCloseHistory = () => {
        setHistoryPopoverAnchor(null);
    };

    return (
        <SiteContainer>
            <ProgressBarContainer>
                <ProgressBar
                    checkupSections={compact(
                        initialCheckupReportSections.map((checkupSection) => {
                            if (!checkupSection.name) {
                                return checkupSection;
                            }

                            if (checkupSection.items?.length !== 0) {
                                const newItems = checkupSection.items?.filter((item) => {
                                    const itemName = item.name?.replace("Report", "");
                                    if (itemName === "ultrasound") {
                                        return checkupReport?.ultrasound && !isEmpty(checkupReport.ultrasound);
                                    }

                                    const { checkupDate, ...latestValues } = checkupReport?.[itemName]?.latest ?? {};
                                    return !isEmpty(latestValues);
                                });

                                return newItems?.length !== 0
                                    ? {
                                          ...checkupSection,
                                          items: newItems,
                                      }
                                    : undefined;
                            }

                            return checkupReport?.[checkupSection.name?.replace("Report", "")]?.latest
                                ? checkupSection
                                : undefined;
                        }),
                    )}
                    showDoctorRecommendation
                />
            </ProgressBarContainer>
            <NonPrintableDivider orientation="vertical" flexItem style={{ marginLeft: PROGRESS_BAR_WIDTH }} />
            <MainContainer>
                <PatientInformationReport checkupReport={checkupReport} />
                <Status
                    status={
                        checkupReport?.recommendations?.latest?.savedTexts?.find(
                            (recommendation) => recommendation.category === "general.patientstatus",
                        )?.text
                    }
                />
                <LifestyleParametersReport onClickHistory={handleClickOpenHistory} checkupReport={checkupReport} />
                <LaboratoryResultsReport checkupReport={checkupReport} onClickHistory={handleClickOpenHistory} />
                <Diagnostic checkupReport={checkupReport} onClickHistory={handleClickOpenHistory} />
                <DoctorRecommendation checkupReport={checkupReport} />
                <Attachments checkupReport={checkupReport} />
                <Popover
                    open={!!historyPopoverAnchor}
                    onClose={handleCloseHistory}
                    anchorEl={historyPopoverAnchor}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    {currentItemHistory ? (
                        <>
                            <div style={{ margin: "10px 12px", minWidth: 300 }}>{currentItemHistory?.[0].title}</div>
                            <Divider />
                            {currentItemHistory?.slice(0, currentItemHistory.length - 1)?.map((historyItem, index) => (
                                <React.Fragment key={index}>
                                    <HistoryRow
                                        date={historyItem.date}
                                        adornmentText={historyItem.adornmentText}
                                        color={historyItem.color}
                                        value={historyItem.value}
                                        backgroundColor={index % 2 === 0 ? Colors.LIGHT_BACKGROUND : Colors.WHITE}
                                    />
                                    {index + 1 !== currentItemHistory.length && <Divider />}
                                </React.Fragment>
                            ))}
                            <Divider style={{ height: 4, backgroundColor: Colors.GREY_200 }} />
                            <HistoryRow
                                date={currentItemHistory?.[currentItemHistory?.length - 1].date}
                                adornmentText={currentItemHistory?.[currentItemHistory?.length - 1].adornmentText}
                                color={currentItemHistory?.[currentItemHistory?.length - 1].color}
                                value={currentItemHistory?.[currentItemHistory?.length - 1].value ?? ""}
                                backgroundColor={Colors.WHITE}
                            />
                        </>
                    ) : (
                        <div style={{ padding: 8 }}>{t("error.noHistory")}</div>
                    )}
                </Popover>
            </MainContainer>
        </SiteContainer>
    );
};
