import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import * as React from "react";
import { ICell } from "../../types";
import { Colors } from "../util/Colors";
import { TextDot } from "./Dots";
import { StyledTableCell } from "./StyledTableCell";
import { TableHeaderCell } from "./TableHeaderCell";
import { TableLabel } from "./TableLabel";

type IHeaderItem = {
    name: string;
    label?: string;
    style?: React.CSSProperties;
};

type IProps = {
    headerItems?: IHeaderItem[];
    rows: ICell[][];
    style?: React.CSSProperties;
};

export const CurrentValueTable = ({ headerItems, rows, style }: IProps) => (
    <Table style={style}>
        <colgroup>
            <col style={{ width: 160 }} />
            <col style={{ width: 135 }} />
            <col style={{ width: 300 }} />
        </colgroup>
        <TableHead>
            <TableRow>
                {headerItems?.map((headerItem, index) => (
                    <TableHeaderCell
                        key={index}
                        style={{
                            textAlign: index === 0 ? "left" : "center",
                            whiteSpace: "nowrap",
                            backgroundColor: "unset",
                        }}
                    >
                        {headerItem.label}
                    </TableHeaderCell>
                ))}
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map((row, index) => (
                <TableRow key={index}>
                    {row.map((cell, cellIndex) => (
                        <StyledTableCell
                            key={cellIndex}
                            style={{
                                color: cell.color,
                                borderRight: cellIndex < row.length - 1 ? `1px solid ${Colors.GREY_200}` : undefined,
                                backgroundColor: "unset",
                            }}
                        >
                            <div
                                style={
                                    cellIndex !== 0
                                        ? {
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              whiteSpace: "nowrap",
                                          }
                                        : undefined
                                }
                            >
                                {cell.color && <TextDot style={{ backgroundColor: cell.color, marginRight: 6 }} />}
                                {cellIndex === 0 && typeof cell.content === "string" ? (
                                    <TableLabel
                                        style={{
                                            width: 160,
                                            textAlign: "left",
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: Colors.TEXT_LIGHT,
                                        }}
                                        tooltip={cell.content}
                                    >
                                        {cell.content}
                                    </TableLabel>
                                ) : (
                                    <div style={{ whiteSpace: "nowrap" }}>
                                        {cell.content !== undefined && cell.content !== "" ? cell.content : "-"}
                                        {cell.content !== undefined && cell.content !== "" && cell.adornmentText && (
                                            <span>&nbsp;{cell.adornmentText}</span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </StyledTableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    </Table>
);
