import { Divider, styled } from "@mui/material";
import { compact } from "lodash";
import * as React from "react";
import { IMessageIDS, t } from "../../i18n/util";
import { CheckupReport } from "../../network/APITypes";
import { IBreakpoints, IDotPosition, IHistory, IMultiValueDisplayDotPosition } from "../../types";
import { StyledIconContainer } from "../ui/IconContainer";
import { ValueCell } from "../ui/ValueCell";
import { Colors } from "../util/Colors";
import { BMI_MAX_VALUE, BMI_MIN_VALUE } from "../util/constants";
import { getRecommendationText } from "../util/helpers";
import { ImageBewegungRadfahren, ImageBlutdruck, ImageRauchen } from "../util/Images";
import { getBmiDotPosition } from "../util/MultiValueDisplayHelpers";
import {
    getAbdominalGirthBreakpoints,
    getAbdominalGirthColor,
    getBloodpressureColor,
    getBmiColor,
    getExerciseColor,
    getExerciseDropdownText,
    getSmokerColor,
    getSmokerText,
} from "../util/ValueDisplayHelpers";
import { CustomCard, Title } from "./sites/CheckupReportSite";

type IProps = {
    // TODO: fix typing for history
    onClickHistory: (event: React.MouseEvent<HTMLButtonElement>, history: any) => void;
    checkupReport?: CheckupReport | null;
};

type ILifestyleValues = {
    name: string;
    title: IMessageIDS;
    value?: number | string;
    adornmentText?: IMessageIDS;
    color?: string;
    history?: IHistory;
    indicatorReplacement?: IMessageIDS;
};

type ISection = {
    name: string;
    title: string;
    recommendation?: string;
    items?: ILifestyleValues[];
    graphType?: "gradient" | "normal" | "multi";
    graphValues?: { minVal: number; maxVal: number };
    graphMultiDotPosition?: IMultiValueDisplayDotPosition;
    graphDotPosition?: IDotPosition;
    graphBreakpoints?: IBreakpoints;
    graphValue?: number;
    exerciseDropdown?: string;
    icon?: React.ReactNode;
};

type ICustomGridProps = {
    singleColumn?: boolean;
};

const CustomGrid = styled("div", {
    shouldForwardProp: (prop: string) => prop !== "singleColumn",
})(({ singleColumn }: ICustomGridProps) => ({
    display: "grid",
    gridTemplateColumns: singleColumn ? "1fr" : "1fr 1fr",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
}));

const SectionText = styled("div")({
    padding: 16,
    fontSize: 16,
    fontWeight: 400,
});

const SectionTitle = styled("div")({
    fontSize: 16,
    fontWeight: 500,
    color: Colors.TEXT_BOLD,
    height: 46,
    display: "flex",
    alignItems: "center",
    padding: "13px 16px",
    backgroundColor: "#FCFCFC",
});

const lightBorderStyle = `1px solid ${Colors.GREY_200}`;

const initialLifestyleValues: ILifestyleValues[] = [
    {
        name: "height",
        title: "screen.checkupReport.lifestyleParameters.height.label",
        value: "",
        adornmentText: "common.centimeter",
    },
    {
        name: "weight",
        title: "screen.checkupReport.lifestyleParameters.weight.label",
        value: "",
        adornmentText: "common.kilogram",
    },
    {
        name: "bmi",
        title: "screen.checkupReport.lifestyleParameters.bmi.label",
        value: "",
    },
    {
        name: "abdominalGirth",
        title: "screen.checkupReport.lifestyleParameters.abdominalGirth.label",
        value: "",
        adornmentText: "common.centimeter",
    },
    {
        name: "exerciseDropdown",
        title: "screen.checkupReport.lifestyleParameters.exercise.label",
        value: "",
    },
    {
        name: "smokerDropdown",
        title: "screen.checkupReport.lifestyleParameters.smoker.label",
        value: "",
    },
    {
        name: "bloodpressure",
        title: "screen.checkupReport.lifestyleParameters.bloodpressure.label",
        value: "",
    },
];

const initialSections: ISection[] = [
    {
        name: "abdominalGirth",
        title: "screen.checkupReport.lifestyleParameters.abdominalGirth",
    },
    {
        name: "bmi",
        title: "screen.checkupReport.lifestyleParameters.bmi",
    },
    {
        name: "exercise",
        title: "screen.checkupReport.lifestyleParameters.exercise",
    },
    {
        name: "bloodpressure",
        title: "screen.checkupReport.lifestyleParameters.bloodpressure",
    },
    {
        name: "smoker",
        title: "screen.checkupReport.lifestyleParameters.smoker",
    },
];

const lifestyleValueItems = [
    "height",
    "weight",
    "bmi",
    "abdominalGirth",
    "exerciseDropdown",
    "smokerDropdown",
    "bloodpressure",
];

const getBloodpressureText = (bloodpressureDropdown: string) => {
    switch (bloodpressureDropdown) {
        case "notSpecified":
            return t("common.notSpecified");
        case "normal":
            return t("common.normal");
        case "conspicuous":
            return t("common.conspicuous");
        default:
            return bloodpressureDropdown;
    }
};

export const LifestyleParametersReport = ({ onClickHistory, checkupReport }: IProps) => {
    const [sections, setSections] = React.useState<ISection[]>(initialSections);
    const [lifestyleValues, setLifestyleValues] = React.useState<ILifestyleValues[]>(initialLifestyleValues);

    React.useEffect(() => {
        const lifeStyleReportHistory = checkupReport?.lifestyle;

        if (checkupReport && lifeStyleReportHistory) {
            const latestReport = lifeStyleReportHistory.latest;

            if (latestReport) {
                const newLifestyleValues = initialLifestyleValues.map((lifestyleValue) => {
                    const currentItem = lifestyleValueItems.find((item) => lifestyleValue.name === item);
                    if (currentItem) {
                        const latestReportItem = latestReport[currentItem];
                        if (latestReportItem) {
                            const newItem = { ...lifestyleValue, value: latestReportItem };

                            newItem.history = {
                                valueKey: currentItem,
                                category: "lifestyle",
                            };

                            if (currentItem === "abdominalGirth") {
                                newItem.history.adornmentText = "common.centimeter";
                                newItem.history.title = "screen.checkupReport.lifestyleParameters.abdominalGirth";

                                newItem.color = getAbdominalGirthColor(
                                    latestReportItem,
                                    checkupReport.patientInformation?.sex,
                                );
                                newItem.history.getColor = (value) =>
                                    getAbdominalGirthColor(value, checkupReport.patientInformation?.sex);
                            }
                            if (currentItem === "exerciseDropdown") {
                                newItem.value = getExerciseDropdownText(latestReportItem);
                                newItem.history.title = "screen.checkupReport.lifestyleParameters.exercise";
                                newItem.color = getExerciseColor(latestReportItem);
                                newItem.history.getColor = getExerciseColor;
                            }
                            if (currentItem === "smokerDropdown") {
                                newItem.value = getSmokerText(latestReportItem);
                                newItem.history.title = "screen.checkupReport.lifestyleParameters.smoker";

                                const getColor = (value: string) => {
                                    if (value === "smoker" || value === "occasionalSmoker") {
                                        return Colors.VALUE_DISPLAY_ORANGE;
                                    }
                                    if (latestReportItem === "nonSmoker" || value === "exSmoker") {
                                        return Colors.VALUE_DISPLAY_GREEN;
                                    }
                                    return undefined;
                                };

                                newItem.color = getColor(latestReportItem);
                                newItem.history.getColor = getColor;
                            }
                            if (currentItem === "bloodpressure") {
                                newItem.value =
                                    latestReport.bloodpressure?.systole && latestReport.bloodpressure.diastole
                                        ? `${latestReport.bloodpressure.systole}/${latestReport.bloodpressure.diastole}`
                                        : "";
                                newItem.history.title = "screen.checkupReport.lifestyleParameters.bloodpressure";

                                const bloodpressureDropdown = latestReport.bloodpressureDropdown;

                                newItem.color = getBloodpressureColor(bloodpressureDropdown);
                                newItem.history.getColor = getBloodpressureColor;
                            }
                            if (currentItem === "weight") {
                                newItem.history.adornmentText = "common.kilogram";
                                newItem.history.title = "screen.checkupReport.lifestyleParameters.weight.label";
                            }
                            if (currentItem === "height") {
                                newItem.history = undefined;
                            }

                            return newItem;
                        }
                        if (currentItem === "bmi" && latestReport.weight && latestReport.height) {
                            const bmi =
                                Math.round((latestReport.weight / Math.pow(latestReport.height / 100, 2)) * 100) / 100;

                            const newItem: ILifestyleValues = {
                                ...lifestyleValue,
                                value: bmi,
                                color: getBmiColor(bmi),
                                history: {
                                    valueKey: currentItem,
                                    title: "screen.checkupReport.lifestyleParameters.bmi",
                                    category: "lifestyle",
                                    getColor: getBmiColor,
                                },
                            };

                            return newItem;
                        }
                    }
                    return lifestyleValue;
                });

                let bmi;
                let abdominalGirth;

                if (latestReport.weight && latestReport.height) {
                    bmi = Math.round((latestReport.weight / Math.pow(latestReport.height / 100, 2)) * 100) / 100;
                }
                if (latestReport.abdominalGirth) {
                    abdominalGirth = latestReport.abdominalGirth;
                }

                const bmiGraphValues = { minVal: BMI_MIN_VALUE, maxVal: BMI_MAX_VALUE };
                const bmiPosition = getBmiDotPosition(bmi);

                const abdominalGirthBreakpoints = getAbdominalGirthBreakpoints(checkupReport.patientInformation?.sex);

                const newSections: ISection[] = [
                    ...initialSections,
                    {
                        name: "bmi",
                        title: t("screen.checkupReport.lifestyleParameters.bmi"),
                        recommendation: getRecommendationText(
                            "lifestyle.bmi",
                            checkupReport.recommendations?.latest?.savedTexts,
                        ),
                        items: [
                            {
                                name: "bmi",
                                title: "screen.checkupReport.lifestyleParameters.section.bmi.label",
                                value: bmi,
                                color: bmi ? getBmiColor(bmi) : "",
                                history: {
                                    category: "lifestyle",
                                    valueKey: "bmi",
                                    title: "screen.checkupReport.lifestyleParameters.bmi",
                                    getColor: getBmiColor,
                                },
                            },
                        ],
                        graphType: "multi",
                        graphValues: bmiGraphValues,
                        graphMultiDotPosition: bmiPosition,
                    },
                    {
                        name: "abdominalGirth",
                        title: t("screen.checkupReport.lifestyleParameters.abdominalGirth"),
                        recommendation: getRecommendationText(
                            "lifestyle.abdominalGirth",
                            checkupReport.recommendations?.latest?.savedTexts,
                        ),
                        items: [
                            {
                                name: "abdominalGirth",
                                title: "screen.checkupReport.lifestyleParameters.section.abdominalGirth.label",
                                value: abdominalGirth,
                                adornmentText: "common.centimeter",
                                color: getAbdominalGirthColor(abdominalGirth, checkupReport.patientInformation?.sex),
                                history: {
                                    category: "lifestyle",
                                    valueKey: "abdominalGirth",
                                    title: "screen.checkupReport.lifestyleParameters.abdominalGirth",
                                    adornmentText: "common.centimeter",
                                    getColor: (value) =>
                                        getAbdominalGirthColor(value, checkupReport.patientInformation?.sex),
                                },
                            },
                        ],
                        graphType: "gradient",
                        graphBreakpoints: abdominalGirthBreakpoints,
                        graphValue: abdominalGirth,
                    },
                    {
                        name: "exercise",
                        title: t("screen.checkupReport.lifestyleParameters.exercise"),
                        icon: <ImageBewegungRadfahren />,
                        recommendation: getRecommendationText(
                            "lifestyle.exercise",
                            checkupReport.recommendations?.latest?.savedTexts,
                        ),
                        items: [
                            {
                                name: "exercise",
                                title: "screen.checkupReport.lifestyleParameters.section.exercise.label",
                                value: latestReport?.exerciseDropdown
                                    ? getExerciseDropdownText(latestReport.exerciseDropdown)
                                    : "",
                                color: getExerciseColor(latestReport.exerciseDropdown),
                                history: {
                                    category: "lifestyle",
                                    valueKey: "exerciseDropdown",
                                    title: "screen.checkupReport.lifestyleParameters.exercise",
                                    getColor: getExerciseColor,
                                },
                            },
                        ],
                        graphType: "gradient",
                        exerciseDropdown: latestReport.exerciseDropdown,
                    },
                    {
                        name: "bloodpressure",
                        title: t("screen.checkupReport.lifestyleParameters.bloodpressure"),
                        recommendation: getRecommendationText(
                            "lifestyle.bloodpressure",
                            checkupReport.recommendations?.latest?.savedTexts,
                        ),
                        icon: <ImageBlutdruck />,
                        items: [
                            {
                                name: "bloodpressureDropdown",
                                title: "screen.checkupReport.lifestyleParameters.section.bloodpressureDropdown.label",
                                value: latestReport?.bloodpressureDropdown
                                    ? getBloodpressureText(latestReport.bloodpressureDropdown)
                                    : "",
                                color: getBloodpressureColor(latestReport.bloodpressureDropdown),
                                history: {
                                    category: "lifestyle",
                                    valueKey: "bloodpressureDropdown",
                                    title: "screen.checkupReport.lifestyleParameters.section.bloodpressureDropdown.label",
                                    getColor: getBloodpressureColor,
                                },
                            },
                            {
                                name: "bloodpressure",
                                title: "screen.checkupReport.lifestyleParameters.section.bloodpressure.label",
                                value:
                                    latestReport.bloodpressure?.systole && latestReport.bloodpressure.diastole
                                        ? `${latestReport.bloodpressure.systole}/${latestReport.bloodpressure.diastole}`
                                        : "",
                                color: latestReport?.bloodpressureDropdown
                                    ? getBloodpressureColor(latestReport?.bloodpressureDropdown)
                                    : "",
                            },
                        ],
                    },
                    {
                        name: "smoker",
                        title: t("screen.checkupReport.lifestyleParameters.smoker"),
                        recommendation: getRecommendationText(
                            "lifestyle.smoker",
                            checkupReport.recommendations?.latest?.savedTexts,
                        ),
                        icon: <ImageRauchen />,
                        items: [
                            {
                                name: "smoker",
                                title: "screen.checkupReport.lifestyleParameters.section.smoker.label",
                                value: latestReport?.smokerDropdown ? getSmokerText(latestReport.smokerDropdown) : "",
                                color: getSmokerColor(latestReport.smokerDropdown),
                                history: {
                                    category: "lifestyle",
                                    valueKey: "smokerDropdown",
                                    title: "screen.checkupReport.lifestyleParameters.smoker",
                                    getColor: getSmokerColor,
                                },
                            },
                        ],
                    },
                ];

                setLifestyleValues(newLifestyleValues.filter((item) => !!item.value));

                const sanitizedSections = compact(
                    newSections.map((section) => {
                        const sanitizedSectionItems = section.items?.filter((item) => (item.value ? item : undefined));
                        return sanitizedSectionItems && sanitizedSectionItems.length > 0
                            ? { ...section, items: sanitizedSectionItems }
                            : undefined;
                    }),
                );
                setSections(sanitizedSections);
            }
        }
    }, [checkupReport]);

    const hasLifestyleValues = lifestyleValues.length !== 0;
    const hasSections = sections.filter((section) => (section.items?.length !== 0 ? section : undefined)).length !== 0;

    if (!checkupReport?.lifestyle?.latest || (!hasLifestyleValues && !hasSections)) {
        return null;
    }

    return (
        <>
            <Title>{t("screen.checkupReport.lifestyleParameters.title")}</Title>
            <CustomCard>
                <CustomGrid singleColumn={lifestyleValues.length === 1}>
                    {lifestyleValues.map((item, index) => (
                        <ValueCell
                            key={item.name}
                            title={t(item.title)}
                            value={item.value}
                            adornmentText={item.adornmentText ? t(item.adornmentText) : undefined}
                            color={item.color}
                            history={item.history}
                            indicatorReplacement={item.indicatorReplacement ? t(item.indicatorReplacement) : undefined}
                            style={{
                                borderRight: index % 2 === 0 ? `1px solid ${Colors.GREY_200}` : undefined,
                            }}
                            containerStyle={{
                                borderBottom:
                                    lifestyleValues.length % 2 === 0 || index !== lifestyleValues.length - 1
                                        ? "1px solid #E7E7E7"
                                        : undefined,
                            }}
                            onClickHistory={onClickHistory}
                        />
                    ))}
                </CustomGrid>
                {hasLifestyleValues && hasSections && <Divider style={{ borderBottomWidth: 4 }} />}
                <div>
                    {sections.map((section, index) => {
                        return (
                            section.items &&
                            section.items.length > 0 && (
                                <React.Fragment key={index}>
                                    <SectionTitle>
                                        {section.icon && (
                                            <StyledIconContainer style={{ marginRight: 8 }}>
                                                {section.icon}
                                            </StyledIconContainer>
                                        )}
                                        {section.title}
                                    </SectionTitle>
                                    <Divider />
                                    {section.items.length > 1 ? (
                                        <CustomGrid>
                                            {section.items.map((item, itemIndex) => (
                                                <ValueCell
                                                    key={item.name}
                                                    title={t(item.title)}
                                                    value={item.value}
                                                    adornmentText={
                                                        item.adornmentText ? t(item.adornmentText) : undefined
                                                    }
                                                    color={item.color}
                                                    history={item.history}
                                                    indicatorReplacement={
                                                        item.indicatorReplacement
                                                            ? t(item.indicatorReplacement)
                                                            : undefined
                                                    }
                                                    style={{
                                                        borderRight: itemIndex % 2 === 0 ? lightBorderStyle : undefined,
                                                    }}
                                                    onClickHistory={onClickHistory}
                                                />
                                            ))}
                                        </CustomGrid>
                                    ) : (
                                        <ValueCell
                                            title={t(section.items[0].title)}
                                            value={section.items[0].value}
                                            adornmentText={
                                                section.items[0].adornmentText
                                                    ? t(section.items[0].adornmentText)
                                                    : undefined
                                            }
                                            color={section.items[0].color}
                                            history={section.items[0].history}
                                            indicatorReplacement={
                                                section.items[0].indicatorReplacement
                                                    ? t(section.items[0].indicatorReplacement)
                                                    : undefined
                                            }
                                            onClickHistory={onClickHistory}
                                            graphType={section.graphType}
                                            graphValues={section.graphValues}
                                            graphMultiDotPosition={section.graphMultiDotPosition}
                                            graphDotPosition={section.graphDotPosition}
                                            graphBreakpoints={section.graphBreakpoints}
                                            graphValue={section.graphValue}
                                            exerciseDropdown={section.exerciseDropdown}
                                        />
                                    )}
                                    {section.recommendation && (
                                        <>
                                            <Divider />
                                            <SectionText>{section.recommendation}</SectionText>
                                        </>
                                    )}
                                    {index !== sections.length - 1 && <Divider />}
                                </React.Fragment>
                            )
                        );
                    })}
                </div>
            </CustomCard>
        </>
    );
};
