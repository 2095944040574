import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { API } from "../../../network/API";
import { authStore } from "../../../stores/AuthStore";
import { patientStore } from "../../../stores/PatientStore";
import { PatientSite } from "../../patients/sites/PatientSite";
import { PatientsOverviewSite } from "../../patients/sites/PatientsOverviewSite";
import { NavigationBar } from "../../shared/NavigationBar";
import { NotFoundSite } from "../../shared/NotFoundSite";
import { HistorySite } from "../../users/sites/HistorySite";
import { WelcomeSite } from "../../users/sites/WelcomeSite";
import { Routes } from "./Routes";

export const AuthorizedRouter = observer((props: any): any => {
    if (!authStore.isAuthenticated) {
        return null;
    }

    React.useEffect(() => {
        const loadCheckups = async () => {
            const checkupsResponse = await API.getPatientCheckups();
            patientStore.totalCheckups = checkupsResponse.length;
        };

        if (authStore.isPatient) {
            loadCheckups();
        }
    }, []);

    if (authStore.isAuthenticated) {
        if (authStore.isDoctor) {
            return (
                <>
                    <Route exact path={[Routes.DOCTOR.OVERVIEW, Routes.DOCTOR.CHECKUP, Routes.DOCTOR.HISTORY]}>
                        <NavigationBar />
                    </Route>
                    <Switch>
                        <Route exact path={Routes.DOCTOR.OVERVIEW}>
                            <PatientsOverviewSite />
                        </Route>
                        <Route exact path={Routes.DOCTOR.CHECKUP}>
                            <PatientSite />
                        </Route>
                        <Route exact path={Routes.DOCTOR.HISTORY}>
                            <HistorySite />
                        </Route>
                        <Route>
                            <NotFoundSite />
                        </Route>
                    </Switch>
                </>
            );
        } else if (authStore.isPatient) {
            return (
                <>
                    <Route exact path={Routes.PATIENT.HISTORY}>
                        <NavigationBar />
                    </Route>
                    <Switch>
                        <Route exact path={Routes.PATIENT.WELCOME}>
                            <WelcomeSite />
                        </Route>
                        {patientStore.totalCheckups > 1 && (
                            <Route exact path={Routes.PATIENT.HISTORY}>
                                <HistorySite />
                            </Route>
                        )}
                        <Route>
                            <NotFoundSite />
                        </Route>
                    </Switch>
                </>
            );
        }
    }

    return <Redirect to={{ pathname: Routes.ROOT, state: { fron: props.location } }} />;
});
