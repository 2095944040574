import { makeObservable, observable } from "mobx";
import { ILocales } from "../i18n/ILocales";
import { isUnauthorizedError } from "../network/API";

const DEFAULT_LOCALE = "de";

class GeneralStore {
    @observable locale: ILocales = DEFAULT_LOCALE;
    @observable error?: { message: string; error?: any };

    _isLoading = observable.array<string>([]);
    set isLoading(loading: boolean) {
        if (loading) {
            this.beginLoading("unknown");
        } else {
            this.endLoading("unknown");
        }
    }

    get isLoading() {
        return this._isLoading.length > 0;
    }

    beginLoading(label: string) {
        this._isLoading.push(label);
    }

    endLoading(label: string) {
        const found = this._isLoading.indexOf(label);
        if (found >= 0) {
            this._isLoading.splice(found, 1);
        } else {
            throw new Error("Could not find loading label");
        }
    }

    findLoading(label: string) {
        return this._isLoading.indexOf(label) >= 0;
    }

    setError = (message: string, error?: any) => {
        this.error = { message, error: error && !isUnauthorizedError(error) ? error : undefined };
    };

    constructor() {
        makeObservable(this);
    }
}

const generalStore = new GeneralStore();

export { generalStore };
