import { Accordion, AccordionDetails, AccordionSummary, Divider, styled } from "@mui/material";
import dayjs from "dayjs";
import { compact, groupBy, orderBy, sortBy, uniq } from "lodash";
import * as React from "react";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/ic_chevron_down.svg";
import { IMessageIDS, t } from "../../i18n/util";
import {
    ECGReportHistory,
    ExerciseErgometricReportHistory,
    RecommendationsReportHistory,
    RelaxationReportHistory,
    SpirometricReport,
    SpirometricReportHistory,
} from "../../network/APITypes";
import { ExerciseValueDisplay } from "../patients/ExerciseValueDisplay";
import { CAROTIS_CATEGORY, HEART_CATEGORY, STOMACH_CATEGORY, THYROID_CATEGORY } from "../users/sites/HistorySite";
import { Colors } from "../util/Colors";
import { formatDate, getRecommendations } from "../util/helpers";
import {
    getConspicuousnessColor,
    getConspicuousnessDropdownLabel,
    getExerciseErgometricPercentColor,
    getHistoryHeaderItems,
    getObstructiveDropdownLabel,
    getRelaxationColor,
    getRelaxationDropdownLabel,
    getSmileyByColor,
} from "../util/ValueDisplayHelpers";
import { Card } from "./Card";
import { ConspicuousnessDisplay } from "./ConspicuousnessDisplay";
import { CurrentValueTable } from "./CurrentValueTable";
import { TextDot } from "./Dots";
import { HistoryValuesTable } from "./HistoryValuesTable";
import {
    RecommendationHistory,
    RecommendationHistoryAccordion,
    RecommendationHistoryAccordionSummary,
} from "./RecommendationHistory";
import { HistorySectionTitle } from "./HistorySectionTitle";
import { VerticalTableDivider } from "./VerticalTableDivider";

const ECG_CATEGORY = "ecg";
const RELAXATION_CATEGORY = "relaxation";
const SPIROMETRIC_CATEGORY = "spirometric";
const EXERCISE_CATEGORY = "exerciseErgometric";

type IProps = {
    ecg?: ECGReportHistory;
    relaxation?: RelaxationReportHistory;
    spirometric?: SpirometricReportHistory;
    exercise?: ExerciseErgometricReportHistory;
    recommendations?: RecommendationsReportHistory;
    checkupDate?: string;
    showEcg?: boolean;
    showRelaxation?: boolean;
    showSpirometric?: boolean;
    showExercise?: boolean;
};
type ISpirometricReport = SpirometricReport & {
    checkupDate?: string | Date | undefined;
};

const StyledCard = styled(Card)({
    marginTop: 40,
});

const Title = styled("div")({
    marginTop: 40,
    fontWeight: 600,
    fontSize: 19,
});

const Headers = styled("div")({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    lineHeight: "14px",
    height: 47,
    color: Colors.DISABLED,
    fontSize: 12,
    fontWeight: 500,
});

const EcgValuesContainer = styled("div")({
    display: "flex",
    justifyContent: "space-around",
    margin: "16px 0",
    fontSize: 16,
    fontWeight: 400,
});

const EcgValue = styled("div")({
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "9px 0",
});

const EcgAssessmentContainer = styled("div")({
    width: "50%",
    textAlign: "center",
    margin: "9px 0",
});

const getSpirometricHistoryItems = (
    key: keyof SpirometricReport,
    reports: ISpirometricReport[],
    adornmentText?: string,
) =>
    reports.map((spirometricItem) => ({
        content: key === "assessment" ? getObstructiveDropdownLabel(spirometricItem.assessment) : spirometricItem[key],
        color: getConspicuousnessColor(spirometricItem.assessment),
        adornmentText,
    }));

const getSpirometricHistoryItem = (key: keyof SpirometricReport, report?: ISpirometricReport, adornmentText?: string) =>
    report
        ? {
              content: key === "assessment" ? getObstructiveDropdownLabel(report.assessment) : report[key] ?? "",
              color: getConspicuousnessColor(report.assessment),
              adornmentText,
          }
        : {
              content: "",
          };

export const DiagnosticHistory = ({
    ecg,
    relaxation,
    spirometric,
    exercise,
    recommendations,
    checkupDate,
    showEcg,
    showRelaxation,
    showSpirometric,
    showExercise,
}: IProps) => {
    const [isEcgAccordionOpen, setIsEcgAccordionOpen] = React.useState(false);

    const currentValueHeaderItems = [
        {
            name: "name",
            label: t("screen.history.table.checkup"),
        },
        {
            name: "value",
            label: t("screen.history.table.currentValue", {
                date: formatDate(checkupDate),
                br: (<br />) as any,
            }),
        },
        {
            name: "referenceRange",
            label: t("screen.history.table.referenceRange"),
        },
    ];

    const handleClickEcgAccordion = (event: React.MouseEvent<HTMLElement>) => {
        setIsEcgAccordionOpen(!isEcgAccordionOpen);
    };

    const { currentRecommendation: ecgRecommendation, currentRecommendationHistory: ecgRecommendationHistory } =
        getRecommendations(ECG_CATEGORY, recommendations);

    const ecgDates = compact(
        uniq([
            ...(ecg?.history?.map((ecgHistoryValue) => ecgHistoryValue.checkupDate) ?? []),
            ...ecgRecommendationHistory.recommendations.map((recommendation) => recommendation?.checkupDate),
        ]),
    ).sort((a, b) => new Date(b).valueOf() - new Date(a).valueOf());

    const ecgCard = showEcg && ecg && (
        <StyledCard>
            <HistorySectionTitle>{t("screen.history.diagnostic.ecg")}</HistorySectionTitle>
            <Divider />
            <Headers>
                <div style={{ textAlign: "center" }}>
                    {t("screen.history.table.currentValue", {
                        date: formatDate(checkupDate),
                        br: (<br />) as any,
                    })}
                </div>
                <div>{t("screen.history.diagnostic.ecg.referenceRange")}</div>
            </Headers>
            <Divider />
            <EcgValuesContainer>
                <EcgValue
                    style={{
                        color: ecg.latest?.assessment === "conspicuous" ? Colors.VALUE_DISPLAY_ORANGE : undefined,
                    }}
                >
                    {ecg.latest?.assessment === "conspicuous" && (
                        <TextDot style={{ backgroundColor: Colors.VALUE_DISPLAY_ORANGE, marginRight: 4 }} />
                    )}
                    {ecg.latest?.value ?? "-"}
                </EcgValue>
                <VerticalTableDivider style={{ width: 1, margin: "0 16px" }} />
                <EcgAssessmentContainer>
                    {ecg.latest?.assessment ? <ConspicuousnessDisplay value={ecg.latest?.assessment} /> : "-"}
                </EcgAssessmentContainer>
            </EcgValuesContainer>
            <Divider />
            <HistorySectionTitle>{t("screen.history.recommendation")}</HistorySectionTitle>
            {ecgRecommendation?.text && (
                <>
                    <Divider />
                    <HistorySectionTitle>{formatDate(ecgRecommendation.date)}</HistorySectionTitle>
                    <Divider />
                    <div style={{ fontWeight: 400, padding: 16, whiteSpace: "pre-wrap" }}>{ecgRecommendation.text}</div>
                </>
            )}
            {ecgDates.length && (
                <>
                    <Divider />
                    <RecommendationHistoryAccordion defaultExpanded disableGutters elevation={0}>
                        <RecommendationHistoryAccordionSummary
                            style={{ padding: 0, paddingRight: 16, height: 45 }}
                            expandIcon={<ChevronDownIcon />}
                        >
                            <Divider />
                            <HistorySectionTitle style={{ cursor: "pointer" }} onClick={handleClickEcgAccordion}>
                                {ecgDates.length === 1
                                    ? dayjs(ecgDates[0]).format("YYYY")
                                    : `${dayjs(ecgDates[ecgDates.length - 1]).format("YYYY")} - ${dayjs(
                                          ecgDates[0],
                                      ).format("YYYY")}`}
                            </HistorySectionTitle>
                        </RecommendationHistoryAccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                            <Divider />
                            {ecgDates.map((checkupDate, index) => {
                                const ecgValue = ecg?.history?.find(
                                    (ecgHistory) => ecgHistory.checkupDate === checkupDate,
                                )?.value;

                                const recommendationText = ecgRecommendationHistory.recommendations?.find(
                                    (recommendation) => recommendation?.checkupDate === checkupDate,
                                )?.text;

                                return (
                                    <div style={{ fontWeight: 400 }} key={index}>
                                        <>
                                            <div style={{ display: "flex", padding: 16 }}>
                                                <div style={{ display: "flex", width: "50%" }}>
                                                    <div>{formatDate(checkupDate)}</div>
                                                    <VerticalTableDivider style={{ width: 1, margin: "0 16px" }} />
                                                    <div>{ecgValue}</div>
                                                </div>
                                                <VerticalTableDivider style={{ width: 1, margin: "0 16px" }} />
                                                <div>{recommendationText}</div>
                                            </div>
                                            {index !== ecgDates.length - 1 && <Divider />}
                                        </>
                                    </div>
                                );
                            })}
                        </AccordionDetails>
                    </RecommendationHistoryAccordion>
                </>
            )}
        </StyledCard>
    );

    const {
        valueHeaderItems: relaxationValueHeaderItems,
        latestHistoryItems: latestRelaxationHistoryItems,
        lastCheckupReport: lastRelaxationCheckupReport,
        emptyColumns: emptyRelaxationColumns,
    } = getHistoryHeaderItems(relaxation?.history);
    const relaxationColor = getRelaxationColor(relaxation?.latest?.value);
    const relaxationCurrentValueRow = [
        {
            content: t("screen.history.diagnosis.relaxation"),
        },
        {
            content: getRelaxationDropdownLabel(relaxation?.latest?.value),
            color: relaxationColor,
        },
        {
            content: relaxation?.latest?.value ? (
                <ConspicuousnessDisplay
                    value={relaxation?.latest?.value}
                    label={t("screen.history.sufficentAndInsufficent")}
                />
            ) : (
                "-"
            ),
        },
    ];

    const latestRelaxationHistory = latestRelaxationHistoryItems.map((relaxationItem) => ({
        content: getRelaxationDropdownLabel(relaxationItem.value),
        color: getRelaxationColor(relaxationItem.value),
    }));
    const lastRelaxationItem = {
        content: getRelaxationDropdownLabel(lastRelaxationCheckupReport?.value),
        color: getRelaxationColor(lastRelaxationCheckupReport?.value),
    };

    const relaxationHistoryValuesRow = [
        { content: getSmileyByColor(relaxationColor) },
        ...emptyRelaxationColumns,
        ...latestRelaxationHistory,
        lastRelaxationItem,
    ];

    const {
        currentRecommendation: relaxationRecommendation,
        currentRecommendationHistory: relaxationRecommendationHistory,
    } = getRecommendations(RELAXATION_CATEGORY, recommendations);

    const relaxationCard = showRelaxation && (
        <StyledCard>
            <HistorySectionTitle>{t("screen.history.diagnostic.relaxation")}</HistorySectionTitle>
            <Divider />
            <div style={{ display: "flex" }}>
                <CurrentValueTable headerItems={currentValueHeaderItems} rows={[relaxationCurrentValueRow]} />
                <VerticalTableDivider />
                <HistoryValuesTable headerItems={relaxationValueHeaderItems} rows={[relaxationHistoryValuesRow]} />
            </div>
            <RecommendationHistory
                title={"screen.history.recommendation"}
                recommendation={relaxationRecommendation}
                recommendationHistory={relaxationRecommendationHistory}
            />
        </StyledCard>
    );

    const {
        valueHeaderItems: spirometricValueHeaderItems,
        latestHistoryItems: latestSpirometricHistoryItems,
        lastCheckupReport: lastSpirometricCheckupReport,
        emptyColumns: emptySpirometricColumns,
    } = getHistoryHeaderItems(spirometric?.history);
    const spirometricColor = getConspicuousnessColor(spirometric?.latest?.assessment);

    const spirometricAssesmentCurrentValueRow = [
        {
            content: t("screen.history.diagnostic.spirometric"),
        },
        {
            content: getObstructiveDropdownLabel(spirometric?.latest?.assessment),
            color: spirometricColor,
        },
        {
            content: spirometric?.latest?.assessment ? (
                <ConspicuousnessDisplay value={spirometric?.latest?.assessment} />
            ) : (
                "-"
            ),
        },
    ];

    const spirometricItems: { title: IMessageIDS; key: keyof SpirometricReport; adornmentText?: string }[] = [
        { title: "screen.history.diagnostic.spirometricFvc", key: "FVC", adornmentText: t("common.liter") },
        {
            title: "screen.history.diagnostic.spirometricFvcPercent",
            key: "FVCPercent",
            adornmentText: t("common.percent"),
        },
        { title: "screen.history.diagnostic.spirometricFeV1", key: "FeV1FVC", adornmentText: t("common.percent") },
    ];

    const currentValueTableRows = [
        spirometricAssesmentCurrentValueRow,
        ...spirometricItems.map((item) => [
            { content: t(item.title) },
            { content: spirometric?.latest?.[item.key], color: spirometricColor, adornmentText: item.adornmentText },
            { content: "" },
        ]),
    ];

    const latestSpirometricAssesmentHistory = getSpirometricHistoryItems("assessment", latestSpirometricHistoryItems);
    const latestSpirometricFvcHistory = getSpirometricHistoryItems(
        "FVC",
        latestSpirometricHistoryItems,
        t("common.liter"),
    );
    const latestSpirometricFvcPercentHistory = getSpirometricHistoryItems(
        "FVCPercent",
        latestSpirometricHistoryItems,
        t("common.percent"),
    );
    const latestSpirometricFeV1History = getSpirometricHistoryItems(
        "FeV1FVC",
        latestSpirometricHistoryItems,
        t("common.percent"),
    );

    const lastSpirometricAssesment = getSpirometricHistoryItem("assessment", lastSpirometricCheckupReport);
    const lastSpirometricFvc = getSpirometricHistoryItem("FVC", lastSpirometricCheckupReport, t("common.liter"));
    const lastSpirometricFvcPercent = getSpirometricHistoryItem(
        "FVCPercent",
        lastSpirometricCheckupReport,
        t("common.percent"),
    );
    const lastSpirometricFeV1 = getSpirometricHistoryItem("FeV1FVC", lastSpirometricCheckupReport, t("common.percent"));

    const spirometricHistoryValuesRow = [{ content: getSmileyByColor(spirometricColor) }, ...emptySpirometricColumns];

    const spirometricAssesmentHistoryValuesRow = [
        ...spirometricHistoryValuesRow,
        ...latestSpirometricAssesmentHistory,
        lastSpirometricAssesment,
    ];

    const spirometricFvcHistoryValuesRow = [
        ...spirometricHistoryValuesRow,
        ...latestSpirometricFvcHistory,
        lastSpirometricFvc,
    ];

    const spirometricFvcPercentHistoryValuesRow = [
        ...spirometricHistoryValuesRow,
        ...latestSpirometricFvcPercentHistory,
        lastSpirometricFvcPercent,
    ];

    const spirometricFeV1HistoryValuesRow = [
        ...spirometricHistoryValuesRow,
        ...latestSpirometricFeV1History,
        lastSpirometricFeV1,
    ];

    const {
        currentRecommendation: spirometricRecommendation,
        currentRecommendationHistory: spirometricRecommendationHistory,
    } = getRecommendations(SPIROMETRIC_CATEGORY, recommendations);

    const spirometricCard = showSpirometric && spirometric && (
        <StyledCard>
            <HistorySectionTitle>{t("screen.history.diagnostic.spirometric")}</HistorySectionTitle>
            <Divider />
            <div style={{ display: "flex" }}>
                <CurrentValueTable headerItems={currentValueHeaderItems} rows={currentValueTableRows} />
                <VerticalTableDivider />
                <HistoryValuesTable
                    headerItems={spirometricValueHeaderItems}
                    rows={[
                        spirometricAssesmentHistoryValuesRow,
                        spirometricFvcHistoryValuesRow,
                        spirometricFvcPercentHistoryValuesRow,
                        spirometricFeV1HistoryValuesRow,
                    ]}
                />
            </div>
            <RecommendationHistory
                title={"screen.history.recommendation"}
                recommendation={spirometricRecommendation}
                recommendationHistory={spirometricRecommendationHistory}
            />
        </StyledCard>
    );

    const {
        valueHeaderItems: exerciseValueHeaderItems,
        latestHistoryItems: latestExerciseHistoryItems,
        lastCheckupReport: lastExerciseCheckupReport,
        emptyColumns: emptyExerciseColumns,
    } = getHistoryHeaderItems(exercise?.history);
    const exerciseColor = getConspicuousnessColor(exercise?.latest?.heartRate);
    const exercisePercentColor = getExerciseErgometricPercentColor(exercise?.latest?.percent);

    const exerciseHeartRateCurrentValueRow = [
        {
            content: t("screen.history.diagnsotic.exercise.conspicuousness"),
        },
        {
            content: getConspicuousnessDropdownLabel(exercise?.latest?.heartRate),
            color: exerciseColor,
        },
        {
            content: exercise?.latest?.heartRate ? <ConspicuousnessDisplay value={exercise?.latest?.heartRate} /> : "-",
        },
    ];

    const exercisePercentCurrentValueRow = [
        { content: t("screen.history.diagnostic.exercisePercent") },
        { content: exercise?.latest?.percent, color: exercisePercentColor, adornmentText: t("common.percent") },
        {
            content:
                exercise?.latest?.percent && typeof exercise?.latest?.percent === "number" ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <ExerciseValueDisplay value={exercise?.latest?.percent} />
                    </div>
                ) : null,
        },
    ];

    const latestExerciseHeartRateHistory = latestExerciseHistoryItems.map((exerciseItem) => ({
        content: getConspicuousnessDropdownLabel(exerciseItem.heartRate),
        color: getConspicuousnessColor(exerciseItem.heartRate),
    }));

    const latestExercisePercentHistory = latestExerciseHistoryItems.map((exerciseItem) => ({
        content: exerciseItem.percent ?? "",
        color: exercisePercentColor,
        adornmentText: t("common.percent"),
    }));

    const lastExerciseHeartRate = {
        content: getConspicuousnessDropdownLabel(lastExerciseCheckupReport?.heartRate),
        color: getConspicuousnessColor(lastExerciseCheckupReport?.heartRate),
    };

    const lastExercisePercent = {
        content: lastExerciseCheckupReport?.percent ?? "",
        color: getConspicuousnessColor(lastExerciseCheckupReport?.heartRate),
        adornmentText: t("common.percent"),
    };

    const exerciseHeartRateHistory = [{ content: getSmileyByColor(exerciseColor) }, ...emptyExerciseColumns];
    const exercisePercentHistory = [{ content: getSmileyByColor(exercisePercentColor) }, ...emptyExerciseColumns];

    const exerciseHeartRateHistoryValuesRow = [
        ...exerciseHeartRateHistory,
        ...latestExerciseHeartRateHistory,
        lastExerciseHeartRate,
    ];

    const exercisePercentHistoryValuesRow = [
        ...exercisePercentHistory,
        ...latestExercisePercentHistory,
        lastExercisePercent,
    ];

    const {
        currentRecommendation: exerciseRecommendation,
        currentRecommendationHistory: exerciseRecommendationHistory,
    } = getRecommendations(EXERCISE_CATEGORY, recommendations);

    const exerciseCard = showExercise && exercise && (
        <StyledCard>
            <HistorySectionTitle>{t("screen.history.diagnostic.exercise")}</HistorySectionTitle>
            <Divider />
            <div style={{ display: "flex" }}>
                <CurrentValueTable
                    headerItems={currentValueHeaderItems}
                    rows={[exerciseHeartRateCurrentValueRow, exercisePercentCurrentValueRow]}
                />
                <VerticalTableDivider />
                <HistoryValuesTable
                    headerItems={exerciseValueHeaderItems}
                    rows={[exerciseHeartRateHistoryValuesRow, exercisePercentHistoryValuesRow]}
                />
            </div>
            <RecommendationHistory
                title={"screen.history.recommendation"}
                recommendation={exerciseRecommendation}
                recommendationHistory={exerciseRecommendationHistory}
            />
        </StyledCard>
    );

    const ultrasoundItems: { title: IMessageIDS; category: string }[] = [
        {
            title: "screen.history.diagnostic.ultrasound.heart",
            category: HEART_CATEGORY,
        },
        {
            title: "screen.history.diagnostic.ultrasound.thyroid",
            category: THYROID_CATEGORY,
        },
        {
            title: "screen.history.diagnostic.ultrasound.carotis",
            category: CAROTIS_CATEGORY,
        },
        {
            title: "screen.history.diagnostic.ultrasound.stomach",
            category: STOMACH_CATEGORY,
        },
    ];

    const ultrasoundCards = (
        <>
            {ultrasoundItems.map((item, index) => {
                const { currentRecommendation, currentRecommendationHistory } = getRecommendations(
                    item.category,
                    recommendations,
                );

                return currentRecommendation.text ||
                    currentRecommendationHistory.recommendations.filter((recommendation) => !!recommendation?.text)
                        .length > 0 ? (
                    <StyledCard key={index}>
                        <RecommendationHistory
                            title={item.title}
                            recommendation={currentRecommendation}
                            recommendationHistory={currentRecommendationHistory}
                        />
                    </StyledCard>
                ) : null;
            })}
        </>
    );

    return (
        <div>
            <Title>{t("screen.history.diagnostic")}</Title>
            {ecgCard}
            {relaxationCard}
            {spirometricCard}
            {exerciseCard}
            {ultrasoundCards}
        </div>
    );
};
