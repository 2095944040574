import { Accordion, AccordionDetails, AccordionSummary, Divider, styled } from "@mui/material";
import * as React from "react";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/ic_chevron_down.svg";
import { IMessageIDS, t } from "../../i18n/util";
import { SavedText } from "../../network/APITypes";
import { Colors } from "../util/Colors";
import { formatDate } from "../util/helpers";
import { HistorySectionTitle } from "./HistorySectionTitle";
import { VerticalTableDivider } from "./VerticalTableDivider";

export const RecommendationHistoryAccordion = styled(Accordion)({
    backgroundColor: "transparent",

    "::before": {
        backgroundColor: "transparent",
    },

    "& .MuiPaper-elevation": {
        backgroundColor: "transparent",
    },
});

export const RecommendationHistoryAccordionSummary = styled(AccordionSummary)({
    backgroundColor: Colors.LIGHT_BACKGROUND,
    borderRadius: 50,
});

export const RecommendationHistory = ({
    title,
    recommendation,
    recommendationHistory,
}: {
    title?: IMessageIDS;
    recommendation?: { text?: string; date?: string };
    recommendationHistory: {
        firstYear: string;
        lastYear: string;
        recommendations: (SavedText | undefined)[];
    };
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClickAccordion = (event: React.MouseEvent<HTMLElement>) => {
        setIsOpen(!isOpen);
    };

    if (!recommendation?.text && recommendationHistory.recommendations.length === 0) {
        return null;
    }

    return (
        <>
            {title && <HistorySectionTitle>{t(title)}</HistorySectionTitle>}
            {recommendation?.text && (
                <>
                    <Divider />
                    <HistorySectionTitle>{formatDate(recommendation.date)}</HistorySectionTitle>
                    <Divider />
                    <div style={{ fontWeight: 400, padding: 16, whiteSpace: "pre-wrap" }}>{recommendation.text}</div>
                </>
            )}
            {recommendationHistory.recommendations.length > 0 && (
                <>
                    <Divider />
                    <RecommendationHistoryAccordion defaultExpanded disableGutters elevation={0}>
                        <RecommendationHistoryAccordionSummary
                            style={{ padding: 0, paddingRight: 16, height: 45 }}
                            expandIcon={<ChevronDownIcon />}
                        >
                            <Divider />
                            <HistorySectionTitle style={{ cursor: "pointer" }} onClick={handleClickAccordion}>
                                {recommendationHistory.firstYear === recommendationHistory.lastYear
                                    ? recommendationHistory.firstYear
                                    : `${recommendationHistory.firstYear} - ${recommendationHistory.lastYear}`}
                            </HistorySectionTitle>
                        </RecommendationHistoryAccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                            <Divider />
                            {recommendationHistory.recommendations.map((recommendationItem, index) => (
                                <div style={{ fontWeight: 400 }} key={index}>
                                    <>
                                        <div style={{ display: "flex", padding: 16 }}>
                                            <div>{formatDate(recommendationItem?.checkupDate)}</div>
                                            <VerticalTableDivider
                                                style={{ width: 1, marginLeft: 16, marginRight: 16 }}
                                            />
                                            <div style={{ whiteSpace: "pre-wrap" }}>{recommendationItem?.text}</div>
                                        </div>
                                        {index !== recommendationHistory.recommendations.length - 1 && <Divider />}
                                    </>
                                </div>
                            ))}
                        </AccordionDetails>
                    </RecommendationHistoryAccordion>
                </>
            )}
        </>
    );
};
