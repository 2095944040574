import { styled } from "@mui/styles";
import { Field } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { CustomInputField } from "../ui/CustomInputField";

type IProps = {
    disabled?: boolean;
};

const Container = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 16,
});

const fields = [
    {
        name: "spirometric.FVC",
        label: t("screen.patientInformation.field.spirometric.fvcLiter"),
        adornmentText: t("common.liter"),
        type: "number",
    },
    {
        name: "spirometric.FVCPercent",
        label: t("screen.patientInformation.field.spirometric.fvcPercent"),
        adornmentText: t("common.percent"),
        type: "number",
    },
    {
        name: "spirometric.FeV1FVC",
        label: t("screen.patientInformation.field.spirometric.feV1"),
        adornmentText: t("common.percent"),
        type: "number",
    },
];

export const Spirometric = ({ disabled }: IProps) => (
    <Container>
        {fields.map((field) => (
            <Field
                key={field.name}
                label={field.label}
                name={field.name}
                type={field.type}
                disabled={disabled}
                component={CustomInputField}
                adornmentText={field.adornmentText}
                min={field.type === "number" ? 0 : undefined}
            />
        ))}
    </Container>
);
