import { Divider } from "@mui/material";
import { Colors } from "../util/Colors";

type IProps = {
    style?: React.CSSProperties;
};

export const VerticalTableDivider = ({ style }: IProps) => (
    <Divider orientation="vertical" style={{ height: "auto", width: 4, backgroundColor: Colors.GREY_200, ...style }} />
);
