import { Divider, IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import { Field, FieldArray, getIn, useFormikContext } from "formik";
import * as React from "react";
import { useParams } from "react-router";
import { ReactComponent as AddIcon } from "../../assets/icons/ic_add.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/ic_trash.svg";
import { IMessageIDS, t } from "../../i18n/util";
import { API } from "../../network/API";
import { Attachment, BloodpanelHL7File } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { patientStore } from "../../stores/PatientStore";
import { CustomInputField } from "../ui/CustomInputField";
import { CustomSelect } from "../ui/CustomSelect";
import { DocumentUpload } from "../ui/DocumentUpload";
import { FieldRowHeader } from "../ui/FieldRowHeader";
import { FileLine } from "../ui/FileLine";
import { formatDate, getInputSiteOptions } from "../util/helpers";
import { initialBloodpanelItem } from "../util/patientSiteConfig";

type IProps = {
    disabled?: boolean;
};

const MAX_UPLOAD_SIZE_MB = 2;
const MAX_UPLOAD_SIZE = MAX_UPLOAD_SIZE_MB * 1024 * 1024;

// 53px is the width of the trash icon + margin, auto does not work here
const gridStyles = { display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 53px", gridGap: 16 };

const SectionContainer = styled("div")({
    padding: 16,
    fontWeight: 400,
});

const StyledIconButton = styled(IconButton)({
    border: "1px solid #EDEDED",
    borderRadius: 100,
    width: 64,
});

const referenceTypeOptions = [
    { value: "span", label: "screen.patientInformation.field.bloodpanel.furtherValues.range" },
    { value: "greaterThan", label: "screen.patientInformation.field.bloodpanel.furtherValues.greaterThan" },
    { value: "lowerThan", label: "screen.patientInformation.field.bloodpanel.furtherValues.lowerThan" },
];

const headers: { name: string; title?: IMessageIDS }[] = [
    {
        name: "checkup",
        title: "screen.patientInformation.field.bloodpanel.furtherValues.checkup",
    },
    {
        name: "unit",
        title: "screen.patientInformation.field.bloodpanel.furtherValues.unit",
    },
    {
        name: "referenceType",
        title: "screen.patientInformation.field.bloodpanel.furtherValues.rangeLabel",
    },
    {
        name: "referenceMin",
    },
    {
        name: "referenceMax",
    },
    {
        name: "value",
        title: "screen.patientInformation.field.bloodpanel.furtherValues.currentValueLabel",
    },
    {
        name: "icon",
    },
];

const SubSectionTitle = ({ title }: { title: string }) => (
    <div style={{ backgroundColor: "#FDFDFD" }}>
        <Divider />
        <div style={{ margin: "12px 16px" }}>{title}</div>
        <Divider />
    </div>
);

export const Bloodpanel = ({ disabled }: IProps) => {
    const { checkupId } = useParams<{ checkupId?: string }>();
    const { values, setFieldValue } = useFormikContext<any>();

    const [hl7Files, setHl7Files] = React.useState<BloodpanelHL7File[]>(
        patientStore.checkup?.bloodpanel?.filesHL7 ?? [],
    );

    const [attachments, setAttachments] = React.useState<Attachment[]>(
        patientStore.checkup?.bloodpanel?.attachments ?? [],
    );

    const bloodpanelValues = getInputSiteOptions(values)?.bloodpanel?.values;

    const loadCheckup = React.useCallback(async () => {
        if (checkupId) {
            generalStore.isLoading = true;
            try {
                const checkup = await API.getCheckup({ checkupId });
                patientStore.checkup = checkup;

                setHl7Files(checkup.bloodpanel?.filesHL7 ?? []);
                setAttachments(checkup.bloodpanel?.attachments ?? []);
            } catch (error) {
                generalStore.setError(t("error.load.bloodpanelDocuments"), error);
            } finally {
                generalStore.isLoading = false;
            }
        }
    }, [checkupId]);

    const handleUploadHl7 = async (file: File) => {
        if (!checkupId) {
            return;
        }

        try {
            await API.uploadHl7({ file, checkupId });
            loadCheckup();
        } catch (error) {
            generalStore.setError(t("error.upload"), error);
        }
    };

    const handleUploadAttachments = async (file: File) => {
        if (!checkupId) {
            return;
        }

        try {
            await API.uploadAttachments({ file, checkupId });
            loadCheckup();
        } catch (error) {
            generalStore.setError(t("error.upload"), error);
        }
    };

    const handleClickDeleteHl7File = async (hl7File: BloodpanelHL7File) => {
        if (!checkupId) {
            return;
        }

        try {
            await API.deleteHl7File({ hl7File, checkupId });
            loadCheckup();
        } catch (error) {
            generalStore.setError(t("error.delete"), error);
        }
    };

    const handleClickDeleteAttachment = async (attachment: Attachment) => {
        if (!checkupId) {
            return;
        }

        try {
            await API.deleteAttachment({ attachment, checkupId });
            loadCheckup();
        } catch (error) {
            generalStore.setError(t("error.delete"), error);
        }
    };

    const handleSuccess = () => {
        // TODO
    };

    return (
        <div>
            <SectionContainer style={{ padding: 0 }}>
                {hl7Files.map((hl7File, index) => (
                    <React.Fragment key={index}>
                        <FileLine
                            fileName={hl7File.filename}
                            onDelete={() => handleClickDeleteHl7File(hl7File)}
                            date={formatDate(hl7File.date)}
                            name={`${hl7File.firstName} ${hl7File.lastName}`}
                            totalValues={hl7File.nrValues}
                            disabled={disabled}
                        />
                        {index !== hl7Files.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
                {hl7Files.length > 0 && <Divider />}
                <div style={{ padding: 16 }}>
                    <DocumentUpload
                        acceptedFilesFormat=".hl7"
                        fileTypesLabel="screen.patientInformation.field.bloodpanel.upload.fileFormatHl7"
                        uploadFile={handleUploadHl7}
                        text={t("screen.patientInformation.field.bloodpanel.upload.hl7")}
                        maxFileSize={MAX_UPLOAD_SIZE}
                        onSuccess={handleSuccess}
                        disabled={disabled}
                    />
                </div>
            </SectionContainer>
            <SubSectionTitle title={t("screen.patientInformation.field.bloodpanel.externalResults")} />
            <SectionContainer style={{ padding: 0 }}>
                {attachments.map((attachment, index) => (
                    <React.Fragment key={index}>
                        <FileLine
                            fileName={attachment.filename}
                            fileSize={attachment.size}
                            onDelete={() => handleClickDeleteAttachment(attachment)}
                            disabled={disabled}
                            deleteIconStyle={{ marginRight: 13 }}
                        />
                        {index !== attachments.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
                {attachments.length > 0 && <Divider />}
                <div style={{ padding: 16 }}>
                    <DocumentUpload
                        acceptedFilesFormat="application/pdf"
                        fileTypesLabel="screen.patientInformation.field.bloodpanel.upload.fileFormatPdf"
                        uploadFile={handleUploadAttachments}
                        text={t("screen.patientInformation.field.bloodpanel.upload.attachments")}
                        maxFileSize={MAX_UPLOAD_SIZE}
                        onSuccess={handleSuccess}
                        disabled={disabled}
                    />
                </div>
            </SectionContainer>
            <SubSectionTitle title={t("screen.patientInformation.field.bloodpanel.furtherValues")} />
            <SectionContainer>
                <div>
                    <FieldRowHeader style={{ ...gridStyles, marginBottom: 16 }}>
                        {headers.map((header) => (
                            <div key={header.name}>{header.title && t(header.title)}</div>
                        ))}
                    </FieldRowHeader>
                    <FieldArray
                        name="bloodpanel.values"
                        render={(arrayHelpers) => (
                            <div>
                                {bloodpanelValues.map((row, index) => (
                                    <div key={index} style={{ ...gridStyles, marginBottom: 16 }}>
                                        <Field
                                            name={`bloodpanel.values[${index}].name`}
                                            label={t("screen.patientInformation.field.bloodpanel.furtherValues.name")}
                                            component={CustomInputField}
                                            disabled={disabled}
                                            paddingTop={8}
                                            hideError
                                            ignoreHeaderLabel
                                        />
                                        <Field
                                            name={`bloodpanel.values[${index}].unit`}
                                            label={t("screen.patientInformation.field.bloodpanel.furtherValues.title")}
                                            component={CustomInputField}
                                            ignoreHeaderLabel
                                            disabled={disabled}
                                            paddingTop={8}
                                            hideError
                                        />
                                        <Field
                                            name={`bloodpanel.values[${index}].referenceType`}
                                            component={CustomSelect}
                                            options={referenceTypeOptions}
                                            onChange={(event) => {
                                                const value = event.target.value;

                                                if (value === "lowerThan") {
                                                    setFieldValue(`bloodpanel.values[${index}].referenceMin`, "");
                                                } else if (value === "greaterThan") {
                                                    setFieldValue(`bloodpanel.values[${index}].referenceMax`, "");
                                                }
                                            }}
                                            disabled={disabled}
                                            hideError
                                        />
                                        <Field
                                            name={`bloodpanel.values[${index}].referenceMin`}
                                            label={t(
                                                "screen.patientInformation.field.bloodpanel.furtherValues.minValue",
                                            )}
                                            type="number"
                                            component={CustomInputField}
                                            disabled={
                                                disabled ||
                                                getIn(values, `bloodpanel.values[${index}].referenceType`) ===
                                                    "lowerThan"
                                            }
                                            paddingTop={8}
                                            ignoreHeaderLabel
                                            hideError
                                        />
                                        <Field
                                            name={`bloodpanel.values[${index}].referenceMax`}
                                            label={t(
                                                "screen.patientInformation.field.bloodpanel.furtherValues.maxValue",
                                            )}
                                            type="number"
                                            component={CustomInputField}
                                            disabled={
                                                disabled ||
                                                getIn(values, `bloodpanel.values[${index}].referenceType`) ===
                                                    "greaterThan"
                                            }
                                            paddingTop={8}
                                            ignoreHeaderLabel
                                            hideError
                                        />
                                        <Field
                                            name={`bloodpanel.values[${index}].value`}
                                            label={t(
                                                "screen.patientInformation.field.bloodpanel.furtherValues.currentValue",
                                            )}
                                            type="number"
                                            component={CustomInputField}
                                            disabled={disabled}
                                            paddingTop={8}
                                            ignoreHeaderLabel
                                            hideError
                                        />
                                        <IconButton
                                            onClick={() => arrayHelpers.remove(index)}
                                            style={{ marginRight: 13 }}
                                            disabled={disabled}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ))}
                                <Divider style={{ marginTop: 32 }} />
                                <SectionContainer style={{ textAlign: "right" }}>
                                    <StyledIconButton
                                        disabled={disabled}
                                        onClick={() => arrayHelpers.push(initialBloodpanelItem)}
                                    >
                                        <AddIcon />
                                    </StyledIconButton>
                                </SectionContainer>
                            </div>
                        )}
                    />
                </div>
            </SectionContainer>
        </div>
    );
};
