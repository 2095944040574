import { Field } from "formik";
import { t } from "../../i18n/util";
import { CustomSelect } from "../ui/CustomSelect";

type IProps = {
    disabled?: boolean;
};

const options = [
    { value: "vorsorgePlus", label: "screen.patientInformation.field.productType.plus" },
    { value: "vorsorgeGold", label: "screen.patientInformation.field.productType.gold" },
    { value: "vorsorgePlatin", label: "screen.patientInformation.field.productType.platin" },
    { value: "vorsorgeDiamond", label: "screen.patientInformation.field.productType.diamond" },
    {
        value: "besserLebenCheckWrStaedtische",
        label: "screen.patientInformation.field.productType.wienerStaedtische",
    },
    {
        value: "donauAktivCheckDonauversicherung",
        label: "screen.patientInformation.field.productType.donauversicherung",
    },
    { value: "uniquaVitalcheck", label: "screen.patientInformation.field.productType.uniqaVital" },
    { value: "uniquaVorsorgeprogramm", label: "screen.patientInformation.field.productType.uniqaPrecaution" },
    { value: "vorsorgeCheck", label: "screen.patientInformation.field.productType.vorsorgeCheck" },
    { value: "vorsorgePlusBusiness", label: "screen.patientInformation.field.productType.vorsorgePlusBusiness" },
    { value: "vorsorgeGoldBusiness", label: "screen.patientInformation.field.productType.vorsorgeGoldBusiness" },
    { value: "vorsorgePlatinBusiness", label: "screen.patientInformation.field.productType.vorsorgePlatinBusiness" },
    {
        value: "vorsorgeDiamondBusiness",
        label: "screen.patientInformation.field.productType.vorsorgeDiamondBusiness",
    },
];

export const products = options.reduce((previousValue, currentValue) => {
    const { value, label } = currentValue;
    return { ...previousValue, [value]: label };
}, {});

export const ProductSelection = ({ disabled }: IProps) => (
    <Field
        key="product"
        component={CustomSelect}
        label={t("screen.patientInformation.field.productType.label")}
        name="productType"
        options={options}
        disabled={disabled}
    />
);
