import { Divider } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { compact } from "lodash";
import * as React from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { CheckupReport } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { DiagnosticHistory } from "../../ui/DiagnosticHistory";
import { DocumentationHistory } from "../../ui/DocumentationHistory";
import { LaboratoryResultsHistory } from "../../ui/LaboratoryResultsHistory";
import { LifestyleParametersHistory } from "../../ui/LifestyleParametersHistory";
import { Colors } from "../../util/Colors";
import { ImageLogo } from "../../util/Images";
import { sanitizeCheckupReport } from "../../util/ValueDisplayHelpers";

export const HEART_CATEGORY = "ultrasound.heart";
export const THYROID_CATEGORY = "ultrasound.thyroid";
export const CAROTIS_CATEGORY = "ultrasound.carotis";
export const STOMACH_CATEGORY = "ultrasound.stomach";

const SiteContainer = styled("div")({
    display: "table",
    backgroundColor: Colors.BACKGROUND,
    flexGrow: 1,
});

const Title = styled("div")({
    fontSize: 40,
    lineHeight: "normal",
    fontWeight: 700,
    marginBottom: 4,
});

const getMonthYearDateFormat = (date?: Date | string) => dayjs(date).format("MMM YYYY");

export const HistorySite = () => {
    const [checkupReport, setCheckupReport] = React.useState<CheckupReport | null>(null);

    const { checkupId } = useParams<{ checkupId?: string }>();

    React.useEffect(() => {
        const loadCheckups = async () => {
            if (checkupId) {
                generalStore.isLoading = true;
                try {
                    if (authStore.isDoctor) {
                        const checkupReportResponse = await API.getDoctorCheckupReport({ checkupId });
                        setCheckupReport(sanitizeCheckupReport(checkupReportResponse));
                    } else if (authStore.isPatient) {
                        const checkupReportResponse = await API.getPatientCheckupReport({ checkupId });
                        setCheckupReport(sanitizeCheckupReport(checkupReportResponse));
                    }
                } catch (error) {
                    generalStore.setError(t("error.loadCheckups"));
                } finally {
                    generalStore.isLoading = false;
                }
            }
        };

        loadCheckups();
    }, [checkupId]);

    const isUltrasoundTextCategory = (savedText) =>
        savedText.category === HEART_CATEGORY ||
        savedText.category === THYROID_CATEGORY ||
        savedText.category === CAROTIS_CATEGORY ||
        savedText.category === STOMACH_CATEGORY;

    const ultrasoundHistory = compact(
        checkupReport?.recommendations?.history?.map((recommendation) => {
            const ultrasoundTexts = recommendation.savedTexts?.filter(
                (savedText) => isUltrasoundTextCategory(savedText) && !!savedText.text,
            );

            if (ultrasoundTexts && ultrasoundTexts.length > 0) {
                return ultrasoundTexts;
            }

            return false;
        }),
    );

    const showUltrasound =
        (ultrasoundHistory && ultrasoundHistory.length > 0) ||
        !!checkupReport?.recommendations?.latest?.savedTexts?.find(isUltrasoundTextCategory)?.text;

    const bloodpanelReportHistory = checkupReport?.bloodpanels?.history?.filter(
        (bloodpanelReport) => bloodpanelReport.values?.length && bloodpanelReport.values?.length > 0,
    );

    const showBloodpanels =
        (checkupReport?.bloodpanels?.latest?.values?.length &&
            checkupReport?.bloodpanels?.latest?.values?.length > 0) ||
        (bloodpanelReportHistory && bloodpanelReportHistory.length > 0);

    const stoolSampleReportHistory = checkupReport?.stoolSample?.history?.filter(
        (stoolSampleReport) => stoolSampleReport.value && stoolSampleReport.value !== "notSpecified",
    );

    const showStoolSample =
        (checkupReport?.stoolSample?.latest?.value && checkupReport?.stoolSample?.latest?.value !== "notSpecified") ||
        (stoolSampleReportHistory && stoolSampleReportHistory.length > 0);

    const lifestyleReportHistory = checkupReport?.lifestyle?.history;
    const biaReportHistory = checkupReport?.bia?.history;

    const showBiaAndLifestyle = !!(
        (checkupReport?.lifestyle?.latest?.bloodpressure?.systole &&
            checkupReport?.lifestyle?.latest?.bloodpressure?.diastole) ||
        checkupReport?.lifestyle?.latest?.abdominalGirth ||
        checkupReport?.lifestyle?.latest?.bloodpressureDropdown !== "notSpecified" ||
        checkupReport?.lifestyle?.latest?.exerciseDropdown !== "notSpecified" ||
        checkupReport?.lifestyle?.latest?.smokerDropdown ||
        (checkupReport?.lifestyle?.latest?.height && checkupReport?.lifestyle?.latest?.weight) ||
        checkupReport?.bia?.latest?.muscle ||
        checkupReport?.bia?.latest?.fat ||
        checkupReport?.bia?.latest?.fatPercent ||
        (lifestyleReportHistory && lifestyleReportHistory?.length > 0) ||
        (biaReportHistory && biaReportHistory?.length > 0)
    );

    const ecgReportHistory = checkupReport?.ecg?.history?.filter(
        (ecgReport) => ecgReport.assessment && ecgReport.value && ecgReport.assessment !== "notSpecified",
    );

    const showEcg =
        (checkupReport?.ecg?.latest?.assessment &&
            checkupReport?.ecg?.latest.value &&
            checkupReport?.ecg?.latest?.assessment !== "notSpecified") ||
        (ecgReportHistory && ecgReportHistory.length > 0);

    const relaxationReportHistory = checkupReport?.relaxation?.history?.filter(
        (relaxationReport) => relaxationReport.value && relaxationReport.value !== "notSpecified",
    );

    const showRelaxation =
        (checkupReport?.relaxation?.latest?.value && checkupReport?.relaxation?.latest?.value !== "notSpecified") ||
        (relaxationReportHistory && relaxationReportHistory.length > 0);

    const spirometricReportHistory = checkupReport?.spirometric?.history?.filter((spirometricReport) => {
        if (spirometricReport.FVC || spirometricReport.FVCPercent || spirometricReport.FeV1FVC) {
            return true;
        }
        return spirometricReport.assessment && spirometricReport.assessment !== "notSpecified";
    });

    const showSpirometric =
        !!checkupReport?.spirometric?.latest?.FVC ||
        !!checkupReport?.spirometric?.latest?.FVCPercent ||
        !!checkupReport?.spirometric?.latest?.FeV1FVC ||
        (checkupReport?.spirometric?.latest?.assessment &&
            checkupReport?.spirometric?.latest?.assessment !== "notSpecified") ||
        (spirometricReportHistory && spirometricReportHistory.length > 0);

    const exerciseReportHistory = checkupReport?.exercise?.history?.filter(
        (exerciseReport) => exerciseReport.percent || exerciseReport.heartRate,
    );
    const showExercise =
        !!checkupReport?.exercise?.latest?.percent ||
        !!checkupReport?.exercise?.latest?.heartRate ||
        (exerciseReportHistory && exerciseReportHistory.length > 0);

    return (
        <SiteContainer>
            <div style={{ display: "flex", gap: 32, alignItems: "center", padding: "40px 108px" }}>
                <ImageLogo style={{ height: 70 }} />
                <div>
                    <Title>{t("screen.history.title")}</Title>
                    <div>
                        {getMonthYearDateFormat(checkupReport?.date)} -{" "}
                        {getMonthYearDateFormat(checkupReport?.historyDateFrom)}
                    </div>
                </div>
            </div>
            <Divider />
            <div style={{ padding: "32px 108px", minWidth: "min-content" }}>
                {(checkupReport?.lifestyle || checkupReport?.bia) && showBiaAndLifestyle && (
                    <LifestyleParametersHistory
                        lifestyleReport={checkupReport.lifestyle}
                        biaReport={checkupReport.bia}
                        patientInformation={checkupReport.patientInformation}
                    />
                )}
                {checkupReport && (showBloodpanels || showStoolSample) && (
                    <LaboratoryResultsHistory
                        bloodpanels={checkupReport.bloodpanels}
                        stoolSample={checkupReport.stoolSample}
                        recommendations={checkupReport.recommendations}
                        patientInformation={checkupReport.patientInformation}
                        showBloodpanels={showBloodpanels}
                        showStoolSample={showStoolSample}
                    />
                )}
                {checkupReport && (showEcg || showRelaxation || showSpirometric || showExercise || showUltrasound) && (
                    <DiagnosticHistory
                        ecg={checkupReport.ecg}
                        relaxation={checkupReport.relaxation}
                        spirometric={checkupReport.spirometric}
                        exercise={checkupReport.exercise}
                        recommendations={checkupReport.recommendations}
                        checkupDate={checkupReport.date}
                        showEcg={showEcg}
                        showRelaxation={showRelaxation}
                        showSpirometric={showSpirometric}
                        showExercise={showExercise}
                    />
                )}
                <DocumentationHistory recommendations={checkupReport?.recommendations} />
            </div>
        </SiteContainer>
    );
};
