import { IconButton } from "@mui/material";
import { Field, FormikErrors, getIn, useFormikContext } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { IFormFieldMessageIDS } from "../../types";
import { CustomInputField } from "./CustomInputField";

type IFieldsMap = {
    fields: IFormFieldMessageIDS[];
    disabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
    hideError?: boolean;
};

export const FieldRow = ({ fields, disabled, style, className, hideError }: IFieldsMap) => {
    const { errors, touched, values } = useFormikContext<any>();

    return (
        <div style={style} className={className}>
            {fields.map((field) => {
                return field.type === "button" ? (
                    <IconButton key={field.name} disabled={disabled} onClick={field.onClick} style={field.style}>
                        <field.icon />
                    </IconButton>
                ) : (
                    <Field
                        key={field.name}
                        component={field.component ?? CustomInputField}
                        label={field.label && t(field.label)}
                        name={field.name}
                        type={field.type ?? "input"}
                        errorMessage={getIn(errors, field.name)}
                        isTouched={getIn(touched, field.name)}
                        disabled={disabled || field.disabled}
                        onChange={field.onChange}
                        options={field.options}
                        ignoreHeaderLabel={field.ignoreHeaderLabel}
                        paddingTop={field.paddingTop}
                        adornmentText={field.adornmentText}
                        required={field.required}
                        hideError={hideError}
                        disableFuture={field.disableFuture}
                        min={field.type === "number" ? 0 : undefined}
                    />
                );
            })}
        </div>
    );
};
