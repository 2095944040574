import * as React from "react";
import { styled } from "@mui/styles";
import { BlackDot, BLACK_DOT_WIDTH } from "../ui/Dots";

const GRADIENT_BAR_WIDTH = 140;

export const getExercisePercentDotPosition = (value: number) => {
    return Math.min(Math.max(value, 0), GRADIENT_BAR_WIDTH - BLACK_DOT_WIDTH);
};

const GradientBar = styled("div")({
    // Be aware if the gradient changes, getExercisePercentDotPosition has to be changed too
    background: `
        linear-gradient(
            90deg, 
            rgba(255, 98, 10, 0.88) 0px, rgba(255, 98, 10, 0.88) 75px,
            rgba(255, 166, 33, 0.88) 85px, rgba(255, 166, 33, 0.88) 100px,
            rgba(0, 202, 20, 0.88) 110px, rgba(0, 202, 20, 0.88) 140px
        )
     `,
    height: 12,
    minWidth: GRADIENT_BAR_WIDTH,
    display: "flex",
    alignItems: "center",
    position: "relative",
});

export const ExerciseValueDisplay = ({ value }: { value: number }) => {
    return (
        <GradientBar>
            <BlackDot
                style={{
                    position: "absolute",
                    left: getExercisePercentDotPosition(value),
                }}
            />
        </GradientBar>
    );
};
