export const Routes = {
    ROOT: "/",
    // AUTH: {
    // ROOT: "/auth",
    // FORGOT_PASSWORD: "/forgot-password",
    // RESET_PASSWORD: "/set-new-password",
    // },
    DOCTOR: {
        OVERVIEW: "/overview",
        CHECKUP: "/checkup/:checkupId",
        HISTORY: "/history/:checkupId",
    },
    PATIENT: {
        WELCOME: "/welcome",
        HISTORY: "/history/:checkupId",
    },
    CHECKUP_REPORT: "/report/:checkupId",
};
