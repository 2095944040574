import { styled } from "@mui/material";
import { IBreakpoints } from "../../types";
import { Colors } from "../util/Colors";
import { BlackDot, BLACK_DOT_WIDTH } from "./Dots";

const GRADIENT_BAR_WIDTH = 140;

const GradientBar = styled("div")({
    background: `linear-gradient(90deg, rgba(0, 202, 20, 0.88) 0%, rgba(0, 202, 20, 0.88) 26.56%, rgba(255, 166, 33, 0.88) 35.42%, rgba(255, 166, 33, 0.88) 65.1%, rgba(255, 98, 10, 0.88) 75%, rgba(255, 98, 10, 0.88) 100%)`,
    height: 12,
    minWidth: GRADIENT_BAR_WIDTH,
    display: "flex",
    alignItems: "center",
    position: "relative",
});

const ValueRange = styled("div")({
    fontSize: 16,
    fontWeight: 400,
    color: Colors.TEXT_LIGHT,
    marginRight: 16,
    minWidth: 120,
});

const getDotPosition = (breakpoints: IBreakpoints, value: number) => {
    if (value < breakpoints.yellow) {
        return 16.6;
    }
    if (value < breakpoints.red) {
        return 50;
    }
    return 83.3;
};

const getExerciseDropdownDotPosition = (exerciseDropdown: string) => {
    if (exerciseDropdown === "none") {
        // Middle of the 3rd bar in the gradient. Its a percentage, the width gets calculated in the Gradient component
        return 83.3;
    }
    if (exerciseDropdown === "lessThan2h5") {
        // Middle of the 2nd bar in the gradient
        return 50;
    }
    if (exerciseDropdown === "moreThan2h5") {
        // Middle of the 1st bar in the gradient
        return 16.6;
    }
    return 0;
};

const Gradient = ({
    valueRange,
    dotPosition,
    hideRange,
    valueAlignment = "center",
}: {
    valueRange?: string;
    dotPosition: number;
    hideRange?: boolean;
    valueAlignment?: "left" | "center" | "right";
}) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {!hideRange && <ValueRange style={{ textAlign: valueAlignment }}>{valueRange}</ValueRange>}
            <GradientBar>
                <BlackDot
                    style={{
                        position: "absolute",
                        left: dotPosition * ((GRADIENT_BAR_WIDTH - BLACK_DOT_WIDTH) / 100),
                    }}
                />
            </GradientBar>
        </div>
    );
};

export const ValueGradient = ({
    breakpoints,
    value,
    hideRange,
    exerciseDropdown,
    valueAlignment = "center",
}: {
    breakpoints?: IBreakpoints;
    value?: number;
    hideRange?: boolean;
    exerciseDropdown?: string;
    valueAlignment?: "left" | "center" | "right";
}) => {
    if ((value && breakpoints) || exerciseDropdown) {
        return (
            <>
                {breakpoints && value && (
                    <Gradient
                        valueAlignment={valueAlignment}
                        valueRange={`${breakpoints.green} - ${breakpoints.red - 1}`}
                        hideRange={hideRange}
                        dotPosition={getDotPosition(breakpoints, value)}
                    />
                )}
                {exerciseDropdown && exerciseDropdown !== "notSpecified" && (
                    <Gradient
                        valueAlignment={valueAlignment}
                        dotPosition={getExerciseDropdownDotPosition(exerciseDropdown)}
                        hideRange={hideRange}
                    />
                )}
            </>
        );
    }
    return null;
};
