import { Field, useFormikContext } from "formik";
import { values } from "lodash";
import * as React from "react";
import { t } from "../../i18n/util";
import { TemplateTexts } from "../../network/APITypes";
import { CustomSelect } from "../ui/CustomSelect";
import { CustomTextField } from "../ui/CustomTextField";
import { getRecommendationSiteOptions } from "../util/helpers";

type IProps = {
    disabled?: boolean;
};

const relaxationOptions = [
    {
        value: "notSpecified",
        label: "common.notSpecified",
    },
    {
        value: "sufficient",
        label: "screen.patientInformation.field.relaxation.sufficient",
    },
    {
        value: "insufficient",
        label: "screen.patientInformation.field.relaxation.insufficient",
    },
];

export const RelaxationRecommendation = ({ disabled }: IProps) => {
    const { values, setFieldValue } = useFormikContext<any>();

    const handleChangeRelaxation = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const templateTexts = getRecommendationSiteOptions(values).relaxation.templateTexts;

        const templateText =
            templateTexts?.find((item) => item.targetValue === `relaxation.${event.target.value}`)?.templateText ?? "";

        setFieldValue("textInput", templateText);
    };

    return (
        <>
            <Field
                component={CustomSelect}
                onChange={handleChangeRelaxation}
                label={t("screen.patientInformation.field.relaxation.dropdownLabel")}
                name="relaxation"
                type="input"
                disabled={disabled}
                options={relaxationOptions}
                style={{ marginBottom: 16 }}
            />
            <Field name="textInput" component={CustomTextField} minRows={3} disabled={disabled} />
        </>
    );
};
