import { authStore } from "../stores/AuthStore";
import { generalStore } from "../stores/GeneralStore";

export type IAPITarget = {
    url: string;
    method?: "GET" | "POST" | "DELETE" | "PUT" | "PATCH";
    body?: object | string;
    queryParameters?: object;
    mockResponseJSON?: () => object;
    headers?: object;
};

export class APIClientStatusCodeError {
    response: any;
    statusCode: number;

    constructor(response: any, statusCode: number) {
        this.statusCode = statusCode;
        this.response = response;
    }
}

export const getAuthHeaders = () => ({
    Authorization: `Bearer ${authStore.credentials && authStore.credentials.access_token}`,
});

export const urljoin = (base?: string, url?: string) => {
    // trim trailing /
    let left = base ?? "";
    if (left.length > 0 && left[left.length - 1] === "/") {
        left = left.slice(0, -1);
    }

    // trim leading /
    let right = url ?? "";
    if (right.length > 0 && right[0] === "/") {
        right = right.substr(1);
    }

    const separator = right.length > 0 && right[0] === "?" ? "" : "/";
    return left + separator + right;
};

export const sanitizeFilename = (name: string) => {
    return name.replace(/[/<>|?*:"\\]/g, "");
};

export const injectHeaders = (target: IAPITarget) => {
    const ret: any = {
        ...target.headers,
        "Content-Type": target.headers?.["Content-Type"] ?? "application/json",
        Accept: "application/json",
        "Accept-Language": generalStore.locale,
    };

    if (authStore.credentials?.access_token) {
        ret.Authorization = `Bearer ${authStore.credentials?.access_token}`;
    }

    return ret;
};
