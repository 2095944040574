import { Field, useFormikContext } from "formik";
import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { t } from "../../i18n/util";
import { TemplateTexts } from "../../network/APITypes";
import { CustomSelect } from "../ui/CustomSelect";
import { CustomTextField } from "../ui/CustomTextField";
import { getRecommendationSiteOptions } from "../util/helpers";

type IProps = {
    disabled?: boolean;
};

const assessmentOptions = [
    {
        value: "notSpecified",
        label: "common.notSpecified",
    },
    {
        value: "normal",
        label: "screen.patientInformation.field.spirometric.normal",
    },
    {
        value: "obstructive",
        label: "screen.patientInformation.field.spirometric.obstructive",
    },
    {
        value: "restrictive",
        label: "screen.patientInformation.field.spirometric.restrictive",
    },
];

const medicalConditionOptions = [
    {
        value: "notSpecified",
        label: "common.notSpecified",
    },
    {
        value: "smoker",
        label: "screen.patientInformation.field.spirometric.smoker",
    },
    {
        value: "asthmatic",
        label: "screen.patientInformation.field.spirometric.asthmatic",
    },
    {
        value: "smokerAsthmatic",
        label: "screen.patientInformation.field.spirometric.smokerAsthmatic",
    },
];

export const SpirometricRecommendation = ({ disabled }: IProps) => {
    const [assessmentText, setAssessmentText] = React.useState("");
    const [medicalConditionText, setMedicalConditionText] = React.useState("");
    const { values, setFieldValue } = useFormikContext<any>();

    const options = getRecommendationSiteOptions(values).spirometric;

    useDeepCompareEffect(() => {
        if (medicalConditionText || assessmentText) {
            setFieldValue(
                "textInput",
                `${assessmentText}${assessmentText && medicalConditionText ? "\n\n" : ""}${medicalConditionText}`,
            );
        }
    }, [assessmentText, medicalConditionText, setFieldValue]);

    const handleChangeAssessment = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const templateText =
            options?.templateTexts?.find((item) => item.targetValue === `spirometric.assessment.${event.target.value}`)
                ?.templateText ?? "";

        setAssessmentText(templateText);

        if (event.target.value === "notSpecified") {
            setFieldValue("textInput", "");
        }
        if (event.target.value !== "obstructive") {
            setFieldValue("medicalCondition", "");
            setMedicalConditionText("");
        }
    };

    const handleChangeMedicalCondition = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const templateText =
            options?.templateTexts?.find(
                (item) => item.targetValue === `spirometric.medicalCondition.${event.target.value}`,
            )?.templateText ?? "";

        setMedicalConditionText(templateText);
    };

    return (
        <>
            <Field
                component={CustomSelect}
                onChange={handleChangeAssessment}
                label={t("screen.patientInformation.field.spirometric.assessmentLabel")}
                name="assessment"
                type="input"
                disabled={disabled}
                options={assessmentOptions}
                style={{ marginBottom: 16 }}
            />
            <Field
                component={CustomSelect}
                onChange={handleChangeMedicalCondition}
                label={t("screen.patientInformation.field.spirometric.medicalConditionDropdown")}
                name="medicalCondition"
                type="input"
                disabled={options?.assessment !== "obstructive" || disabled}
                options={medicalConditionOptions}
                style={{ marginBottom: 16 }}
            />
            <Field name="textInput" component={CustomTextField} minRows={3} disabled={disabled} />
        </>
    );
};
