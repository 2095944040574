import styled from "styled-components";
import { Colors } from "../util/Colors";

const IconContainerWrapper = styled("div")({
    padding: 4,
    border: `1px solid ${Colors.GREY_200}`,
    borderRadius: 8,
    marginRight: 12,
});

export const StyledIconContainer = styled("div")({
    width: 32,
    height: 32,
    display: "flex",
});

export function IconContainer(props: { children: React.ReactNode }) {
    return (
        <IconContainerWrapper>
            <StyledIconContainer>{props.children}</StyledIconContainer>
        </IconContainerWrapper>
    );
}
