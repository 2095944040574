import dayjs from "dayjs";
import { FormikValues } from "formik";
import { flatMap } from "lodash";
import { t } from "../../i18n/util";
import { BloodpanelReportValue, RecommendationsReportHistory, SavedText } from "../../network/APITypes";
import { patientStore } from "../../stores/PatientStore";
import { ISectionName } from "../../types";

export const getRecommendationSiteOptions = (values: FormikValues) => {
    const checkupRecommendations = patientStore.checkupRecommendations;
    const results = checkupRecommendations?.lifestyle?.results;
    const smokerDropdown = results?.smokerDropdown;
    const exerciseDropdown = results?.exerciseDropdown;

    let smoker = "";
    let exercise = "";

    if (smokerDropdown === "smoker") {
        smoker = t("screen.patientInformation.field.lifestyleParameters.smokingStatus.smoker");
    } else if (smokerDropdown === "exSmoker") {
        smoker = t("screen.patientInformation.field.lifestyleParameters.smokingStatus.exSmoker");
    } else if (smokerDropdown === "nonSmoker") {
        smoker = t("screen.patientInformation.field.lifestyleParameters.smokingStatus.nonSmoker");
    } else if (smokerDropdown === "occasionalSmoker") {
        smoker = t("screen.patientInformation.field.lifestyleParameters.smokingStatus.occasionalSmoker");
    }

    if (exerciseDropdown === "lessThan2h5") {
        exercise = t("screen.patientInformation.field.lifestyleParameters.activity.lessThan2h5");
    } else if (exerciseDropdown === "moreThan2h5") {
        exercise = t("screen.patientInformation.field.lifestyleParameters.activity.moreThan2h5");
    } else if (exerciseDropdown === "none") {
        exercise = t("screen.patientInformation.field.lifestyleParameters.activity.noActivity");
    }

    return {
        bia: checkupRecommendations?.bia?.results,
        ecg: {
            templateTexts: checkupRecommendations?.ecg?.templateTexts,
        },
        exercise: {
            templateTexts: checkupRecommendations?.exercise?.templateTexts,
        },
        relaxation: {
            templateTexts: checkupRecommendations?.relaxation?.templateTexts,
        },
        spirometric: {
            templateTexts: checkupRecommendations?.spirometric?.templateTexts,
            assessment: values.assessment,
        },
        ultrasound: { ultrasound: checkupRecommendations?.ultrasound },
        lifestyle: {
            abdominalGirth: results?.abdominalGirth ? `${results?.abdominalGirth}${t("common.centimeter")}` : "",
            exercise,
            smoker,
        },
    };
};

export const getInputSiteOptions = (values: FormikValues) => {
    return {
        bloodpanel: {
            values: values.bloodpanel?.values ?? undefined,
        },
    };
};

export const formatDate = (date?: string | Date) => {
    if (date) {
        return dayjs(date).format("DD.MM.YYYY");
    }
    return "";
};

export const getRecommendationText = (category: string, recommendations?: SavedText[]) => {
    if (recommendations) {
        return recommendations.find((recommendation) => recommendation.category === category)?.text;
    }
    return "";
};

export const getRecommendations = (categoryName: string, recommendations?: RecommendationsReportHistory) => {
    const recommendation = recommendations?.latest?.savedTexts?.find(
        (recommendation) => recommendation.category === categoryName,
    );

    const currentRecommendation = {
        text: recommendation?.text,
        date: recommendation?.checkupDate,
    };

    const currentRecommendations = flatMap(recommendations?.history, (recommendation) =>
        recommendation.savedTexts?.filter((savedText) => savedText.category === categoryName),
    );
    const currentRecommendationHistory = {
        firstYear: dayjs(currentRecommendations?.[currentRecommendations.length - 1]?.checkupDate).format("YYYY"),
        lastYear: dayjs(currentRecommendations?.[0]?.checkupDate).format("YYYY"),
        recommendations: currentRecommendations,
    };

    return { currentRecommendation, currentRecommendationHistory };
};

export const getBloodpanelConspicuousnessValue = (bloodpanelValue?: BloodpanelReportValue) => {
    if (!bloodpanelValue) {
        console.error("getBloodpanelConspicuousnessValue: No bloodpanelValue given");
        return;
    }

    if (bloodpanelValue.valueType === "numeric" && typeof bloodpanelValue.valueNumeric === "number") {
        if (
            bloodpanelValue.rangeType === "greaterThan" &&
            bloodpanelValue.rangeFrom !== undefined &&
            bloodpanelValue.valueNumeric < bloodpanelValue.rangeFrom
        ) {
            return "conspicuous";
        } else if (
            bloodpanelValue.rangeType === "lowerThan" &&
            bloodpanelValue.rangeTo !== undefined &&
            bloodpanelValue.valueNumeric > bloodpanelValue.rangeTo
        ) {
            return "conspicuous";
        } else {
            return "normal";
        }
    }

    return "normal";
};
