import { Divider, Switch } from "@mui/material";
import { Field, useFormikContext } from "formik";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { TemplateTexts } from "../../network/APITypes";
import { CustomSelect } from "../ui/CustomSelect";
import { CustomSwitch } from "../ui/CustomSwitch";
import { CustomTextField } from "../ui/CustomTextField";
import { Colors } from "../util/Colors";
import { getRecommendationSiteOptions } from "../util/helpers";

type IProps = {
    disabled?: boolean;
};

const TrainingDetailsContainer = styled("div")({
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#FDFDFD",
    padding: 16,
});

const TrainingDetailsLabel = styled("div")({
    marginRight: 12,
    fontSize: 16,
    fontWeight: 400,
});

const exerciseOptions = [
    {
        value: "normal",
        label: "common.normal",
    },
    {
        value: "conspicuous",
        label: "common.conspicuous",
    },
];

export const ExerciseRecommendation = ({ disabled }: IProps) => {
    const { values, setFieldValue } = useFormikContext<any>();

    const handleChangeHeartRate = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const templateTexts = getRecommendationSiteOptions(values).exercise.templateTexts;

        const templateText = templateTexts?.find(
            (item) => item.targetValue === `exerciseErgometric.heartRate.${event.target.value}`,
        )?.templateText;

        const percentTemplateText = templateTexts?.find(
            (item) => item.targetValue === "exerciseErgometric.percent.lt100",
        )?.templateText;

        setFieldValue("textInput", `${percentTemplateText ?? ""}${percentTemplateText ? "\n\n" : ""}${templateText}`);
    };

    const handleChangeDisplayTraining = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue("displayTraining", event.target.checked);
    };

    return (
        <>
            <div style={{ padding: 16 }}>
                <Field
                    component={CustomSelect}
                    onChange={handleChangeHeartRate}
                    label={t("screen.patientInformation.field.exercise.dropdownLabel")}
                    name="heartRate"
                    type="input"
                    disabled={disabled}
                    options={exerciseOptions}
                    ignoreHeaderLabel={false}
                    style={{ marginBottom: 16 }}
                />
                <Field name="textInput" component={CustomTextField} minRows={3} disabled={disabled} />
            </div>
            <Divider />
            <TrainingDetailsContainer>
                <div style={{ display: "flex" }}>
                    <TrainingDetailsLabel>
                        {t("screen.patientInformation.field.exercise.trainingDetails")}
                    </TrainingDetailsLabel>
                    <Field
                        component={CustomSwitch}
                        onChange={handleChangeDisplayTraining}
                        name="displayTraining"
                        defaultChecked={values.displayTraining}
                        disabled={disabled}
                    />
                </div>
            </TrainingDetailsContainer>
        </>
    );
};
