import { Divider, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/styles";
import { ReactComponent as EditIcon } from "../../assets/icons/ic_edit.svg";
import { ReactComponent as ViewIcon } from "../../assets/icons/ic_show_password.svg";
import { IHeaderField } from "../ui/TableHeader";
import { TableLabel } from "../ui/TableLabel";
import { TableLabelName } from "../ui/TableLabelName";
import { Colors } from "../util/Colors";
import { formatDate } from "../util/helpers";

type IProps = {
    firstName?: string;
    lastName?: string;
    checkupDate: string;
    checkupDoctor: string;
    headerFields: IHeaderField[];
    onClickEditCheckup?: () => void;
    showDivider: boolean;
    onClickCheckupReport?: () => void;
};

const StyledTableCell = styled(TableCell)({
    backgroundColor: Colors.LIGHT_BACKGROUND,
});

export const PatientCheckupRow = ({
    firstName,
    lastName,
    checkupDate,
    checkupDoctor,
    headerFields,
    onClickEditCheckup,
    showDivider,
    onClickCheckupReport,
}: IProps) => (
    <>
        <TableRow>
            {headerFields.map((headerField) => {
                let label = "";

                if (headerField.column === "name") {
                    return (
                        <StyledTableCell key={headerField.column}>
                            <TableLabelName style={{ fontWeight: 400 }}>{lastName ?? ""}</TableLabelName>
                            <TableLabelName style={{ color: Colors.TEXT }}>{firstName ?? ""}</TableLabelName>
                        </StyledTableCell>
                    );
                } else if (headerField.column === "lastCheckup") {
                    label = formatDate(checkupDate);
                } else if (headerField.column === "doctor") {
                    return (
                        <StyledTableCell key={headerField.column}>
                            <TableLabelName style={{ fontWeight: 400 }}>{checkupDoctor}</TableLabelName>
                        </StyledTableCell>
                    );
                } else if (headerField.column === "addPatient") {
                    return (
                        <StyledTableCell key={headerField.column}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                {onClickCheckupReport && (
                                    <ViewIcon
                                        style={{ cursor: "pointer", marginRight: 16 }}
                                        onClick={onClickCheckupReport}
                                    />
                                )}
                                {onClickEditCheckup && (
                                    <EditIcon onClick={onClickEditCheckup} style={{ cursor: "pointer" }} />
                                )}
                            </div>
                        </StyledTableCell>
                    );
                }

                return (
                    <StyledTableCell key={headerField.column}>
                        <TableLabel style={{ fontWeight: 400 }}>{label}</TableLabel>
                    </StyledTableCell>
                );
            })}
        </TableRow>
        {showDivider && (
            <TableRow>
                <TableCell colSpan={headerFields.length} style={{ padding: 0 }}>
                    <Divider style={{ height: 4, backgroundColor: Colors.BORDER_LIGHT, width: "100%" }} />
                </TableCell>
            </TableRow>
        )}
    </>
);
