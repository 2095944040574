import { styled } from "@mui/system";
import { Colors } from "../util/Colors";
import { StyledTableCell } from "./StyledTableCell";

export const TableHeaderCell = styled(StyledTableCell)({
    fontWeight: 500,
    fontSize: 12,
    color: Colors.DISABLED,
    lineHeight: "14px",
    height: 54,
    padding: "12px 16px",
});
