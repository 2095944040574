import { Close } from "@mui/icons-material";
import { ReactComponent as AddIcon } from "../../../assets/icons/ic_add.svg";
import { Fab, InputAdornment, Table, TableBody, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { FormikValues } from "formik";
import { compact, debounce } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { generalStore } from "../../../stores/GeneralStore";
import { patientStore } from "../../../stores/PatientStore";
import { IPatient } from "../../../types";
import { history } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { IHeaderField, TableHeader } from "../../ui/TableHeader";
import { TablePagination } from "../../ui/TablePagination";
import { Colors } from "../../util/Colors";
import { CreateNewCheckupDialog } from "../CreateNewCheckupDialog";
import { PatientCheckupRow } from "../PatientCheckupRow";
import { PatientRow } from "../PatientRow";
import { CustomDialog } from "../../ui/CustomDialog";
import { ChangePasswordDialog } from "../../ui/ChangePasswordDialog";

const CHEVRON_COLUMN_WIDTH = 96;
const VIEW_HISTORY_COLUMN_WIDTH = 64;
const CHANGE_PASSWORD_COLUMN_WIDTH = 64;
const ADD_PATIENT_COLUMN_WIDTH = 64;
const ROWS_PER_PAGE = 12;
const SEARCH_DEBOUNCE_MS = 500;

const SiteContainer = styled("div")({
    padding: "48px 108px",
    backgroundColor: Colors.BACKGROUND,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

const StyledTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        borderRadius: 12,
    },
});

// https://stackoverflow.com/questions/628301/css3s-border-radius-property-and-border-collapsecollapse-dont-mix-how-can-i
const StyledTable = styled(Table)({
    borderStyle: "hidden",
    boxShadow: `0 0 0 1px ${Colors.TABLE_BORDER}`,
    borderRadius: 12,
    backgroundColor: Colors.WHITE,
    marginTop: 16,

    "& tr:last-child td:first-child": {
        borderBottomLeftRadius: 12,
    },

    "& tr:last-child td:last-child": {
        borderBottomRightRadius: 12,
    },
});

const StyledFab = styled(Fab)({
    position: "fixed",
    bottom: 35,
    right: 35,
});

const EmptyState = () => <div style={{ marginTop: 32 }}>{t("screen.overview.empty")}</div>;

const headerFields: IHeaderField[] = [
    { column: "expandCell", style: { width: CHEVRON_COLUMN_WIDTH } },
    { column: "name", label: "screen.overview.table.label.name", style: { textAlign: "left", width: 280 } },
    { column: "patientId", label: "screen.overview.table.label.patientId" },
    { column: "sex", label: "screen.overview.table.label.sex" },
    { column: "lastCheckup", label: "screen.overview.table.label.lastCheckup" },
    { column: "totalCheckups", label: "screen.overview.table.label.totalCheckups" },
    { column: "doctor", label: "screen.overview.table.label.doctor", style: { textAlign: "left" } },
    { column: "history", style: { width: VIEW_HISTORY_COLUMN_WIDTH } },
    { column: "changePassword", style: { width: CHANGE_PASSWORD_COLUMN_WIDTH } },
    { column: "addPatient", style: { width: ADD_PATIENT_COLUMN_WIDTH } },
];

export const PatientsOverviewSite = observer(() => {
    const [patients, setPatients] = React.useState<IPatient[]>([]);
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalCount] = React.useState(0);
    const [searchKeyword, setSearchKeyword] = React.useState("");
    const [debouncedSearchKeyword, setDebouncedSearchKeyword] = React.useState("");
    const [isCreateNewCheckupDialogOpen, setIsCreateNewCheckupDialogOpen] = React.useState(false);
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = React.useState(false);
    const [changePasswordDialogPatient, setChangePasswordDialogPatient] = React.useState<IPatient | null>(null);

    const prevKeywordRef = React.useRef(searchKeyword);

    const loadPatients = React.useCallback(async () => {
        try {
            if (prevKeywordRef.current !== debouncedSearchKeyword) {
                // set new previous value
                prevKeywordRef.current = debouncedSearchKeyword;
                if (page !== 1) {
                    // force first page and return, the effect will pick up the changed page and rerun
                    setPage(1);
                    return;
                }
            }

            const patientCheckups = await API.getPatientCheckupOverview({
                limit: ROWS_PER_PAGE,
                offset: (page - 1) * ROWS_PER_PAGE,
                query: debouncedSearchKeyword,
            });

            const initialPatients = patientCheckups.list?.map((patient) => {
                if (patient.checkupList) {
                    return {
                        ...patient,
                        lastCheckup: patient.checkupList[0].date,
                        totalCheckups: patient.checkupList.length,
                        isExpanded: false,
                        locumDoctor: patient.checkupList[0].locumDoctor,
                        patientId: patient.patientId ?? "",
                        checkupList: patient.checkupList ?? [],
                    };
                }
                return undefined;
            });

            const sanitizedPatients = compact(initialPatients);

            setTotalCount(patientCheckups.total);

            setPatients(sanitizedPatients);
        } catch (error) {
            generalStore.setError(t("error.load"), error);
        }
    }, [page, debouncedSearchKeyword]);

    React.useEffect(() => {
        try {
            generalStore.isLoading = true;
            loadPatients();
        } catch (error) {
            generalStore.setError(t("error.load"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [loadPatients, page]);

    const handleChangePage = (page: number) => {
        setPage(page);
    };

    // useCallback doesn't know which deps are needed, imo unnecessary warning in this case
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeSearchKeywordDebounced = React.useCallback(
        debounce((value) => {
            setDebouncedSearchKeyword(value);
        }, SEARCH_DEBOUNCE_MS),
        [],
    );

    const handleChangeSearchKeyword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;

        setSearchKeyword(value);
        handleChangeSearchKeywordDebounced(value);
    };

    const handleResetSearchKeyword = () => {
        setSearchKeyword("");
        setDebouncedSearchKeyword("");
    };

    const handleCreateNewCheckup = async (values: FormikValues) => {
        if (values.email && values.password && values.patientId) {
            try {
                const checkup = await API.createNewCheckup({
                    email: values.email,
                    password: values.password,
                    patientId: values.patientId,
                });

                patientStore.checkup = checkup;
                patientStore.checkupId = checkup.checkupId;

                history.push(Routes.DOCTOR.CHECKUP.replace(":checkupId", checkup.checkupId));
            } catch (error) {
                generalStore.setError(t("error.createCheckup"), error);
            }
        }
        setIsCreateNewCheckupDialogOpen(false);
    };

    const handleCloseCreateNewCheckupDialog = () => {
        setIsCreateNewCheckupDialogOpen(false);
    };

    const handleClickOpenCreateNewCheckupDialog = () => {
        setIsCreateNewCheckupDialogOpen(true);
    };

    const handleClickExpand = (id: string) => {
        const patientItems = patients.map((patient) =>
            patient.patientId === id ? { ...patient, isExpanded: !patient.isExpanded } : patient,
        );
        setPatients(patientItems);
    };

    const handleClickCheckup = (checkupId: string) => {
        history.push(Routes.DOCTOR.CHECKUP.replace(":checkupId", checkupId));
    };

    const handleClickCreateCheckup = async (patientId: string) => {
        const newCheckup = await API.createNewCheckupForPatient({ patientId: patientId });
        history.push(Routes.DOCTOR.CHECKUP.replace(":checkupId", newCheckup.checkupId));
    };

    const handleClickChangePassword = async (patient: IPatient) => {
        setChangePasswordDialogPatient(patient);
        setChangePasswordDialogOpen(true);
    };

    const handleClickCheckupReport = (checkupId: string) => {
        history.push(Routes.CHECKUP_REPORT.replace(":checkupId", checkupId));
    };

    const handleClickHistory = (checkupId: string) => {
        history.push(Routes.DOCTOR.HISTORY.replace(":checkupId", checkupId));
    };

    const tableBody = (
        <TableBody>
            {patients.map((patient: IPatient, index) => {
                const isLastRow = index === patients.length - 1;

                const releasedCheckups = patient.checkupList.filter((checkup) => checkup.released);

                return (
                    <React.Fragment key={patient.patientId}>
                        <PatientRow
                            patient={patient}
                            headerFields={headerFields}
                            isLastRow={isLastRow}
                            onClickExpand={handleClickExpand}
                            onClickCreateCheckup={handleClickCreateCheckup}
                            onClickChangePassword={handleClickChangePassword}
                            onClickHistory={
                                releasedCheckups.length > 1
                                    ? () => handleClickHistory(releasedCheckups[0].checkupId ?? "")
                                    : undefined
                            }
                        />
                        {patient.isExpanded &&
                            patient.checkupList.map((checkup, checkupIndex) => {
                                const isLastCheckupRow = checkupIndex === patient.totalCheckups - 1;
                                const checkupId = checkup.checkupId;

                                return (
                                    <PatientCheckupRow
                                        key={checkupId}
                                        firstName={checkup.firstName}
                                        lastName={checkup.lastName}
                                        checkupDate={checkup.date ?? ""}
                                        checkupDoctor={checkup.locumDoctor ?? ""}
                                        headerFields={headerFields}
                                        onClickEditCheckup={checkupId ? () => handleClickCheckup(checkupId) : undefined}
                                        onClickCheckupReport={
                                            checkup.released && checkupId
                                                ? () => handleClickCheckupReport(checkupId)
                                                : undefined
                                        }
                                        showDivider={!isLastRow && isLastCheckupRow}
                                    />
                                );
                            })}
                    </React.Fragment>
                );
            })}
        </TableBody>
    );

    return (
        <SiteContainer>
            <StyledTextField
                placeholder={t("screen.overview.table.searchbar.placeholder")}
                variant="outlined"
                fullWidth
                onChange={handleChangeSearchKeyword}
                value={searchKeyword}
                InputProps={{
                    endAdornment: searchKeyword ? (
                        <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={handleResetSearchKeyword}>
                            <Close color="primary" fontSize="small" />
                        </InputAdornment>
                    ) : null,
                }}
            />
            {totalCount === 0 ? (
                <EmptyState />
            ) : (
                <>
                    <StyledTable>
                        <TableHeader headerFields={headerFields} />
                        {tableBody}
                    </StyledTable>
                    <TablePagination
                        totalCount={totalCount}
                        rowsPerPage={ROWS_PER_PAGE}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                </>
            )}
            <StyledFab onClick={handleClickOpenCreateNewCheckupDialog} color="primary">
                <AddIcon fill={Colors.WHITE} />
            </StyledFab>
            <CreateNewCheckupDialog
                isOpen={isCreateNewCheckupDialogOpen}
                onSubmit={handleCreateNewCheckup}
                onClose={handleCloseCreateNewCheckupDialog}
            />
            {changePasswordDialogPatient && (
                <ChangePasswordDialog
                    patientId={changePasswordDialogPatient.patientId}
                    open={changePasswordDialogOpen}
                    onClose={() => {
                        setChangePasswordDialogOpen(false);
                    }}
                    onSuccess={() => {
                        setChangePasswordDialogOpen(false);
                    }}
                />
            )}
        </SiteContainer>
    );
});
