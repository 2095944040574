import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { isEqual, merge, omit } from "lodash";
import { makeAutoObservable } from "mobx";
import { getHasSavedRecommendation } from "../components/patients/CheckupInput";
import { Checkup, CheckupReport, DoctorsRecommendations } from "../network/APITypes";
import { ICheckupItemStatus, ISectionName, ITab } from "../types";
dayjs.extend(localizedFormat);

type ICheckupStatus = {
    [key in ISectionName]: ICheckupItemStatus;
};

const emptyCheckupStatus: ICheckupStatus = {
    productType: "empty",
    patientInfo: "empty",
    bloodpanel: "empty",
    bloodpanelRecommendation: "empty",
    stoolSample: "empty",
    stoolSampleRecommendation: "empty",
    bia: "empty",
    biaResult: "empty",
    ecg: "empty",
    ecgResult: "empty",
    relaxation: "empty",
    spirometric: "empty",
    spirometricRecommendation: "empty",
    exercise: "empty",
    exerciseErgometric: "empty",
    ultrasound: "empty",
    cardiovascularRisk: "empty",
    lifestyleParameters: "empty",
    patientStatus: "empty",
    clinicCheckup: "empty",
    vaccinations: "empty",
    lifestyle: "empty",
    diagnosis: "empty",
    medication: "empty",
    furtherCheckup: "empty",
};

class PatientStore {
    checkupStatus = emptyCheckupStatus;
    checkupId?: string;
    checkup?: Checkup;
    checkupReport?: CheckupReport;
    checkupRecommendations?: DoctorsRecommendations;
    firstName? = "";
    lastName? = "";
    totalCheckups = 0;
    previousCheckups = [dayjs()];

    constructor() {
        makeAutoObservable(this);
    }

    setCheckupSectionFinished(name: string) {
        this.checkupStatus[name] = "finished";
    }

    setCheckupSectionEditable(name: string) {
        this.checkupStatus[name] = "edited";
    }

    resetCheckupStatus() {
        this.checkupStatus = emptyCheckupStatus;
    }

    setPatientName(firstName?: string, lastName?: string) {
        this.firstName = firstName ?? this.firstName;
        this.lastName = lastName ?? this.lastName;
    }

    setItemCheckupStatus({
        currentTab,
        itemStatus,
        checkupCategory,
        initialValues,
        itemName,
        checkupRecommendations,
        released,
    }: {
        currentTab: ITab;
        itemStatus: ICheckupItemStatus;
        checkupCategory: any;
        initialValues: any;
        itemName: ISectionName;
        checkupRecommendations?: DoctorsRecommendations;
        released?: boolean;
    }) {
        if (itemStatus === "finished" || (released && itemStatus !== "edited")) {
            this.setCheckupSectionFinished(itemName);
        }

        if (itemStatus !== "edited") {
            if (currentTab === "input") {
                if (itemName === "ecgResult") {
                    if (checkupCategory && checkupCategory?.value) {
                        this.setCheckupSectionFinished(itemName);
                    }
                }

                const bloodpanelKeysToOmit = itemName === "bloodpanel" ? ["filesHL7", "attachments"] : [];
                const patientInformationKeysToOmit =
                    itemName === "patientInfo" ? ["patientId", "birthday", "email", "checkupDate"] : [];

                const keysToOmit = ["checkupDate", ...bloodpanelKeysToOmit, ...patientInformationKeysToOmit];

                if (
                    checkupCategory &&
                    !isEqual(merge({}, initialValues, omit(checkupCategory, keysToOmit)), initialValues)
                ) {
                    this.setCheckupSectionFinished(itemName);
                }
            }

            if (getHasSavedRecommendation(itemName, currentTab, checkupRecommendations)) {
                this.setCheckupSectionFinished(itemName);
            }
        }
    }
}

const patientStore = new PatientStore();

export { patientStore };
