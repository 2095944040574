import { Divider, Link, styled } from "@mui/material";
import * as React from "react";
import HeartsBackgroundImage from "../../../assets/images/hearts_background.png";
import { t } from "../../../i18n/util";
import { ILinkItem } from "../../../types";
import { Colors } from "../../util/Colors";
import { ImageLogo } from "../../util/Images";
import privacyPolicyPdf from "../../../assets/documents/Datenschutzbestimmungen.pdf";

const Topbar = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px",
});

const TopbarLinkContainer = styled("div")({
    border: "1px solid #EDEDED",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 600,
});

const SiteContainer = styled("div")({
    padding: "0 24px 24px 24px",
    display: "flex",
    flexGrow: 1,
});

const BackgroundImage = styled("div")({
    marginRight: 16,
    width: "50%",
    flexGrow: 1,
    borderRadius: 20,
    backgroundImage: `url(${HeartsBackgroundImage}), linear-gradient(148.93deg, #4CD061 -11.59%, #4CA1D0 103.47%)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
});

const ImageContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100%",
});

const TitleContainer = styled("div")({
    padding: 56,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
});

const Title = styled("div")({
    color: "#FFFFFF",
    fontSize: 88,
    fontWeight: 900,
    lineHeight: "initial",
    whiteSpace: "pre-line",
});

const Subtitle = styled("div")({
    fontWeight: 600,
    fontSize: 20,
    color: "#FFFFFF",
});

const FooterLinkContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    height: "100%",
    paddingLeft: 56,
    whiteSpace: "break-spaces",
});

const AuthContainer = styled("div")({
    backgroundColor: "#F5F5F5",
    width: "50%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
});

const topbarLinkItems: ILinkItem[] = [
    {
        label: "screen.auth.links.about",
        url: "https://www.vorsorgeinstitut.at/ueber-uns/dr-petra-fabritz",
    },
    {
        label: "screen.auth.links.products",
        url: "https://www.vorsorgeinstitut.at/preise",
    },
    {
        label: "screen.auth.links.contact",
        url: "https://www.vorsorgeinstitut.at/ueber-uns/kontakt",
    },
];

// TODO: add actual items
const footerLinkItems: ILinkItem[] = [
    {
        label: "screen.auth.links.privacyPolice",
        url: privacyPolicyPdf,
    },
    {
        label: "screen.auth.links.imprint",
        url: "https://www.vorsorgeinstitut.at/impressum",
    },
];

export const AuthContainerSite = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <Topbar>
                <ImageLogo style={{ maxWidth: 95 }} />
                <TopbarLinkContainer>
                    {topbarLinkItems.map((linkItem, index) => (
                        <React.Fragment key={index}>
                            <Link
                                style={{ padding: 12, color: Colors.TEXT_BOLD }}
                                underline="none"
                                href={linkItem.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t(linkItem.label)}
                            </Link>
                            {index < topbarLinkItems.length - 1 ? <Divider orientation="vertical" /> : null}
                        </React.Fragment>
                    ))}
                </TopbarLinkContainer>
            </Topbar>
            <SiteContainer>
                <BackgroundImage>
                    <ImageContainer>
                        <TitleContainer>
                            <Title>{t("screen.auth.image.title", { br: (<br />) as any })}</Title>
                            <Subtitle>{t("screen.auth.image.subtitle")}</Subtitle>
                        </TitleContainer>
                        <div style={{ width: "100%", height: 100 }}>
                            <Divider style={{ borderColor: "#FFFFFF3D" }} />
                            <FooterLinkContainer>
                                {footerLinkItems.map((footerItem, index) => (
                                    <React.Fragment key={index}>
                                        <Link
                                            color={Colors.WHITE}
                                            href={footerItem.url}
                                            underline="none"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t(footerItem.label)}
                                        </Link>
                                        {index < footerLinkItems.length - 1 && (
                                            <span style={{ margin: "0 6px" }}>•</span>
                                        )}
                                    </React.Fragment>
                                ))}
                            </FooterLinkContainer>
                        </div>
                    </ImageContainer>
                </BackgroundImage>
                <AuthContainer>{children}</AuthContainer>
            </SiteContainer>
        </>
    );
};
